import React from 'react';
import {connect} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import DialogTitleWithClose from '../../base/dialogTitleWithClose';
import {setCartRenameDialogState, setCart} from '../../../redux/cart/cartAction';
import withCartApi from '../../../api/cartApi';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import withSnackbar from '../../../service/withSnackbar';
import i18n from '../../../i18n';

class CartRenameDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cartId: '',
            cartName: '',
        };
    };

    componentDidMount() {
        this.setState({
            cartId: this.props.cartContext.cartId,
            cartName: this.props.cartContext.cartName,
        });
    };

    handleNameChange = e => {
        this.setState({ cartName: e.target.value });
    };

    handleCartRenameClose = () => {
        this.props.setCartRenameDialogState(false);
    };

    onInputSubmit = e => {
        e.preventDefault();

        if (!this.state.cartName) {
            this.props.enqueueWarningSnackbar(i18n.t('error_on_rename_cart_no_empty_content'));

            return;
        }

        if (/[&_'"<>?]/.test(this.state.cartName)) {
            this.props.enqueueWarningSnackbar(i18n.t('error_on_rename_cart_invalid_characters', {
                chars: '" , & , ? , \' , _ , < , >'
            }));

            return;
        }

        if (this.props.cartName === this.state.cartName) {
            return;
        }

        this.props.renameCart(this.state.cartId, this.state.cartName).then(() => {
            this.props.updateCurrentCart(false, this.state.cartId);
            this.props.onUpdate();
        });

        this.props.setCartRenameDialogState(false);
    };

    render() {
        const { t } = this.props;

        return (
            <Dialog
                open={this.props.cartRenameDialogShow}
                onClose={this.handleCartRenameClose}
                aria-labelledby="cart-rename-dialog-title"
                aria-describedby="cart-rename-dialog-description"
            >
                <DialogTitleWithClose onClose={this.handleCartRenameClose}>
                    {t('cart_rename_title')}
                </DialogTitleWithClose>
                <DialogContent>
                    <form onSubmit={this.onInputSubmit} noValidate>
                        <TextField
                            required
                            label={t('cart_rename_label')}
                            variant="filled"
                            value={this.state.cartName}
                            inputProps={{ maxLength: 64 }}
                            onChange={this.handleNameChange}
                            error={!this.state.cartName}
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={this.onInputSubmit}
                    >
                        {t('cart_rename_submit')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    cartRenameDialogShow: state.cart.cartRenameDialogShow,
});

const mapDispatchToProps = {
    setCartRenameDialogState,
    setCart,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withSnackbar,
    withCartApi,
    withTranslation(),
)
(CartRenameDialog);
