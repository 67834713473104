import React, {Fragment} from 'react';
import {compose} from 'redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import {withStyles} from '@mui/styles';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import {LightBulbIcon} from '../../../base/additionalIcons';

const useStyles = (theme) => (
    {
        tableCell: {
            borderLeft: `1px solid ${theme.palette.grey[300]}`,
            borderRight: `1px solid ${theme.palette.grey[300]}`,
        },
        tableHead: {
            borderBottomColor: theme.palette.grey[300]
        }
    });

class ValidityMatrix extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            records: []
        };
    }

    getTableHead() {
        const {data, classes} = this.props;

        if (!data.head && !data.head.cols) {
            return null;
        }

        return <TableHead className={classes.tableHead}>
            <TableRow>
                {data.head.cols.map((el, i) => {
                    return <TableCell key={i} align="left">{el}</TableCell>
                })}
            </TableRow>
        </TableHead>
    }

    render() {
        const { classes, data } = this.props;

        return (
            <Fragment>
                <TableContainer>
                    <Table aria-label="ValidityMatrix table" size={'small'} width={''}>
                        {this.getTableHead()}

                        <TableBody>
                            {data.content.map((row, i) => {
                                return <TableRow key={i}>
                                    {row.cols.map((cell, j) => {
                                        return <TableCell key={j}
                                                          align="left"
                                                          className={classes.tableCell}>
                                            <Typography variant="body2">
                                                {cell}
                                            </Typography>
                                        </TableCell>
                                    })}
                                </TableRow>;
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {data.remark &&
                    <Box px={2} mt={4} mb={2} display={'flex'} component={'div'}>
                        <Box pr={1}>
                            <LightBulbIcon />
                        </Box>
                        <Typography variant="body2">
                            {data.remark}
                        </Typography>
                    </Box>
                }
            </Fragment>
        );
    }
}


export default compose(
    withStyles(useStyles),
)
(ValidityMatrix);