import React, {Fragment} from 'react';
import {compose} from 'redux';
import withCartApi from '../../api/cartApi';
import {Button, Typography} from '@mui/material';
import {withTranslation} from 'react-i18next';
import Container from '../base/container';
import Box from '@mui/material/Box';
import {trackPromise} from 'react-promise-tracker';
import {loadingAreas} from '../../config/loadingAreas';
import Link from '@mui/material/Link';
import {CHANGE_BILLING_ADDRESS_URL, CHANGE_SHIPPING_ADDRESS_URL} from '../../config/urlConfig';
import ModelSubheader from "../catalog/restriction/restrictionSubheader";
import withMenu from "../../service/withMenu";

class Addresses extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shippingAddresses: [],
            billingAddresses: [],
        };

        this.onBackClick = this.onBackClick.bind(this);
    }

    componentDidMount() {
        trackPromise(
            new Promise(resolve => {
                Promise.all(
                    [
                        this.props.getAddresses('shippingAddresses'),
                        this.props.getAddresses('billingAddresses'),
                    ]).then((responses) => {
                    this.setState({shippingAddresses: responses[0]});
                    this.setState({billingAddresses: responses[1]});
                    resolve();

                }).catch((error) => {
                    console.error('Error happened on get addresses', error);
                    resolve();
                });
            })
            , loadingAreas.global);
    }

    onBackClick() {
        this.props.routeToRootComponent();
    }

    getAddressBlock(addresses) {
        return addresses.map(address => (
            <Box mb={2} key={address.addressId + '_box'}>
                {address.addressLines.map((line, index) => (
                    <Typography
                        key={address.addressId + '_' + index}
                        variant={'body2'}
                    >
                        {line}
                    </Typography>
                ))}
            </Box>
        ));
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <Container disableGutters>
                    <ModelSubheader
                        title={t('addresses_title')}
                        onBackClick={this.onBackClick}
                    />
                </Container>
                <Container paddingY overflow>
                    <Box mb={1}>
                        <Typography component='h2' variant='h5'>{t('shipping_addresses_title')}</Typography>
                    </Box>
                    {this.getAddressBlock(this.state.shippingAddresses)}

                    <Box mb={1} mt={4}>
                        <Typography component='h2' variant='h5'>{t('billing_addresses_title')}</Typography>
                    </Box>
                    {this.getAddressBlock(this.state.billingAddresses)}

                    <Box mt={5}>
                        <Typography variant='body1'>{t('addresses_description')}</Typography>
                    </Box>
                    <Box mt={2}>
                        <Button
                            variant="contained"
                            component={Link}
                            underline="none"
                            href={CHANGE_SHIPPING_ADDRESS_URL}
                            target="_blank"
                            sx={{ width: '100%' }}
                        >
                            {t('shipping_addresses_to_desktop')}
                        </Button>
                    </Box>
                    <Box mt={2}>
                        <Button
                            variant="contained"
                            component={Link}
                            underline="none"
                            href={CHANGE_BILLING_ADDRESS_URL}
                            target="_blank"
                            sx={{ width: '100%' }}
                        >
                            {t('billing_addresses_to_desktop')}
                        </Button>
                    </Box>
                </Container>
            </Fragment>
        );
    }
}

export default compose(
    withTranslation(),
    withMenu,
    withCartApi,
)
(Addresses);
