import {loadingAreas} from '../config/loadingAreas';
import Api from './api';
import {store} from '../redux/store';
import {enqueueErrorSnackbar} from '../redux/snackbar/snackbarAction';
import i18n from '../i18n';
import {trackPromise} from 'react-promise-tracker';

class appgtwApi {

    static getCatalogs() {
        return new Promise((resolve) => {
            trackPromise(
                Api().get(`/pl24-appgtw/ext/api/1.0/catalogs?lang=${i18n.language}`).then(response => {
                        if (!response || !response.data) {
                            resolve([]);
                        }

                        resolve(response.data.catalogs);
                    }).catch(error => {
                        store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_data_loading')));
                        resolve([]);
                    })
                , loadingAreas.catalog);
        });
    }

    // catmeta request
    static getBrand(url) {
        return new Promise(resolve => {
            trackPromise(
                Api().get(url)
                    .then(response => {
                        if (!response || !response.data || !response.data.data) {
                            // console.error('Error happened on getCatalogs: data not inside \'response.data.data\'');
                            resolve(null);
                            return;
                        }

                        resolve(response.data.data);
                    })
                    .catch(error => {
                        // console.error('error', error);
                        store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_data_loading')));
                        resolve(null);
                    })
                , loadingAreas.catalog);
        });
    }

    static getContentLinks() {
        return new Promise((resolve) => {
            trackPromise(
                Api().get('/pl24-appgtw/ext/api/1.0/legal/' + i18n.language)
                    .then(response => {
                        if (!response || !response.data || !response.data.legalDocuments) {
                            // console.error('Error happened on getContentLinks: data not inside \'response.data.legalDocuments\'');
                            resolve([]);
                            return;
                        }

                        resolve(response.data.legalDocuments);
                    })
                    .catch(error => {
                        // console.error('error', error);
                        store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_get_content_links')));
                        resolve([]);
                    })
                , loadingAreas.global);
        });
    }

    static getTranslations(textToTranslate) {
        return new Promise((resolve) => {
            trackPromise(
                Api().get('/pl24-appgtw/ext/api/1.0/translation/' + i18n.language + '?code=' + textToTranslate)
                    .then(response => {
                        // console.log('response', response);

                        if (!response || !response.data) {
                            // console.error('Error happened on getTranslations: data not inside \'response.data\'');
                            resolve([]);
                            return;
                        }

                        resolve(response.data);
                    })
                    .catch(error => {
                        // console.error('error', error);
                        store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_get_translations')));
                        resolve([]);
                    })
                , loadingAreas.global);
        });
    }
}

export default (appgtwApi);
