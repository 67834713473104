import React, {Fragment} from 'react';
import {compose} from 'redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import {Box} from '@mui/material';
import TextField from '@mui/material/TextField';
import {withTranslation} from 'react-i18next';
import withCartApi from '../../../api/cartApi';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

class ListOptions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            listOpen: false,
            originalCustomerReference: this.props.cart.customerReference,
            customerReference: this.props.cart.customerReference,
            originalUpsNumber: this.props.cart.upsNumber,
            upsNumber: this.props.cart.upsNumber,
            originalCustomerNumber: this.props.cart.dealerProperties?.customerNumber,
            customerNumber: this.props.cart.dealerProperties?.customerNumber,
            dealerId: this.props.cart.dealerProperties?.dealerId,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.originalCustomerNumber !== this.props.cart.dealerProperties?.customerNumber) {
            this.setState({
                originalCustomerNumber: this.props.cart.dealerProperties?.customerNumber,
                customerNumber: this.props.cart.dealerProperties?.customerNumber,
            });
        }
    }

    handleListToggleClick = () => {
        this.setState(state => ({listOpen: !state.listOpen}));
    };

    onSave = () => {
        this.props.setCartOptions(this.props.cart.cartId, this.state.customerReference, this.state.upsNumber).then(() => {
            let noSupplyCache = false;
            if (!this.props.cart.dealerProperties.customerNumberReadOnly) {

                this.props.setCustomerNumber(this.state.dealerId, this.state.customerNumber).then(() => {
                    //
                    if (this.state.originalCustomerNumber !== this.state.customerNumber) {
                        noSupplyCache = true;
                        this.setState({originalCustomerNumber: this.state.customerNumber})
                    }

                });
            }
            this.props.updateCurrentCart(noSupplyCache);
        });
    };

    onReset = () => {
        this.setState({
            customerReference: this.props.cart.customerReference,
            upsNumber: this.props.cart.upsNumber,
            customerNumber: this.props.cart.dealerProperties.customerNumber,
        });
    };

    render() {

        const {t, cart} = this.props;
        const {listOpen, customerReference, upsNumber, customerNumber} = this.state;

        return (
            <Fragment>
                <ListItem divider button onClick={this.handleListToggleClick}>
                    <ListItemText
                        primaryTypographyProps={{variant: 'h6'}}
                        primary={t('cart_options_title')}
                    />
                    {listOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </ListItem>

                <Collapse in={listOpen}>
                    <Box border={1} borderColor="border.main" borderTop={0} borderLeft={0} borderRight={0}>

                        <ListItem divider={false}>

                            <Grid container spacing={1} justifyContent="flex-end">

                                <Grid item xs={12} md={4}>

                                    <TextField
                                        disabled={this.props.readonly}
                                        fullWidth
                                        size="small"
                                        inputProps={{maxLength: 64}}
                                        label={t('cart_options_customer_reference_label')}
                                        value={customerReference}
                                        onChange={
                                            (e) => this.setState({
                                                customerReference: e.target.value,
                                            })
                                        }
                                    />

                                </Grid>

                                <Grid item xs={12} md={4}>

                                    <TextField
                                        disabled={this.props.readonly || (cart.dealerProperties && cart.dealerProperties.customerNumberReadOnly)}
                                        fullWidth
                                        size="small"
                                        inputProps={{maxLength: 16}}
                                        label={t('cart_options_customer_number_label')}
                                        value={customerNumber ? customerNumber : ''}
                                        onChange={
                                            (e) => this.setState({
                                                customerNumber: e.target.value,
                                            })
                                        }
                                    />

                                </Grid>

                                <Grid item xs={12} md={4}>

                                    <TextField
                                        disabled={this.props.readonly}
                                        fullWidth
                                        size="small"
                                        inputProps={{maxLength: 64}}
                                        label={t('cart_options_ups_number_label')}
                                        value={upsNumber}
                                        onChange={
                                            (e) => this.setState({
                                                upsNumber: e.target.value,
                                            })
                                        }
                                    />

                                </Grid>

                                {!this.props.readonly && (
                                    <Fragment>
                                        <Grid item xs={12} sm={3} md={2}>
                                            <Button variant="outlined"
                                                    onClick={this.onReset}>{t('cart_options_reset')}</Button>
                                        </Grid>

                                        <Grid item xs={12} sm={3} md={2}>
                                            <Button onClick={this.onSave}>{t('cart_options_save')}</Button>
                                        </Grid>
                                    </Fragment>
                                )}

                            </Grid>

                        </ListItem>
                    </Box>

                </Collapse>
            </Fragment>
        );
    }
}

export default compose(
    withTranslation(),
    withCartApi,
)(ListOptions);
