import React from 'react';
import { compose } from 'redux';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography,
    Container as MuiContainer
}  from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PartItem from './partItem';
import {withTranslation} from 'react-i18next';

class SubPartItems extends React.Component {
    render() {
        const { t, title, defaultExpanded, originalPart } = this.props;

        return (
            <Accordion defaultExpanded={defaultExpanded}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        {t(title)}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ px: 0 }}>
                    <MuiContainer maxWidth="md" sx={{ padding: '0 !important' }}>
                        {this.props.items.map((item, index) => (
                            <Box key={index} mb={3}>
                                <PartItem 
                                    item={item}
                                    originalPart={originalPart}
                                />
                            </Box>
                        ))}
                    </MuiContainer>
                </AccordionDetails>
            </Accordion>
        );
    }
}

export default compose(
    withTranslation(),
)(SubPartItems);