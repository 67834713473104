import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import BottomNavigation from '@mui/material/BottomNavigation';
import {Link, matchPath, withRouter} from 'react-router-dom';
import React from 'react';
import {withTranslation} from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import {groupMain, routeCatalogPart} from '../../config/routeItems';
import Container from '../base/container';
import Badge from '@mui/material/Badge';
import {withStyles} from '@mui/styles';
import {connect} from 'react-redux';
import Zoom from '@mui/material/Zoom';
import {compose} from 'redux';
import {setCartContext} from '../../redux/cart/cartAction';
import withCartApi from '../../api/cartApi';
import {palette} from '../../theme/baseTheme';

const useStyles = theme => ({
    dotBadgeInactive: {
        backgroundColor: theme.palette.white,
        top: '4px',
    },
    dotBadgeActive: {
        top: '4px',
    },
    appleBottomNotch: {
        paddingBottom: `env(safe-area-inset-bottom, 0px)`,
    }
});

class AppFooter extends React.Component {
    constructor(props) {
        super(props);

        this.timeouts = [];

        this.state = {
            activeNav: this.getActiveNavItem(),
            cartHasChanged: false,
            notificationHasChanged: false,
        };
    }

    componentDidMount() {
        const { history } = this.props;

        this.handleNavChange(this.getActiveNavItem());
        this.unregisterHistoryListener = history.listen(() => {
            this.handleNavChange(this.getActiveNavItem());
        });

        /**
         * On refresh (e.g. when getting redirected from the checkout of the cart back to the PWA), we have to check
         * if the current cart (cartContext) is still part of the available carts (is removed on ordering it).
         */
        if (this.props.currentBrand.id) {
            this.props.getCarts(this.props.currentBrand.id, false).then(carts => {
                if (!carts.some(cart => cart.cartId === this.props.cartContext.cartId)) {
                    this.props.setCartContext(null);
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cartItemsCount !== this.props.cartItemsCount) {
            this.setState({
                cartHasChanged: true,
            });
        }

        if (this.props.location !== prevProps.location) {
            this.handleNavChange(this.getActiveNavItem());
        }
    }

    componentWillUnmount() {
        if (this.unregisterHistoryListener) {
            this.unregisterHistoryListener();
        }

        this.timeouts.forEach(timeout => clearTimeout(timeout))
    }

    getActiveNavItem(path = this.props.location.pathname) {
        const matchedItem = groupMain.find(item => {
            return matchPath(path, {
                path: item.url(),
                strict: false,
            }) !== null;
        });

        return matchedItem ? matchedItem.key : '';
    }

    handleNavChange(newValue) {
        this.setState({ activeNav: newValue });
    }

    getBottomNavigationIcon(item) {
        const { classes } = this.props;
        const isActive = this.state.activeNav === item.key;
        let badgeContent;
        let addTransition;

        switch (item.key) {
            case 'cart':
                badgeContent = this.props.cartItemsCount;
                addTransition = this.state.cartHasChanged;

                if (addTransition) {
                    this.timeouts.push(setTimeout(() => {
                        this.setState({
                            cartHasChanged: false,
                        });
                    }, 200));
                }

                break;
            default:
                badgeContent = 0;
                break;
        }

        return (
            <Badge
                badgeContent={(parseInt(badgeContent) ? parseInt(badgeContent) : 0)}
                classes={{badge: !isActive ? classes.dotBadgeInactive : classes.dotBadgeActive}}
                overlap="rectangular"
                className={classes.icon}
            >
                <Zoom in={!addTransition}>
                    {item.icon}
                </Zoom>
            </Badge>
        );
    }

    getDisabledState(item) {
        switch (item.key) {
            case 'cart':
                return !this.props.currentBrand || !this.props.currentBrand.id || !this.props.cartContext.cartId;
            case 'changeCart':
                return !this.props.currentBrand || !this.props.currentBrand.id;
            case 'part':
                return !(this.props.currentVehicle && this.props.currentVehicle.link);
            default:
                return false;
        }
    };

    navigationTo(item) {
        if (item.key === 'cart') {
            return '/';
        }

        let url = item.url();

        if (url === routeCatalogPart.url() && this.props.partHistory.length) {
            url = this.props.partHistory[this.props.partHistory.length - 1];
        }

        return url;
    }

    onItemClick(e, item) {
        if (item.key !== 'cart') {
            return;
        }

        e.preventDefault();

        const { dealer, redirectToCart, selectDealer } = this.props;

        if (!dealer || !dealer.dealerId) {
            selectDealer();
        } else {
            redirectToCart();
        }
    };

    render() {
        const { t, classes } = this.props;

        return (
            <AppBar position="static" sx={{ backgroundColor: palette.primary.main }}>
                <Container maxWidth="md" disableGutters={true}>
                    <BottomNavigation
                        value={this.state.activeNav}
                        className={classes.appleBottomNotch}
                        onChange={(event, item) => this.handleNavChange(item)}>

                        {groupMain.map(item => (
                            <BottomNavigationAction
                                component={Link}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                                key={item.key}
                                label={t(item.label)}
                                value={item.key}
                                icon={this.getBottomNavigationIcon(item)}
                                to={this.navigationTo(item)}
                                disabled={this.getDisabledState(item)}
                                onClick={e => this.onItemClick(e, item)}
                            />
                        ))}

                    </BottomNavigation>
                </Container>
            </AppBar>
        );
    }
}

const mapStateToProps = state => ({
    cart: state.cart.cartContext,
    dealer: state.cart.dealer,
    cartItemsCount: state.cart.cartItemsCount,
    currentVehicle: state.catalog.currentVehicle,
    currentBrand: state.catalog.currentBrand,
    partHistory: state.browserHistory.partHistory,
});

const mapDispatchToProps = dispatch => ({
    setCartContext: payload => dispatch(setCartContext(payload))
})

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withCartApi,
    withRouter,
    withTranslation(),
    withStyles(useStyles),
)
(AppFooter);
