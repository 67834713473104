import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import SmallIconButton from '../base/smallIconButton';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {routeCatalogPart, routeCatalogVehicle, routeSearchPart, routeSearchVehicle} from '../../config/routeItems';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {
    Box, 
    Typography
} from '@mui/material';
import withSnackbar from '../../service/withSnackbar';
import withSearchApi from '../../api/searchApi';
import theme from '../../theme/theme';

class SearchTrigger extends React.Component {
    routeToSearch() {
        const { history, t } = this.props;

        if (history.location.pathname.includes(routeCatalogVehicle.url())) {
            if (!this.props.getVehicleSearchUrl()) {
                this.props.enqueueInfoSnackbar(t('vehicle_search_not_available'));

                return;
            }

            history.push(routeSearchVehicle.url());

        } else if (history.location.pathname.includes(routeCatalogPart.url())) {
            if (!this.props.getPartSearchUrl()) {
                this.props.enqueueInfoSnackbar(t('part_search_not_available'));

                return;
            }

            history.push(routeSearchPart.url());
        }
    }

    render() {
        const { label = "", styles } = this.props;

        return (
            <Box 
                onClick={() => this.routeToSearch()}
                sx={{
                    ...styles,
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
            }}>
                <Typography variant="h6" component="h1" sx={{
                    whiteSpace: 'nowrap'
                }}>
                    {label}
                </Typography>

                <SmallIconButton
                    insideBox
                    color="inherit"
                >
                    <SearchIcon/>
                </SmallIconButton>

                <Box sx={{
                    position: 'absolute',
                    left: 0,
                    right: theme.spacing(2),
                    bottom: theme.spacing(2.25),
                    height: 0,
                    borderBottom: `2px solid ${theme.palette['grey200']}`,
                }}/>
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    currentBrand: state.catalog.currentBrand,
});

const mapDispatchToProps = () => ({});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withSearchApi,
    withTranslation(),
    withSnackbar,
    withRouter,
)
(SearchTrigger);
