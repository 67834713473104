import * as React from 'react';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import {routeDealer, routeDealerManagement, routeLastDealers, routeMenu} from "../config/routeItems";

function withMenu(WrappedComponent) {
    class HOC extends React.Component {

        routeToRootComponent = () => {
            const {history} = this.props;
            history.push(routeMenu.url());
        }

        routeToDealerManagement = () => {
            const {history} = this.props;
            history.push(routeDealerManagement.url());
        }

        routeToLastDealers = (serviceName) => {
            const {history} = this.props;
            history.push(routeLastDealers.url(serviceName));
        }

        routeToDealer = (serviceName) => {
            const {history} = this.props;
            history.push(routeDealer.url(serviceName));
        }

        render() {
            return <WrappedComponent
                routeToRootComponent={this.routeToRootComponent}
                routeToDealerManagement={this.routeToDealerManagement}
                routeToDealer={this.routeToDealer}
                routeToLastDealers={this.routeToLastDealers}
                {...this.props} />;
        }
    }

    return compose(
        withRouter,
    )
    (HOC);
}

export default withMenu;