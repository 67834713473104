import React from 'react';
import {connect} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import DialogTitleWithClose from '../../base/dialogTitleWithClose';
import {setCartContext, setCartRemoveDialogState} from '../../../redux/cart/cartAction';
import withCartApi from '../../../api/cartApi';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import {trackPromise} from 'react-promise-tracker';
import {loadingAreas} from '../../../config/loadingAreas';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

class CartRemoveDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cartId: '',
            cartName: '',
        };
    };

    componentDidMount() {
        this.setState({
            cartId: this.props.cartContext.cartId,
            cartName: this.props.cartContext.cartName,
        });
    };

    handleCartRemoveClose = () => {
        this.props.setCartRemoveDialogState(false);
    };

    handleRemoveCart = () => {
        trackPromise(
            this.props.deleteCart(this.state.cartId).then(() => {
                this.props.getCarts(this.props.currentBrand.id).then(carts => {
                    if (!carts || !carts.length) {
                        this.props.setCartContext(null);
                    } else {
                        this.props.setCartContext(carts[0]);
                    }

                    this.props.setCartRemoveDialogState(false);
                    this.props.onUpdate();
                });
            })
            , loadingAreas.cart);
    };

    render() {
        const {t} = this.props;

        return (
            <Dialog
                open={this.props.cartRemoveDialogShow}
                onClose={this.handleCartRemoveClose}
                aria-labelledby="cart-remove-dialog-title"
                aria-describedby="cart-remove-dialog-description"
            >
                <DialogTitleWithClose onClose={this.handleCartRemoveClose}>
                    {t('cart_remove_title')}
                </DialogTitleWithClose>

                <DialogContent>
                    <DialogContentText>
                        {t('cart_remove_description', { cartName: this.state.cartName })}
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" onClick={this.handleCartRemoveClose}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={this.handleRemoveCart} color="primary">
                        {t('ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    cartRemoveDialogShow: state.cart.cartRemoveDialogShow,
    currentBrand: state.catalog.currentBrand,
});

const mapDispatchToProps = {
    setCartRemoveDialogState,
    setCartContext,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withCartApi,
    withTranslation(),
)
(CartRemoveDialog);
