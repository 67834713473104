import React, { Fragment } from 'react';
import ModelSubheader from '../../catalog/restriction/restrictionSubheader';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import SearchResultList from './searchResultList';

class SearchResults extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <ModelSubheader title={t('search_search_results')} />
                <SearchResultList 
                    items={this.props.searchResults} 
                    onItemClick={this.props.onSearchItemClick}
                />
            </Fragment>
        );
    }
}

export default
compose(
    withTranslation()
)
(SearchResults);