import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { store } from '../../redux/store';
import { withTranslation } from 'react-i18next';
import withSnackbar from '../../service/withSnackbar';
import withSearchApi from '../../api/searchApi';
import { updateSearchQuery } from '../../redux/search/searchAction';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Box,
    IconButton,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FlipIcon from '@mui/icons-material/Flip';
import InfoIcon from '@mui/icons-material/Info';
import VinLensApi from '../../api/vinLensApi';
import VinLensCanvas from './vinLensCanvas';

class VinLens extends React.Component {
    constructor(props) {
        super(props);

        const isDev = process.env.NODE_ENV === 'development';
        let env = window.location.hostname.split('.')[0];

        // Workaround for PROD 
        // In PROD is no "m.etak.com" available, only "www.etka.com"
        env = env === "m" ? "www" : env

        // Workaround to prevent CORS issues when developing locally
        if (isDev) {
            window.history.pushState({}, '', '/vinscanner');
        }

        this.state = {
            apiUrl: isDev
                ? `${window.location.origin}/vinscanner/api/files`
                : `https://${env}.etka.com/vinscanner/api/files`,
            apiToken: store.getState().global.token,
            isOpen: false,
            isLoadingApi: false,
            isPictureTaken: false,
            showCanvas: true
        };
    }

    openDialog = () => {
        this.setState({ isOpen: true });
    }

    closeDialog = () => {
        this.setState({
            isOpen: false,
            isLoadingApi: false
        });
    }

    sendPicture = async (imageSrc) => {
        const { t, enqueueWarningSnackbar } = this.props;
        const formData = new FormData();

        await fetch(imageSrc)
            .then(res => res.blob())
            .then(blob => {
                formData.append('file', new File([blob], 'scan.png', { type: 'image/png' }));
            });

        this.setState({
            isLoadingApi: true,
            isSelectionActive: false
        });

        return VinLensApi.sendImage(this.state.apiUrl, formData).then(data => {
            if (!data || !data.vin || !data.vin.length) {
                enqueueWarningSnackbar(t('scan_warning'));
                return false;
            }

            // VIN was successfully scanned but vehicle might not be found.
            // enqueueSuccessSnackbar(t('scan_success'));
            this.props.updateSearchQuery(data.vin);
            this.closeDialog();

            return true;
        }).catch(() => {
            enqueueWarningSnackbar(t('scan_warning'));

            return false;
        }).finally(() => {
            this.setState({ isLoadingApi: false });
        });
    }

    render() {
        const { t } = this.props;

        const styleDialog = {
            padding: '0 !important',
            maxHeight: '100vh',
            overflow: 'hidden'
        };

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <IconButton onClick={this.openDialog}>
                    <FlipIcon/>
                </IconButton>
                <Dialog
                    open={this.state.isOpen}
                    fullWidth
                    fullScreen
                    aria-labelledby={t('scan_vin_number')}
                    onClose={this.closeDialog}
                    sx={styleDialog}
                >
                    {this.state.isLoadingApi && (
                        <Box sx={{
                            zIndex: 30,
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        }}>
                            <CircularProgress
                                color="secondary"
                                sx={{
                                    position: 'absolute',
                                    left: 'calc(50% - 20px)',
                                    top: '15%'
                                }}
                            />
                        </Box>
                    )}
                    <DialogContent sx={styleDialog}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            <DialogTitle sx={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                {t('scan_vin_number')}
                            </DialogTitle>
                            <DialogActions>
                                <IconButton onClick={this.closeDialog}>
                                    <CloseIcon/>
                                </IconButton>
                            </DialogActions> 
                        </Box>
                        <VinLensCanvas
                            isLoading={this.state.isLoadingApi}
                            isPictureTaken={this.state.isPictureTaken}
                            onPictureTaken={value => this.setState({ isPictureTaken: value })}
                            onSendSelection={this.sendPicture}
                            onError={this.closeDialog}
                        />
                        <List>
                            {this.state.isPictureTaken && (
                                <ListItem>
                                    <ListItemIcon>
                                        <InfoIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('scan_vin_info_2')}  />
                                </ListItem>
                            )}
                        </List>
                    </DialogContent>
                </Dialog>
            </Box>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    updateSearchQuery: (payload) => dispatch(updateSearchQuery(payload))
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withSearchApi,
    withSnackbar,
    withTranslation(),
)(VinLens);