import React, { Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Container as MuiContainer,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CartItemPrice from '../../../cart/item/cartItemPrice';
import NumberField from '../../../base/numberField';
import withCartApi from '../../../../api/cartApi';
import withSnackbar from '../../../../service/withSnackbar';
import { 
    setCartContext, 
    setCartsCatalog,
    updateCart 
} from '../../../../redux/cart/cartAction';
import Container from '../../../base/container';
import SubPartItems from './subPartItems';
import FormattedText from '../../../text/formattedText';
import { LightBulbIcon } from '../../../base/additionalIcons';
import IlluLink from './functionTypes/IlluLink';
import theme from '../../../../theme/theme';

class PartItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            itemQuantity: this.props.item.hasOwnProperty('qty') ? this.props.item.qty : 1,
        };
    }

    addToCart = () => {
        const { 
            t, 
            item, 
            addPartToCart, 
            getCarts, 
            updateCart, 
            enqueueWarningSnackbar, 
            setCartsCatalog,
            currentVehicle, 
            originalPart, 
            cartContext 
        } = this.props;
        
        const originalPartNo = originalPart ? originalPart.partno : null;

        if (this.state.itemQuantity <= 0) {
            enqueueWarningSnackbar(t('add_part_to_cart_to_few_quantity', { minQuantity: 1 }));
            return;
        }

        addPartToCart(item, currentVehicle.vin, this.state.itemQuantity, originalPartNo).then(({ data }) => {
            getCarts(this.props.currentBrand.id, false).then(carts => {
                const currentCart = carts.find(cart => cart.cartId === cartContext.cartId);

                if (currentCart) {
                    updateCart(currentCart);
                }
            });

            if (!data || !data.cartId) {
                return;
            }

            setCartsCatalog({
                brandId: this.props.currentBrand.id,
                cart: data
            });
        });
    };

    getMessage() {
        if (!this.props.messages) {
            return null;
        }

        return this.props.messages.map((message, index) => {
            return (
                <Box mt={2} key={index}>
                    <Box display={'inline'} mr={1}>
                        <LightBulbIcon />
                    </Box>
                    <Typography variant="caption">
                        {message}
                    </Typography>
                </Box>
            );
        });
    }

    addSubParts(segment, index) {
        if(!segment.records || segment.records === null){
            return null
        }

        const subParts = segment.records.filter(subPart => subPart.description && subPart.partno);

        if (!subParts.length) {
            return null;
        }

        return (
            <Container key={index} noPaddingXSM paddingX paddingY>
                <SubPartItems 
                    items={subParts}
                    originalPart={this.props.item}
                    title={segment.tabNameOverride || segment.title} 
                    defaultExpanded={index === 0}
                />
            </Container>
        );
    }

    getTableHeaders(partinfoTab) {
        return (
            <TableHead>
                <TableRow>
                    {partinfoTab.elements.map((column, i) => (
                        <TableCell key={i} align={column.id === 'price' ? 'right' : 'left'}>
                            {column.title}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    getTableRows(partinfoTab, values) {
        const style = !this.props.item.dropped ? {} : {
            textDecoration: 'line-through'
        };

        return (
            <TableRow>
                {partinfoTab.elements.map((column, i) => {
                    let text = values[column.id];

                    return <TableCell key={i} sx={{
                        verticalAlign: 'baseline'
                    }}>
                        <Box sx={style}>
                            {column.id === 'price' ?
                                <CartItemPrice 
                                    bestPrice={text.bestPrice} 
                                    price={text.price}
                                /> :
                                column.id === 'illulink' && typeof text === 'object' 
                                    ? <IlluLink data={text}/> 
                                    : <FormattedText value={text}/>
                                }
                        </Box>
                    </TableCell>;
                })}
            </TableRow>
        );
    }

    render() {
        const { t, item, segments, currentBrand } = this.props;
        const { values, remark, orderable, dropped } = item;

        const partinfoTab = currentBrand.definitions.partinfotab
            ? currentBrand.definitions.partinfotab
            : currentBrand.definitions.partinfoTab;

        const styleDropped = !dropped ? {} : { textDecoration: 'line-through' };

        return (
            <Fragment>
                <MuiContainer maxWidth="md" sx={{ px: { sm: '0 !important' }}}>
                    <Typography component="div" variant="body2">
                        <Box mb={4}>
                            <TableContainer component={Paper}>
                                <Table stickyHeader={true} size="small">
                                    {this.getTableHeaders(partinfoTab)}

                                    <TableBody>
                                        {this.getTableRows(partinfoTab, values)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
                            <Grid item xs={9}>
                                {remark && (
                                    <Box sx={{
                                        ...styleDropped,
                                        my: 1 
                                    }}>
                                        <Box mr={1} component="span">
                                            <LightBulbIcon />
                                        </Box> 
                                        {remark}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                        {!!orderable &&
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                <Grid item xs="auto">
                                    <Box sx={{ 
                                        mr: theme.spacing(4),
                                        py: theme.spacing(1),
                                        fontWeight: 700 
                                    }}>
                                        {t('part_info_view_quantity')}
                                    </Box>
                                </Grid>

                                <Grid item xs="auto">
                                    <Grid container alignContent="flex-end">
                                        <Box sx={{
                                            width: theme.spacing(16),
                                            display: 'inline-block'
                                        }}>
                                            <NumberField
                                                change={(newValue) => this.setState({itemQuantity: newValue})}
                                                value={this.state.itemQuantity}
                                                min={1}
                                            />
                                        </Box>
                                        <Button
                                            variant="contained"
                                            onClick={this.addToCart}
                                            sx={{
                                                width: '67px',
                                                ml: 0.5
                                        }}>
                                            <ShoppingCartIcon/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Typography>
                    {this.getMessage()}
                </MuiContainer>
                {segments && this.props.inlineSegments.map((segment, index) => {
                    return this.addSubParts(segment, index);
                })}
            </Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setCartContext: payload => dispatch(setCartContext(payload)),
    setCartsCatalog: payload => dispatch(setCartsCatalog(payload)),
    updateCart: payload => dispatch(updateCart(payload)),
});

const mapStateToProps = state => ({
    currentBrand: state.catalog.currentBrand,
    currentVehicle: state.catalog.currentVehicle,
    cartContext: state.cart.cartContext,
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withSnackbar,
    withCartApi,
    withTranslation()
)(PartItem);
