import React, {Fragment} from 'react';
import Container from '../base/container';
import {withTranslation} from 'react-i18next';
import ModelSubheader from '../catalog/restriction/restrictionSubheader';
import {compose} from 'redux';
import withMenu from '../../service/withMenu';
import Typography from '@mui/material/Typography';
import {connect} from 'react-redux';
import dealerApi from '../../api/dealerApi';
import {setLastDealers} from '../../redux/dealerManagement/dealerManagementAction';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import {withStyles} from '@mui/styles';
import DealerList from './dealerList';
import {DESKTOP_VIEW_URL} from '../../config/urlConfig';
import {resetCartDealer, resetCartOnly, setDealer} from '../../redux/cart/cartAction';

const useStyles = () => ({
    image: {
        height: '10rem',
        width: 'auto',
    },
});

class LastDealers extends React.Component {

    constructor(props) {
        super(props);
        let serviceName = this.props.match.params.serviceName;
        this.state = {
            serviceName,
            quantityDealersToShow: 5,
        };
    }

    componentDidMount() {
        dealerApi.getDealers(this.state.serviceName).then(dealers => {
            // show only last 5 dealers
            this.props.setLastDealers(dealers.slice(0, this.state.quantityDealersToShow));
        });
    }

    onBackClick() {
        this.props.history.goBack();
    }

    onDealerClick(dealer) {
        dealerApi.setCatalogDealer(dealer.dealerId, this.state.serviceName)
            .then(() => {
                dealerApi.getDealer(dealer.dealerId).then(dealerSummery => {
                    if (dealerSummery) {
                        this.props.setDealer(dealerSummery);
                    }
                    this.props.resetCartOnly();
                    this.props.routeToDealerManagement();
                });
            });
    }

    render() {
        const {t} = this.props;
        return (
            <Fragment>
                <Container disableGutters>
                    <ModelSubheader
                        title={t('dealermanagement_your_last_dealers', {quantity: this.state.quantityDealersToShow})}
                        onBackClick={this.onBackClick.bind(this, this.props.currentPartRestriction)}
                    />
                </Container>

                <Container overflow>
                    <DealerList catalogDealers={this.props.lastDealers}
                                onDealerClick={(dealer) => this.onDealerClick(dealer)}/>
                    <Box py={3}>
                        <Typography
                            variant="body1"><strong>{t('dealermanagement_choose_dealer_condition')}</strong>
                        </Typography>
                    </Box>
                    <Box my={1}>
                        <Button href={DESKTOP_VIEW_URL} target="_blank" variant="contained">
                            <Box color="black">{t('dealermanagement_to_desktop')}</Box>
                        </Button>
                    </Box>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    catalogDealer: state.dealerManagement.catalogDealer,
    lastDealers: state.dealerManagement.lastDealers,
    catalogs: state.catalog.catalogs,
});

const mapDispatchToProps = dispatch => ({
    setLastDealers: (payload) => dispatch(setLastDealers(payload)),
    resetCartOnly: (payload) => dispatch(resetCartOnly(payload)),
    resetCartDealer: (payload) => dispatch(resetCartDealer(payload)),
    setDealer: (payload) => dispatch(setDealer(payload)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withMenu,
    withStyles(useStyles),
)(LastDealers);
