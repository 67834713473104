import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { updateCatalogs, updateCurrentBrand } from '../../redux/catalog/catalogAction';
import { 
    setDealer, 
    resetCartDealer, 
    setCartContext 
} from '../../redux/cart/cartAction';
import { updateLogInState } from '../../redux/auth/authAction';
import BrandItem from './brandItem';
import { compose } from 'redux';
import appgtwApi from '../../api/appgtwApi';
import BrandService from '../../service/brandService';
import withCartApi from '../../api/cartApi';
import { withTranslation } from 'react-i18next';
import dealerApi from '../../api/dealerApi';


class BrandList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            brands: [],
            message: ''
        };
    }

    componentDidMount() {
        this.loadCatalogs();
    }

    loadCatalogs() {
        if (this.props.catalogs && this.props.catalogs.length) {
            this.setState({ brands: this.props.catalogs });
        } else {
            appgtwApi.getCatalogs().then(catalogData => {
                const catalogs = BrandService.getCatalogFromArray(catalogData);
                this.props.updateCatalogs(catalogs);
                this.setState({ brands: catalogs });

                if (!catalogs || !catalogs.length) {
                    this.props.updateLoginState(false);

                }
            });
        }
    }

    onBrandItemClick(catalogItem) {
        this.setCatalogDealer(catalogItem.id);

        appgtwApi.getBrand(catalogItem.catUrl).then(resp => {
            const brand = BrandService.getBrandFromObject(resp, catalogItem);

            if (!brand) {
                return;
            }

            this.props.updateCurrentBrand(brand);
            this.props.onBrandItemClick(brand);

            this.props.getLastUsedCart(brand.id).then(cartContext => {
                if (cartContext && (!this.props.hasCart || this.props.cartContextId !== cartContext.cartId)) {
                    this.props.setCartContext(cartContext || 0);
                } 
            }).catch(e => {
                this.props.setCartContext(null)
            });
        });
    }

    setCatalogDealer(catalogId) {
        dealerApi.getCatalogDealer(catalogId).then(catalogDealer => {
            if (catalogId === 0) {
                this.props.resetCartDealer();
                return;
            }

            if (catalogDealer && catalogDealer.dealerId) {
                //An additional check if respond dealer is valid!
                dealerApi.getDealer(catalogDealer.dealerId).then(dealerSummery => {
                    if (dealerSummery) {
                        this.props.setDealer(dealerSummery);
                    } else {
                        this.props.resetCartDealer();
                    }
                });
            }
        });
    }

    render() {
        if (!this.state.brands.length) {
            return (
                <Box p={1} pb={3}>
                    <Typography>
                        {this.state.message}
                    </Typography>
                </Box>
            );
        }

        return (
            <Grid 
                container 
                alignItems="flex-start" 
                spacing={2} 
                sx={{ 
                    pb: 2 
            }}>
                {this.state.brands.map(brand => (
                    <Grid item key={brand.id} xs={4} sm={2} sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <BrandItem
                            brand={brand}
                            variant="image"
                            onClick={this.onBrandItemClick.bind(this)}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    }
}

const mapStateToProps = state => ({
    brands: state.catalog.brands,
    catalogs: state.catalog.catalogs,
    currentBrand: state.catalog.currentBrand,
    cartsCatalog: state.cart.cartsCatalog,
});

const mapDispatchToProps = dispatch => ({
    updateCurrentBrand: (payload, full) => dispatch(updateCurrentBrand(payload, full)),
    setCartContext: (payload) => dispatch(setCartContext(payload)),
    updateCatalogs: (payload) => dispatch(updateCatalogs(payload)),
    setDealer: (payload) => dispatch(setDealer(payload)),
    resetCartDealer: (payload) => dispatch(resetCartDealer(payload)),
    updateLogInState: (payload) => dispatch(updateLogInState(payload))
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withCartApi,
)(BrandList);
