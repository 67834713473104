import React from 'react';
import './dependencies/imageserver-jsviewer.min';
import {connect} from 'react-redux';
import {compose} from 'redux';

import jQuery from 'jquery';
import Api from '../../../api/api';
import {withStyles} from "@mui/styles";


const useStyles = (theme) => ({
    imageViewer: {
        height: 'calc(100% - 10px)',
        position: 'relative',
    },
});

class ImageViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {imageWidget: null}
    }

    componentDidMount() {
        // eslint-disable-next-line no-undef
        window.ImageOptions = ImageOptions;
        window.ImageWidget = ImageWidget;// eslint-disable-line no-undef

        const imageOptions = this.getImageOptions();
        const imageWidget = this.createImageViewerCanvas(imageOptions);

        this.loadImageViewerCanvas(imageWidget);

        jQuery(window).on("orientationchange", function (event) {
            setTimeout(function () {
                if (this.state.imageWidget) {
                    this.state.imageWidget.handle_resize();
                }
            }.bind(this), 200)
        }.bind(this))

    }

    componentWillUnmount() {
        jQuery(window).off("orientationchange");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.imageWidget && prevProps.selectedPart && this.props.selectedPart &&
            prevProps.selectedPart.hotspotId !== this.props.selectedPart.hotspotId) { // active hotspotId changed
            this.state.imageWidget.select_hotspot(this.props.selectedPart.hotspotId, false)
        }
        if (this.state.imageWidget && prevProps.currentTopHeight !== this.props.currentTopHeight && this.props.currentTopHeight > 30) { // currentTopHeight changed
            setTimeout(() => {
                this.state.imageWidget.handle_resize();
            }, 200)
        }
    }

    loadImageViewerCanvas(imageWidget) {
        let uri = Api().defaults.baseURL + this.props.image.uri;
        imageWidget.load(uri, (response) => {
            this.setState({imageWidget})
            if (this.props.selectedPart) {
                imageWidget.select_hotspot(this.props.selectedPart.hotspotId, false)
            }
            if(imageWidget.hotspots) {
                for(let i = imageWidget.hotspots.length -1; i >= 0 ; i--) {
                    if(!this.props.records.find(hotspot => hotspot.hotspotId === imageWidget.hotspots[i].key)){
                        imageWidget.hotspots.splice(i, 1);
                    }
                }
            }
        }, (error) => {
            console.error('image request failed', error);
        });
    }

    getImageOptions() {
        return new window.ImageOptions() // eslint-disable-line no-unused-vars
            .with_wid(this.props.bomPage.image.wid || 'imgv')
            .with_hotspot_padding(this.props.bomPage.image.hotspotPadding || 0)
            .with_hotspots_stroke_style('rgba(247,196,0,.25)')
            .with_hovered_hotspots_stroke_style('rgba(125,0,0)')
            .with_tooltip_background_style('rgba(248,209,90,.87)')
            .with_selected_hotspots_fill_style('rgba(247,196,0,.34)')
            .with_selected_hotspots_stroke_style('rgb(247,196,0)')
            .with_hotspot_selection_changed_handler((hotspot_keys) => {
                this.props.onHotspotClick(hotspot_keys[0])
            })
            .with_double_click_handler((event) => {
                let selectedPart;

                selectedPart = this.props.currentPartOverview.records.filter(record => !record.unavailable && record.hotspotId === event.hotspot);

                // disable double click if there are multiple entries for the same hotspot
                if(!selectedPart || !selectedPart.length || selectedPart.length > 1) {
                    return;
                }

                this.props.onHotSpotDoubleClick(selectedPart[0])
            })
            .with_scale_factor_min(0.6)
            .with_scale_factor_max(6)
            .with_button_margin(0)
    }

    createImageViewerCanvas(imagOptions) {
        return window.new_image_widget(jQuery('.image-viewer'), imagOptions);
    }

    render() {
        let {classes} = this.props;
        return (
            <div className={'image-viewer ' + classes.imageViewer}>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    bomPage: state.catalog.currentBrand.pages.bom,
    currentPartOverview: state.catalog.currentPartOverview,
    currentTopHeight: state.catalog.currentTopHeight,
    currentPartImages: state.catalog.currentPartImages,
});

export default compose(
    connect(
        mapStateToProps
    ),
    withStyles(useStyles),
)
(ImageViewer);