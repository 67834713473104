import React from 'react';
import { compose } from 'redux';
import { Dialog } from '@mui/material';
import { withTranslation} from 'react-i18next';
import { connect } from 'react-redux';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { setSessionOverTakeShown } from '../../redux/auth/authAction';
import DialogTitleWithClose from './dialogTitleWithClose';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import withAuthApi from '../../api/authApi';

class SessionInUseDialog extends React.Component {
    handleClose = () => {
        this.props.setSessionOverTakeShown(false);
    }

    handleForceSession = () => {
        this.props.onConfirm();
        this.props.setSessionOverTakeShown(false);
    }

    render() {
        const { t } = this.props;

        return (
            <Dialog
                open={this.props.sessionOverTakeShown}
                onClose={this.handleClose} 
                aria-labelledby="simple-dialog-title">

                <DialogTitleWithClose onClose={this.handleClose}>
                    {t('user_already_logged_in_title')}
                </DialogTitleWithClose>

                <DialogContent>
                    <DialogContentText>
                        {t('user_already_logged_in')}
                    </DialogContentText>
                </DialogContent>

                <DialogActions sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    pb: 0
                }}>
                    <Button onClick={this.handleClose} variant="outlined" color="primary" sx={{
                        width: 'auto',
                        mb: 1
                    }}>
                        {t('cancel')}
                    </Button>
                    <Button onClick={this.handleForceSession} variant="contained" color="primary" sx={{
                        width: 'auto',
                        mb: 1
                    }}>
                        {t('confirm')}
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionOverTakeShown: state.auth.sessionOverTakeShown,
});

const mapDispatchToProps = {
    setSessionOverTakeShown,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withAuthApi,
    withTranslation(),
)(SessionInUseDialog);