import React, {Fragment} from 'react';
import {compose} from 'redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import {Box} from '@mui/material';
import {withTranslation} from 'react-i18next';
import CartRow from '../partial/cartRow';
import CartItemReadonly from '../item/cartItemReadonly';

class ListItems extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            listOpen: this.props.listOpen || false,
        };
    }

    handleListToggleClick = () => {
        this.setState(state => ({listOpen: !state.listOpen}));
    };

    render() {
        const {t, items} = this.props;
        const {listOpen} = this.state;

        return (
            <Fragment>
                <ListItem button onClick={this.handleListToggleClick}>
                    <ListItemText
                        primaryTypographyProps={{variant: 'h6'}}
                        primary={t('cart_articles_title')}
                    />
                    {listOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </ListItem>

                <Collapse in={listOpen}>
                    <Box border={1} borderColor="border.main" borderTop={0} borderLeft={0} borderRight={0}>
                        <ListItem divider={false}>

                            <ListItemText>

                                {items.map((item) => (
                                    <CartRow key={item.cartItemId}>
                                        <CartItemReadonly item={item}/>
                                    </CartRow>
                                ))}

                            </ListItemText>

                        </ListItem>
                    </Box>
                </Collapse>
            </Fragment>
        );
    }
}

export default compose(
    withTranslation(),
)(ListItems);
