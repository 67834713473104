import React, {Fragment} from 'react';
import Typography from '@mui/material/Typography';
import Container from '../base/container';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import {CHANGE_PASSWORD_URL} from '../../config/urlConfig';
import {withTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import ModelSubheader from "../catalog/restriction/restrictionSubheader";
import {compose} from "redux";
import withMenu from "../../service/withMenu";

class ChangePassword extends React.Component {

    constructor(props) {
        super(props);

        this.onBackClick = this.onBackClick.bind(this);
    }

    onBackClick() {
        this.props.routeToRootComponent();
    }

    render() {
        const {t} = this.props;

        return (
            <Fragment>
                <Container disableGutters>
                    <ModelSubheader
                        title={t('change_password_title')}
                        onBackClick={this.onBackClick}
                    />
                </Container>
                <Container paddingY overflow>
                    <Box mb={3}>
                        <Typography variant="body1">
                            {t('change_password_text')}
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        component={Link}
                        underline="none"
                        href={CHANGE_PASSWORD_URL}
                        target="_blank"
                    >
                        {t('change_password_link')}
                    </Button>
                </Container>
            </Fragment>
        );
    }
}

const ChangePasswordContainer =
    compose(
        withTranslation(),
        withMenu,
    )(ChangePassword);

export default ChangePasswordContainer;
