import React from 'react';
import { compose } from 'redux';
import dealerApi from '../../../api/dealerApi';
import { Box, Dialog } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setDealerDialogState } from '../../../redux/cart/cartAction';
import DialogTitleWithClose from '../../base/dialogTitleWithClose';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DealerList from '../../dealer/dealerList';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import { DESKTOP_VIEW_URL } from '../../../config/urlConfig';

class CartDealerDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            catalogDealers: [],
            quantityDealersToShow: 5,
        };
    }

    componentDidMount() {
        this.getDealersFromApi();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentBrand.id === this.props.currentBrand.id) {
            return;
        }

        this.getDealersFromApi();
    }

    getDealersFromApi() {
        if (!this.props.currentBrand || !this.props.currentBrand.id) {
            return;
        }

        dealerApi.getDealers(this.props.currentBrand.id).then(resp => {
            if (!resp || !resp.length) {
                return;
            }

            let filteredDealer = resp.filter((d) => d.dealerName);
            this.setState({ catalogDealers: filteredDealer.slice(0, this.state.quantityDealersToShow) });
        });
    }

    handleClose = () => {
        this.props.setDealerDialogState(false);
    };

    onDealerClick = (dealer) => {
        dealerApi.setCatalogDealer(dealer.dealerId, this.props.currentBrand.id)
            .then(() => {
                this.props.setDealerDialogState(false);
                this.props.onDealerSelected(dealer.dealerId);
            });
    };

    render() {
        const { t, cartDealerDialogShow } = this.props;

        return (
            <Dialog 
                open={cartDealerDialogShow}
                onClose={this.handleClose}
            >
                <DialogTitleWithClose onClose={this.props.handleClose}>
                    {t('select_dealer')}
                </DialogTitleWithClose>
                <DialogContent>
                    <Box mb={2}>
                        <DealerList 
                            catalogDealers={this.state.catalogDealers}
                            currentDealerId={this.props.currentDealerId}
                            onDealerClick={dealer => this.onDealerClick(dealer)}
                        />
                    </Box>
                    <Typography variant="body1">
                        <strong>{t('dealermanagement_choose_dealer_condition')}</strong>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        href={DESKTOP_VIEW_URL} 
                        target="_blank" 
                        onClick={this.handleClose}
                        sx={{
                            mr: 1
                    }}>
                        {t('dealermanagement_to_desktop')}
                    </Button>
                    <Button variant="outlined" onClick={this.handleClose}>
                        {t('cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    currentDealerId: state.cart.dealer.dealerId,
    cartDealerDialogShow: state.cart.cartDealerDialogShow,
    currentBrand: state.catalog.currentBrand,
});

const mapDispatchToProps = {
    setDealerDialogState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
)(CartDealerDialog);
