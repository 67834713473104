import React from 'react';
import {compose} from 'redux';
import withAuthApi from '../../api/authApi';

class CookieLifeTime extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            intervalDuration: 60000, // 4m
            cookieLifeTimeInterval: null,
        };
    }

    componentDidMount() {
        this.handelCookieLifeTime();
    }

    handelCookieLifeTime() {
        const cookieLifeTimeInterval = setInterval(() => {
            this.props.extendCookieLifeTime();
        }, this.state.intervalDuration);

        this.setState({cookieLifeTimeInterval: cookieLifeTimeInterval});
    }

    componentWillUnmount() {
        clearInterval(this.state.cookieLifeTimeInterval);
    }

    render() {
        return null;
    }
}

export default compose(
    withAuthApi,
)(CookieLifeTime);