import Api from './api';
import * as React from 'react';
import { compose } from 'redux';
import withSnackbar from '../service/withSnackbar';
import { trackPromise } from 'react-promise-tracker';
import i18n from '../i18n';
import { loadingAreas } from '../config/loadingAreas';
import { setCart, setCartContext } from '../redux/cart/cartAction';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';


function withCartApi(WrappedComponent) {
    class HOC extends React.Component {

        /**
         * Fetches the 'cart context' by brand, is only necessary information not whole cart
         * @param {String} service
         * @returns {Promise}
         */
        getCartContext = service => {
            return new Promise((resolve, reject) => {
                trackPromise(
                    Api().get(`/cart/ext/api/2.0/contextCart?catalogService=${service}&timestamp=${new Date().getTime()}`).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        if (error?.response.status === 410) {
                            // console.log(error.response.data.title, 'no cart exists');
                        } else {
                            this.props.enqueueErrorSnackbar(i18n.t('error_on_loading_cart'));
                            // console.error('Error happened on getCartContext: ', error);
                        }

                        reject();
                    })
                , loadingAreas.cart);
            });
        };

        createNewCart = service => {
            return new Promise((resolve, reject) => {
                trackPromise(
                    Api().post(`/cart/ext/api/2.0/carts?catalogService=${service}`, {}).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_create_new_cart'));
                        // console.error('Error happened on creating new cart: ', error);
                        reject();
                    })
                , loadingAreas.cart);
            });
        };

        /**
         * @param {Boolean} noSupplyCache   - forces price and availability to update -> be careful, it generates cost per use
         * @param {Number} cartId
         * @returns {Promise}
         */
        updateCurrentCart = (noSupplyCache = false, cartId = null) => {
            this.getCart(cartId || this.props.cartId, this.props.dealerId, noSupplyCache).then(resp => {
                this.props.setCart(resp);
            });
        };

        isFinalResponse(response) {
            return !response || !response.items ||
                !response.items.length ||
                !response.items.some(item => item.finalResponse === false);
        }

        /**
         * @param {Number} cartId
         * @param {Number} dealerId
         * @param {Boolean} noSupplyCache   - forces price and availability to update -> be careful, it generates cost per use
         * @returns {Promise<unknown>}
         */
        getCart = (cartId, dealerId, noSupplyCache = false) => {
            if (!dealerId) {
                return Promise.reject(console.error('"dealerId" is not defined'));
            }

            return new Promise((resolve, reject) => {
                trackPromise(
                    this.getCartUntilFinalResponse(cartId, dealerId, noSupplyCache)
                        .then((response) => resolve(response))
                        .catch(error => {
                            // console.error('Error happened on getCart final response: ', error);
                            resolve(null);
                        }), loadingAreas.cart);
            });
        };

        /**
         *
         * @param {Number} cartId
         * @param {Number} dealerId
         * @param {Boolean} isPolling
         * @param {Boolean} noSupplyCache   - forces price and availability to update -> be careful, it generates cost per use
         * @returns {Promise}
         */
        getCartByApi = (cartId, dealerId, isPolling = false, noSupplyCache = false) => {
            return new Promise((resolve) => {
                // TODO: Check why this returns a 405 response
                Api().get('/cart/ext/api/2.0/carts/' + cartId + '?forDealer=' + dealerId + '&noSupplyCache=' + noSupplyCache + '&isPolling=' + isPolling)
                    .then(response => {
                        if (!response.data) {
                            // console.error('Error happened on getCart: data not inside \'response.data\'');
                            resolve(null);
                            return;
                        }

                        resolve(response.data);
                    })
                    .catch(error => {
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_loading_cart'));
                        // console.error('Error happened on getCart: ', error);
                        resolve(null);
                    });
            });
        };

        /**
         * @param {Number} cartId
         * @param {Number} dealerId
         * @param {Boolean} noSupplyCache   - forces price and availability to update -> be careful, it generates cost per use
         * @returns {Promise}
         */
        getCartUntilFinalResponse = (cartId, dealerId, noSupplyCache = false) => {
            return new Promise(resolve => {
                let counter = 0;
                let isFinalResponse = false;
                let startTime = new Date();

                let getCartByTimeout = (isPolling = true) => {
                    let nowTime = new Date();
                    let timeDiff = nowTime - startTime;
                    counter++;

                    this.getCartByApi(cartId, dealerId, isPolling, noSupplyCache).then(r => {
                        isFinalResponse = this.isFinalResponse(r);

                        if (counter > 12 || isFinalResponse || timeDiff > 10000) {
                            resolve(r);
                        } else {
                            setTimeout(getCartByTimeout, 1000);
                        }
                    }).catch(error => {
                        // console.error('Error happened on getCart: ', error);
                        resolve(null);
                    });
                };

                // first call must not be polling
                getCartByTimeout(false);
            });
        };

        /**
         * @param {String} service
         * @param {Boolean} showNotification
         * @returns {Promise}
         */
        getCarts = (service, showNotification = true) => {
            return new Promise((resolve) => {
                trackPromise(
                    Api().get(`/cart/ext/api/2.0/carts?catalogService=${service}`).then(response => {
                        if (!response.data) {
                            resolve({});
                            return;
                        }

                        resolve(response.data);
                    }).catch(() => {
                        if (showNotification) {
                            this.props.enqueueErrorSnackbar(i18n.t('error_on_get_carts'));
                        }

                        resolve([]);
                    })
                , loadingAreas.cart);
            });
        };

        /**
         * @param cartItemId
         * @param newValue
         * @returns {Promise}
         */
        setCartItemQuantity = (cartItemId, newValue) => {
            return new Promise((resolve) => {
                trackPromise(
                    Api().patch(`/cart/ext/api/2.0/cartItems/${cartItemId}`, {
                        quantity: newValue,
                    }).then(response => {
                        resolve(response);
                    }).catch(() => {
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_set_cart_items'));
                        resolve();
                    })
                , loadingAreas.cart);
            });
        };

        /**
         * @param {Object} item
         * @param {String} vin
         * @param {Number} quantity
         * @returns {Promise}
         */
        addPartToCart = (item, vin = null, quantity = 1, originalPartNo = null) => {
            const { t, cartContext, currentBrand } = this.props;
            const { partno, assortment = null, description = '', manufacturer = null, supplierRemark = null, remark = null  } = item;

            if (!partno || !partno.length) {
                return;
            }

            let cartUrl = !cartContext || !cartContext.cartId // If no cart exists, we create a new one
                ? `/cart/ext/api/2.0/catalogPartsTransfer?catalogService=${currentBrand.id}`
                : `/cart/ext/api/2.0/carts/${cartContext.cartId}/catalogPartsTransfer`;

            const partInfo = {
                partNumberNormalized: partno,
                quantity: quantity,
                description: description,
            };

            // vin must be specified twice, as a changeover is in progress in the backend
            // Currently the VIN is read out via the DataObject, but it is planned that it will be read out via the query.
            if (vin) {
                if(cartUrl.includes('?')){
                    cartUrl += `&vin=${vin}`
                } else {
                    cartUrl += `?vin=${vin}`;
                }
                partInfo.attributes = { vin: vin };
            }

            if (assortment) {
                partInfo.assortment = assortment;

                if (assortment === 'QP' && originalPartNo) {
                    partInfo.originalPartNumber = originalPartNo;
                }
            }

            if (supplierRemark || remark) {
                partInfo.remark = supplierRemark || remark;
            }

            if (manufacturer) {
                partInfo.manufacturer = manufacturer;
            }

            return new Promise((resolve) => {
                trackPromise(
                    Api().post(cartUrl, [partInfo]).then(response => {
                        if (response.data) {
                            this.props.setCartContext(response.data);
                        } 

                        this.props.enqueueSuccessSnackbar(t('add_part_to_cart_success'));
                        resolve(response);
                    }).catch((error) => {
                        if (error && error.response && error.response.data) {
                            this.props.enqueueErrorSnackbar(error.response.data.title);
                        } else {
                            this.props.enqueueErrorSnackbar(i18n.t('error_on_add_part_to_cart'));
                        }
                        resolve();
                    })
                , loadingAreas.catalog);
            });
        };

        /**
         * @param cartItemId
         * @param comment
         * @returns {Promise<unknown>}
         */
        setCartItemComment = (cartItemId, comment) => {
            return new Promise((resolve) => {
                trackPromise(
                    Api().patch('/cart/ext/api/2.0/cartItems/' + cartItemId, {
                        comment: comment,
                    })
                        .then(response => {
                            resolve(response);
                        })
                        .catch(() => {
                            // console.error('Error on setCartItemComment', error);
                            this.props.enqueueErrorSnackbar(i18n.t('error_on_set_cart_item_comment'));
                            resolve();
                        })
                    , loadingAreas.cart);
            });
        };

        /**
         * @param cartItemId
         * @returns {Promise<unknown>}
         */
        deleteCartItem = (cartItemId) => {
            return new Promise((resolve) => {
                trackPromise(
                    Api().delete('/cart/ext/api/2.0/cartItems/' + cartItemId).then(response => {
                        resolve(response);
                    }).catch(() => {
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_delete_cart_items'));

                        resolve();
                    }), loadingAreas.cart);
            });
        };

        /**
         * @param cartId
         * @param value
         * @returns {Promise<unknown>}
         */
        renameCart = (cartId, value) => {
            return new Promise((resolve, reject) => {
                trackPromise(
                    Api().patch(`/cart/ext/api/2.0/carts/${cartId}`, {
                        cartName: value,
                        customerReference: value,
                    }).then(response => {
                        this.props.enqueueSuccessSnackbar(i18n.t('success_on_rename_cart'));

                        resolve(response);
                    }).catch(() => {
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_rename_cart'));

                        reject();
                    }), loadingAreas.cart);
            });
        };

        /**
         * @param cartId
         * @param comment
         * @returns {Promise<unknown>}
         */
        setCartComment = (cartId, comment) => {
            return new Promise((resolve, reject) => {
                trackPromise(
                    Api().patch(`/cart/ext/api/2.0/carts/${cartId}`, {
                        comment: comment,
                    }).then(response => {
                        resolve(response);
                    }).catch(() => {
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_cart_comment'));
                        reject();
                    }), loadingAreas.cart);
            });
        };

        /**
         * @param cartId
         * @param addressId
         * @param type (shippingAddresses | billingAddresses)
         * @returns {Promise<unknown>}
         */
        setCartAddress = (cartId, addressId, type) => {
            return new Promise((resolve, reject) => {
                trackPromise(
                    Api().put(`/cart/ext/api/2.0/carts/${cartId}/${type}`, {
                        addressId: addressId,
                    }).then(response => {
                        resolve(response);
                    }).catch(() => {
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_update_cart_address'));

                        reject();
                    }), loadingAreas.cart);
            });
        };

        /**
         * @param cartId
         * @param orderTypeId
         * @returns {Promise<unknown>}
         */
        setOrderTypeId = (cartId, orderTypeId) => {
            return new Promise((resolve, reject) => {
                trackPromise(
                    Api().patch(`/cart/ext/api/2.0/carts/${cartId}`, {
                        orderTypeId: orderTypeId,
                    }).then(response => {
                        resolve(response);
                    }).catch(() => {
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_update_order_type'));

                        reject();
                    }), loadingAreas.cart);
            });
        };

        /**
         *
         * @param cartId
         * @param dispatchTypeId
         * @returns {Promise<unknown>}
         */
        setDispatchTypeId = (cartId, dispatchTypeId) => {
            return new Promise((resolve, reject) => {
                trackPromise(
                    Api().patch(`/cart/ext/api/2.0/carts/${cartId}`, {
                        dispatchTypeId: dispatchTypeId,
                    }).then(response => {
                        resolve(response);
                    }).catch(() => {
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_update_dispatch_type'));

                        reject();
                    }), loadingAreas.cart);
            });
        };

        /**
         *
         * @param type (shippingAddresses | billingAddresses)
         * @returns {Promise<unknown>}
         */
        getAddresses = type => {
            return new Promise((resolve) => {
                Api().get(`/cart/ext/api/2.0/${type}`).then(response => {
                        resolve(response.data);
                    }).catch(error => {
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_get_addresses'));
                        resolve([]);
                    });
            });
        };

        /**
         * @param cartId
         * @param dealerId
         * @returns {Promise}
         */
        orderSubmissions = (cartId, dealerId) => {
            const { t } = this.props;

            return new Promise((resolve, reject) => {
                trackPromise(
                    Api().post('/cart/ext/api/2.0/orderSubmissions', {
                        cartId: cartId.toString(),
                        dealerId: dealerId.toString(),
                        infoOrdered: false,
                    }).then(response => {    
                        resolve(response.data);
                    }).catch(error => {
                        // console.error('Error happened on cart submission: ', error);

                        if (error.response && error.response.data && error.response.data.detail) {
                            this.props.enqueueErrorSnackbar(error.response.data.title + ' - ' + error.response.data.detail);

                        } else {
                            this.props.enqueueErrorSnackbar(t('error_on_cart_submission'));
                        }

                        reject(error);
                    })
                , loadingAreas.cart);
            });
        };

        /**
         * @param cartId
         * @returns {Promise}
         */
        deleteCart = cartId => {
            return Api().delete(`/cart/ext/api/2.0/carts/${cartId}`).catch(() => {
                this.props.enqueueErrorSnackbar(i18n.t('error_on_cart_remove'));
                // console.error('Error happened on deleteCart: ', error);
            });
        };

        /**
         * @param cartId
         * @param customerReference
         * @param upsNumber
         * @returns {Promise}
         */
        setCartOptions = (cartId, customerReference, upsNumber) => {
            return new Promise((resolve, reject) => {
                trackPromise(
                    Api().patch(`/cart/ext/api/2.0/carts/${cartId}`, {
                        customerReference: customerReference,
                        upsNumber: upsNumber,
                    })
                        .then(response => {
                            resolve(response);
                        })
                        .catch(error => {
                            this.props.enqueueErrorSnackbar(i18n.t('error_on_cart_options'));
                            reject();
                        })
                    , loadingAreas.cart);
            });
        };

        /**
         * @param dealerId
         * @param customerNumber
         * @returns {Promise}
         */
        setCustomerNumber = (dealerId, customerNumber) => {
            return new Promise((resolve, reject) => {
                trackPromise(
                    Api().patch(`/cart/ext/api/2.0/dealers/${dealerId}/dealerProperties/`, {
                        customerNumber: customerNumber,
                    }).then(response => {
                        resolve(response);
                    }).catch(error => {
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_cart_dealers_customer_number'));
                        reject();
                    })
                , loadingAreas.cart);
            });
        };

        areAddressesValid = () => {
            const { cart } = this.props;
            
            return !!cart.shippingAddress && !!cart.billingAddress
        }

        areDealerPropertiesValid = () => {
            const { cart } = this.props;

            return !cart.dealerProperties || ((!cart.dealerProperties.orderTypes || cart.orderTypeId) && (!cart.dealerProperties.dispatchTypes || cart.dispatchTypeId));
        }

        render() {
            return <WrappedComponent
                getLastUsedCart={this.getCartContext}
                getCart={this.getCart}
                getCarts={this.getCarts}
                setCartItemQuantity={this.setCartItemQuantity}
                deleteCartItem={this.deleteCartItem}
                renameCart={this.renameCart}
                updateCurrentCart={this.updateCurrentCart}
                setCartItemComment={this.setCartItemComment}
                setCartComment={this.setCartComment}
                getAddresses={this.getAddresses}
                setCartAddress={this.setCartAddress}
                setOrderTypeId={this.setOrderTypeId}
                setDispatchTypeId={this.setDispatchTypeId}
                orderSubmissions={this.orderSubmissions}
                addPartToCart={this.addPartToCart}
                deleteCart={this.deleteCart}
                createNewCart={this.createNewCart}
                setCartOptions={this.setCartOptions}
                setCustomerNumber={this.setCustomerNumber}
                areAddressesValid={this.areAddressesValid}
                areDealerPropertiesValid={this.areDealerPropertiesValid}
                {...this.props} 
            />;
        }
    }

    const mapStateToProps = (state) => ({
        cartContext: state.cart.cartContext,
        currentBrand: state.catalog.currentBrand,
        dealerId: state.cart.dealer.dealerId,
        cartId: state.cart.cart.cartId,
        cart: state.cart.cart,
    });

    const mapDispatchToProps = dispatch => ({
        setCartContext: (payload) => dispatch(setCartContext(payload)),
        setCart: (payload) => dispatch(setCart(payload)),
    });

    return compose(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
        withTranslation(),
        withSnackbar,
    )(HOC);
}

export default withCartApi;
