import React, {Fragment} from 'react';
import {compose} from 'redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CartItemEditDialog from './cartItemEditDialog';
import withCartApi from '../../../api/cartApi';

class CartItemEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openDialog: false,
        };
    }

    handleDialogConfirm = (cartItemId, comment) => {
        this.props.setCartItemComment(cartItemId, comment).then(() => {
            this.props.updateCurrentCart();
            this.handleDialogClose();
        });
    };

    handleDialogOpen = () => {
        this.setState({openDialog: true});
    };

    handleDialogClose = () => {
        this.setState({openDialog: false});
    };

    render() {
        const {item} = this.props;

        return (
            <Fragment>
                <IconButton size="small" onClick={this.handleDialogOpen}>
                    <EditIcon/>
                </IconButton>
                <CartItemEditDialog
                    handleClose={() => this.handleDialogClose()}
                    handleConfirm={(comment) => this.handleDialogConfirm(item.cartItemId, comment)}
                    open={this.state.openDialog}
                    itemName={item.description}
                    itemComment={item.comment ? item.comment : ''}/>
            </Fragment>
        );
    }
}

export default compose(
    withCartApi,
)(CartItemEdit);
