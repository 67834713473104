import Api from './api';
import * as React from 'react';
import {compose} from 'redux';
import withSnackbar from '../service/withSnackbar';
import {trackPromise} from 'react-promise-tracker';
import {loadingAreas} from '../config/loadingAreas';
import {store} from '../redux/store';
import {enqueueErrorSnackbar} from '../redux/snackbar/snackbarAction';
import i18n from '../i18n';
import {connect} from 'react-redux';

function withSearchApi(WrappedComponent) {

    class HOC extends React.Component {

        isObjectEmpty = (obj) => {
            return Object.keys(obj).length === 0;
        };

        getVehicleSearchUrl = () => {
            return this.props.currentBrand.searchUrl;
        }

        getPartSearchUrl = () => {
                // on SubPartRestriction searchUrl from currentPartRestriction is used
            if (this.props.currentPartRestriction && this.props.currentPartRestriction.searchUrl) {
                return this.props.currentPartRestriction.searchUrl;

                // on MainPartRestriction searchUrl from currentVehicle is used
            } else if (this.props.currentVehicle && this.props.currentVehicle.searchUrl) {
                return this.props.currentVehicle.searchUrl;

            } else {
                return '';
            }
        }

        searchVehicle = query => {
            const { t } = this.props;
            const searchQuery = query ? query : this.props.searchQuery;

            return new Promise((resolve) => {
                trackPromise(
                    Api().get(`${this.getVehicleSearchUrl()}${searchQuery}`).then((response) => {
                        if (!response || !response.data) {
                            // console.error('Error happened on searchVehicle: data not inside \'response.data\'');
                            resolve([]);
                            return;
                        }

                        resolve(response.data);
                    }).catch(error => {
                        // console.error('error', error);
                        this.props.enqueueErrorSnackbar(t('error_on_search'));
                        resolve([]);
                    })
                , loadingAreas.search);
            });
        };

        getExtendedVehicle(url) {
            return new Promise((resolve) => {
                trackPromise(
                    Api().get(url)
                        .then((response) => {
                            if (!response || !response.data || !response.data.data) {
                                // console.error('Error happened on getExtendedVehicle: data not inside \'response.data.data\'');
                                resolve({});
                                return;
                            }

                            resolve(response.data);

                        }).catch(error => {
                        // console.error('error', error);
                        store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_data_loading')));
                        resolve({});
                    })
                    , loadingAreas.search);
            });
        }

        searchPart = (url = this.getPartSearchUrl(), addSearchQuery = true) => {
            const {t} = this.props;

            return new Promise((resolve) => {
                trackPromise(
                    Api().get(url + ( addSearchQuery ? this.props.searchQuery : ''))
                        .then((response) => {
                            if (!response || !response.data) {
                                // console.error('Error happened on searchPart: data not inside \'response.data\'');
                                resolve({});
                                return;
                            }

                            resolve(response.data);
                        })
                        .catch(error => {
                            // console.error('error', error);
                            this.props.enqueueErrorSnackbar(t('error_on_search'));
                            resolve([]);
                        })
                    , loadingAreas.search);
            });
        };

        render() {
            return <WrappedComponent
                searchVehicle={this.searchVehicle}
                getExtendedVehicle={this.getExtendedVehicle}
                searchPart={this.searchPart}
                getVehicleSearchUrl={this.getVehicleSearchUrl}
                getPartSearchUrl={this.getPartSearchUrl}
                {...this.props} />;
        }
    }

    const mapStateToProps = state => ({
        currentBrand: state.catalog.currentBrand,
        currentPartRestriction: state.catalog.currentPartRestriction,
        currentVehicle: state.catalog.currentVehicle,
    });

    const mapDispatchToProps = dispatch => ({
    });

    return compose(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
        withSnackbar,
    )(HOC);
}

export default withSearchApi;

