import React, {Fragment} from 'react';
import {compose} from 'redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {withTranslation} from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OrderTypeSelect from '../partial/orderTypeSelect';
import withCartApi from '../../../api/cartApi';
import DispatchTypeSelect from '../partial/dispatchTypeSelect';

class ListDeliveryDetail extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            listOpen: false,
        };
    }

    handleListToggleClick = () => {
        this.setState(state => ({listOpen: !state.listOpen}));
    };

    setOrderType = (oldOrderType , newOrderType) => {
        this.props.setOrderTypeId(this.props.cartId, newOrderType.id).then(() => {
            let noSupplyCache = false;
            if(newOrderType.cNumber || (oldOrderType && oldOrderType.cNumber) ){
                noSupplyCache = true;
            }
            this.props.updateCurrentCart(noSupplyCache);
        });
    };

    setDispatchType = (dispatchTypeId) => {
        this.props.setDispatchTypeId(this.props.cartId, dispatchTypeId).then(() => {
            this.props.updateCurrentCart();
        });
    };

    isValid() {
        return this.props.currentOrderTypeId && this.props.currentDispatchTypeId;
    }

    render() {

        const {t, dealerProperties, currentOrderTypeId, currentDispatchTypeId, readonly, cartSubmitClick} = this.props;
        const {listOpen} = this.state;

        return (
            <Fragment>
                <ListItem button onClick={this.handleListToggleClick}>
                    <ListItemText
                        primaryTypographyProps={{variant: 'h6'}}
                        primary={t('cart_delivery_details_title')}
                        secondaryTypographyProps={{color: (cartSubmitClick ? 'error': 'initial')}}
                        secondary={(this.isValid() || readonly ? '' : t('please_select')) }
                    />
                    {listOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </ListItem>

                <Collapse in={listOpen}>
                    {
                        dealerProperties && dealerProperties.orderTypes &&
                        <OrderTypeSelect
                            orderTypes={dealerProperties.orderTypes}
                            currentOrderTypeId={currentOrderTypeId}
                            onSelect={(oldOrderType, newOrderType) => this.setOrderType(oldOrderType, newOrderType)}
                            readonly={readonly}
                        />
                    }

                    {
                        dealerProperties && dealerProperties.dispatchTypes &&
                        <DispatchTypeSelect
                            dispatchTypes={dealerProperties.dispatchTypes}
                            currentDispatchTypeId={currentDispatchTypeId}
                            onSelect={(dispatchTypeId) => this.setDispatchType(dispatchTypeId)} readonly={readonly}
                        />
                    }
                </Collapse>
            </Fragment>
        );
    }
}

export default compose(
    withCartApi,
    withTranslation(),
)(ListDeliveryDetail);
