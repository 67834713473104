import { store } from '../redux/store';
import { updateBomBaseTriggerItems } from '../redux/catalog/catalogAction';

class CatmetaService {
    static VEHICLE_INFO_KEY = 'vehicleinfo';
    static BASIC_VEHICLE_INFO_KEY = 'vinfoBasic';
    static FALL_BACK_TITLE = 'Fallback Model Title';
    static VEHICLE_END_TRIGGER = 'mainGroupsTable';
    static VEHICLE_END_TRIGGER_ALT = 'graphnavcategories';
    static VEHICLE_END_TRIGGER_RENAULT = 'mainGroupTable';
    static VEHICLE_END_TRIGGER_MERCEDES = 'scopeGroupsTable';
    static VEHICLE_END_VW = 'chemicalsitemstable';

    static SPRING_TRIGGER = 'suspensionoptions';
    static RESTRICTION_HEADLINE_KEY = 'sectionrow';
    static TABLE_WIDGET_TRIGGER = 'table';

    static KEY_BOMLIST = 'bomlist';
    static KEY_BOMLIST_WITH_DEPLOYMENT = 'bomlistWithDeploymentTime';

    static addBomBaseWidget(widgetKey) {
        if (widgetKey && !CatmetaService.isBomBaseWidget(widgetKey)) {
            store.dispatch(updateBomBaseTriggerItems([...store.getState().catalog.bomBaseTriggerItems, widgetKey.toLowerCase()]));
        }
    }

    static isBomBaseWidget(widgetKey) {
        return widgetKey && store.getState().catalog.bomBaseTriggerItems.indexOf(widgetKey.toLowerCase()) !== -1;
    }

    /**
     * mapping to find type in catmeta
     * eg.
     * given: servicePartsItemsTable
     * wanted: filterbom
     *
     * to get "filterbom" the nextwidgetkey need to be "serviceparts" not "servicePartsItemsTable"
     *
     * "serviceparts" : {
        "filter" : {
          "wid" : "servicePartsGroupsTable",
          "definition" : "servicePartsGroupsTable",
          "width" : 0.2
        },
        "filterMaxOne" : true,
        "bom" : {
          "wid" : "servicePartsItemsTable",
          "definition" : "servicePartsItemsTable"
        },
        "type" : "filterbom"
      },
     */
    static convertWidget(nextWidgetKey) {
        if (nextWidgetKey === 'chemicalsGroupsTable' || nextWidgetKey === 'chemicalsItemsTable') {
            nextWidgetKey = 'chemicals';

        } else if (nextWidgetKey === 'suspensionoptions' || nextWidgetKey === 'suspensionbom') {
            nextWidgetKey = 'suspension';

        } else if (nextWidgetKey === 'servicePartsItemsTable' || nextWidgetKey === 'servicePartsGroupsTable') {
            nextWidgetKey = 'serviceparts';
        }

        return nextWidgetKey;
    }

    static getPageByNextWidgetKey(nextWidgetKey) {
        const pages = store.getState().catalog.currentBrand.pages;
        return pages[CatmetaService.convertWidget(nextWidgetKey)];
    }

    static getDefinitionByNextWidgetKey(nextWidgetKey) {
        const definitions = store.getState().catalog.currentBrand.definitions;
        return definitions[nextWidgetKey];
    }

    static getPageWidgetByNextWidgetKey(nextWidgetKey) {
        const pages = store.getState().catalog.currentBrand.pages;
        const pageKeys = Object.keys(pages);
        nextWidgetKey = CatmetaService.convertWidget(nextWidgetKey);
        let widget = null;

        for (let pageKey of pageKeys) {
            const widgets = pages[pageKey].widgets || pages[pageKey].segments;

            if (!widgets) {
                continue;
            }

            const currentWidget = widgets.find(widget => widget.wid === nextWidgetKey);
            if (currentWidget) {
                widget = currentWidget;
                break;
            }
        }
        return widget;
    }

    static getListWidgetByNextWidgetKey(nextWidgetKey) {
        const listDefinitions = store.getState().catalog.currentBrand.listDefinitions;
        return listDefinitions[nextWidgetKey];
    }

    static getCurrentRestriction() {
        const currentPartRestriction = store.getState().catalog.currentPartRestriction;
        const currentVehicleRestriction = store.getState().catalog.currentVehicleRestriction;

        return currentPartRestriction ? currentPartRestriction : currentVehicleRestriction;
    }

    static getPageTitle(nextWidgetKey) {
        const warnings = [];
        let title = '';

        if (!title) {
            const page = this.getPageByNextWidgetKey(nextWidgetKey);

            if (!page) {
                warnings.push('No page');

            } else if (page.title) {
                title = page.title;
            }
        }

        const widget = this.getPageWidgetByNextWidgetKey(nextWidgetKey);
        if (!widget) {
            warnings.push('No widget');

        } else if (widget.title) {
            title = widget.title;
        }

        // get title by list defintion widget
        if (!title) {
            const listWidget = this.getListWidgetByNextWidgetKey(nextWidgetKey);

            if (!listWidget) {
                warnings.push('No widget');

            } else {
                if (!listWidget.elements || !listWidget.elements.length) {
                    warnings.push('No elements on widget');

                } else if (!listWidget.elements[0].title) {
                    warnings.push('No title on first element');

                } else if (listWidget.elements.length === 1) {
                    title = listWidget.elements[0].title;
                }

                if (!title) {
                    if (listWidget.allSpanTitle) {
                        title = listWidget.allSpanTitle;
                    }
                }
            }
        }

        // on multiple elements, get title from last clicked restriction
        if (!title) {
            const currentRestriction = this.getCurrentRestriction();

            if (!currentRestriction) {
                warnings.push('No restriction set');

            } else if (!currentRestriction.values || !currentRestriction.values.caption) {
                warnings.push('No caption on values');
            } else {
                title = currentRestriction.values.caption;
            }
        }

        // warnings.forEach(warning => console.warn(warning));

        return title;
    }
}

export default CatmetaService;