import {
    ADD_PART_HISTORY, CANCEL_ADD_PART_HISTORY,
    CLEAR_PART_HISTORY, REMOVE_LAST_PART_HISTORY,
    REMOVE_PART_HISTORY,
    SET_PART_HISTORY
} from './browserHistoryTypes';

const initialState = {
    partHistory: [],
};

export default function browserHistoryReducer(state = initialState, action) {

    switch (action.type) {
        case ADD_PART_HISTORY:
            return {...state, partHistory: [...state.partHistory, action.value]}
        case REMOVE_PART_HISTORY:
            return {...state, partHistory: [...state.partHistory, action.value]}
        case SET_PART_HISTORY:
            return {...state, partHistory: [...action.value]}
        case CLEAR_PART_HISTORY:
            return {...state, partHistory: []}
        case CANCEL_ADD_PART_HISTORY:
            return {...state}
        case REMOVE_LAST_PART_HISTORY:
            let newPartHistory = state.partHistory;
            newPartHistory.pop();

            return {...state, partHistory: [...newPartHistory]}
        default:
            return state;
    }
}
