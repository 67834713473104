import React, {Fragment} from 'react';
import {compose} from 'redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {withTranslation} from 'react-i18next';
import withCartApi from '../../../api/cartApi';
import {connect} from 'react-redux';
import {Box, Button} from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import withMenu from '../../../service/withMenu';
import {setDealerDialogState} from '../../../redux/cart/cartAction';

class ListDealer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            listOpen: false,
        };
    }

    handleListToggleClick = () => {
        this.setState(state => ({listOpen: !state.listOpen}));
    };

    getDealerAsText() {
        return `${this.props.dealerName}, ${this.props.dealerAddress}`;
    }

    getNoneDealerText() {
        const {t} = this.props;
        return t('no_dealer_selected');
    }

    onChangeDealerClick = () => {
        this.props.setDealerDialogState(true);
    };

    render() {
        const {t, readOnly} = this.props;
        const {listOpen} = this.state;

        return (
            <Fragment>
                <ListItem button onClick={this.handleListToggleClick}>
                    <ListItemText
                        primaryTypographyProps={{variant: 'h6'}}
                        primary={t('dealer')}
                        secondary={this.props.hasDealer ? this.getDealerAsText() : this.getNoneDealerText()}
                    />
                    {readOnly ? null : listOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </ListItem>

                {!readOnly && <Collapse in={listOpen}>
                    <Box border={1} borderColor="border.main" borderTop={0} borderLeft={0} borderRight={0}>
                        <ListItem divider={false}>
                            <Button onClick={this.onChangeDealerClick}>{t('change_dealer')}</Button>
                        </ListItem>
                    </Box>
                </Collapse>}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    hasDealer: state.cart.dealer.dealerId > 0,
    dealerId: state.cart.dealer.dealerId,
    dealerName: state.cart.dealer.companyName,
    dealerAddress: state.cart.dealer.addressLines.join(' '),
});

const mapDispatchToProps = {
    setDealerDialogState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withCartApi,
    withMenu,
)(ListDealer);
