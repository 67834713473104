import React from 'react';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Box,
    MenuItem,
    Select,
    Toolbar,
    Typography
} from '@mui/material';
import SmallIconButton from '../../base/smallIconButton';
import theme from '../../../theme/theme';


const useStyles = (theme) => ({
    modelSubheader: {
        display: 'flex',
        flexDirection: 'row',
    },
    edgeElements: {
        minWidth: '24px',
        width: '24px',
    },
    ellipseContainer: {
        width: '100%',
        textAlign: 'center',
    },
    ellipse: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
});

class RestrictionSubheader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orientation: this.props.orientation | 'center',
        };
    }

    getBackElement() {
        if (this.props.onBackClick) {
            return (
                <SmallIconButton color="inherit" onClick={this.props.onBackClick}>
                    <ArrowBackIcon/>
                </SmallIconButton>
            );
        } else {
            return null;
        }
    }

    getRightElement() {
        if (this.props.rightElement) {
            return (this.props.rightElement);
        } else {
            return null;
        }
    }

    isTitleStandalone() {
        return !this.props.onBackClick && !this.props.rightElement;
    }

    render() {
        const {classes, dropdown} = this.props;
        const isDropdown = dropdown && dropdown.length > 0 && this.props.selectedValue;

        const stylesToolbar = this.isTitleStandalone() ? {
            justifyContent: 'flex-start',
        } : {
            justifyContent: 'space-between',
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
        };

        return (
            <Toolbar sx={{
                ...stylesToolbar,
                minHeight: `${theme.spacing(5)} !important`,
            }}>
                <Box className={(this.isTitleStandalone() ? '' : classes.edgeElements)}>
                    {this.getBackElement()}
                </Box>

                <Box className={classes.ellipseContainer}>
                    {!isDropdown && <Typography variant="h6" component="h2" noWrap={true}
                                className={classes.ellipse}>{this.props.title}</Typography>}
                    {isDropdown &&
                    <Select
                        labelId="select-images"
                        id="select-images"
                        value={this.props.selectedValue}
                        onChange={this.props.handleSelectChange}
                    >
                        {dropdown.map((name, i) => <MenuItem key={i} value={name}>{name}</MenuItem>)}
                    </Select>
                    }
                </Box>

                <Box className={(this.isTitleStandalone() ? '' : classes.edgeElements)}>
                    {this.getRightElement()}
                </Box>
            </Toolbar>
        );
    }
}

export default compose(
    withStyles(useStyles),
)(RestrictionSubheader);
