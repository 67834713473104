import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {addPartHistory} from '../../redux/browserHistory/browserHistoryAction';
import {Component} from 'react';


class HistoryListener extends Component {
    componentDidMount() {
        this.props.history.listen((location, action) => {
            if (location.pathname.indexOf('/catalog/part') >= 0) {
                if (this.props && this.props.partHistory[this.props.partHistory.length - 1] !== location.pathname) {
                    this.props.addPartHistory(location.pathname);
                }
            }
        });
    }

    render() {
        return this.props.children
    }

}

const mapDispatchToProps = dispatch => ({
    addPartHistory: (payload) => dispatch(addPartHistory(payload)),
});

const mapStateToProps = state => ({
    currentBrand: state.catalog.currentBrand,
    partHistory: state.browserHistory.partHistory
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
)
(HistoryListener);