import React from 'react';
import {
    Dialog,
    DialogContent,
    DialogContentText
} from '@mui/material';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';


class PwaResolutionMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = { isBadResolution: false };
        this.isSupportingScreenOrientation = this.supportsScreenOrientation()
    }

    componentDidMount() {
        this.setScreenDimensions();

        if (window.screen.orientation) {
            window.screen.orientation.addEventListener('change', () => {
                this.setScreenDimensions();
            });
        } else {
            window.addEventListener('orientationchange', () => {
                setTimeout(() => {
                    this.setScreenDimensions();
                }, 100);
            });
        }
    }

    setScreenDimensions() {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const smallerSide = Math.min(screenWidth, screenHeight);

        if (smallerSide >= 450) {
            return;
        }

        if (this.isSupportingScreenOrientation) {
            this.setState({ isBadResolution: window.screen.orientation.type.includes('landscape') });
        } else if (typeof window.orientation !== 'undefined') {
            this.setState({ isBadResolution: window.orientation !== 0 });
        } else {
            this.setState({ isBadResolution: window.innerHeight < window.innerWidth });
        }   
    }

    supportsScreenOrientation() {
        return typeof window.screen.orientation !== 'undefined' &&
            !navigator.userAgent.toLowerCase().includes('redmi note') &&
            // on Desktop PWA -> orientation not working --> orientation with angel 0 BUT type landscape-primary (Feb. 2021)
            !(window.screen.orientation.angle === 0 && window.screen.orientation.type === 'landscape-primary')
    }

    render() {
        const { t } = this.props;

        return (
            <Dialog
                open={this.state.isBadResolution}
                aria-labelledby="resolution-dialog-title"
                aria-describedby="resolution-dialog-description"
            >
                <DialogContent>
                    <DialogContentText>
                        {t('pwa_wrong_resolution_message')}
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

export default compose(
    withTranslation(),
)(PwaResolutionMessage);