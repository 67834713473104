import {
    UPDATE_CATALOG_DEALERS,
    ADD_CATALOG_DEALER,
    SET_CATALOG_DEALER, SET_LAST_DEALERS,
    RESET_CATALOG_DEALER,
} from './dealerManagementTypes';

const initialState = {
    lastDealers: [],
    catalogDealers: [],
    catalogDealer: {
        dealer: {
            addressLines: []
        }
    }
};

export default function dealerManagementReducer(state = initialState, action) {

    switch (action.type) {
        case ADD_CATALOG_DEALER:
            return {...state, catalogDealers: [...state.catalogDealers, action.value]}
        case SET_CATALOG_DEALER:
            return {...state, catalogDealer: {...action.value}}
        case RESET_CATALOG_DEALER:
            return {...state, catalogDealer: initialState.catalogDealer}
        case UPDATE_CATALOG_DEALERS:
            return {
                ...state, catalogDealers: state.catalogDealers.map((catalogDealer) => {
                    if (catalogDealer.id.toString() !== action.value.id.toString()) {
                        // This isn't the item we care about - keep it as-is
                        return catalogDealer
                    }

                    // Otherwise, this is the one we want - return an updated value
                    return {
                        ...catalogDealer,
                        ...action.value
                    }
                })
            }
        case SET_LAST_DEALERS:
            return {...state, lastDealers: [...action.value]}
        default:
            return state;
    }
}
