import React, { Fragment } from 'react';
import Container from '../../base/container';
import { compose} from 'redux';
import { connect } from 'react-redux';
import { addItemToSelectHistory, updateSearchDialogState } from '../../../redux/search/searchAction';
import LastSelected from '../lastSelected';
import SearchResults from './searchResults';
import withSearchApi from '../../../api/searchApi';
import {selectHistoryByBrand} from '../../../redux/search/searchReducer';
import {
    resetCurrentPart,
    resetRestrictions,
    updateCurrentVehicle
} from '../../../redux/catalog/catalogAction';
import withCatalog from '../../../service/withCatalog';
import VehicleService from '../../../service/vehicleService';
import LoadingBackdrop from '../../base/loadingBackdrop';
import { loadingAreas } from '../../../config/loadingAreas';
import { withRouter } from 'react-router-dom';
import catmetaService from '../../../service/catmetaService';
import CatalogService from '../../../service/catalogService';

class SearchVehicle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchResults: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.searchQuery && prevProps.searchQuery !== this.props.searchQuery) {
            this.handleSearchQueryChange();
        }
    }

    handleSearchQueryChange = () => {
        this.setState({ searchResults: [] });
        
        this.props.searchVehicle().then(response => {
            if (this.isMessageOnly(response)) {
                this.showMessage(response.messages);

                return;
            }
    
            if (this.hasMessage(response)) {
                this.showMessage(response.messages);
            }
    
            if (this.isSingleElementResponse(response)) {
                this.navigateToVehicleOverviewByItem(response.data);

                return;
            }
    
            if (this.isMultipleElementResponse(response)) {
                this.setState({ 
                    searchResults: this.state.searchResults.concat(response.data.narrowDownOptions)
                });
    
                return;
            }
    
            if (this.isFoundButNoDataResponse(response)) {
                this.navigateToVehicleOverviewByItem(response.data);

                return;
            }
    
            if (this.isGoToResponse(response)) {
                this.handleGoTo(response.data.p5goto.ws.find(obj => obj.wid === 'scopeGroupsTable'));

                return;
            }
        });
    };

    handleGoTo(vehicle) {
        if (!vehicle.path) {
            return;
        }

        this.props.getExtendedVehicle(vehicle.path).then(extendedVehicleObject => {
            // build up needed object based on provided information
            const data = {
                segments: {
                    vinfoBasic: {
                        records: [],
                        showUnformattedText: true,
                    }
                },
                link: {
                    wid: 'scopeGroupsTable',
                    path: vehicle.path,
                },
                vin: this.props.searchQuery,
                description: '',
            };

            if (extendedVehicleObject.crumbs.length) {
                data.segments.vinfoBasic.records = extendedVehicleObject.crumbs.map(crumb => {
                    let definition = catmetaService.getDefinitionByNextWidgetKey(crumb.link.wid);
                    return {
                        values: {
                            description: definition ? definition.title : '',
                            value: crumb.name,
                        }
                    }
                })
            }

            this.navigateToVehicleOverviewByItem(data);
            this.props.resetCurrentPart();
            this.props.routeToPartRootComponent();
        });
    }

    isGoToResponse(response) {
        return CatalogService.isMercedes() && response.data && response.data.resultStatus === 'GOTO' && response.data.p5goto &&
            response.data.p5goto.ws.length && response.data.p5goto.ws.find(obj => obj.wid === 'mainGroupsTable');
    }

    isFoundButNoDataResponse(response) {
        return response.data && response.data.resultStatus === 'VEHICLE_IDENTIFIED' && response.data.link && response.data.link.path;
    }

    isSingleElementResponse(response) {
        return response.data && response.data.description && !response.data.narrowDownOptions;
    }

    isMultipleElementResponse(response) {
        return response.data && !response.data.description && response.data.narrowDownOptions;
    }

    hasMessage(response) {
        return response.messages && response.messages.length;
    }

    isMessageOnly(response) {
        return this.hasMessage(response) && !response.data && !response.data.description && !response.data.narrowDownOptions;
    }

    showMessage(messages) {
        const msg = messages.join('. ');
        this.props.updateSearchDialogState(msg);
    }

    handleRouteToVehicleOverview(vehicle) {
        this.props.updateCurrentVehicle(vehicle);
        this.props.addItemToSelectHistory(vehicle);
        this.props.routeToVehicleOverview();
    }

    navigateToVehicleOverviewByItem(vehicleItem) {
        const vehicle = VehicleService.getExtendedVehicleFromObject(vehicleItem);
        this.handleRouteToVehicleOverview(vehicle);
    }

    handleItemClick = vehicleItem => () => this.navigateToVehicleOverviewByItem(vehicleItem);
    handleItemSelection = item => () => this.handleRouteToVehicleOverview(item);

    render() {
        return (
            <Fragment>
                <Container paddingX overflow>
                    {this.state.searchResults && this.state.searchResults.length
                        ? (
                            <SearchResults 
                                searchResults={this.state.searchResults} 
                                onSearchItemClick={this.handleItemClick}
                            />
                        ) : (
                            <LastSelected 
                                items={this.props.selectHistoryByBrand} 
                                onItemClick={this.handleItemSelection}
                            />
                        )}
                    <LoadingBackdrop area={loadingAreas.search}/>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    searchQuery: state.search.searchQuery,
    currentBrand: state.catalog.currentBrand,
    currentVehicle: state.catalog.currentVehicle,
    selectHistoryByBrand: selectHistoryByBrand(state),
});

const mapDispatchToProps = dispatch => ({
    addItemToSelectHistory: (payload) => dispatch(addItemToSelectHistory(payload)),
    updateSearchDialogState: (payload) => dispatch(updateSearchDialogState(payload)),
    resetCurrentPart: (payload) => dispatch(resetCurrentPart(payload)),
    updateCurrentVehicle: (payload) => dispatch(updateCurrentVehicle(payload)),
    resetRestrictions: (payload) => dispatch(resetRestrictions(payload)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withSearchApi,
    withCatalog,
    withRouter,
)
(SearchVehicle);
