import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '../base/container';

class Home extends React.Component {
    render() {
        return (
            <Container>
                <Typography variant="h2">Home</Typography>
            </Container>
        );
    }
}

export default (Home);
