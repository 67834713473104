import React, {Fragment} from 'react';
import {compose} from 'redux';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {withTranslation} from 'react-i18next';
import Paper from '@mui/material/Paper';
import {withStyles} from '@mui/styles';
import {connect} from 'react-redux';
import ModelSubheader from '../../catalog/restriction/restrictionSubheader';
import FormattedText from '../../text/formattedText';

const useStyles = () => ({
    tableContainer: {
        height: 'calc(100% - 35px)',
    },
    tableCell: {
        verticalAlign: 'top',
    },
});

class SearchResultTable extends React.Component {

    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
    }

    componentDidMount() {
        this.containerRef.current.addEventListener('scroll', this.props.handleScroll, true);
    }

    componentWillUnmount() {
        this.containerRef.current.removeEventListener('scroll', this.props.handleScroll, true);
    }

    getTableHeadCells() {
        const {classes} = this.props;

        if (!this.checkIfSearchResultHeadersAreDefined()) {
            return null;
        }

        return (
            this.props.currentBrand.definitions.searchresult.elements.map((item, index) => (
                <TableCell key={item.key + '_' + index} className={classes.tableCell}>{item.title}</TableCell>
            ))
        );
    }

    getTableBodyRows() {
        const {classes} = this.props;

        if (!this.checkIfSearchResultHeadersAreDefined()) {
            return null;
        }

        return (
            this.props.searchResults.map((row, index) => (
                <TableRow key={row.id + '_' + index} onClick={this.props.onRowClick(row)}>
                    {this.props.currentBrand.definitions.searchresult.elements.map((headerItem, index) => {
                        const item = row.values[headerItem.id];

                        if (item) {
                            return (<TableCell key={index} className={classes.tableCell}><FormattedText
                                value={item}/></TableCell>);
                        } else {
                            return null;
                        }
                    })}
                </TableRow>
            ))
        );
    }

    checkIfSearchResultHeadersAreDefined() {
        if (!this.props.currentBrand.definitions || !this.props.currentBrand.definitions.searchresult ||
            !this.props.currentBrand.definitions.searchresult.elements) {
            console.error('Error happened on getTableHeadCells: table-heads not inside \'definitions.searchresult.elements\'');
            return false;
        }
        return true;
    }

    render() {
        const {t, classes} = this.props;

        return (
            <Fragment>
                <ModelSubheader title={t('search_search_results')}/>
                <TableContainer component={Paper} className={this.props.searchResults.length ? classes.tableContainer : null} ref={this.containerRef}>
                    <Table stickyHeader className={classes.table} aria-label="part-result-table">
                        <TableHead><TableRow>{this.getTableHeadCells()}</TableRow></TableHead>
                        <TableBody>{this.getTableBodyRows()}</TableBody>
                    </Table>
                </TableContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentBrand: state.catalog.currentBrand,
});

const mapDispatchToProps = dispatch => ({});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withStyles(useStyles),
)(SearchResultTable);