import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DefaultHeader from './header/defaultHeader';
import VehicleHeader from './header/vehicleHeader';
import Container from '../base/container';
import {
    groupSearch,
    routePartMainRestriction, 
    routeVehicleMainRestriction,
} from '../../config/routeItems';
import PartHeader from './header/partHeader';
import SearchInput from '../search/searchInput';
import VinLens from '../base/vinLens';
import { palette } from '../../theme/baseTheme';

class AppHeader extends React.Component {
    render() {
        return (
            <AppBar 
                id="header-top" 
                position='static' 
                color='default' 
                sx={{
                    borderBottom: `1px solid ${palette.border.main}`
            }}>
                <Container maxWidth="md" disableGutters>
                    <Toolbar>
                        <Switch>
                            <Route path={groupSearch.map(item => item.url())}>
                                <SearchInput/>
                                {navigator.mediaDevices && <VinLens />}
                            </Route>
                            <Route path={routeVehicleMainRestriction.url()}>
                                <VehicleHeader/>
                            </Route>
                            <Route path={routePartMainRestriction.url()}>
                                <PartHeader/>
                            </Route>
                            <Route>
                                <DefaultHeader/>
                            </Route>
                        </Switch>
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }
}

export default AppHeader;
