import React, {Fragment} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {
    catalogRoutes,
    routeBrandSelection,
    routeCatalogPart,
    routeCatalogVehicle,
    routePartMainRestriction, routePartOverview,
    routePartSubRestriction,
    routeVehicleOverview,
    routeVehicleMainRestriction,
    routeVehicleSubRestriction, routeSearchVehicle, routePartInformation, routeBomBaseOverviewTable,
} from '../../config/routeItems';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {compose} from 'redux';
import {promiseTrackerHoc} from 'react-promise-tracker';
import LoadingBackdrop from '../base/loadingBackdrop';
import {loadingAreas} from '../../config/loadingAreas';
import withCatalog from '../../service/withCatalog';
import SearchDialog from '../search/searchDialog';
import CatmetaService from '../../service/catmetaService';

class Catalog extends React.Component {

    constructor(props) {
        super(props);

        this.extendCatalogRoutes();
    }

    componentDidMount() {
        const {history} = this.props;

        if (history.location.pathname.indexOf(routeCatalogVehicle.url()) !== -1) {
            this.moveToCurrentVehiclePosition();
        }
    }

    moveToCurrentVehiclePosition() {
        const {history} = this.props;
        if (this.props.currentVehicle.id) {
            this.props.routeToVehicleOverview(true);

        } else if (!routeVehicleMainRestriction.redirectConditions() && history.location.pathname.indexOf(routeVehicleMainRestriction.url()) === -1) {
            this.props.routeToMainVehicle(true, this.props.currentBrand.id);
        }
    }

    moveToCurrentPartPosition() {
        const {history} = this.props;
        if (!routePartOverview.redirectConditions() && history.location.pathname.indexOf(routePartOverview.url()) === -1) {
            this.props.routeToPartOverview(true);
        }
    }

    onEnter(routeItem) {
        if (routeItem.redirectConditions && routeItem.redirectConditions()) {
            return (<Redirect to={{pathname: routeBrandSelection.url()}}/>);
        } else {
            return (routeItem.component());
        }
    }

    addRedirects() {
        if (!this.props.currentBrand) {
            return null;
        }

        return (
            <Redirect
                from={routeCatalogPart.url()}
                to={routePartMainRestriction.url(this.props?.currentBrand?.id)}
            />
        );
    }

    extendCatalogRoutes() {
        routeVehicleMainRestriction.redirectConditions = () => !this.props.currentBrand.id;
        routeVehicleSubRestriction.redirectConditions = () => !this.props.currentBrand.id || !this.props.currentVehicleRestriction.id;
        routeVehicleOverview.redirectConditions = () => (!this.props.currentVehicle.id && !this.props.currentVehicle.vin);
        routeCatalogPart.redirectConditions = () => !this.props.currentBrand.id || !this.props.currentVehicle.id || !this.props.currentVehicle?.link;
        routePartMainRestriction.redirectConditions = () => !this.props.currentBrand.id || !this.props.currentVehicle.id || !this.props.currentVehicle?.link;
        routePartSubRestriction.redirectConditions = () => !this.props.currentBrand.id || !this.props.currentPartRestriction.id;
        routePartOverview.redirectConditions = () => !this.props.currentPart.link;
        routeSearchVehicle.redirectConditions = () => !this.props.currentBrand.id;
        routePartInformation.redirectConditions = () => !this.props.currentPartInformation;
        routeBomBaseOverviewTable.redirectConditions = () => this.checkBomBaseTableConditions();
    }

    checkBomBaseTableConditions() {
        if (this.props.currentBomBasePart?.nextWidgetKey) {
            return !CatmetaService.isBomBaseWidget(this.props.currentBomBasePart.nextWidgetKey.toLowerCase());
        } else if (this.props.currentVehicle.nextWidgetKey) {
            return !CatmetaService.isBomBaseWidget(this.props.currentVehicle.nextWidgetKey.toLowerCase());
        }
        return true;
    }

    render() {
        return (
            <Fragment>
                <Switch>
                    {catalogRoutes.map((item) =>
                        (
                            <Route
                                key={item.key}
                                exact={item.exact}
                                path={item.url()}>
                                {/* todo better redirecting when new Router is released: do check only if entered not on each change */}
                                {this.onEnter(item)}
                            </Route>
                        ),
                    )}
                    {this.addRedirects()}
                </Switch>

                {/* need to be in catalog-component and not in search-component
                because ah dialog can be shown after switch to vehicle-overview (outside of search-component */}
                <SearchDialog/>

                <LoadingBackdrop area={loadingAreas.catalog}/>
                <LoadingBackdrop area={loadingAreas.cart}/>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentBrand: state.catalog.currentBrand,
    currentVehicleRestriction: state.catalog.currentVehicleRestriction,
    currentVehicle: state.catalog.currentVehicle,
    currentPartRestriction: state.catalog.currentPartRestriction,
    currentPart: state.catalog.currentPart,
    currentPartPath: state.catalog.currentPartPath,
    currentPartInformation: state.catalog.currentPartInformation,
    currentBomBase: state.catalog.currentBomBase,
    currentBomBasePart: state.catalog.currentBomBasePart,
});

export default compose(
    connect(
        mapStateToProps,
    ),
    promiseTrackerHoc,
    withCatalog,
    withRouter,
)
(Catalog);

