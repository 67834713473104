import React from 'react';
import {connect} from 'react-redux';
import {updateInstallMessageState} from '../../redux/global/globalAction';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {IosShareIcon} from './additionalIcons';
import {Trans, withTranslation} from 'react-i18next';
import {compose} from 'redux';
import {withStyles} from '@mui/styles';

const useStyles = () => ({
    dialog: {
        marginLeft: '0',
    },
    button: {
        width: 'auto',
    }
});

class NoSuspensionMessage extends React.Component {

    constructor(props) {
        super(props);

        this.handleNoSuspensionMessageClose = this.handleNoSuspensionMessageClose.bind(this);
    }

    handleNoSuspensionMessageClose() {
        this.props.handleNoSuspensionMessageClose();
    };

    render() {
        const {t, classes} = this.props;

        console.log(this.props.noSuspensionMessageState);
        return (
            <Dialog
                open={this.props.noSuspensionMessageState}
                onClose={this.handleNoSuspensionMessageClose}
                aria-labelledby="install-dialog-title"
                aria-describedby="install-dialog-description"
            >
                <DialogContent>
                    <DialogContentText>
                        <Trans i18nKey="pwa_no_suspension_message" components={{shareIcon: <IosShareIcon/>}}/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    className={classes.dialog}>
                    <Button onClick={this.handleNoSuspensionMessageClose} className={classes.button}>
                        {t('ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    installMessageShow: state.global.installMessageShow,
});

const mapDispatchToProps = {
    updateInstallMessageState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withStyles(useStyles),
)(NoSuspensionMessage);
