import * as React from 'react';
import {compose} from 'redux';
import {
    routeBrandSelection,
    routeCatalogPart,
    routeCatalogVehicle, routeBomBaseInformation, routeBomBaseOverviewTable,
    routePartInformation,
    routePartMainRestriction,
    routePartOverview,
    routePartSubRestriction,
    routeVehicleMainRestriction,
    routeVehicleOverview,
    routeVehicleSubRestriction,
    routeSpringRestriction, routeSearchVehicle,
} from '../config/routeItems';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {resetCurrentPartDetailOverview} from '../redux/catalog/catalogAction';
import {setPartHistory} from '../redux/browserHistory/browserHistoryAction';

function withCatalog(WrappedComponent) {
    class HOC extends React.Component {
        routeToRootComponent = () => {
            const {history} = this.props;

            history.push(routeBrandSelection.url());
        };

        routeToModel = (brandId, replace = false) => {
            const {history} = this.props;

            if (history.location.pathname.indexOf(routeCatalogVehicle.url()) !== -1) {
                this.routeToMainVehicle(replace, brandId);

            } else if (history.location.pathname.indexOf(routeCatalogPart.url()) !== -1) {
                this.routeToMainPart(replace, brandId);
            }
        };

        routeToSubModel = (restrictionId) => {
            const {history} = this.props;

            if (history.location.pathname.indexOf(routeCatalogVehicle.url()) !== -1) {
                history.push(routeVehicleSubRestriction.url(this.props.currentBrand.id, restrictionId));

            } else if (history.location.pathname.indexOf(routeCatalogPart.url()) !== -1) {
                history.push(routePartSubRestriction.url(this.props.currentBrand.id, restrictionId));
            }
        };

        // replace is needed on a redirects to prevent loops on history.back
        routeToMainVehicle = (replace, brandId) => {
            const {history} = this.props;
            history.push(routeVehicleMainRestriction.url(brandId), replace);
        }

        routeToMainPart = (replace, brandId) => {
            const {history} = this.props;
            history.push(routePartMainRestriction.url(brandId), replace);
        }

        routeToVehicleOverview = (replace) => {
            this.historyGoTo(routeVehicleOverview.url(), replace);
        };

        routeToPartRootComponent = (replace) => {
            this.historyGoTo(routePartMainRestriction.url(this.props.currentBrand.id), replace);
        };

        routeToPartOverview = (replace) => {
            this.props.resetCurrentPartDetailOverview();
            this.historyGoTo(routePartOverview.url(), replace);
        };

        routeToPartInformation = (replace) => {
            this.historyGoTo(routePartInformation.url(), replace);
        };

        routeToSpringOverview = (replace) => {
            this.historyGoTo(routeSpringRestriction.url(this.props.currentBrand.id), replace);
        };

        routeToBomBaseOverview = (replace) => {
            const routeArea = this.props.history.location.pathname.indexOf(routeCatalogVehicle.url()) !== -1 ? 'vehicle' : 'part';
            this.historyGoTo(routeBomBaseOverviewTable.url(routeArea), replace);
        };

        routeToVehicleInformation = (replace) => {
            const routeArea = this.props.history.location.pathname.indexOf(routeCatalogVehicle.url()) !== -1 ? 'vehicle' : 'part';
            this.historyGoTo(routeBomBaseInformation.url(routeArea), replace);
        };

        routeToSearch = (replace) => {
            const routeArea = this.props.history.location.pathname.indexOf(routeCatalogVehicle.url()) !== -1 ? 'vehicle' : 'part';
            this.historyGoTo(routeSearchVehicle.url(routeArea), replace);
        };

        partHistoryGoBack = () => {
            let {history, partHistory} = this.props;

            if(partHistory.length > 1) {
                history.push(partHistory[partHistory.length - 2]);
                partHistory.pop();
                this.props.setPartHistory(partHistory);
            }
        }

        historyGoTo(routeUrl, replace = false){
            const {history} = this.props;
            let historyNavigation = history.push;
            if(replace){
                historyNavigation = history.replace;
            }
            historyNavigation(routeUrl);
        }

        render() {
            return <WrappedComponent
                routeToRootComponent={this.routeToRootComponent}
                routeToModel={this.routeToModel}
                routeToSubModel={this.routeToSubModel}
                routeToMainVehicle={this.routeToMainVehicle}
                routeToMainPart={this.routeToMainPart}
                routeToVehicleOverview={this.routeToVehicleOverview}
                routeToPartRootComponent={this.routeToPartRootComponent}
                routeToPartOverview={this.routeToPartOverview}
                routeToPartInformation={this.routeToPartInformation}
                routeToBomBaseOverview={this.routeToBomBaseOverview}
                routeToVehicleInformation={this.routeToVehicleInformation}
                routeToSpringOverview={this.routeToSpringOverview}
                partHistoryGoBack={this.partHistoryGoBack}
                routeToSearch={this.routeToSearch}
                {...this.props} />;
        }
    }

    const mapStateToProps = state => ({
        currentBrand: state.catalog.currentBrand,
        partHistory: state.browserHistory.partHistory,
    });

    const mapDispatchToProps = (dispatch) => ({
        resetCurrentPartDetailOverview: () => dispatch(resetCurrentPartDetailOverview()),
        setPartHistory: (payload) => dispatch(setPartHistory(payload)),
    });

    return compose(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
        withRouter,
    )
    (HOC);
}

export default withCatalog;
