import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {compose} from 'redux';
import {Box} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListSubheader from '@mui/material/ListSubheader';
import {withTranslation} from 'react-i18next';


class OrderTypeSelect extends React.Component {

    getOrderTypeById(orderTypeId) {
        return this.props.orderTypes.find(orderType => orderType.id === orderTypeId);
    }

    render() {
        const {t, orderTypes, currentOrderTypeId, onSelect, readonly} = this.props;

        return (
            <Box border={1} borderColor="border.main" borderTop={0} borderLeft={0} borderRight={0}>

                <List
                    dense
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {t('cart_order_typ_title')}
                        </ListSubheader>
                    }
                >
                    {orderTypes &&
                    orderTypes.map((orderType) => {
                        return (
                            <ListItem
                                key={orderType.id}
                                button
                                onClick={() => onSelect(this.getOrderTypeById(currentOrderTypeId), orderType)}
                                disabled={readonly}>

                                {
                                    orderType.id === currentOrderTypeId && (
                                        <ListItemIcon>
                                            <CheckCircleIcon/>
                                        </ListItemIcon>
                                    )
                                }

                                <ListItemText
                                    primary={orderType.namePretty}
                                    inset={orderType.id !== currentOrderTypeId}
                                />

                            </ListItem>
                        );
                    })
                    }
                </List>

            </Box>
        );
    }

}


export default compose(
    withTranslation(),
)(OrderTypeSelect);

