import * as React from 'react';
import {withSnackbar as withNotistackSnackbar} from 'notistack';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';

function withSnackbar(WrappedComponent) {
    class HOC extends React.Component {

        closeSnack = (snackbarKey) => {
            if(!snackbarKey) {
                return;
            }

            this.props.closeSnackbar(snackbarKey);
        };

        closeAllSnacks = () => {
            this.props.closeSnackbar()
        };

        enqueueInfoSnackbar = (message, options) => {
            const key = this.props.enqueueSnackbar(message, {
                ...options,
                variant: 'info',
                onClick: () => this.closeSnack(key),
            });

            return key;
        };

        enqueueSuccessSnackbar = (message, options) => {
            const key = this.props.enqueueSnackbar(message, {
                ...options,
                variant: 'success',
                onClick: () => this.closeSnack(key),
            });

            return key;
        };

        enqueueWarningSnackbar = (message, options) => {
            const key = this.props.enqueueSnackbar(message, {
                ...options,
                variant: 'warning',
                onClick: () => this.closeSnack(key),
            });

            return key;
        };

        enqueueErrorSnackbar = (message, options) => {
            const key = this.props.enqueueSnackbar(message, {
                variant: 'error',
                persist: true,
                onClick: () => this.closeSnack(key),
                ...options,
            });

            return key;
        };

        render() {
            return <WrappedComponent
                enqueueInfoSnackbar={this.enqueueInfoSnackbar}
                enqueueSuccessSnackbar={this.enqueueSuccessSnackbar}
                enqueueWarningSnackbar={this.enqueueWarningSnackbar}
                enqueueErrorSnackbar={this.enqueueErrorSnackbar}
                closeAllSnacks={this.closeAllSnacks}
                {...this.props} />;
        }
    }

    return compose(
        withNotistackSnackbar,
        withTranslation(),
    )
    (HOC);
}

export default withSnackbar;
