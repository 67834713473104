import React from 'react';
import {compose} from 'redux';
import {withStyles} from '@mui/styles';

const useStyles = () => ({
    markupBold: {
        display: 'inline',
        fontWeight: 'bold',
    },
    markupHit: {
        display: 'inline-block',
        backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAALCAYAAACtWacbAAAAAXNSR0IArs4c6QAAAF1JREFUGNOtkLENgDAMBA2ioWaAZIuslU2c9WCGMMNRgBEhikjBV5b/fIVF/soA8AWNPaYm5DcvaU9tyMowh3PBFbc6NGs1A0x2HZd4GwrL0wSgWSsLQAEZ+E7Xnw4RgXJKtDrC0AAAAABJRU5ErkJggg==)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '9px',
        height: '11px',
    },
    markupMiss: {
        display: 'inline-block',
        backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAALCAYAAACtWacbAAAAAXNSR0IArs4c6QAAAItJREFUGNOt0LENgzAUhOELkqkZw4091ZvIFcKeAJbwDBaCJdjhT0UIVRIpV13xFaeT/pUHwCfUnWUvRUuMyn2vJUbtpVwKYMuZUaKacbRGNWOU2HIGQABzCFQz3lPNmEO40OQcR2s3dLTG5BwAnSQN3mtN6TZ2TUmD9z9uOuEcApNzzCG8AMBXPz0B4UyeMzKprgcAAAAASUVORK5CYII=);',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '9px',
        height: '11px',
    },
    markupUnknown: {
        display: 'inline-block',
        backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAALCAYAAACtWacbAAAAAXNSR0IArs4c6QAAALBJREFUGNOt0L0KgnAUBfBjTVqTQWA0avQEPUsIPo0UmO9gQ0uv0lSOQVuEfxWELC0COw325VAtne1y7/3BvcC/IpHkr6Hae3GKIsz7Bs5J8nnId8fYrzfwJ6MqxXvyMOS0pTBcgJ6qMI/jR4tPaenY0M0C7QGgD69VjSQzIeipCo87kAQP21LLhHhJK8dGzyrQ6JSLzS5gmAV8t9SkLAg407SP51tCfP/TJU1Rl2XcADxkZfx2pshqAAAAAElFTkSuQmCC)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: '9px',
        height: '11px',
    },
});

class FormattedText extends React.Component {

    constructor(props) {
        super(props);

        this.markupHighlightRegEx = /\[([\s\S]*?)]/g
        this.uniqueString = 'xAvI23P^KLrWOb$kFY%$%)(zvamsX' // can not contain characters that are needed for formatting -> - + #
        this.ignoredMarkupRegEx = new RegExp('(\\\\\\[|\\\\]|\\\\-|\\\\\\+|\\\\#)')

        this.markupBoldStartPlaceholder = '{%MARKUPBOLDSTART%}';
        this.markupBoldEndPlaceholder = '{%MARKUPBOLDEND%}';
        this.markupHitPlaceholder = '{%MARKUPHIT%}';
        this.markupMissPlaceholder = '{%MARKUPMISS%}';
        this.markupUnknownPlaceholder = '{%MARKUPUNKNOWN%}';
    }

    formatNewLines(text) {
        let lines, formattedLines;

        if (text && typeof text === 'string' && text.indexOf('\n')) {
            lines = text.split('\n');
            formattedLines = lines.map((line, i) => {
                return `${line}${i === lines.length - 1 ? '' : '<br/>'}`
            })

            return formattedLines.join('');
        }
        return text;
    }

    getFormattedText(value) {
        const {classes} = this.props;

        try {
            if (value.match(this.markupHighlightRegEx)) {
                value.match(this.markupHighlightRegEx).forEach(match => {

                    if (!this.hasLeadingEscape(value, match)) {
                        let formattedMatch = `${this.markupBoldStartPlaceholder}${match.substring(1, match.length - 1)}${this.markupBoldEndPlaceholder}`;
                        value = value.replace(match, formattedMatch)
                    }
                })
            }

            if (value.indexOf('+') >= 0) {
                value = value.replaceAll('\\+', this.uniqueString)
                value = value.replaceAll('+', this.markupHitPlaceholder)
                value = value.replaceAll(this.uniqueString, '\\+')
            }

            if (value.indexOf('-') >= 0) {
                value = value.replaceAll('\\-', this.uniqueString)
                value = value.replaceAll('-', this.markupMissPlaceholder)
                value = value.replaceAll(this.uniqueString, '\\-')
            }

            if (value.indexOf('#') >= 0) {
                value = value.replaceAll('\\#', this.uniqueString)
                value = value.replaceAll('#', this.markupUnknownPlaceholder)
                value = value.replaceAll(this.uniqueString, '\\#')
            }

            value = value.replaceAll(this.markupBoldStartPlaceholder, `<div class=${classes.markupBold}>`);
            value = value.replaceAll(this.markupBoldEndPlaceholder, `</div>`);
            value = value.replaceAll(this.markupHitPlaceholder, `<div class=${classes.markupHit}></div>`);
            value = value.replaceAll(this.markupMissPlaceholder, `<div class=${classes.markupMiss}></div>`);
            value = value.replaceAll(this.markupUnknownPlaceholder, `<div class=${classes.markupUnknown}></div>`);

            while (value.search(this.ignoredMarkupRegEx) >= 0) {
                let pos = value.search(this.ignoredMarkupRegEx);
                value = value.substring(0, pos) + value.substring(pos + 1, value.length);
            }

            value = value.replace(this.ignoredMarkupRegEx, '');
        } catch (e) {
            console.error('ERROR IN FORMAT');
            console.log(e);
        }

        return this.formatNewLines(value);
    }

    hasLeadingEscape(value, match) {
        return value.substr(value.indexOf(match) - 1, 1) === '\\'
    }


    render() {
        const {value, onClick} = this.props;
        let newValue = value;

        if(!value) {
            newValue = '';
        }

        if (this.props.formatTabs && newValue) {
            newValue = this.getFormattedText(value.replace('\t', '&ensp;'));
        } else {
            newValue = this.getFormattedText(newValue);
        }

        return (
            <span onClick={onClick ? onClick : null} dangerouslySetInnerHTML={{__html: newValue}}/>
        );
    }
}

export default compose(
    withStyles(useStyles),
)
(FormattedText);