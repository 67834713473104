import {store} from '../redux/store';
import CatmetaService from './catmetaService';
import RestrictionService from './restrictionService';

class VehicleService {

    static getSimpleVehicleFromObject(vehicleObject, listDefinitions = null) {
        if (!vehicleObject) {
            return [];
        }

        // tempTitle is title from next element in loop
        let tempTitle = null;
        return vehicleObject.map(vehicle => {
            const title = tempTitle;
            let key = 'description';
            tempTitle = vehicle.link.wid;

            if(listDefinitions.vinfoBasic && listDefinitions.vinfoBasic.elements && listDefinitions.vinfoBasic.elements.length) {
                key = listDefinitions.vinfoBasic.elements[0].id;
            }

            // only add items with title
            if (!title) {
                return null;
            }

            return {
                values: {
                    [`${key}`]: CatmetaService.getPageTitle(title),
                    value: vehicle.name,
                },
            };
        }).filter((item) => item);
    }

    static getExtendedVehicleFromObject(vehicleObject, simpleVehicle) {
        if (!vehicleObject) {
            return [];
        }

        let extendedVehicle = {
            id: store.getState().catalog.currentBrand.id,
            brandId: store.getState().catalog.currentBrand.id,
            parentId: null,
            vin: vehicleObject.vin || '',
            description: vehicleObject.description,
            nextWidgetKey: vehicleObject.link ? vehicleObject.link.wid : '',
            link: vehicleObject.link ? vehicleObject.link.path : '',
            segments: vehicleObject.segments,
        };

        if (simpleVehicle) {
            extendedVehicle.originalDescription = simpleVehicle.description;
        }

        return extendedVehicle;
    }

    static getVehicleData(segments) {
        if (!segments || !Object.keys(segments).length) {
            return [];
        }
        let tabOrderSegmentKeys = store.getState().catalog.currentBrand.vehicleInfoTabOrder;

        if (!tabOrderSegmentKeys || !tabOrderSegmentKeys.length) {
            console.warn('No tab-order given, fallback will be shown!');
            tabOrderSegmentKeys = Object.keys(segments);
        }

        return tabOrderSegmentKeys.map(segmentKey => {
            if (!segments[segmentKey]) {
                return null;
            }
            return {
                nextWidgetKey: segmentKey,
                restrictions: RestrictionService.getRestrictionsFromArray(segments[segmentKey]),
                showUnformattedText: segments[segmentKey].showUnformattedText,
            };
        }).filter(i => i);
    }

    static getCurrentBrand(props) {
        if (!props || !props.hasOwnProperty('match') || !props.brands) {
            return null;
        }

        const brandId = props.match.params.apa;
        return props.brands.find(brand => brand.id === brandId);
    }
}

export default (VehicleService);
