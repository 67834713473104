import React from 'react';
import 'split.js'
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import FormattedText from '../../../../text/formattedText';
import {connect} from 'react-redux';
import {
    updateCurrentPart,
} from '../../../../../redux/catalog/catalogAction';
import withCatalog from '../../../../../service/withCatalog';
import {withStyles} from '@mui/styles';

const useStyles = (theme) => ({
    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
    }
})

class IlluLink extends React.Component {

    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        let currentPart = {
            link: this.props.data.path,
            values: {
                caption: this.props.data.caption,
            }
        }
        this.props.updateCurrentPart(currentPart);
        this.props.routeToPartOverview();
    }

    render() {
        const {data, classes} = this.props;

        return (
            <div className={classes.link}>
                <FormattedText onClick={this.onClick} value={data.caption}/>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    updateCurrentPart: (payload) => dispatch(updateCurrentPart(payload)),
});

const mapStateToProps = state => ({
    restrictions: state.catalog.restrictions,
    currentBrand: state.catalog.currentBrand,
    currentPartRestriction: state.catalog.currentPartRestriction,
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withTranslation(),
    withCatalog,
    withStyles(useStyles),
)(IlluLink);
