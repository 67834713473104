import React, {Fragment} from 'react';
import {compose} from 'redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import {withTranslation} from 'react-i18next';
import withCartApi from '../../../api/cartApi';
import AddressSelect from '../partial/addressSelect';
import CartService from '../../../service/cartService';

class ListAddress extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            listOpen: false,
        };
    }

    handleListToggleClick = () => {
        this.setState(state => ({listOpen: !state.listOpen}));
    };

    setAddress = (addressId) => {
        this.props.setCartAddress(this.props.cartId, addressId, this.props.addressType + 'Address').then(() => {
            this.props.updateCurrentCart().then(() => {
                this.setState({listOpen: false});
            });
        });
    };

    isValid() {
        return !!this.props.selectedAddress;
    }

    render() {

        const {t, addressType, selectedAddress, readonly, cartSubmitClick} = this.props;
        const {listOpen} = this.state;

        return (
            <Fragment>

                <ListItem button onClick={this.handleListToggleClick}>
                    <ListItemText
                        primaryTypographyProps={{variant: 'h6'}}
                        primary={t('cart_address_title_' + addressType)}
                        secondaryTypographyProps={{color: (cartSubmitClick && !this.isValid() ? 'error': 'initial')}}
                        secondary={(this.isValid() || readonly ? CartService.getAddressString(selectedAddress) : t('please_select'))}
                    />
                    {!readonly ? listOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/> : <Fragment/>}
                </ListItem>

                {!readonly && (
                    <Collapse in={listOpen}>
                        <AddressSelect selectedAddressId={selectedAddress?.addressId} addresses={this.props.addresses}
                                       onSelect={(addressId) => this.setAddress(addressId)} readonly={readonly}/>
                    </Collapse>
                )}

            </Fragment>
        );
    }
}

export default compose(
    withTranslation(),
    withCartApi,
)(ListAddress);
