export const availabilityMapping = {
    'IMMEDIATELY': 'immediately',
    'SHORT_TERM': 'shortTerm',
    'PARTIALLY': 'partlyAvailable',
    'ON_REQUEST': 'onRequest',
};

export const availabilities = {
    'immediately': {
        'class': 'full',
    },
    'shortTerm': {
        'class': 'outline',
    },
    'partlyAvailable': {
        'class': 'half',
    },
    'onRequest': {
        'class': 'questionMark',
    },
};
