import React from 'react';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import {withStyles} from '@mui/styles';
import {compose} from 'redux';

const useStyles = () => ({
    textEllipsis: {
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
    },
});

class cartTitle extends React.Component {

    render() {

        const {classes} = this.props;

        return (
            <Typography component="div" variant="body2">
                <Box className={classes.textEllipsis} fontWeight="fontWeightBold">
                    {this.props.cartTitle}
                </Box>
                <Box className={classes.textEllipsis}>
                    {this.props.cartSubTitle}
                </Box>
            </Typography>
        );
    }

}

export default compose(
    withStyles(useStyles),
)
(cartTitle);
