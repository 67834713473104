import {Redirect, Route, Switch} from 'react-router-dom';
import React from 'react';
import {withStyles} from '@mui/styles';
import {allRoutes, routeCatalogVehicle} from '../../config/routeItems';


const useStyles = () => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        height: '100%',
    },
});

class AppBody extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <Switch>
                    <Redirect exact from="/login" to={routeCatalogVehicle.url()} />
                    <Redirect exact from="/" to={routeCatalogVehicle.url()} />
                    {allRoutes.map((item) => (
                        <Route key={item.key} exact={item.exact} path={item.url()}>
                            {item.component}
                        </Route>
                    ))};
                </Switch>
            </div>
        );
    }
}

export default withStyles(useStyles)(AppBody);
