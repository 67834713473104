import {
    SET_STEP,
    SET_CART,
    SET_CART_CONTEXT,
    SET_CART_SELECTION_DIALOG_STATE,
    SET_CART_RENAME_DIALOG_STATE,
    SET_DEALER,
    CART_SUBMISSION,
    RESET_ALL_CART,
    SET_CART_REMOVE_DIALOG_STATE,
    SET_DEALER_DIALOG_STATE,
    RESET_CART_SUBMISSION,
    RESET_CART_ONLY,
    RESET_CART_DEALER,
    SET_CART_SUBMIT_CLICK,
    ADD_CARTS_CATALOG,
    DELETE_CARTS_CATALOG,
    UPDATE_CART
} from './cartTypes';

const initialState = {
    cartItemsCount: 0,
    cartSelectionDialogShow: false,
    cartRenameDialogShow: false,
    cartRemoveDialogShow: false,
    cartDealerDialogShow: false,
    step: 0,
    cart: {
        cartId: 0,
        items: [],
        cartName: '',
        creationTime: '',
        catalogProperties: {
            brandLogoPath: '',
            brandName: '',
            catalogService: '',
        },
        dealerProperties: {
            addressLines: [],
            companyName: '',
            customerNumber: '',
            customerNumberReadOnly: false,
            dealerHref: '',
            dealerId: '',
            email: '',
        },
    },
    cartSubmitClick: false,
    cartSubmission: {
        submitted: false,
        submitMsg: '',
        cartId: 0,
        cart: {},
    },
    cartContext: {
        cartId: 0,
        cartName: '',
        catalogProperties: {
            brandLogoPath: '',
            brandName: '',
            catalogService: '',
        },
    },
    dealer: {
        dealerId: 0,
        companyName: '',
        addressLines: [],
        dealerHref: '',
    },
    cartsCatalog: {}
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_CARTS_CATALOG:
            if (action.value.brandId && action.value.cart) {
                state.cartsCatalog = {
                    ...state.cartsCatalog,
                    [action.value.brandId]: action.value.cart
                }
            }

            return state;
        case DELETE_CARTS_CATALOG:
            return { ...state, cartsCatalog: {} };
        case SET_CART:
            return Object.assign({}, state, {
                cart: action.value ? action.value : initialState.cart,
                cartItemsCount: action.value && action.value.itemCount ? action.value.itemCount : 0,
            });
        case UPDATE_CART: 
            return {
                ...state,
                cart: {
                    ...state.cart,
                    ...action.value
                },
                cartItemsCount: action.value && action.value.itemCount ? action.value.itemCount : 0
            };
        case SET_CART_CONTEXT:
            return Object.assign({}, state, {
                cartContext: action.value ? action.value : initialState.cartContext,
                cartItemsCount: action.value && action.value.itemCount ? action.value.itemCount : 0,
                cart: initialState.cart,
            });
        case SET_CART_SUBMIT_CLICK:
            return {...state, cartSubmitClick: action.value};
        case SET_STEP:
            return Object.assign({}, state, {
                step: action.value,
            });
        case SET_CART_SELECTION_DIALOG_STATE:
            return {...state, cartSelectionDialogShow: action.value};
        case SET_CART_RENAME_DIALOG_STATE:
            return {...state, cartRenameDialogShow: action.value};
        case SET_CART_REMOVE_DIALOG_STATE:
            return {...state, cartRemoveDialogShow: action.value};
        case SET_DEALER_DIALOG_STATE:
            return {...state, cartDealerDialogShow: action.value};
        case SET_DEALER:
            return {...state, dealer: action.value ? action.value : initialState.dealer};
        case CART_SUBMISSION:
            return {
                ...state,
                cartSubmission: {
                    submitted: true,
                    submitMsg: action.value.message,
                    cartId: action.value.cartId,
                    cart: action.cart,
                },
            };
        case RESET_CART_SUBMISSION:
            return {...state, cartSubmission: {...initialState.cartSubmission}};
        case RESET_CART_DEALER:
            return {...state, dealer: initialState.dealer};
        case RESET_CART_ONLY:
            return {...state, cart: initialState.cart};
        case RESET_ALL_CART:
            return {
                ...initialState,
                cartsCatalog: state.cartsCatalog
            };
        default:
            return state;
    }
}
