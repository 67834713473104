import {
    UPDATE_INSTALL_MESSAGE_STATE, SET_CONTENT_LINKS, RESET_CONTENT_LINKS, SET_TOKEN, RESET_TOKEN, UPDATE_NEW_VERSION_MESSAGE_STATE,
} from './globalTypes';

const initialState = {
    installMessageShow: false,
    newVersionMessageShow: false,
    devEnv: process.env.NODE_ENV !== 'production',
    contentLinks: [],
    token: '',                  // auth token
};

export default function globalReducer(state = initialState, action) {

    switch (action.type) {
        case UPDATE_INSTALL_MESSAGE_STATE:
            return Object.assign({}, state, {
                installMessageShow: action.value,
            });
        case UPDATE_NEW_VERSION_MESSAGE_STATE:
            return {...state, newVersionMessageShow: action.value};
        case SET_CONTENT_LINKS:
            return {...state, contentLinks: action.value};
        case SET_TOKEN:
            return {...state, token: action.value};
        case RESET_TOKEN:
            return {...state, token: initialState.token};
        case RESET_CONTENT_LINKS:
            return {...state, contentLinks: initialState.contentLinks};
        default:
            return state;
    }
}
