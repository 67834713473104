import React, {Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import {connect} from 'react-redux';
import CartBackHeader from './partial/cartBackHeader';
import Container from '../base/container';
import List from '@mui/material/List';
import {Box, Button} from '@mui/material';
import CartTotal from './partial/cartTotal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ListComment from './list/listComment';
import ListDeliveryDetail from './list/listDeliveryDetail';
import withCartApi from '../../api/cartApi';
import ListItems from './list/listItems';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import {cartSubmission, setCartSubmitClick} from '../../redux/cart/cartAction';
import CartHeaderContainer from './partial/cartHeaderContainer';
import ListAddressGroup from './list/listAddressGroup';
import Api from '../../api/api';
import {withStyles} from '@mui/styles';
import ListDealer from './list/listDealer';
import ListOptions from './list/listOptions';
import withSnackbar from '../../service/withSnackbar';
import {resetCurrentBrandToDefault} from '../../redux/catalog/catalogAction';

const useStyles = (theme) => ({
    disableLook: {
        backgroundColor: theme.palette.disabled.main,
        '&, &:hover:hover, &:focus:focus': {
            backgroundColor: theme.palette.disabled.main,
        },
    },
    overflowAuto: {
        overflow: 'auto'
    }
});

class StepOverview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formError: false,
            confirmTerms: !this.areConfirmTermsSet(),
            shippingAddresses: [],
            billingAddresses: [],
        };
    }

    componentDidMount() {
        // force price and availability to update -> be careful, it generates cost per use
        this.props.updateCurrentCart(true);
    }

    handleCheckTermsChange = () => {
        this.setState(state => ({confirmTerms: !state.confirmTerms}));
    };

    areConfirmTermsValid() {
        return !this.areConfirmTermsSet() || this.state.confirmTerms;
    }

    isValid() {
        return this.props.areAddressesValid() && this.props.areDealerPropertiesValid() && this.areConfirmTermsValid();
    }

    handleSubmit = () => {
        const { t } = this.props;
        
        this.props.setCartSubmitClick(true);

        if (!this.props.areAddressesValid()) {
            this.props.enqueueWarningSnackbar(t('warn_addresses_not_empty'));
            this.props.handleBack();

        } else if (!this.props.areDealerPropertiesValid()) {
            this.props.enqueueWarningSnackbar(t('warn_dealerProperties_not_empty'));
            this.props.handleBack();

        } else if (!this.areConfirmTermsValid()) {
            this.setState({formError: true});
            this.confirmElement.scrollIntoView({behavior: 'smooth', block: 'start'});

        } else {
            this.props.orderSubmissions(this.props.cart.cartId, this.props.dealerId)
                .then((resp) => {
                    this.props.cartSubmission(resp, this.props.cart);
                    this.getCartContext();
                    this.props.resetCurrentBrandToDefault();
                    this.props.handleNext();
                }).catch(() => {
                this.setState({confirmTerms: false});
            });
        }
    };

    getCartContext = () => {
        this.props.getLastUsedCart(this.props.currentBrand.id).then(cartContext => {
            this.props.setCartContext(cartContext || 0);
        });
    };

    showDeliveryDetail() {
        const {cart} = this.props;
        return cart.dealerProperties && (cart.dealerProperties.orderTypes || cart.dealerProperties.dispatchTypes);
    };

    areConfirmTermsSet() {
        const {cart} = this.props;
        return cart.dealerProperties && cart.dealerProperties.tosHref;
    }

    render() {
        const {t, cart, classes} = this.props;

        return (
            <Fragment>
                <CartHeaderContainer>
                    <CartBackHeader cart={cart} handleBack={this.props.handleBack}/>
                </CartHeaderContainer>

                <Box className={classes.overflowAuto}>
                    <Container>
                        <Box my={2}>
                            <CartTotal cart={cart}/>
                        </Box>

                        {this.areConfirmTermsSet() && <FormControl
                            required
                            error={this.state.formError}
                            component="fieldset"
                            ref={(ref) => this.confirmElement = ref}>

                            {this.state.formError && (
                                <FormHelperText
                                    variant={'standard'}>{t('cart_overview_terms_checkbox_error')}</FormHelperText>
                            )}

                            <FormControlLabel
                                label={t('cart_overview_terms_checkbox_label')}
                                control={
                                    <Fragment>
                                        <Checkbox
                                            checked={this.state.confirmTerms}
                                            onChange={(event) => this.handleCheckTermsChange(event)}
                                        />
                                    </Fragment>
                                }
                            />

                            <Box pl={4}>
                                <Typography>
                                    <Link
                                        target={'_blank'}
                                        href={Api().defaults.baseURL + cart.dealerProperties.tosHref}>{t('cart_overview_terms_link_text')}</Link>
                                </Typography>
                            </Box>
                        </FormControl>}
                    </Container>

                    <Container disableGutters flexGrow>
                        <List component="nav">
                            <ListItems items={cart.items} listOpen={true}/>

                            <ListDealer readOnly={true}/>
                            {cart.comment && (
                                <ListComment readonly comment={cart.comment}/>
                            )}

                            {
                                this.showDeliveryDetail() &&
                                <ListDeliveryDetail
                                    readonly
                                    cartId={cart.cartId}
                                    dealerProperties={cart.dealerProperties}
                                    currentOrderTypeId={cart.orderTypeId}
                                    currentDispatchTypeId={cart.dispatchTypeId}/>
                            }

                            <ListOptions cart={cart} readonly/>
                            <ListAddressGroup cart={cart} readonly/>
                        </List>
                    </Container>
                </Box>
                <Container paddingY>
                    <Button className={this.isValid() ? '' : classes.disableLook}
                            onClick={this.handleSubmit}>
                        {t('cart_confirm_purchase')}
                    </Button>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    cart: state.cart.cart,
    dealerId: state.cart.dealer.dealerId,
    hasDealer: state.cart.dealer.dealerId > 0,
});

const mapDispatchToProps = {
    cartSubmission,
    setCartSubmitClick,
    resetCurrentBrandToDefault,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withSnackbar,
    withCartApi,
    withStyles(useStyles),
)
(StepOverview);