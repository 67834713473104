import {Fragment} from 'react';
import React from 'react';
import MessageIcon from '@mui/icons-material/Message';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import LockIcon from '@mui/icons-material/Lock';
import InfoIcon from '@mui/icons-material/Info';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Home from '../components/pages/home';
import Menu from '../components/pages/menu';
import ChangeCart from '../components/pages/changeCart';
import NoMatchPage from '../components/pages/noMatchPage';
import VehicleOverview from '../components/catalog/vehicle/vehicleOverview';
import ChangePassword from '../components/myAccount/changePassword';
import Settings from '../components/myAccount/settings';
import BrandSelection from '../components/brand/brandSelection';
import Catalog from '../components/catalog/catalog';
import MainVehicleRestriction from '../components/catalog/vehicle/mainVehicleRestriction';
import SubVehicleRestriction from '../components/catalog/vehicle/subVehicleRestriction';
import MainPartRestriction from '../components/catalog/part/mainPartRestriction';
import SubPartRestriction from '../components/catalog/part/subPartRestriction';
import PartOverview from '../components/catalog/part/partOverview';
import ExternalContentPage from '../components/base/externalContentPage';
import Login from '../components/pages/login';
import SearchIcon from '@mui/icons-material/Search';
import PartInformation from '../components/catalog/part/partInformation';
import DealerManagement from '../components/dealer/dealerManagement';
import Dealer from '../components/dealer/dealer';
import {store} from '../redux/store';
import Status from '../components/pages/status';
import LastDealers from "../components/dealer/lastDealers";
import StepCart from '../components/cart/stepCart';
import StepDelivery from '../components/cart/stepDelivery';
import StepOverview from '../components/cart/stepOverview';
import StepSuccess from '../components/cart/stepSuccess';
import SearchPart from '../components/search/partSearch/searchPart';
import SearchVehicle from '../components/search/vehicleSearch/searchVehicle';
import Info from '../components/pages/info';
import Addresses from '../components/myAccount/addresses';
import BomBaseOverview from '../components/catalog/specialCases/bomBaseTable';
import Suspension from '../components/catalog/specialCases/suspension/suspension';

import { ReactComponent as CartIcon } from '../assets/icons/cart.svg';
import { ReactComponent as CartDoubleIcon } from '../assets/icons/cart-double.svg';

// Main
export const routeStatus = {
    key: 'status',
    label: 'route_status',
    url: () => '/status',
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <Status {...props} />,
};
export const routeInfo = {
    key: 'info',
    label: 'route_info',
    url: () => '/info',
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <Info {...props} />,
};
export const routeLogin = {
    key: 'login',
    label: 'route_login',
    url: () => '/login',
    icon: <Fragment/>,
    exact: false,
    component: (props = {}) => <Login {...props} />,
};
export const routeCatalogVehicle = {
    key: 'vehicle',
    label: 'route_vehicle',
    url: () => '/catalog/vehicle',
    icon: <DirectionsCarIcon/>,
    exact: false,
    component: (props = {}) => <Catalog {...props} />,
};
export const routeCatalogPart = {
    key: 'part',
    label: 'route_part',
    url: () => '/catalog/part',
    icon: <ImportContactsIcon/>,
    exact: false,
    component: (props = {}) => <Catalog {...props} />,
};

export const routeCart = {
    key: 'cart',
    label: 'route_cart',
    url: () => '/cart',
    icon: <CartIcon/>,
    exact: true
};

export const routeMenu = {
    key: 'menu',
    label: 'route_menu',
    url: () => '/menu',
    icon: <MoreHorizIcon/>,
    exact: true,
    component: (props = {}) => <Menu {...props} />,
};

export const routeChangeCart = {
    key: 'changeCart',
    label: 'route_change_cart',
    url: () => '/change-cart',
    icon: <CartDoubleIcon/>,
    exact: false,
    component: (props = {}) => <ChangeCart {...props} />,
};

// Cart
export const routeCartStepCart = {
    key: 'stepCart',
    label: 'route_cart_step_cart',
    url: () => '/cart/step-cart',
    icon: <ShoppingCartIcon/>,
    exact: true,
    component: (props = {}) => <StepCart {...props} />,
};

export const routeCartStepDelivery = {
    key: 'stepDelivery',
    label: 'route_cart_step_delivery',
    url: () => '/cart/step-delivery',
    icon: <ShoppingCartIcon/>,
    exact: true,
    component: (props = {}) => <StepDelivery {...props} />,
};

export const routeCartStepOverview = {
    key: 'stepOverview',
    label: 'route_cart_step_overview',
    url: () => '/cart/step-overview',
    icon: <ShoppingCartIcon/>,
    exact: true,
    component: (props = {}) => <StepOverview {...props} />,
};

export const routeCartStepSuccess = {
    key: 'stepSuccess',
    label: 'route_cart_step_success',
    url: () => '/cart/step-success',
    icon: <ShoppingCartIcon/>,
    exact: true,
    component: (props = {}) => <StepSuccess{...props} />,
};

export const groupCart = [
    routeCartStepCart,
    routeCartStepDelivery,
    routeCartStepOverview,
    routeCartStepSuccess,
];

// search
export const routeSearchVehicle = {
    key: 'searchVehicle',
    label: 'route_search_vehicle',
    url: () => '/catalog/vehicle/search',
    icon: <SearchIcon/>,
    exact: false,
    component: (props = {}) => <SearchVehicle {...props} />,
};
export const routeSearchPart = {
    key: 'searchPart',
    label: 'route_search_part',
    url: () => '/catalog/part/search',
    icon: <SearchIcon/>,
    exact: false,
    component: (props = {}) => <SearchPart {...props} />,
};

export const groupSearch = [
    routeSearchVehicle,
    routeSearchPart
];

// Vehicle Catalog
export const routeBrandSelection = {
    key: 'catalog',
    label: 'route_vehicle_catalog',
    url: () => '/catalog/vehicle',
    icon: <DirectionsCarIcon/>,
    exact: true,
    component: (props = {}) => <BrandSelection {...props} />,
};

// Must be set before the routes with params!
export const routeVehicleOverview = {
    key: 'vehicleOverview',
    label: 'route_vehicle_overview',
    url: () => '/catalog/vehicle/overview',
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <VehicleOverview {...props} />,
};

export const routeBomBaseOverviewTable = {
    key: 'catalogBomBasesOverviewTable',
    label: 'route_bomBase_overview_table',
    url: (routeArea = ':routeArea') => `/catalog/${routeArea}/bomBases/overview`,
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <BomBaseOverview {...props} />,
};

export const routeBomBaseInformation = {
    key: 'bomBaseInfo',
    label: 'route_bomBase_information',
    url: (routeArea = ':routeArea') => `/catalog/${routeArea}/overview/information`,
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <PartInformation {...props} />,
};

export const routeVehicleMainRestriction = {
    key: 'catalogVehicleMainRestriction',
    label: 'route_vehicle_main_restriction',
    url: (apa = ':apa') => `/catalog/vehicle/${apa}`,
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <MainVehicleRestriction {...props} />,
};

export const routeVehicleSubRestriction = {
    key: 'catalogVehicleSubRestriction',
    label: 'route_vehicle_sub_restriction',
    url: (apa = ':apa', restrictionId = ':restrictionId') => `/catalog/vehicle/${apa}/${restrictionId}`,
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <SubVehicleRestriction {...props} />,
};

// Part Catalog

// Must be set before the routes with params!
export const routePartOverview = {
    key: 'partOverview',
    label: 'route_part_overview',
    url: () => '/catalog/part/overview',
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <PartOverview {...props} />,
};

export const routePartInformation = {
    key: 'partInfo',
    label: 'route_part_information',
    url: () => '/catalog/part/overview/information',
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <PartInformation {...props} />,
};

export const routePartMainRestriction = {
    key: 'catalogPartMainRestriction',
    label: 'route_part_main_restriction',
    url: (apa = ':apa') => `/catalog/part/${apa}`,
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <MainPartRestriction {...props} />,
};

export const routeSpringRestriction = {
    key: 'catalogPartSpringRestriction',
    label: 'route_part_spring_restriction',
    url: (apa = ':apa', restrictionId = ':restrictionId') => `/catalog/part/${apa}/springs`,
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <Suspension {...props} />,
};

export const routePartSubRestriction = {
    key: 'catalogPartSubRestriction',
    label: 'route_part_sub_restriction',
    url: (apa = ':apa', restrictionId = ':restrictionId') => `/catalog/part/${apa}/${restrictionId}`,
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <SubPartRestriction {...props} />,
};

export const catalogRoutes = [
    routeBrandSelection,
    routeVehicleOverview,
    routeBomBaseOverviewTable,
    routeBomBaseInformation,
    routePartOverview,
    routePartInformation,
    routeSearchVehicle,
    routeSearchPart,
    routeVehicleMainRestriction,
    routeVehicleSubRestriction,
    routePartMainRestriction,
    routeSpringRestriction,
    routePartSubRestriction,
];

// TODO: Remove 'My Account'
export const routeMyAccountNotification = {
    key: 'myAccountNotification',
    label: 'route_my_account_notification',
    url: () => '/my-account/notification',
    icon: <MessageIcon/>,
    exact: true,
    component: (props = {}) => <Home {...props} />,
};

export const routeMyAccountOrderHistory = {
    key: 'myAccountOrderHistory',
    label: 'route_my_account_order_history',
    url: () => '/my-account/order-history',
    icon: <FormatListBulletedIcon/>,
    exact: true,
    component: (props = {}) => <Home {...props} />,
};

export const routeMyAccountAddresses = {
    key: 'myAccountAddresses',
    label: 'route_my_account_addresses',
    url: () => '/my-account/addresses',
    icon: <PersonPinIcon/>,
    exact: true,
    component: (props = {}) => <Addresses {...props} />,
};

export const routeMyAccountChangePassword = {
    key: 'myAccountChangePassword',
    label: 'route_my_account_change_password',
    url: () => '/my-account/change-password',
    icon: <LockIcon/>,
    exact: true,
    component: (props = {}) => <ChangePassword {...props} />,
};

export const groupMyAccount = [
    // routeMyAccountNotification,
    // routeMyAccountOrderHistory,
    routeMyAccountAddresses,
    routeMyAccountChangePassword,
];

// Legal
export const routeExternalContent = {
    key: 'externalContent',
    label: 'route_external_content',
    url: (contentPageId = ':contentPageId') => `/legal/${contentPageId}`,
    icon: <InfoIcon/>,
    exact: false,
    component: (props = {}) => <ExternalContentPage {...props} />,
};

export const groupLegal = () => {
    return store.getState().global.contentLinks.map(contentPage => {
        return {
            key: contentPage.name,
            label: contentPage.name,
            url: () => '/legal/' + contentPage.name,
            icon: <InfoIcon/>,
            exact: true,
            component: (props = {}) => <Fragment/>,
        };
    });
};


// General
export const routeDealer = {
    key: 'dealer',
    label: 'route_dealer',
    url: (serviceName = ':serviceName') => `/dealer-management/${serviceName}`,
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <Dealer {...props} />,
};
export const routeDealerManagement = {
    key: 'dealerManagement',
    label: 'route_dealer_management',
    url: () => '/dealer-management',
    icon: <LocalShippingIcon/>,
    exact: true,
    component: (props = {}) => <DealerManagement {...props} />,
};
export const routeLastDealers = {
    key: 'lastDealers',
    label: 'route_last_dealers',
    url: (serviceName = ':serviceName') => `/dealer-management/${serviceName}/lastDealers`,
    icon: <Fragment/>,
    exact: true,
    component: (props = {}) => <LastDealers {...props} />,
};

export const routeHome = {
    key: 'home',
    label: 'route_home',
    url: () => '/home',
    icon: <Fragment/>,
    exact: true,
    // component: (props = {}) => <Home {...props} />,
    component: () => <BrandSelection reset={true} />,
};

export const routeSettings = {
    key: 'setting',
    label: 'route_setting',
    url: () => '/settings',
    icon: <InfoIcon/>,
    exact: true,
    component: (props = {}) => <Settings {...props} />,
};

export const routeLogout = {
    key: 'logout',
    label: 'route_logout',
    url: () => '/logout',
    icon: <ExitToAppIcon/>,
    exact: true,
    component: (props = {}) => <Home {...props} />,
};

export const routeNoMatchPage = {
    key: 'noMatchPage',
    label: 'route_no_match_page',
    url: () => '',
    icon: <Fragment/>,
    exact: false,
    component: (props = {}) => <NoMatchPage {...props} />,
};

export const groupMyGeneral = [
    routeDealerManagement,
    routeDealer,
    routeLastDealers,
    routeHome,
    routeSettings,
    routeLogout,
    routeNoMatchPage,
];


export const groupInfo = [routeStatus, routeInfo];
export const groupMain = [routeCatalogVehicle, routeCatalogPart, routeCart, routeChangeCart, routeMenu];
export const allRoutes = [].concat(groupMain, groupSearch, groupMyAccount, routeExternalContent, groupMyGeneral);
