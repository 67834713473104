import {
    SET_CART_ITEMS_COUNT,
    SET_STEP,
    SET_CART,
    SET_CART_CONTEXT,
    SET_CART_SELECTION_DIALOG_STATE,
    SET_CART_RENAME_DIALOG_STATE,
    SET_DEALER,
    CART_SUBMISSION,
    SET_CART_REMOVE_DIALOG_STATE,
    SET_DEALER_DIALOG_STATE,
    RESET_CART_SUBMISSION,
    RESET_ALL_CART,
    RESET_CART_ONLY,
    RESET_CART_DEALER,
    SET_CART_SUBMIT_CLICK,
    ADD_CARTS_CATALOG,
    DELETE_CARTS_CATALOG,
    UPDATE_CART
} from './cartTypes';

export const setCartsCatalog = value => ({
    type: ADD_CARTS_CATALOG,
    value: value,
});

export const deleteCartsCatalog = () => ({
    type: DELETE_CARTS_CATALOG
});

export const setCartContext = value => ({
    type: SET_CART_CONTEXT,
    value: value,
});

export const setCart = value => {
    return (dispatch) => {
        dispatch(setCartSubmitClick(false));
        dispatch({
            type: SET_CART, 
            value: value
        });
    }
};

export const updateCart = value => ({
    type: UPDATE_CART,
    value: value,
});

export const setCartItemsCount = value => ({
    type: SET_CART_ITEMS_COUNT,
    value: value,
});

export const setStep = (value) => ({
    type: SET_STEP,
    value: value,
});

export const setCartSelectionDialogState = (brandId, value) => ({
    type: SET_CART_SELECTION_DIALOG_STATE,
    value: value,
});

export const setCartRenameDialogState = (value) => ({
    type: SET_CART_RENAME_DIALOG_STATE,
    value: value,
});

export const setCartRemoveDialogState = (value) => ({
    type: SET_CART_REMOVE_DIALOG_STATE,
    value: value,
});

export const setDealerDialogState = (value) => ({
    type: SET_DEALER_DIALOG_STATE,
    value: value,
});

export const setDealer = (value) => ({
    type: SET_DEALER,
    value: value,
});

export const cartSubmission = (value, cart) => ({
    type: CART_SUBMISSION,
    value: value,
    cart: cart,
});

export const resetCartSubmission = () => {
    return (dispatch) => {
        dispatch(setCartSubmitClick(false))
        dispatch({type: RESET_CART_SUBMISSION});
    }
};

export const setCartSubmitClick = (value) => ({
    type: SET_CART_SUBMIT_CLICK,
    value: value,
});

export const resetCartDealer = () => ({
    type: RESET_CART_DEALER,
});

export const resetCartOnly = () => ({
    type: RESET_CART_ONLY,
});

export const resetAllCart = () => ({
    type: RESET_ALL_CART,
});
