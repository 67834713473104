import React, { Fragment } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import ModelSubheader from '../catalog/restriction/restrictionSubheader';
import SearchResultList from './vehicleSearch/searchResultList';

class LastSelected extends React.Component {
    render() {
        const { t, items, onItemClick } = this.props;

        return (
            <Fragment>
                <ModelSubheader title={t('search_last_selected_vehicles')} />
                <SearchResultList 
                    items={items} 
                    onItemClick={onItemClick}
                />
            </Fragment>
        );
    }
}

export default compose(
    withTranslation()
)
(LastSelected);