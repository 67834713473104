import {
    UPDATE_CATALOG_DEALERS, ADD_CATALOG_DEALER, SET_CATALOG_DEALER, SET_LAST_DEALERS, RESET_CATALOG_DEALER
} from "./dealerManagementTypes";

export const setCatalogDealer = (value) => ({
    type: SET_CATALOG_DEALER,
    value: value,
});
export const resetCatalogDealer = () => ({
    type: RESET_CATALOG_DEALER
});

export const updateCatalogDealer = (value) => ({
    type: UPDATE_CATALOG_DEALERS,
    value: value,
});

export const addCatalogDealer = (value) => {
    return (dispatch, getState) => {
        if(getState().dealerManagement.catalogDealers.find((catalogDealer) => catalogDealer.id.toString() === value.id.toString())) { // update if existent
            dispatch(updateCatalogDealer(value));
        } else {
            dispatch({type: ADD_CATALOG_DEALER, value: value});
        }
    }
}

export const setLastDealers = (value) => ({
    type: SET_LAST_DEALERS,
    value: value,
});