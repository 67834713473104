import React from 'react';
import {compose} from 'redux';
import {withStyles} from '@mui/styles';

const useStyles = (theme) => ({
    gutter: {
        position: 'relative',
        boxShadow: 'rgb(224, 224, 224) 0 -5px 9px 0',
        width: '100%',
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        height: '19px',
        background: theme.palette.white,
        zIndex: '10',
    },
    splitIndicator: {
        height: '2px',
        background: theme.palette.black,
        width: '26px',
        display: 'block',
        position: 'absolute',
        zIndex: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        marginTop: '8px',
    },
});


class SplitPane extends React.Component {

    constructor(props) {
        super(props);
        this.splitRef = React.createRef();
        this.separatorRef = React.createRef();
        this.topRef = React.createRef();
        this.bottomRef = React.createRef();

        this.state = {
            separatorHeight: '19',
            topHeight: '50',
            moves: false,
            resetTransitionTimeOutId: null,
            viewHeight: 0,
            scrollBehaviour: this.props.scrollBehaviour || 'hidden',
        };

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.windowResize = this.windowResize.bind(this);
        this.onOrientationChange = this.onOrientationChange.bind(this);
    }

    componentDidMount() {
        window.addEventListener('mouseup', this.onMouseUp);
        window.addEventListener('touchend', this.onMouseUp);
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('touchmove', this.onTouchMove);
        window.addEventListener('resize', this.windowResize);
        window.addEventListener('orientationchange', this.onOrientationChange);

        this.setState({viewHeight: this.getSplitHeight()});
    }

    componentWillUnmount() {
        window.removeEventListener('mouseup', this.onMouseUp);
        window.removeEventListener('touchend', this.onMouseUp);
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('touchmove', this.onTouchMove);
        window.removeEventListener('resize', this.windowResize);
        window.removeEventListener('orientationchange', this.onOrientationChange);

        if (this.state.resetTransitionTimeOutId) {
            clearTimeout(this.state.resetTransitionTimeOutId);
        }

        if (this.iosResizeTimeout) {
            clearTimeout(this.iosResizeTimeout);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedPart &&
            prevProps.selectedPart && prevProps.selectedPart.hotspotId !== this.props.selectedPart.hotspotId) { // active hotspotId changed
            if (parseInt(this.state.topHeight) > 80 || parseInt(this.state.topHeight) < 20) {
                this.setState({topHeight: '50'});
            }
        }
    }

    onOrientationChange() {
        this.windowResize();

        // ios (iPad Pro) fix, on orientation change changes height not immediately, so a second call is needed.
        this.iosResizeTimeout = setTimeout(() => {
            this.windowResize();
        }, 1000);
    }

    onMouseDown(ev) {
        this.setState({moves: true});
    }

    onMove(pos) {
        let totHeight = this.state.viewHeight,
            offsetTop = this.splitRef.current.offsetTop + document.getElementById('header-top').offsetHeight;

        if (this.state.moves) {
            let newPercent = 100 * (pos - offsetTop) / totHeight;
            if (newPercent >= 0 && newPercent <= 100) {
                this.setState({topHeight: newPercent});
            }
        }
    }

    onMouseMove(ev) {
        this.onMove(ev.clientY);
    }

    onTouchMove(ev) {
        if (parseInt(ev.touches[0].pageY) % 2 === 0) {
            this.onMove(ev.touches[0].pageY);
        }
    }

    onMouseUp(ev) {
        this.setState({moves: false});
        this.adjustToAnchor();
    }

    windowResize() {
        this.setState({viewHeight: this.getSplitHeight()});
    }

    getSplitHeight() {
        let offsetHeight = 0, headers;

        headers = document.getElementsByTagName('header');
        for (let i = 0; i < headers.length; i++) {
            offsetHeight += headers[i].offsetHeight;
        }

        return window.innerHeight - offsetHeight - this.splitRef.current.offsetTop;
    }

    adjustToAnchor() {
        let resetTransitionTimeOutId, anchors = [
            2,
            10,
            20,
            30,
            40,
            50,
            60,
            70,
            80,
            90,
            97,
        ], closestAnchor;
        anchors.sort((a, b) => {
            return Math.abs(this.state.topHeight - a) - Math.abs(this.state.topHeight - b);
        });
        closestAnchor = anchors[0];

        this.topRef.current.style.transition = 'all .2s ease-in';
        this.bottomRef.current.style.transition = 'all .2s ease-in';
        this.setState({topHeight: closestAnchor});
        resetTransitionTimeOutId = setTimeout(() => {
            if (!this.topRef.current || !this.bottomRef.current) {
                return;
            }

            this.topRef.current.style.transition = '';
            this.bottomRef.current.style.transition = '';
        }, 1000);
        this.setState({resetTransitionTimeOutId});
        this.props.onAnchorChange(closestAnchor);
    }

    render() {
        const {classes} = this.props;
        const childrenWithProps = React.Children.map(this.props.children, child => {
            // checking isValidElement is the safe way and avoids a typescript error too
            const props = {topheight: this.state.topHeight};
            if (React.isValidElement(child)) {
                return React.cloneElement(child, props);
            }
            return child;
        });
        return <div ref={this.splitRef} style={{height: this.state.viewHeight}}>
            <div ref={this.topRef} className={'top-view'} style={{
                height: `calc(${this.state.topHeight}% - ${this.state.separatorHeight / 2}px)`,
                minHeight: '2px',
                zIndex: '-1',
                overflow: this.state.scrollBehaviour,
            }}>
                {childrenWithProps[0]}
            </div>
            <div ref={this.separatorRef} className={classes.gutter} onTouchStart={this.onMouseDown}
                 onMouseDown={this.onMouseDown}>
                <div className={classes.splitIndicator}/>
            </div>
            <div ref={this.bottomRef} style={{
                height: `calc(${100 - this.state.topHeight}% - ${this.state.separatorHeight / 2}px)`,
                overflow: this.state.scrollBehaviour,
            }}>
                {this.props.children[1]}
            </div>
        </div>;
    }
}

export default compose(
    withStyles(useStyles),
)
(SplitPane);