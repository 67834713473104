import {
    UPDATE_INSTALL_MESSAGE_STATE,
    SET_CONTENT_LINKS,
    RESET_CONTENT_LINKS,
    SET_TOKEN,
    RESET_TOKEN,
    UPDATE_NEW_VERSION_MESSAGE_STATE
} from './globalTypes';
import {clearStore} from '../auth/authAction';

export const updateNewVersionMessageState = (value) => {
    return (dispatch) => {
        dispatch({type: UPDATE_NEW_VERSION_MESSAGE_STATE, value: value});

        if (!value) {
            dispatch(resetAfterOnNewVersion());
        }
    };
};
export const updateInstallMessageState = (value) => ({
    type: UPDATE_INSTALL_MESSAGE_STATE,
    value: value,
});
export const setContentLinks = (value) => ({
    type: SET_CONTENT_LINKS,
    value: value,
});
export const setToken = (value) => ({
    type: SET_TOKEN,
    value: value,
});
export const resetToken = (value) => ({
    type: RESET_TOKEN,
    value: value,
});
export const resetContentLinks = (value) => ({
    type: RESET_CONTENT_LINKS,
    value: value,
});


export const resetAfterOnNewVersion = () => {
    return (dispatch) => {
        dispatch(clearStore());
        localStorage.removeItem('lastLoadedBuildVersion');

        // otherwise newVersionMessageShow will not be set to false
        process.nextTick(() => window.location.reload())
    }
}
