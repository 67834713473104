import React from 'react';
import {connect} from 'react-redux';
import ModelSubheader from '../restriction/restrictionSubheader';
import {updateCurrentPartInformation, updateCurrentPartRestriction} from '../../../redux/catalog/catalogAction';
import CatalogService from '../../../service/catalogService';
import CatmetaService from '../../../service/catmetaService';
import Container from '../../base/container';
import {compose} from 'redux';
import withCatalog from '../../../service/withCatalog';
import DynamicRestrictionTable from '../dynamicRestrictionTable';
import {addPartHistory, clearPartHistory} from '../../../redux/browserHistory/browserHistoryAction';
import {withRouter} from 'react-router-dom';
import catmetaApi from '../../../api/catmetaApi';

class MainPartRestriction extends React.Component {

    constructor(props) {
        super(props);

        this.onItemClick = this.onItemClick.bind(this);
    }

    componentDidMount() {
        const {history} = this.props;
        // the restrictions for the main-parts view are loaded from the link from the currentVehicle object
        CatalogService.loadMainPartRestriction(this.props.currentVehicle);
        this.props.clearPartHistory();
        this.props.addPartHistory(history.location.pathname);
    }

    isSpringRestriction(restriction) {
        return restriction.nextWidgetKey.toLowerCase() === CatmetaService.SPRING_TRIGGER.toLowerCase();
    }

    onItemClick(restriction) {
        if (this.isSpringRestriction(restriction)) {
            this.props.updateCurrentPartRestriction(restriction);
            this.props.routeToSpringOverview();
            return;
        }

        if(restriction.nextWidgetKey === 'partinfo') {
            catmetaApi.getPartInformation(restriction.link).then(partInformation => {
                this.props.updateCurrentPartInformation(partInformation);
                this.props.routeToVehicleInformation();
            });
        } else {
            this.props.updateCurrentPartRestriction(restriction);
            this.props.routeToSubModel(restriction.id);
        }
    };

    render() {
        return (
            <Container paddingX limitHeight>
                <ModelSubheader
                    title={CatmetaService.getPageTitle(this.props.currentVehicle?.nextWidgetKey)}
                />
                <DynamicRestrictionTable
                    restrictions={CatalogService.getRestrictionsByParent(this.props.restrictions, this.props.currentVehicle?.id)}
                    widgetKey={this.props.currentVehicle?.nextWidgetKey}
                    onItemClick={this.onItemClick}/>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    currentBrand: state.catalog.currentBrand,
    currentVehicle: state.catalog.currentVehicle,
    restrictions: state.catalog.restrictions,
});

const mapDispatchToProps = dispatch => ({
    updateCurrentPartRestriction: (payload) => dispatch(updateCurrentPartRestriction(payload)),
    clearPartHistory: () => dispatch(clearPartHistory()),
    addPartHistory: (payload) => dispatch(addPartHistory(payload)),
    updateCurrentPartInformation: (payload) => dispatch(updateCurrentPartInformation(payload)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withCatalog,
    withRouter,
)(MainPartRestriction);
