import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableHead from '@mui/material/TableHead';

class ListView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            records: []
        };
    }

    getTableHead() {
        const { data, showEmptyHeaders } = this.props;

        if(!showEmptyHeaders) {
            return null;
        }

        return data.listDefintion.elements.some(el => el.title && typeof el.title === 'string') && (
            <TableHead>
                <TableRow>
                    {data.listDefintion.elements.map((el, i) => (
                        <TableCell key={i} align={el.alignment}>{el.title}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    render() {
        const { data } = this.props;

        return (
            <Table aria-label="listView table" size="small">
                {this.getTableHead()}
                <TableBody>
                    {data.records.map((record, i) => {
                        if (record.remark) {
                            return <TableRow key={i}>
                                <TableCell 
                                    align="left"
                                    colSpan={data.listDefintion.elements.length}
                                    sx={{
                                        borderBottom: 'none'
                                }}>
                                    <Typography variant="body2">
                                        {record.remark}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        } else {
                            return <TableRow key={i}>
                                {data.listDefintion.elements.map(element => (
                                    <TableCell 
                                        key={element.id}
                                        align={element.alignment ? record.alignment : 'left'}
                                        sx={{
                                            borderBottom: 'none'
                                    }}>
                                        <Typography variant="body2">
                                            {record.values[element.id]}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>;
                        }
                    })}
                </TableBody>
            </Table>
        );
    }
}


export default ListView;