import React from 'react';
import {compose} from 'redux';
import Api from '../../../../api/api';
import {connect} from 'react-redux';
import RestrictionService from '../../../../service/restrictionService';
import {updateCurrentPartInformation} from '../../../../redux/catalog/catalogAction';
import withCatalog from '../../../../service/withCatalog';
import catmetaApi from '../../../../api/catmetaApi';
import SuspensionPartTable from './suspensionPartTable';
import i18n from '../../../../i18n';
import {trackPromise} from 'react-promise-tracker';
import {loadingAreas} from '../../../../config/loadingAreas';
import withSnackbar from '../../../../service/withSnackbar';
import NoSuspensionMessage from '../../../base/noSuspensionMessage';

class SuspensionResults extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            restrictionData: [],
            noSuspensionMessageState: false,
        };

        this.handleNoSuspensionMessageClose = this.handleNoSuspensionMessageClose.bind(this);
    }

    componentDidMount() {
        this.loadParts(this.props.filterQuery);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filterQuery !== this.props.filterQuery || prevProps.currentPartRestriction.filterUrl !== this.props.currentPartRestriction.filterUrl) {
            this.loadParts(this.props.filterQuery);
        }
    }

    handleNoSuspensionMessageClose() {
        this.setState({noSuspensionMessageState: false});
        this.props.goBack();
    }

    loadParts(query) {
        if(!this.props.currentPartRestriction.filterUrl) {
            return;
        }
        this.setState({restrictionData: []});
        const filterUrlWithQuery = this.props.currentPartRestriction.filterUrl + query + '&_=' + new Date().getTime();

        trackPromise(
            Api().get(filterUrlWithQuery)
                .then(response => {
                    if(!response.data.data.records) {
                        this.setState({noSuspensionMessageState: true})
                    }
                    this.setState({restrictionData: response.data.data});
                })
                .catch(error => {
                    console.error('error', error);
                    this.props.enqueueErrorSnackbar(i18n.t('error_on_data_loading'));
                }), loadingAreas.catalog);
    }

    handleClick = (part) => () => {
        if (!part.link) {
            return;
        }

        catmetaApi.getPartInformation(part.link)
            .then((partInformation) => {
                partInformation.title = part.link.wid;
                this.props.updateCurrentPartInformation(partInformation);
                this.props.routeToVehicleInformation();
            });
    };

    render() {
        return (
            <>
                <SuspensionPartTable
                    restrictions={RestrictionService.getRestrictionsFromArray(this.state.restrictionData)}
                    widgetKey={'suspensionbom'}
                    onItemClick={this.handleClick}/>
                    <NoSuspensionMessage noSuspensionMessageState={this.state.noSuspensionMessageState}
                                         handleNoSuspensionMessageClose={this.handleNoSuspensionMessageClose}/>
            </>
        );
    }
}

const mapStateToProps = state => ({
    currentPartRestriction: state.catalog.currentPartRestriction,
});

const mapDispatchToProps = {
    updateCurrentPartInformation,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withCatalog,
    withSnackbar,
)(SuspensionResults);