import {REMOVE_CRUMB_BY_WIDGET_ID, RESET_CRUMBS, UPDATE_CRUMBS} from './crumbsTypes';

const initialState = [];

export default function crumbsReducer(state = initialState, action) {
    const oldCrumbs = state;
    let newCrumbs = [];

    switch (action.type) {
        case UPDATE_CRUMBS:
            newCrumbs = action.value;

            newCrumbs = newCrumbs.map((newCrumb) => {
                const oldCrumb = oldCrumbs.find(oldCrumb => oldCrumb.link.path === newCrumb.link.path);

                if(oldCrumb) {
                    newCrumb.fetched = oldCrumb.fetched;
                }
                return newCrumb
            })
            newCrumbs[newCrumbs.length - 1].fetched = true;

            return [...newCrumbs];
        case REMOVE_CRUMB_BY_WIDGET_ID:
            return state.filter((obj) => obj.link.wid !== action.value);
        case RESET_CRUMBS:
            return initialState;
        default:
            return state;
    }
}
