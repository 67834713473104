import React from 'react';
import {connect} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import {updateSearchDialogState} from '../../redux/search/searchAction';

class SearchDialog extends React.Component {

    render() {
        const {t} = this.props;

        return (
            <Dialog
                open={!!this.props.searchDialogShow}
                aria-labelledby="search-message-dialog-title"
                aria-describedby="search-message-dialog-description"
            >
                <DialogContent>
                    <DialogContentText>
                        {this.props.searchDialogShow}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={() => this.props.updateSearchDialogState(false)}>
                        {t('ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    searchDialogShow: state.search.searchDialogShow,
});

const mapDispatchToProps = {
    updateSearchDialogState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
)
(SearchDialog);
