import React, {Fragment} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {compose} from 'redux';
import {withStyles} from '@mui/styles';
import {withTranslation} from 'react-i18next';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import {Box} from '@mui/material';


const useStyles = () => ({
    image: {
        height: '2.5rem',
        width: 'auto',
        minWidth: '40px',
        marginRight: '0.6rem',
    },
});

class DealerList extends React.Component {

    getDealerItem(dealer) {
        let primary, secondary,
            currentDealerId = parseInt(this.props.currentDealerId),
            dealerId = parseInt(dealer.dealerId);

        if (dealer) {
            if (dealer.companyName) {
                primary = dealer.companyName;
            } else if (dealer.dealerName) {
                primary = dealer.dealerName;
            } else {
                primary = this.props.t('no_dealer_selected');
            }
            if (dealer.addressLines && dealer.addressLines.length) {
                secondary = dealer.addressLines.join(' ');
            }
        }


        return (

            <Fragment>
                {currentDealerId > 0 && (
                    <Box mr={1}>
                        {currentDealerId === dealerId ? (
                            <RadioButtonCheckedIcon/>
                        ) : (
                            <RadioButtonUncheckedIcon/>
                        )}
                    </Box>
                )}

                <ListItemText primary={primary} secondary={secondary}/>
            </Fragment>
        );
    }

    getDealerList() {
        const {classes} = this.props;
        let catalogDealers = this.props.catalogDealers;

        if (this.props.sortBy) {
            catalogDealers = catalogDealers.sort(this.props.sortBy);
        }

        return catalogDealers
            .map((catalogDealer, i) => {
                let dealer;
                if (catalogDealer.dealer) {
                    dealer = catalogDealer.dealer;
                } else {
                    dealer = catalogDealer;
                }
                return (
                    <ListItem
                        button divider={true} key={'dealer_item_' + i}
                        onClick={this.props.onDealerClick.bind(this, catalogDealer)}>

                        {catalogDealer.iconUrl ?
                            <img className={classes.image} src={catalogDealer.iconUrl} alt={catalogDealer.name}/> : null
                        }

                        {this.getDealerItem(dealer)}
                    </ListItem>
                );
            });
    }

    render() {

        return (
            <List>
                {this.getDealerList()}
            </List>
        );
    }
}

export default compose(
    withTranslation(),
    withStyles(useStyles),
)(DealerList);

