import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import {compose} from 'redux';
import {withStyles} from '@mui/styles';
import {connect} from 'react-redux';
import PartService from '../../service/partService';
import FormattedText from '../text/formattedText';
import RestrictionService from '../../service/restrictionService';
import classNames from 'classnames';
import {Checkbox, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {LightBulbIcon} from '../base/additionalIcons';
import DynamicRestrictionTableCell from './dynamicRestrictionTableCell';

const useStyles = theme => ({
    unavailable: {
        color: theme.palette.grey300,
    },
    tableCell: {
        verticalAlign: props => props.cellAlign || 'top',
        position: 'relative',
    },
    isClickable: {
        cursor: 'pointer',
    },
    headerCell: {
        backgroundColor: `${theme.palette.grey50} !important`,
        // [theme.breakpoints.up('sm')]: {
        //     backgroundColor: `${theme.palette.white} !important`,
        // },
        '&:empty': {
            padding: '0',
        },
    },
    bigHeadline: {
        fontWeight: 'bold !important',
        color: `${theme.palette.grey700} !important`,
        paddingTop: '11px',
        borderTop: 'solid 5px ' + theme.palette.grey200,
        paddingBottom: '11px',
        borderBottom: 'solid 5px ' + theme.palette.grey200,
    },
    smallTableCell: {
        paddingRight: '8px',
    }
});

class DynamicRestrictionTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasListLook: this.getTableColumns().length === 1,
            addAdditionalRow: this.props.showTrigger || this.props.showCheckbox,
        };

        this.onItemClick = this.onItemClick.bind(this);
        this.getText = this.getText.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.widgetKey !== this.props.widgetKey) {
            this.setState({hasListLook: this.getTableColumns().length === 1});
        }
    }

    onItemClick(restriction) {
        if (RestrictionService.isRestrictionNotClickable(restriction)) {
            return null;
        }

        if (restriction.elink) {
            return window.open(restriction.elink, '_blank');
        }

        return this.props.onItemClick(restriction);
    }

    getText(value) {
        if (this.props.showUnformattedText) {
            return value;
        }
        return (<FormattedText value={value}/>);
    }

    getRowTrigger(restriction) {
        const { classes } = this.props;
        const restrictionValue = restriction.values;

        if (!restrictionValue.partno) {
            return (<TableCell/>);
        }

        let cellClass = classNames(classes.tableCell, classes.isClickable);

        if (!this.state.hasListLook) {
            cellClass = classNames(cellClass, classes.smallTableCell);
        }

        return (
            <TableCell
                className={cellClass}
                onClick={() => this.onItemClick(restriction)}
            >
                {PartService.getTrimmedPartNo(restriction).length === 0 ? null : <InfoIcon/>}
            </TableCell>
        );
    }

    getRowCheckbox(restriction) {
        if (restriction.unclickable) {
            return (<TableCell/>);
        }

        const {classes} = this.props;
        let cellClass = classNames(classes.tableCell, classes.isClickable);

        if (!this.state.hasListLook) {
            cellClass = classNames(cellClass, classes.smallTableCell);
        }

        return (
            <TableCell
                className={cellClass}
                onClick={() => this.onItemClick(restriction)}>
                <Checkbox checked={!!restriction.isSelected}/>
            </TableCell>
        );
    }

    getTableColumns() {
        const { widgetKey, listDefinitions } = this.props;

        if (!widgetKey || !listDefinitions[widgetKey]) {
            return [];
        }

        return this.props.listDefinitions[widgetKey].elements;
    }

    getTableHeaders() {
        const { classes } = this.props;

        return this.getTableColumns().length <= 1 ? null : (
            <TableHead>
                <TableRow>
                    {this.state.addAdditionalRow && <TableCell/>}

                    {this.getTableColumns().map((column, i) => (
                        <TableCell
                            key={i}
                            className={classes.headerCell}
                        >
                            {column.title}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    getRowCells(restriction) {
        const { classes } = this.props;
        let cellClass = classes.tableCell;

        if (restriction.unavailable || restriction.validity === 'INVALID') {
            cellClass = classNames(cellClass, classes.unavailable);
        }
        if (!restriction.unclickable) {
            cellClass = classNames(cellClass, classes.isClickable);
        }

        return this.getTableColumns().map((column, i) => (
            <DynamicRestrictionTableCell 
                key={i}
                column={column}
                cellClass={cellClass}
                restriction={restriction}
                onItemClick={this.onItemClick}
                getText={this.getText}
            />
        ));
    }

    getTableRowHeadline(restriction, index) {
        const { classes } = this.props;
        
        const restrictionValues = restriction.values;
        const cellClass = classNames(classes.tableCell, classes.bigHeadline);
        const headerCells = this.getTableColumns()?.map(column => restrictionValues[column.id]);

        if (!headerCells) {
            return null;
        }

        return (
            <TableRow 
                key={'headline_' + index + restriction.id} 
                className={classes.sectionRow}
            >
                {this.state.addAdditionalRow && <TableCell/>}

                {headerCells.map((column, i) => (
                    !column ? (
                        <TableCell className={cellClass} key={i}/>
                    ) : (
                        <TableCell className={cellClass} key={i}>
                            {this.getText(column)}
                        </TableCell>
                    )

                ))}
            </TableRow>
        );
    }

    getTableRow(restriction, index) {
        return (
            <TableRow key={'row_' + restriction.id + '_' + index}>
                {this.props.showTrigger && this.getRowTrigger(restriction)}
                {this.props.showCheckbox && this.getRowCheckbox(restriction)}
                {this.getRowCells(restriction, index)}
            </TableRow>
        );
    }

    getRemark(restriction, i) {
        const { widgetKey, classes, listDefinitions } = this.props;
        const colSpan = listDefinitions[widgetKey] && listDefinitions[widgetKey].elements 
            ? listDefinitions[widgetKey].elements.length
            : 1;

        let cellClass = classes.tableCell;

        if (this.state.hasListLook) {
            cellClass = classNames(cellClass, classes.bigHeadline);
        }

        return (
            <TableRow key={'row_' + restriction.id + '_' + i}>
                <TableCell 
                    key={i} 
                    colSpan={colSpan}
                    className={cellClass}
                >
                    <Box mr={1} component="span">
                        <LightBulbIcon />
                    </Box>
                    <Typography variant="body2">
                        {this.getText(restriction.remark)}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    }

    getTableRows() {
        const { restrictions } = this.props;

        return restrictions.map((restriction, i) => {
            if (restriction.characteristic) {
                return this.getTableRowHeadline(restriction, i);
            } else if (restriction.remark) {
                return this.getRemark(restriction, i);
            } else {
                return this.getTableRow(restriction, i);
            }
        }).filter(i => i);
    }

    render() {
        const styleTableContainer = this.props.fullHeight ? { height: '100%' } : {};        

        return (
            <TableContainer component={Paper} sx={styleTableContainer}>
                <Table stickyHeader={true} size={!this.state.hasListLook ? 'small' : 'medium'}>
                    {this.getTableHeaders()}

                    <TableBody>
                        {this.getTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

const mapStateToProps = state => ({
    listDefinitions: state.catalog.currentBrand.listDefinitions,
});

export default compose(
    connect(
        mapStateToProps,
    ),
    withStyles(useStyles),
)(DynamicRestrictionTable);
