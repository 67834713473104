class PartService {

    static getPartFromObject(partObject) {
        if (!partObject) {
            return {};
        }

        if (!partObject.p5goto || !partObject.p5goto.ws || !partObject.p5goto.ws.length) {
            console.error('Error happened on getPartFromObject (part), goto id and/or link no under \'p5goto.ws[0]\'', partObject);
            return {};
        }

        return {
            id: partObject.p5goto.ws[0].id,
            link: partObject.p5goto.ws[0].path,
            values: partObject.values ? partObject.values : {},
        };
    }

    static getTrimmedPartNo(item){
        if(item && item.values && item.values.partno) {
            return item.values.partno.trim();
        }else {
            return '';
        }
    }
}

export default (PartService);