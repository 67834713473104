import React from 'react';
import {Box} from '@mui/material';

class cartRow extends React.Component {

    render() {
        return (
            <Box my={1} pb={1} border={1} borderColor="border.main" borderTop={0} borderRight={0} borderLeft={0}>
                {this.props.children}
            </Box>
        );
    }

}

export default cartRow;
