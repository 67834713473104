import React from 'react';
import { withTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import BrandSelectionTrigger from '../../brand/brandSelectionTrigger';
import SearchTrigger from '../../search/searchTrigger';
import { compose } from 'redux';
import theme from '../../../theme/theme';

class PartHeader extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
            }}>
                <Box sx={{
                    width: 'auto',
                    height: theme.spacing(8)
                }}>
                    <BrandSelectionTrigger/>
                </Box>

                <SearchTrigger
                    label={t('search_parts')}
                    styles={{ 
                        [theme.breakpoints.down('sm')]: {
                            maxWidth: theme.spacing(32),
                            flexGrow: 1,
                            ml: 4,
                        },
                        [theme.breakpoints.up('sm')]: {
                            width: '50%'
                        }
                    }}
                />
            </Box>
        );
    }
}

export default compose(
    withTranslation()
)
(PartHeader);
