import React from 'react';

class Status extends React.Component {
    render() {
        return (
            'STATUS: UP'
        )
    }
}

export default (Status)
