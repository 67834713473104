import React, {Fragment} from 'react';
import {compose} from 'redux';
import ListAddress from './listAddress';
import withCartApi from '../../../api/cartApi';

class ListAddressGroup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shippingAddresses: [],
            billingAddresses: [],
        };
    }


    componentDidMount() {
        this.props.getAddresses('shippingAddresses').then(resp => {
            this.setState({shippingAddresses: resp});
        });
        this.props.getAddresses('billingAddresses').then(resp => {
            this.setState({billingAddresses: resp});
        });
    }

    render() {

        const {cart, readonly, cartSubmitClick, shippingAddress, billingAddress} = this.props;

        return (
            <Fragment>
                <ListAddress addressType="shipping"
                             cartId={cart.cartId}
                             selectedAddress={shippingAddress ? shippingAddress : cart.shippingAddress}
                             cartSubmitClick={cartSubmitClick}
                             addresses={shippingAddress ? shippingAddress : this.state.shippingAddresses}
                             readonly={readonly}
                />
                <ListAddress addressType="billing"
                             cartId={cart.cartId}
                             selectedAddress={billingAddress ? billingAddress : cart.billingAddress}
                             cartSubmitClick={cartSubmitClick}
                             addresses={billingAddress ? billingAddress : this.state.billingAddresses}
                             readonly={readonly}
                />
            </Fragment>
        );
    }
}

export default compose(
    withCartApi,
)(ListAddressGroup);
