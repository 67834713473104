import React from 'react';
import axios from 'axios';
import Container from './container';
import {compose} from 'redux';
import {connect} from 'react-redux';
import i18n from '../../i18n';
import withSnackbar from '../../service/withSnackbar';
import NoMatchPage from '../pages/noMatchPage';

class ExternalContentPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            externalHTML: '',
        };
    }

    componentDidMount() {
        const contentPageId = this.props.match.params.contentPageId;
        const contentPage = this.props.contentLinks.find(item => item.name === contentPageId);
        this.setState({externalHTML: this.getExternalHtml(contentPage)});
    }

    getExternalHtml(contentPage) {
        if (!contentPage || !contentPage.url) {
            console.error('error happened on getExternalHtml', 'contentPage or url not set');
            return null;
        }

        axios.get( contentPage.url)
            .then((response) => {
                // const doc = document.implementation.createHTMLDocument('');
                // doc.body.innerHTML = response.data;
                // const containerElement = doc.querySelector('body');
                // this.setState({externalHTML: containerElement.innerHTML});

                this.setState({externalHTML: response.data});
            })
            .catch((err) => {
                console.log('contentPage', contentPage);
                console.error('error happened on getExternalHtml', err);
                this.props.enqueueErrorSnackbar(i18n.t('error_on_content_page'));
            });
    }

    createMarkup() {
        return {__html: this.state.externalHTML};
    }

    getContent() {
        if(this.state.externalHTML) {
            return (<div dangerouslySetInnerHTML={this.createMarkup()}/>)
        } else {
            return (<NoMatchPage />);
        }
    }

    render() {
        return (
            <Container>
                {this.getContent()}
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    contentLinks: state.global.contentLinks,
});

export default compose(
    connect(
        mapStateToProps,
    ),
    withSnackbar,
)
(ExternalContentPage);