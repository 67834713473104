import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import {withStyles} from '@mui/styles';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import {availabilities, availabilityMapping} from '../../../config/cartConfig';
import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid';

const useStyles = (theme) => ({
    root: {
        display: 'inline-block',
        width: '9px',
        height: '9px',
        border: '2px solid',
        borderColor: 'transparent',
        borderRadius: '50%',
        background: 'transparent',
        marginRight: theme.spacing(1.5),
        marginLeft: theme.spacing(1.5),
        lineHeight: 1,
    },
    full: {
        borderColor: theme.palette.success.main,
        background: theme.palette.success.main,
    },
    outline: {
        borderColor: theme.palette.success.main,
    },
    half: {
        borderColor: theme.palette.success.main,
        position: 'relative',

        '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '50%',
            backgroundColor: theme.palette.success.main,
        },
    },
    questionMark: {
        position: 'relative',

        '&:before': {
            content: '"?"',
            color: theme.palette.success.main,
            fontWeight: theme.typography.fontWeightBold,
            fontFamily: theme.typography.fontFamily,
            position: 'absolute',
            top: 0,
            left: 0,
            lineHeight: .65,
            fontSize: '16px',
        },
    },
});

class CartItemAvailability extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            originalType: this.props.type,
            mappedType: availabilityMapping[this.props.type],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.type !== this.props.type){
            this.setState({mappedType: availabilityMapping[this.props.type]})
        }
    }

    getClassByType = (type) => {
        return this.props.classes[availabilities[type].class];
    };

    layoutTooltip(text) {
        const {classes} = this.props;

        return (
            <Tooltip title={text}>
                <Box className={[classes.root, this.getClassByType(this.state.mappedType)].join(' ')}/>
            </Tooltip>
        );
    }

    layoutLabel(text) {
        const {classes} = this.props;

        return (
            <Box fontWeight="normal">
                <Grid container alignItems={'center'}>
                    <Grid item xs="auto">
                        <Box display={'flex'} alignItems={'center'}>
                            <Box className={[classes.root, this.getClassByType(this.state.mappedType)].join(' ')}/>
                        </Box>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2">{text}</Typography>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    isValidType(type) {
        return !!availabilityMapping[type];
    }

    render() {
        const {layout, type, t} = this.props;

        if (!type) {
            return null;
        }

        if (!this.isValidType(type)) {
            console.error('Error happened on CartItemAvailability, availability type "' + type + '" is not allowed');
            return null;
        }

        const text = this.props.text ? this.props.text : t('cart_availability_' + availabilityMapping[type]);

        if (layout === 'label') {
            return this.layoutLabel(text);
        } else {
            return this.layoutTooltip(text);
        }

    }
}

export default compose(
    withTranslation(),
    withStyles(useStyles),
)
(CartItemAvailability);
