import Api from './api';
import {store} from '../redux/store';
import * as React from 'react';
import {compose} from 'redux';
import withSnackbar from '../service/withSnackbar';
import i18n from '../i18n';
import UserAgent from '../service/userAgent';
import {setToken} from '../redux/global/globalAction';
import {connect} from 'react-redux';
import {
    setSessionOverTakeShown,
    updateErrorState,
    updateLogInState,
    setUsername
} from '../redux/auth/authAction';
import { deleteCartsCatalog } from '../redux/cart/cartAction';

function withAuthApi(WrappedComponent) {
    class HOC extends React.Component {
        login = (account, user, pwd, forceLogin = false) => {
            return new Promise((resolve, reject) => {
                Api().post('/pl24-appgtw/ext/api/1.0/login', {
                    'authentication': {
                        'account': account,
                        'user': user,
                        'pwd': pwd,
                    },
                    'device': {
                        'id': '0',
                        'os': UserAgent.getOS(),
                        'offset': '0',
                        'lang': UserAgent.getBrowserLanguage(),
                        'os-version': '0',
                    },
                    'app-version': UserAgent.getAppVersion(),
                    'squeezeOut': forceLogin,
                }).then(response => {
                        if (response.status === 200) {
                            if (response.data.status && response.data.status === 'USER_ALREADY_LOGGED_IN') {
                                this.props.setSessionOverTakeShown(true);
                                reject();
                            }

                            if (response.data.token && response.data.token.access_token) {
                                this.props.setToken(response.data.token.access_token);


                                if (this.props.username !== user) {
                                    this.props.setUsername(user);
                                    this.props.deleteCartsCatalog();
                                }
                            }

                            // set refresh-token only on login not on session-expand because it will be null
                            if (response.data.refreshToken) {
                                localStorage.setItem('refreshToken', response.data.refreshToken)
                            }

                            resolve();
                            return;
                        }
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_login'));
                        this.props.updateErrorState(true);
                        reject();

                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 401) {
                            this.props.enqueueErrorSnackbar(i18n.t('error_on_login_wrong_credentials'), {persist: false});
                            this.props.updateErrorState(true);
                            reject();
                            return;
                        }

                        if (error.response && error.response.status === 403) {
                            this.props.enqueueErrorSnackbar(i18n.t('error_on_login_wrong_user_role'), {persist: false});
                            this.props.updateErrorState(true);
                            reject();
                            return;
                        }

                        // console.error('error happened on login', error);
                        this.props.enqueueErrorSnackbar(i18n.t('error_on_login'), {persist: false});
                        this.props.updateErrorState(true);
                        reject();
                    });
            });
        };

        tryAutoLogin = () => {
            return new Promise((resolve, reject) => {
                // login by existing Session (time-range: 5 min)
                Api().get('/pl24-appgtw/ext/api/1.0/session').then(response => {
                        this.onAutoLoginSuccess(response);

                        resolve();
                    }).catch(() => {
                        // login with refreshToken (time-range: 30 days)

                        Api().put('/pl24-appgtw/ext/api/1.0/login', {
                            'squeezeOut': true,
                            'refreshToken': localStorage.getItem('refreshToken'),
                        }).then(response => {
                            if (response.data?.status === 'USER_ALREADY_LOGGED_IN') {
                                resolve(response.data.status);
                            }

                            this.onAutoLoginSuccess(response);
                            resolve();
                        }).catch(error => {
                            reject(error);
                        });
                    });
            });
        };

        logout = () => {
            return new Promise((resolve, reject) => {
                Api().post('/pl24-appgtw/ext/api/1.0/logout', {
                    refreshToken: localStorage.getItem('refreshToken'),
                    squeezeOut: true,
                }).then(() => {
                    // remove refresh token only on manual logout
                    localStorage.removeItem('refreshToken');
                    resolve();
                }).catch(() => reject());
            });
        }

        onAutoLoginSuccess(response) {
            if (response.data.token && response.data.token.access_token) {
                this.props.setToken(response.data.token.access_token);
            }

            this.props.updateLogInState(response.status === 200);
        }

        extendCookieLifeTime = async () => {
            try {
                const response = await Api().get('/pl24-appgtw/ext/api/1.0/session').catch(() => {});

                if (response.status !== 200 && response.status !== 201) {
                    this.props.updateLogInState(false);
                    return;
                }

                if (response.data.token && response.data.token.access_token) {
                    this.props.setToken(response.data.token.access_token);
                }

                if (store.getState().global.devEnv) {
                    console.info('cookie successfully extended', response);
                }

            } catch (error) {
                this.props.updateLogInState(false);
            }
        };

        render() {
            return <WrappedComponent
                login={this.login}
                logout={this.logout}
                tryAutoLogin={this.tryAutoLogin}
                extendCookieLifeTime={this.extendCookieLifeTime}
                {...this.props} 
            />;
        }
    }

    const mapStateToProps = state => ({
        username: state.auth.username
    });

    const mapDispatchToProps = {
        updateLogInState,
        updateErrorState,
        setToken,
        setSessionOverTakeShown,
        setUsername,
        deleteCartsCatalog
    };

    return compose(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
        withSnackbar,
    )(HOC);
}

export default withAuthApi;
