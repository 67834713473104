import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import de from './locales/de.json';
import {store} from './redux/store';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: store.getState().global.devEnv,

        load: 'languageOnly',
        fallbackLng: 'en',
        supportedLngs: ['en', 'de'],

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            formatSeparator: '.',
        },

        react: {
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
        },

        resources: {
            en: {
                translation: en,
            },
            de: {
                translation: de,
            },
        },
    });

export default i18n;