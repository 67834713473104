import React from 'react';
import Select from '@mui/material/Select';
import i18n from 'i18next';
import MenuItem from '@mui/material/MenuItem';
import {withTranslation} from 'react-i18next';
import {FormControl} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import {compose} from 'redux';
import ContentPageService from '../../service/contentPageService';
import {connect} from 'react-redux';
import {clearStore} from '../../redux/auth/authAction';

class LanguageSelect extends React.Component {
    constructor(props) {
        super(props);

        this.languages = [
            { key: 'en', value: 'English' },
            { key: 'de', value: 'Deutsch' },
        ];
    }

    handleLanguageChange = e => {
        const newLanguageKey = e.target.value;

        i18n.changeLanguage(newLanguageKey).then(() => {
            this.props.clearStore();

            ContentPageService.loadContentPageLinks().then();
        });
    }

    render() {
        const { t } = this.props;

        return (
            <FormControl size="small" sx={{
                mt: 2,
                width: '100%' 
            }}>
                <InputLabel sx={{ mt: -2 }}>
                    {t('my_language')}
                </InputLabel>
                <Select
                    variant="filled"
                    value={i18n.language.slice(0, 2)}
                    onChange={this.handleLanguageChange}
                    fullWidth
                    sx={{
                        minWidth: '240px',
                        '.MuiInputBase-input': {
                            py: '8px !important'
                        } 
                }}>
                    {this.languages.map(language => (
                        <MenuItem key={language.key} value={language.key}>
                            {language.value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    clearStore,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
)(LanguageSelect);
