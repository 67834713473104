import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '../base/container';

class NoMatchPage extends React.Component {
    render() {
        return (
            <Container>
                <Typography variant="h1">404</Typography>
                <Typography variant="h4">Page not found</Typography>
            </Container>
        );
    }
}
export default (NoMatchPage);