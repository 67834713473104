const colors = {
    primary: '#F7C400',
    secondary: '#E1AE07',
    tertiary: '#FFF2C6',
    success: '#00C853',
    warning: '#FF8A19',
    error: '#B50D0D',
    errorPrice: '#F50057',
    black: '#000',
    white: '#fff',
    grey50: '#FAFAFA',
    grey100: '#F0F0F0',
    grey200: '#E7E7E7',
    grey300: '#ABACB0',
    grey700: '#817D75'
};

export default colors;
