class CartService {

    static getAddressString(address){
        let addressString = '';

        if(address && address.addressLines) {
            addressString = address.addressLines.join(', ');
        }

        return addressString;
    };
}

export default (CartService);
