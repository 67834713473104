import React from 'react';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import {availabilityMapping} from '../../../config/cartConfig';
import {Box} from '@mui/material';
import Typography from '@mui/material/Typography';
import CartItemAvailability from './cartItemAvailability';
import Grid from '@mui/material/Grid';


class cartAvailabilityLegend extends React.Component {

    render() {
        const {t} = this.props;

        return (
            <Box>
                <Box mb={1}>
                    <Typography variant="body2">{t('cart_availability_legend_title')}</Typography>
                </Box>
                <Grid container spacing={1} justifyContent="space-between">
                    {
                        Object.keys(availabilityMapping).map(function (key) {
                            return (
                                <Grid key={key} item xs={6} md={2}>
                                    <CartItemAvailability key={key} layout="label" type={key}/>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </Box>
        );

    }
}

export default compose(
    withTranslation(),
)
(cartAvailabilityLegend);
