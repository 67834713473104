import axios from 'axios';
import { store } from '../redux/store';
import { updateLogInState } from '../redux/auth/authAction';
import i18n from '../i18n';

const Api = () => {
    let Authorization = null;
    let dealerId = null;

    if (store.getState().global.token){
        Authorization = `Bearer ${store.getState().global.token}`
    }

    if (store.getState().cart.dealer && store.getState().cart.dealer.dealerId > 0) {
        dealerId = store.getState().cart.dealer.dealerId;
    }

    const api = axios.create({
        baseURL: '',
        withCredentials: true,
        headers: {
            Authorization,
            "Accept-Language": i18n.language,
            'PL24-DEALER-ID': dealerId
        }
    });

    api.interceptors.response.use((response) => {
        return Promise.resolve(response);

    }, (error) => {
        // This causes constant reloading on Chrome
        // if (!navigator.onLine) {
            // window.location.reload();
        // }

        if (error.response && [400, 401, 403].includes(error.response.status)) {
        // if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            store.dispatch(updateLogInState(false));

        } else if (error.response && (error.response.status === 500 || ( error.response.data && error.response.data.type && error.response.data.type.indexOf('internalError') !== -1))) {
            // console.error('API-Error: ', error.response.data.detail);

            error.response.data.title = i18n.t('internal_server_error_title');
            error.response.data.detail = i18n.t('internal_server_error_detail');
        }

        return Promise.reject(error);
    });

    return api;
}
export default Api;