import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import lightbulb from './additionalIcons/lightbulb_outline.png'

export function IosShareIcon(props) {
    return (
        <SvgIcon {...props}>
            <polygon
                points="13.8 6.46 13.8 7.384 20.1 7.384 20.1 23.077 3.9 23.077 3.9 7.384 10.2 7.384 10.2 6.46 3 6.46 3 24 21 24 21 6.46 13.8 6.46"/>
            <polygon
                points="11.539 1.812 11.539 15.833 12.461 15.833 12.461 1.812 15.539 4.967 16.191 4.298 12 0 7.809 4.298 8.461 4.967 11.539 1.812"/>
        </SvgIcon>
    );
}

export function BarcodeScanIcon(props) {
    return (
        <SvgIcon {...props}>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Top-Bar-/-Katalog-suche" transform="translate(-284.000000, -17.000000)">
                        <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="320" height="55" />
                        <g id="iconfinder_scanning_5900339" transform="translate(284.000000, 17.000000)" fill="#000000" fillRule="nonzero">
                            <path d="M20.0454545,13.3636364 C19.5182736,13.3636364 19.0909091,13.7910009 19.0909091,14.3181818 L19.0909091,19.0909091 L14.3181818,19.0909091 C13.7910009,19.0909091 13.3636364,19.5182736 13.3636364,20.0454545 C13.3636364,20.5726354 13.7910009,21 14.3181818,21 L20.0454545,21 C20.5726354,21 21,20.5726354 21,20.0454545 L21,14.3181818 C21,13.7910009 20.5726354,13.3636364 20.0454545,13.3636364 Z" id="Path" />
                            <path d="M6.68181818,19.0909091 L1.90909091,19.0909091 L1.90909091,14.3181818 C1.90909091,13.7910009 1.48172635,13.3636364 0.954545455,13.3636364 C0.427364557,13.3636364 0,13.7910009 0,14.3181818 L0,20.0454545 C0,20.5726354 0.427364557,21 0.954545455,21 L6.68181818,21 C7.20899908,21 7.63636364,20.5726354 7.63636364,20.0454545 C7.63636364,19.5182736 7.20899908,19.0909091 6.68181818,19.0909091 Z" id="Path" />
                            <path d="M0.954545455,7.63636364 C1.48172635,7.63636364 1.90909091,7.20899908 1.90909091,6.68181818 L1.90909091,1.90909091 L6.68181818,1.90909091 C7.20899908,1.90909091 7.63636364,1.48172635 7.63636364,0.954545455 C7.63636364,0.427364557 7.20899908,0 6.68181818,0 L0.954545455,0 C0.427364557,0 0,0.427364557 0,0.954545455 L0,6.68181818 C0,7.20899908 0.427364557,7.63636364 0.954545455,7.63636364 Z" id="Path" />
                            <path d="M20.0454545,0 L14.3181818,0 C13.7910009,0 13.3636364,0.427364557 13.3636364,0.954545455 C13.3636364,1.48172635 13.7910009,1.90909091 14.3181818,1.90909091 L19.0909091,1.90909091 L19.0909091,6.68181818 C19.0909091,7.20899908 19.5182736,7.63636364 20.0454545,7.63636364 C20.5726354,7.63636364 21,7.20899908 21,6.68181818 L21,0.954545455 C21,0.427364557 20.5726354,0 20.0454545,0 Z" id="Path" />
                            <path d="M20.0454545,9.54545455 L0.954545455,9.54545455 C0.427364557,9.54545455 0,9.9728191 0,10.5 C0,11.0271809 0.427364557,11.4545455 0.954545455,11.4545455 L20.0454545,11.4545455 C20.5726354,11.4545455 21,11.0271809 21,10.5 C21,9.9728191 20.5726354,9.54545455 20.0454545,9.54545455 Z" id="Path" />
                        </g>
                    </g>
                </g>
        </SvgIcon>
    );
}

export function LightBulbIcon() {
    return <img src={lightbulb} alt={'lightbulb'} />
}
