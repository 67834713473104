import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Container from '../base/container';
import BrandList from './brandList';
import withCatalog from '../../service/withCatalog';
import {compose} from 'redux';
import {resetCurrentBrand} from '../../redux/catalog/catalogAction';
import {resetCrumbs} from '../../redux/crumbs/crumbsAction';
import {routeCatalogVehicle} from '../../config/routeItems';

class BrandSelection extends React.Component {
    componentDidMount() {
        // TODO workaround for vehicleReset if BrandSelection Component is shown
        // --> change rooting (routerItems) so BrandSelection Component is not loaded/passed on 'moveToCurrentVehiclePosition'
        this.timeout = setTimeout(() => {
            this.props.resetCurrentBrand();
        }, 200);

        if (this.props.reset) {
            this.props.resetCrumbs();
            this.props.resetCurrentBrand();
            this.props.history.push(routeCatalogVehicle.url())
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    onBrandSelected = brand => {
        this.props.routeToModel(brand.id);
    };

    render() {
        return (
            <Container paddingY limitHeight overflow>
                <BrandList onBrandItemClick={this.onBrandSelected} />
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    currentBrand: state.catalog.currentBrand,
    currentVehicleRestriction: state.catalog.currentVehicleRestriction,
    currentVehicle: state.catalog.currentVehicle,
});

const mapDispatchToProps = dispatch => ({
    resetCurrentBrand: () => dispatch(resetCurrentBrand()),
    resetCrumbs: () => dispatch(resetCrumbs()),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withCatalog,
    withRouter,
)
(BrandSelection);
