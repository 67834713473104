import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';
import CartList from '../cart/select/cartList';
import withCartApi from '../../api/cartApi';
import {
    setCartRemoveDialogState,
    setCartRenameDialogState,
    setCartsCatalog,
    setCartSelectionDialogState
} from '../../redux/cart/cartAction';
import { updateBrandSelectionDialogState } from '../../redux/catalog/catalogAction';
import CartRenameDialog from '../cart/select/cartRenameDialog';
import CartRemoveDialog from '../cart/select/cartRemoveDialog';
import Container from '../base/container';
import withSnackbar from '../../service/withSnackbar';
import i18n from '../../i18n';

const useStyles = () => ({
    container: {
        justifyContent: 'space-between',
    }
});

class ChangeCart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            carts: [],
            cartCurrent: {}
        };
    }

    componentDidMount() {
        if (!this.props.currentBrand.id) {
            return;
        }

        this.updateCartList();
    }

    updateCartList = () => {
        this.setState({ isLoading: true });

        this.props.getCarts(this.props.currentBrand.id).then(carts => {
            this.setState({ carts: carts });
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }

    selectCart = cart => {
        this.props.setCartSelectionDialogState(false);
        this.props.setCartsCatalog({
            brandId: this.props.currentBrand.id,
            cart: cart
        });

        if (this.props.cartContextId !== cart.cartId) {
            this.props.setCartContext(cart);
            this.props.setCart(cart);

            this.props.enqueueSuccessSnackbar(i18n.t('success_on_switch_cart_context', {
                cartName: cart.cartName
            }));
        }
    }

    createNewCart = () => {
        this.props.createNewCart(this.props.currentBrand.id).then(newCart => {
            if (!newCart) {
                return;
            }

            this.updateCartList();
            this.props.setCartContext(newCart);

            this.props.enqueueSuccessSnackbar(i18n.t('success_on_create_new_cart', {
                cartName: newCart.cartName
            }));
        });
    }

    handleRenameClick = cart => {
        this.props.setCartContext(cart);
        this.props.setCartRenameDialogState(true)
    }

    handleDeleteClick = cart => {
        this.props.setCartContext(cart);
        this.props.setCartRemoveDialogState(true)
    }

    render() {
        const { t, classes } = this.props;

        return (
            <Container className={classes.container} limitHeight paddingX>
                <CartList
                    carts={this.state.carts}
                    isLoading={this.state.isLoading}
                    onItemClick={this.selectCart}
                    onRenameClick={this.handleRenameClick}
                    onDeleteClick={this.handleDeleteClick}
                />
                <Box sx={{ px: { xs: 2, sm: 0 }  }}>
                    <Button variant="contained" onClick={this.createNewCart} sx={{
                        textTransform: 'none',
                        fontWeight: 700,
                        width: '100%'
                    }}>
                        {t('create_new_cart')}
                    </Button>
                </Box>

                {this.props.cartRenameDialogShow &&
                    <CartRenameDialog onUpdate={this.updateCartList} />
                }
                {this.props.cartRemoveDialogShow &&
                    <CartRemoveDialog onUpdate={this.updateCartList} />
                }
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    cartRenameDialogShow: state.cart.cartRenameDialogShow,
    cartRemoveDialogShow: state.cart.cartRemoveDialogShow,
    cart: state.cart.cart,
    currentBrand: state.catalog.currentBrand
});

const mapDispatchToProps = {
    setCartSelectionDialogState,
    setCartRenameDialogState,
    setCartRemoveDialogState,
    updateBrandSelectionDialogState,
    setCartsCatalog,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withSnackbar,
    withStyles(useStyles),
    withCartApi,
)(ChangeCart);