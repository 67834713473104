import React from 'react';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import theme from '../../theme/theme';

class SmallIconButton extends React.Component {
    render() {
        const { 
            children, 
            color = 'inherit', 
            onClick, 
            insideBox = false 
        } = this.props;

        return (
            <IconButton 
                color={color}
                onClick={onClick}
                sx={{
                    minWidth: theme.spacing(3),
                    padding: 0
            }}>
                {insideBox ? (
                    <Box sx={{
                        width: theme.spacing(7),
                        height: theme.spacing(7),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', 
                    }}>
                        {children}
                    </Box>
                    ) : children
                }
            </IconButton>
        );
    }
}

export default SmallIconButton;
