// start: modified by dachcom
/* eslint-disable */
import jQuery from 'jquery';
// end: modified by dachcom

var $jscomp = {scope: {}};
$jscomp.defineProperty = 'function' == typeof Object.defineProperties ? Object.defineProperty : function (h, t, q) {
    if (q.get || q.set) throw new TypeError('ES3 does not support getters and setters.');
    h != Array.prototype && h != Object.prototype && (h[t] = q.value)
};
$jscomp.getGlobal = function (h) {
    return 'undefined' != typeof window && window === h ? h : 'undefined' != typeof global && null != global ? global : h
};
$jscomp.global = $jscomp.getGlobal(this);
$jscomp.polyfill = function (h, t, q, z) {
    if (t) {
        q = $jscomp.global;
        h = h.split('.');
        for (z = 0; z < h.length - 1; z++) {
            var J = h[z];
            J in q || (q[J] = {});
            q = q[J]
        }
        h = h[h.length - 1];
        z = q[h];
        t = t(z);
        t != z && null != t && $jscomp.defineProperty(q, h, {configurable: !0, writable: !0, value: t})
    }
};
$jscomp.polyfill('Array.prototype.fill', function (h) {
    return h ? h : function (h, q, z) {
        var t = this.length || 0;
        0 > q && (q = Math.max(0, t + q));
        if (null == z || z > t) z = t;
        z = Number(z);
        0 > z && (z = Math.max(0, t + z));
        for (q = Number(q || 0); q < z; q++) this[q] = h;
        return this
    }
}, 'es6-impl', 'es3');
(function (h, t) {
    function q(a, b, c) {
        X[a] = {code: b, display: c}
    }

    function z() {
        var a = h.crypto || h.msCrypto;
        if (a && a.subtle) {
            var b = a.subtle.exportKey('jwk', Q.publicKey);
            'function' === typeof b.then ? b.then(function (a) {
                a = JSON.stringify(a);
                R = btoa(a);
                C = B.SUCCESSFUL;
                J()
            })['catch'](function (a) {
                v('' + a);
                C = B.ERROR;
                K('' + a)
            }) : (b.oncomplete = function () {
                var a = new Uint8Array(b.result), a = String.fromCharCode.apply(null, a);
                R = btoa(a);
                C = B.SUCCESSFUL;
                J()
            }, b.onerror = function () {
                v('keyExpRes.onerror with ', b.result);
                C = B.ERROR;
                K('' + b.result)
            })
        } else v('No crypto available; hint: it needs a secure context (https).'),
            C = B.ERROR, K('No crypto available; hint: it needs a secure context (https).')
    }

    function J() {
        for (; 0 < Y.length;) Y.shift().call(null, R)
    }

    function K(a) {
        for (; 0 < Z.length;) Z.shift().call(null, a)
    }

    function Fa(a, b, c) {
        a = aa(a);
        var d = h.crypto || h.msCrypto;
        if (d && d.subtle) {
            var e = d.subtle.decrypt({name: 'RSA-OAEP', hash: {name: 'SHA-256'}}, Q.privateKey, a);
            'function' === typeof e.then ? e.then(function (a) {
                b(new Uint8Array(a))
            })['catch'](function (a) {
                v(a);
                c('' + a)
            }) : (e.oncomplete = function () {
                b(new Uint8Array(e.result))
            }, e.onerror =
                function () {
                    c('decrypt with private key error')
                })
        } else c('No crypto available; hint: it needs a secure context (https).')
    }

    function Ga(a, b, c, d, e) {
        var f = h.crypto || h.msCrypto;
        if (f && f.subtle) {
            var g = f.subtle.importKey('raw', a, 'AES-CBC', !1, ['decrypt']);
            'function' === typeof g.then ? g.then(function (a) {
                ja(a, b, c, d, e)
            })['catch'](function (a) {
                v(a);
                e('' + a)
            }) : (g.oncomplete = function () {
                ja(g.result, b, c, d, e)
            }, g.onerror = function () {
                e('decrypt aes base64 to string error')
            })
        } else e('No crypto available; hint: it needs a secure context (https).')
    }

    function ja(a, b, c, d, e) {
        c = aa(c);
        b = aa(b);
        var f = h.crypto || h.msCrypto;
        if (f && f.subtle) {
            var g = f.subtle.decrypt({name: 'AES-CBC', iv: b}, a, c);
            'function' === typeof g.then ? g.then(function (a) {
                a = (new TextDecoder).decode(a);
                d(a)
            })['catch'](function (a) {
                v(a);
                e('' + a)
            }) : (console.info(g), g.oncomplete = function () {
                var a;
                a:{
                    var b = new Uint8Array(g.result), b = new Blob([b], {type: 'text/plain;charset=UTF-8'}),
                        b = URL.createObjectURL(b);
                    try {
                        var c = new XMLHttpRequest;
                        c.open('GET', b, !1);
                        c.send();
                        a = c.responseText;
                        break a
                    } catch (m) {
                        throw'Your browser does not want to support this.' +
                        m;
                    } finally {
                        URL.revokeObjectURL(b)
                    }
                    a = void 0
                }
                d(a)
            }, g.onerror = function () {
                e('decrypt aes key base64 to string error')
            })
        } else e('No crypto available; hint: it needs a secure context (https).')
    }

    function Ia(a, b) {
        var c = Math.max(a, b);
        return 200 >= c ? 'XS' : 600 >= c ? 'S' : 1024 >= c ? 'M' : 'L'
    }

    function aa(a) {
        a = h.atob(a);
        for (var b = a.length, c = new Uint8Array(b), d = 0; d < b; d++) c[d] = a.charCodeAt(d);
        return c
    }

    function Ja(a, b, c) {
        var d = a.width(), e = a.height(),
            f = t('<canvas></canvas>').attr('id', 'imgcanvas_' + c).attr('width', d).attr('height',
                e).addClass('image_canvas').appendTo(a);
        b.$j_canvas = f;
        f = parseInt(f.css('z-index'), 10);
        isNaN(f) && (f = 0);
        b.$j_event_div = t('<div></div>').attr('id', 'imgdiv_' + c).css('position', 'absolute').css('z-index', f + 1).css('left', '0').css('top', '0').css('width', d + 'px').css('height', e + 'px').addClass('image_div').appendTo(a)
    }

    function ka(a) {
        return 'number' === typeof a.left && 'number' === typeof a.top && 'number' === typeof a.width && 'number' === typeof a.height
    }

    function ba(a, b, c) {
        return 'number' === typeof a && 'number' === typeof b &&
        'number' === typeof c ? (b = c - b, a = Math.abs(c - a), Math.abs(b) < a / 800 ? 0 : b / 4) : 0
    }

    function Ka(a) {
        if ('string' !== typeof a) return 0;
        var b = a.indexOf('wait=');
        if (0 > b) return v('No wait found'), 0;
        b += 5;
        if (a.length <= b) return v('Wait found but right at the end'), 0;
        var c = a.indexOf('&', b);
        a = c > b ? a.substring(b, c) : a.substring(b);
        b = parseInt(a, 10);
        return isNaN(b) ? (v('Wait found but the value is not a number: ', a), 0) : b
    }

    function v() {
        h.console && 'function' === typeof h.console.debug && h.console.debug.apply(this, arguments)
    }

    function y(a,
               b) {
        var c = this, d = La();
        this.cid = d.cid;
        this.options = b;
        Ja(a, d, this.options.wid);
        this.parent_width = Math.floor(a.width());
        this.parent_height = Math.floor(a.height());
        this.state = 1;
        this.touch_manager = new F(this.cid);
        this.path = void 0;
        this.buttons = [];
        this.dragging = this.scaled_height = this.scaled_width = this.original_width = this.original_height = this.current_ajax_request = void 0;
        this.skip_drawing = !1;
        this.hs_overrides = new L;
        this.hotspot_area_hovered = this.hotspot_key_hovered = void 0;
        this.selected_hotspots = new G;
        this.scale_factor =
            1;
        this.offset_y = this.offset_x = 0;
        this.latest_click = this.requested_animation_frame = this.animation = this.prev_mouse_event = void 0;
        this.rect_mousedown = this.rect_active = !1;
        this.rect_mousey = this.rect_mousex = this.rect_start_mousey = this.rect_start_mousex = 0;
        this.rect_last_selected_hotspots = void 0;
        this.load_timeout = -1;
        this.rect_selected_hotspots = new G;
        this.handle_resize = function () {
            ca.call(c)
        };
        this.handle_wheel = function (a) {
            Ma.call(c, a)
        };
        d.$j_event_div.on('wheel', this.handle_wheel);
        this.handle_mousedown = function (a) {
            Na.call(c,
                a)
        };
        d.$j_event_div.on('mousedown', this.handle_mousedown);
        this.handle_mousemove = function (a) {
            Oa.call(c, a)
        };
        d.$j_event_div.on('mousemove', this.handle_mousemove);
        t(h).on('resize', this.handle_resize);
        this.handle_mouseout = function (a) {
            Pa.call(c, a)
        };
        d.$j_event_div.on('mouseout', this.handle_mouseout);
        this.handle_touchstart = function (a) {
            Qa.call(c, a.originalEvent)
        };
        d.$j_event_div.on('touchstart', this.handle_touchstart);
        this.handle_touchend = function (a) {
            Ra.call(c, a.originalEvent)
        };
        d.$j_event_div.on('touchend',
            this.handle_touchend);
        this.handle_touchmove = function (a) {
            Sa.call(c, a.originalEvent)
        };
        d.$j_event_div.on('touchmove', this.handle_touchmove);
        this.handle_touchcancel = function (a) {
            Ta.call(c, a.originalEvent)
        };
        d.$j_event_div.on('touchcancel', this.handle_touchcancel);
        this.button_fit = new S(this.options.button_fit_src, this.options.button_width, this.options.button_height, function () {
            la.call(c)
        });
        this.buttons.push(this.button_fit);
        Ua(b.load_messages);
        ca.call(this);
        l.call(this)
    }

    function ma(a, b) {
        var c = this, d = A(this.cid),
            e = a.image, f = a.scaledWidth, g = a.scaledHeight;
        this.original_width = a.originalWidth;
        this.original_height = a.originalHeight;
        this.scaled_width = f;
        this.scaled_height = g;
        this.state = e ? 3 : 4;
        this.skip_drawing = !1;
        var u = new T(function () {
            l.call(c);
            'function' === typeof b && b.call(this, c.options.wid)
        }), Ha = u.add();
        d.hotspots_from_backend = a.hotspots;
        na.call(this, u);
        var p = new Image(f, g);
        ca.call(c);
        da.call(c);
        d.images = [];
        var m = this.options;
        p.onload = function () {
            var a = 1, b = f, c = g, e, u, r;
            v('image loaded');
            do e = h.document.createElement('canvas'),
                e.width = b, e.height = c, u = e.getContext('2d', {alpha: !1}), r ? u.drawImage(r, 0, 0, b, c) : (u.drawImage(p, 0, 0, b, c), m.display_grayscale && (u.globalCompositeOperation = 'saturation', u.fillStyle = 'hsl(0,100%,0%)', u.fillRect(0, 0, b, c), u.globalCompositeOperation = 'source-over')), d.images.unshift({
                factor: a,
                canvas: e
            }), r = e, a /= 2, b = f * a, c = g * a; while (75 < b && 75 < c);
            Ha.done()
        };
        p.src = 'data:image/png;base64,' + e
    }

    function Va(a, b, c, d, e) {
        function f(a) {
            g.state = 5;
            g.draw();
            'function' === typeof e && e.call(g, g.options.wid, 601, a)
        }

        var g = this;
        Fa(a,
            function (a) {
                Ga(a, b, c, function (a) {
                    a = JSON.parse(a);
                    ma.call(g, a, d)
                }, f)
            }, f)
    }

    function Wa(a, b, c) {
        var d = this;
        this.current_ajax_request = t.ajax(a).done(function (a) {
            var e = a.encryptedSymkey, g = a.encryptedImageIV, u = a.encryptedImage;
            'string' === typeof e && 'string' === typeof g && 'string' === typeof u ? Va.call(d, e, g, u, b, c) : ma.call(d, a, b)
        }).fail(function (a, b, g) {
            v('Could not load content', d.options.wid, a, b, g);
            d.state = 404 === a.status ? 4 : 5;
            d.draw();
            'function' === typeof c && c.call(d, d.options.wid, a.status, b)
        })
    }

    function l() {
        var a =
            this;
        'undefined' === typeof a.requested_animation_frame && (a.requested_animation_frame = h.requestAnimationFrame(function () {
            a.requested_animation_frame = void 0;
            Xa.call(a)
        }))
    }

    function Ya(a, b, c, d) {
        this.red = a;
        this.green = b;
        this.blue = c;
        this.alpha = void 0 === d ? 1 : d
    }

    function M(a) {
        a = a.slice(a.indexOf('(') + 1, a.indexOf(')')).split(',');
        var b = parseInt(a[0], 10);
        if (isNaN(b)) throw'Not a number: ' + a[0];
        var c = parseInt(a[1], 10);
        if (isNaN(c)) throw'Not a number: ' + a[1];
        var d = parseInt(a[2], 10);
        if (isNaN(d)) throw'Not a number: ' +
        a[2];
        b = new Ya(b, c, d);
        if (4 === a.length) {
            c = parseFloat(a[3]);
            if (isNaN(c)) throw'Not a number: ' + a[3];
            b.alpha = c
        }
        return b
    }

    function oa(a) {
        if (this.rect_active) {
            var b = A(this.cid).$j_event_div.offset();
            this.rect_start_mousex = a.clientX - b.left;
            this.rect_start_mousey = a.clientY - b.top;
            this.rect_mousedown = !0
        }
    }

    function pa(a) {
        if (this.rect_active) {
            var b = A(this.cid), c = b.$j_event_div.offset();
            this.rect_mousex = a.clientX - c.left;
            this.rect_mousey = a.clientY - c.top;
            b.$j_event_div.css({cursor: 'crosshair'});
            l.call(this)
        }
    }

    function qa(a) {
        a =
            A(this.cid);
        this.rect_active = !1;
        this.rect_start_mousex = this.rect_mousex = this.rect_start_mousey = this.rect_mousey = 0;
        a.$j_event_div.css({cursor: 'default'});
        this.rect_mousedown = !1;
        this.rect_selected_hotspots.clear();
        l.call(this)
    }

    function U() {
        var a = P.call(this).canvas, b = this.offset_x, c = this.offset_y, d = this.scaled_width * this.scale_factor,
            e = this.scaled_height * this.scale_factor, f = b + d - 1, g = c + e - 1, u = a.width / 2, a = a.height / 2,
            h = !1;
        b > u ? (h = !0, b = u) : f < u && (h = !0, b = u - d);
        c > a ? (h = !0, c = a) : g < a && (h = !0, c = a - e);
        h && ra.call(this,
            b, c, this.scale_factor)
    }

    function sa() {
        var a = A(this.cid).hotspots, b, c, d, e, f;
        b = new G;
        for (c = 0; c < a.length; c++) if (d = a[c], e = (d.key || '').trim(), f = this.selected_hotspots.contains(e)) for (b.select(e, !0), e = 0; e < d.links.length; e++) b.select(d.links[e], !0);
        return b
    }

    function Xa() {
        if (!this.skip_drawing) {
            var a = A(this.cid), b = P.call(this), c = b.canvas, d = c.width, c = c.height, e = this.offset_x,
                f = this.offset_y, g = this.scaled_width * this.scale_factor,
                u = this.scaled_height * this.scale_factor, h = e + g - 1, p = f + u - 1, m;
            var n = A(this.cid);
            m = this.scale_factor;
            var n = n.images, k, l, w = void 0;
            if (n) {
                for (k = 0; k < n.length && !(w = l = n[k], l = l.factor || .01, l >= m); k++) ;
                m = w
            } else m = void 0;
            var n = m, x, r, q, t, y, z;
            m = this.get_state();
            n ? (0 < e && (b.fillStyle = this.options.background_fill_style, b.fillRect(0, 0, e + 1, c)), h < d && (b.fillStyle = this.options.background_fill_style, b.fillRect(h, 0, d - h, c)), 0 < f && (b.fillStyle = this.options.background_fill_style, b.fillRect(0, 0, d, f + 1)), p < c && (b.fillStyle = this.options.background_fill_style, b.fillRect(0, p, d, c - p)), b.drawImage(n.canvas, e, f, g, u)) : (b.fillStyle = this.options.background_fill_style,
                b.fillRect(0, 0, d, c));
            this.rect_mousedown && this.rect_active && (g = this.rect_mousex - this.rect_start_mousex, u = this.rect_mousey - this.rect_start_mousey, h = {
                px: this.rect_start_mousex,
                py: this.rect_start_mousey,
                width: g,
                height: u
            }, b.beginPath(), b.rect(this.rect_start_mousex, this.rect_start_mousey, g, u), b.closePath(), b.lineWidth = 1, b.setLineDash([5, 5]), b.strokeStyle = this.options.rect_angle_stroke_style, b.stroke(), b.fillStyle = this.options.rect_angle_fill_style, b.fill(), Za.call(this, h));
            m && m.display && (b.font = this.options.state_draw_font_style,
                b.fillStyle = this.options.state_draw_fill_style, b.textBaseline = 'bottom', b.textAlign = 'left', b.fillText(m.display, 10, 30));
            g = void 0;
            if (a.hotspots) for (m = sa.call(this), h = this.scaled_width / this.original_width * this.scale_factor, p = this.scaled_height / this.original_height * this.scale_factor, u = 0; u < a.hotspots.length; u++) if (n = a.hotspots[u], w = (n.key || '').trim(), 0 !== w.length) {
                k = n.active && w === this.hotspot_key_hovered;
                w = n.active && m.contains(w);
                if (n.active) for (x = n.areas, l = 0; l < x.length; l++) r = x[l], ka(r) && (q = e + (r.left -
                    this.options.hotspot_padding) * h, t = f + (r.top - this.options.hotspot_padding) * p, y = (r.width + 2 * this.options.hotspot_padding) * h, z = (r.height + 2 * this.options.hotspot_padding) * p, this.hotspot_area_hovered && this.hotspot_area_hovered.left === r.left && this.hotspot_area_hovered.top === r.top && (r = 'string' === typeof n.tooltipp && 0 < n.tooltipp.trim().length ? n.tooltipp : 'string' === typeof r.descr && 0 < r.descr.trim().length ? r.descr : void 0) && (g = {
                    text: r,
                    l: q,
                    t: t,
                    w: y,
                    h: z
                }), b.lineWidth = 1, w ? k ? (b.strokeStyle = n.hovered_stroke_style, r = void 0) :
                    (b.strokeStyle = n.selected_stroke_style, r = n.selected_fill_style) : k ? (b.strokeStyle = n.hovered_stroke_style, r = void 0) : (b.strokeStyle = n.stroke_style, r = n.fill_style), r && (b.fillStyle = r, b.fillRect(q, t, y, z)), b.strokeRect(q, t, y, z));
                k = w ? k ? n.hovered_masks : n.selected_masks : k ? n.hovered_masks : n.default_masks;
                w = !n.active;
                for (n = 0; n < k.length; n++) $a.call(k[n], b, e, f, h, p, w)
            }
            g && (a = g.l + g.w / 2, f = g.t + g.h / 2 >= c / 2, h = a <= d / 2, b.font = this.options.tooltip_font, a = b.measureText(g.text), a = Math.min(d / 2, a.width), c = a + 2 * this.options.tooltip_padding,
                e = this.options.tooltip_height + 2 * this.options.tooltip_padding, f ? (f = g.t - this.options.tooltip_height - 2 * this.options.tooltip_padding, u = g.t - this.options.tooltip_padding) : (f = g.t + g.h, u = f + this.options.tooltip_padding + this.options.tooltip_height), h ? (h = g.l, m = g.l + this.options.tooltip_padding, p = 'left') : (h = g.l + g.w - a - 2 * this.options.tooltip_padding, m = g.l + g.w - this.options.tooltip_padding, p = 'right'), v(h, f, c, e), b.fillStyle = this.options.tooltip_background_style, b.fillRect(h, f, c, e), b.fillStyle = this.options.tooltip_fill_style,
                b.textAlign = p, b.textBaseline = 'bottom', b.fillText(g.text, m, u, a));
            if (3 === this.state) {
                for (u = a = 0; u < this.buttons.length; u++) a += this.options.button_margin + this.buttons[u].button_width;
                a = d - a;
                for (u = 0; u < this.buttons.length; u++) d = this.buttons[u], d.draw(b, a, 0), a += this.options.button_margin + d.button_width
            }
        }
    }

    function ta() {
        var a = A(this.cid);
        return t.isArray(a.images) && 0 < a.images.length && 'number' === typeof this.original_height && 'number' === typeof this.original_width && 'number' === typeof this.scaled_height && 'number' ===
            typeof this.scaled_width && t.isArray(a.hotspots)
    }

    function ua(a, b) {
        var c = A(this.cid), d, e, f, g;
        e = va.call(this, a, b);
        if (3 !== this.state || 0 > e.y || e.y >= this.options.button_height) return -1;
        f = c.$j_canvas.width();
        for (c = d = 0; c < this.buttons.length; c++) d += this.options.button_margin + this.buttons[c].button_width;
        g = -1;
        d = f - d;
        for (c = 0; c < this.buttons.length; c++) {
            f = this.buttons[c];
            if (e.y <= f.button_height && d <= e.x && e.x <= d + f.button_width) {
                g = c;
                break
            }
            d += this.options.button_margin + f.button_width
        }
        return g
    }

    function va(a, b) {
        var c =
            A(this.cid).$j_canvas.offset();
        return {x: a - c.left, y: b - c.top}
    }

    function wa(a, b, c) {
        if (!ta.call(this)) return V;
        var d = A(this.cid), e = this.scaled_width / this.original_width * this.scale_factor,
            f = this.scaled_height / this.original_height * this.scale_factor, g = this.options.hotspot_padding, h, k,
            p, m, n, l, q, w;
        a = va.call(this, a, b);
        c && (g += .03 * Math.min(this.original_height, this.original_width));
        var e = Math.round((a.x - this.offset_x) / e), f = Math.round((a.y - this.offset_y) / f), x = null;
        for (a = 0; a < d.hotspots.length; a++) if (b = d.hotspots[a],
            h = b.key || '', 0 !== h.trim().length) {
            p = b.areas;
            for (k = 0; k < p.length; k++) if (m = p[k], n = m.left - g, l = m.top - g, q = m.width + 2 * g, w = m.height + 2 * g, n = n <= e && e <= n + q, l = l <= f && f <= l + w, n && l) if (m = (new I).with_key(h).with_coords(m.left, m.top).with_dimensions(m.width, m.height), c) x ? (l = x.distance(e, f), w = m.distance(e, f), l > w && (x = m)) : x = m; else return m;
            for (k = 0; k < b.selected_masks.length; k++) if (p = b.selected_masks[k], n = p.left <= e && e <= p.left + p.width, l = p.top <= f && f <= p.top + p.height, n && l && (m = E.call(p, e - p.left, f - p.top))) return (new I).with_key(h).with_coords(p.left,
                p.top).with_dimensions(p.width, p.height)
        }
        return c && x ? x : V
    }

    function xa() {
        var a;
        a = A(this.cid).$j_canvas[0];
        return Math.min(a.width / this.scaled_width, a.height / this.scaled_height)
    }

    function ea() {
        return xa.call(this) * this.options.scale_factor_min
    }

    function fa() {
        return xa.call(this) * this.options.scale_factor_max
    }

    function ya() {
        var a = A(this.cid).$j_canvas[0], b = a.width, a = a.height,
            c = Math.min(b / this.scaled_width, a / this.scaled_height);
        return {sf: c, ox: (b - this.scaled_width * c) / 2, oy: (a - this.scaled_height * c) / 2}
    }

    function da() {
        H.call(this);
        var a = ya.call(this);
        ga.call(this, a.ox, a.oy, a.sf);
        this.button_fit.active = !1;
        'function' === typeof this.options.update_button_states_handler && this.options.update_button_states_handler.call(this)
    }

    function ga(a, b, c) {
        'undefined' !== typeof a && (this.offset_x = a);
        'undefined' !== typeof b && (this.offset_y = b);
        'undefined' !== typeof c && (this.scale_factor = c)
    }

    function ra(a, b, c) {
        var d = this;
        v('animate to', a, b, c);
        H.call(this);
        'number' === typeof a && 'number' === typeof b && 'number' === typeof c && (this.animation = {
            start: {
                ox: this.offset_x,
                oy: this.offset_y, sf: this.scale_factor
            }, end: {ox: a, oy: b, sf: c}, draw_count: 0
        }, this.animation.timeout_id = h.setTimeout(function () {
            za.call(d)
        }, 0))
    }

    function za() {
        var a = this, b, c, d;
        this.animation && (b = ba(this.animation.start.ox, this.offset_x, this.animation.end.ox), c = ba(this.animation.start.oy, this.offset_y, this.animation.end.oy), d = ba(this.animation.start.sf, this.scale_factor, this.animation.end.sf), this.animation.draw_count++, 0 !== b || 0 !== c || 0 !== d ? (this.offset_x += b, this.offset_y += c, this.scale_factor += d, l.call(this),
            this.animation.timeout_id = h.setTimeout(function () {
                za.call(a)
            }, 17)) : (ga.call(this, this.animation.end.ox, this.animation.end.oy, this.animation.end.sf), H.call(this), N.call(this), l.call(this)))
    }

    function H() {
        this.animation && ('number' === typeof this.animation.timeout_id && (h.clearTimeout(this.animation.timeout_id), v('stopped animation', this.animation.timeout_id)), this.animation.draw_count && v('had to draw the image', this.animation.draw_count, 'time(s).'), this.animation = void 0)
    }

    function la() {
        da.call(this);
        l.call(this)
    }

    function Ua(a) {
        var b, c, d;
        for (b in a) a.hasOwnProperty(b) && (c = a[b], (d = X[b]) && c && (d.display = c))
    }

    function N() {
        var a = ya.call(this);
        this.button_fit.active = !(Math.abs(a.sf - this.scale_factor) <= a.sf / 50 && 5 > Math.abs(a.ox - this.offset_x) && 5 > Math.abs(a.oy - this.offset_y));
        'function' === typeof this.options.update_button_states_handler && this.options.update_button_states_handler.call(this)
    }

    function Za(a) {
        var b = {}, c = A(this.cid), d = this, e = a.px, f = a.py, g = a.width;
        a = a.height;
        var h = this.scaled_width / this.original_width * this.scale_factor,
            k = this.scaled_height / this.original_height * this.scale_factor, p, m, n, l, q, w, x, r, v, t, y;
        for (p = 0; p < c.hotspots.length; p++) if (m = c.hotspots[p].key, n = void 0 !== this.selected_hotspots.idx['hs_' + m], l = !1, this.hs_overrides && this.hs_overrides.excl && this.hs_overrides.mem[m] && !this.hs_overrides.mem[m].ref_hotspot) {
            for (q = 0; q < c.hotspots[p].areas.length; q++) if (w = c.hotspots[p].areas[q], x = this.offset_x + (w.left - this.options.hotspot_padding) * h, r = this.offset_y + (w.top - this.options.hotspot_padding) * k, v = (w.width + 2 * this.options.hotspot_padding) *
                h, t = (w.height + 2 * this.options.hotspot_padding) * k, w = e <= x + v && e + g >= x && f <= r + t && f + a >= r, y = (e <= x + v && e >= x || e >= x + v && e + g <= x + v) && (f <= r + t && f >= r || f >= r + t && f + a <= r + t), t = e < x && e + g > x && f > r && f + a < r + t, x = f <= r && f + a >= r && e >= x + v && e + g <= x + v, w || y || t || x) l = !0, b[m] = m;
            !n && l && (this.rect_selected_hotspots.select(m, !0), this.selected_hotspots.select(m, !0))
        }
        this.rect_selected_hotspots.get_hotspot_keys().forEach(function (a) {
            void 0 === b[a] && (d.rect_selected_hotspots.unselect(a, !0), d.selected_hotspots.unselect(a, !0))
        });
        this.rect_last_selected_hotspots =
            this.rect_selected_hotspots.get_hotspot_keys();
        'function' === typeof this.options.hotspot_selection_changed_handler && this.options.hotspot_selection_changed_handler.call(this, this.selected_hotspots.get_hotspot_keys())
    }

    function Aa(a, b, c) {
        var d;
        d = ua.call(this, a, b, c);
        0 <= d ? this.buttons[d].fire_click() : (c = wa.call(this, a, b, c).key, 'string' === typeof c && this.hs_overrides && this.hs_overrides.excl && !this.hs_overrides.mem[c] && (c = void 0), d = 'string' === typeof c, a = {
            t: Date.now(),
            px: a,
            py: b
        }, (b = 'object' === typeof this.latest_click &&
            350 > a.t - this.latest_click.t && 20 > Math.abs(a.px - this.latest_click.px) && 20 > Math.abs(a.py - this.latest_click.py)) ? ('function' === typeof this.options.double_click_handler && this.options.double_click_handler.call(this, {
            px: a.px,
            py: a.py,
            hotspot: c
        }), this.rect_last_selected_hotspots = a = void 0) : (d ? (this.rect_last_selected_hotspots = void 0, this.options.multi_selection_enabled || !this.options.hotspots_deselect_on_void_click ? (this.toggle_hotspot_selection(c, !0), b = !0) : b = this.selected_hotspots.select(c, this.options.multi_selection_enabled)) :
            b = this.options.hotspots_deselect_on_void_click ? this.selected_hotspots.clear() : this.options.deselect_last_selected_hotspots_per_rectangle_on_void_click ? this.unselect_last_selected_hotspots_per_rectangle.call(this) : !1, b && 'function' === typeof this.options.hotspot_selection_changed_handler && this.options.hotspot_selection_changed_handler.call(this, this.get_selected_hotspot_keys())), this.latest_click = a)
    }

    function P() {
        return A(this.cid).$j_canvas[0].getContext('2d', {alpha: !1})
    }

    function ca() {
        var a = A(this.cid),
            b = a.$j_canvas.parent(), c = Math.floor(b.width()), b = Math.floor(b.height());
        if (this.parent_width !== c || this.parent_height !== b) this.parent_width = c, this.parent_height = b, a.$j_canvas.attr('width', c), a.$j_event_div.css('width', c + 'px'), a.$j_canvas.attr('height', b), a.$j_event_div.css('height', b + 'px'), da.call(this), l.call(this)
    }

    function Ma(a) {
        var b = A(this.cid), c = a.originalEvent, d = this.scale_factor, e = 0, f;
        a.preventDefault();
        H.call(this);
        a = c.wheelDelta;
        c.deltaY ? e = 0 < c.deltaY ? this.options.wheel_zoom_factor : -this.options.wheel_zoom_factor :
            a && (e = 0 > a ? this.options.wheel_zoom_factor : -this.options.wheel_zoom_factor);
        0 !== e && (e = d - d * e, ea.call(this) <= e && e <= fa.call(this) && (this.scale_factor = e, e = b.$j_canvas.offset(), b = c.pageX - e.left, c = c.pageY - e.top, e = this.scaled_width * d, d *= this.scaled_height, a = this.scaled_width * this.scale_factor, f = this.scaled_height * this.scale_factor, this.offset_x = b - (b - this.offset_x) * a / e, this.offset_y = c - (c - this.offset_y) * f / d, N.call(this), l.call(this), U.call(this)))
    }

    function Ba(a, b) {
        b = void 0 === b ? this.options.wheel_zoom_factor :
            b;
        var c = A(this.cid), d = this.scale_factor, e, f, g;
        H.call(this);
        g = a ? d + d * b : d - d * b;
        if (ea.call(this) <= g && g <= fa.call(this)) {
            this.scale_factor = g;
            g = c.$j_canvas.width() / 2;
            var h = c.$j_canvas.height() / 2, c = this.scaled_width * d, d = this.scaled_height * d;
            e = this.scaled_width * this.scale_factor;
            f = this.scaled_height * this.scale_factor;
            this.offset_x = g - (g - this.offset_x) * e / c;
            this.offset_y = h - (h - this.offset_y) * f / d;
            N.call(this);
            l.call(this);
            U.call(this)
        }
    }

    function Na(a) {
        this.rect_active && oa.call(this, a);
        if (ta.call(this)) {
            H.call(this);
            var b = A(this.cid), c = t(h.document), d = a.originalEvent, e = this;
            a.preventDefault();
            this.dragging = {
                t: Date.now(),
                px: d.pageX,
                py: d.pageY,
                prev_cur: b.$j_event_div.css('cursor'),
                ch_cur: !1
            };
            this.prev_mouse_event = a;
            this.handle_mousemove = function (a) {
                ab.call(e, a)
            };
            c.on('mousemove', this.handle_mousemove);
            this.handle_mouseup = function (a) {
                bb.call(e, a)
            };
            c.on('mouseup', this.handle_mouseup)
        }
    }

    function ab(a) {
        if (this.rect_active) a.stopPropagation(), a.preventDefault(), pa.call(this, a); else {
            var b = A(this.cid), c = a.originalEvent,
                d = this.prev_mouse_event.originalEvent;
            this.offset_x += c.pageX - d.pageX;
            this.offset_y += c.pageY - d.pageY;
            l.call(this);
            this.prev_mouse_event = a;
            this.dragging && !this.dragging.ch_cur && (b.$j_event_div.css({cursor: 'move'}), this.dragging.ch_cur = !0);
            a.preventDefault()
        }
    }

    function bb(a) {
        var b, c, d;
        qa.call(this, a);
        var e = A(this.cid);
        b = t(h.document);
        var f = a.originalEvent;
        b.off('mouseup', this.handle_mouseup);
        this.handle_mouseup = void 0;
        b.off('mousemove', this.handle_mousemove);
        this.prev_mouse_event = this.handle_mousemove =
            void 0;
        this.dragging ? (b = Date.now(), c = f.pageX, d = f.pageY, b = 400 > b - this.dragging.t && 20 > Math.abs(c - this.dragging.px) && 20 > Math.abs(d - this.dragging.py), this.dragging.ch_cur && e.$j_event_div.css({cursor: this.dragging.prev_cur})) : b = !1;
        this.dragging = void 0;
        b ? (Aa.call(this, f.pageX, f.pageY), l.call(this)) : U.call(this);
        N.call(this);
        a.preventDefault()
    }

    function Oa(a) {
        var b = A(this.cid), c, d, e, f, g;
        if (!this.dragging && b.hotspots) {
            c = a.originalEvent;
            b = a = !1;
            e = ua.call(this, c.pageX, c.pageY);
            for (d = 0; d < this.buttons.length; d++) g =
                d === e, f = this.buttons[d], g !== f.hovered && (f.hovered = g, a = !0);
            c = 0 <= e ? V : wa.call(this, c.pageX, c.pageY);
            c.defined && this.hs_overrides && this.hs_overrides.excl && !this.hs_overrides.mem[c.key] && (c = V);
            c.key !== this.hotspot_key_hovered && (v('image mouse over from', this.hotspot_key_hovered, 'to', c.key), this.hotspot_key_hovered = c.key, b = !0, 'function' === typeof this.options.hotspot_hover_changed_handler && this.options.hotspot_hover_changed_handler.call(this, this.hotspot_key_hovered));
            d = this.hotspot_area_hovered || {};
            if (d.left !==
                c.left || d.top !== c.top) this.hotspot_area_hovered = c.defined ? {
                left: c.left,
                top: c.top,
                width: c.width,
                height: c.height
            } : void 0, b = !0;
            (a || b) && l.call(this)
        }
    }

    function Pa() {
        var a, b, c;
        a = !1;
        'undefined' !== typeof this.hotspot_key_hovered && (a = !0, 'function' === typeof this.options.hotspot_hover_changed_handler && this.options.hotspot_hover_changed_handler.call(this, void 0));
        for (b = 0; b < this.buttons.length; b++) c = this.buttons[b], c.hovered && (c.hovered = !1, a = !0);
        this.hotspot_area_hovered = this.hotspot_key_hovered = void 0;
        a && l.call(this)
    }

    function La() {
        var a = cb++, b = new Ca(a);
        return W[a] = b
    }

    function A(a) {
        var b = W[a];
        if (!b) throw'No classifies found for ' + a;
        return b
    }

    function Ca(a) {
        function b() {
            return 'scc'
        }

        function c() {
            return !1
        }

        var d = this;
        this.cid = a;
        this.$j_event_div = this.$j_canvas = void 0;
        this.images = [];
        this.hotspots_from_backend = [];
        this.hotspots = [];
        this.override_stuff = function () {
            this.$j_canvas && (this.$j_canvas.contextmenu(c), this.$j_canvas.each(function (a, c) {
                c.toDataURL = b;
                c.toBlob = b;
                'function' === typeof c.getContext && (c.getContext('2d').getImageData =
                    b)
            }));
            this.$j_event_div && this.$j_event_div.contextmenu(c)
        };
        this.override_stuff_timeout = h.setInterval(function () {
            d.override_stuff.call(d)
        }, 30)
    }

    function Qa(a) {
        var b, c;
        v(a);
        a.preventDefault();
        oa.call(this, a.changedTouches[0]);
        H.call(this);
        a = a.changedTouches;
        for (b = 0; b < a.length; b++) c = a[b], this.touch_manager.add(c.identifier, c.pageX, c.pageY, this.offset_x, this.offset_y, this.scale_factor)
    }

    function Sa(a) {
        var b, c, d;
        v(a);
        a.preventDefault();
        b = a.changedTouches;
        for (c = 0; c < b.length; c++) d = b[c], this.touch_manager.move(d.identifier,
            d.pageX, d.pageY);
        this.rect_active ? pa.call(this, a.changedTouches[0]) : (a = this.touch_manager.calc(this), ga.call(this, a.ox, a.oy, a.sf), N.call(this), l.call(this))
    }

    function Ra(a) {
        qa.call(this, a.changedTouches[0]);
        var b, c, d, e = this.touch_manager.duration_millis(), f = this.touch_manager.primary_page_x_start,
            g = this.touch_manager.primary_page_y_start;
        v(a);
        a.preventDefault();
        a = a.changedTouches;
        d = !1;
        for (b = 0; b < a.length; b++) c = a[b], d = d || this.touch_manager.remove(c.identifier);
        d && 'undefined' !== typeof f && 'undefined' !==
        typeof g && (0 <= e && 200 >= e ? (Aa.call(this, f, g, !0), N.call(this), l.call(this)) : U.call(this))
    }

    function Ta(a) {
        var b, c;
        v(a);
        a.preventDefault();
        a = a.changedTouches;
        for (b = 0; b < a.length; b++) c = a[b], this.touch_manager.remove(c.identifier);
        this.touch_manager.reset()
    }

    function F(a) {
        this.cid = a;
        this.reset()
    }

    function Da() {
        'undefined' !== typeof this.primary_override && (this.primary_page_x_start = this.primary_override.px, this.primary_page_y_start = this.primary_override.py, this.primary_offset_x_start = this.primary_override.ox,
            this.primary_offset_y_start = this.primary_override.oy, this.primary_override = void 0)
    }

    function S(a, b, c, d) {
        this.button_width = b;
        this.button_height = c;
        this.img = new Image(b, 3 * c);
        this.img.src = a;
        this.active = this.hovered = !1;
        this.click_handler = d
    }

    function db(a) {
        this.left = a.left;
        this.top = a.top;
        this.width = a.width;
        this.height = a.height;
        this.descr = a.descr
    }

    function ha(a, b, c) {
        this.left = a.left;
        this.top = a.top;
        this.width = a.width;
        this.height = a.height;
        this.rgb_color = b;
        this.image = this.mask = void 0;
        var d = c.add();
        eb.call(this,
            a.mask, function () {
                d.done()
            })
    }

    function E(a, b) {
        return 0 > a || 0 > b || a >= this.width || b >= this.height ? !1 : 0 < this.mask.data[4 * (b * this.width + a)]
    }

    function $a(a, b, c, d, e, f) {
        b += (this.left - 1) * d;
        c += (this.top - 1) * e;
        d *= this.width + 2;
        e *= this.height + 2;
        (void 0 === f ? 0 : f) ? a.drawImage(this.image, b, c, d, e) : (f = a.globalCompositeOperation, a.globalCompositeOperation = 'overlay', a.drawImage(this.image, b, c, d, e), a.globalCompositeOperation = f)
    }

    function eb(a, b) {
        var c, d, e, f, g;
        c = this;
        f = this.width;
        g = this.height;
        d = new Image(f, g);
        e = function () {
            d.removeEventListener('load',
                e);
            var a = h.document.createElement('canvas');
            a.setAttribute('width', '' + f);
            a.setAttribute('height', '' + g);
            a = a.getContext('2d', {alpha: !1});
            a.drawImage(d, 0, 0);
            c.mask = a.getImageData(0, 0, f, g);
            fb.call(c, b)
        };
        d.addEventListener('load', e);
        d.src = a
    }

    function fb(a) {
        var b, c, d, e, f, g, k, l, p, m;
        d = this.width + 2;
        e = this.height + 2;
        b = h.document.createElement('canvas');
        b.setAttribute('width', '' + d);
        b.setAttribute('height', '' + e);
        c = b.getContext('2d', {alpha: !0});
        f = c.getImageData(0, 0, d, e);
        for (k = 0; k < e; k++) for (p = d * k, g = 0; g < d; g++) {
            l =
                g - 1;
            m = k - 1;
            var n = 0;
            E.call(this, l, m) && (n += 40);
            E.call(this, l, m - 1) && (n += 10);
            E.call(this, l + 1, m - 1) && (n += 5);
            E.call(this, l + 1, m) && (n += 10);
            E.call(this, l + 1, m + 1) && (n += 5);
            E.call(this, l, m + 1) && (n += 10);
            E.call(this, l - 1, m + 1) && (n += 5);
            E.call(this, l - 1, m) && (n += 10);
            E.call(this, l - 1, m - 1) && (n += 5);
            l = n / 100;
            l = Math.round(255 * l);
            m = 4 * (p + g);
            f.data[m] = this.rgb_color.red;
            f.data[m + 1] = this.rgb_color.green;
            f.data[m + 2] = this.rgb_color.blue;
            f.data[m + 3] = l * this.rgb_color.alpha
        }
        c.putImageData(f, 0, 0);
        b = b.toDataURL();
        this.image = new Image(d, e);
        'function' === typeof a && (this.image.onload = a);
        this.image.src = b
    }

    function gb(a, b, c, d) {
        var e = c.excl;
        c = a.key ? c.mem[a.key] : void 0;
        this.key = a.key;
        this.active = !e || !!c;
        this.tooltipp = c ? c.tooltip : void 0;
        this.stroke_style = b.hotspots_stroke_style;
        c && c.stroke_style && (this.stroke_style = c.stroke_style);
        this.fill_style = c ? c.fill_style : void 0;
        this.hovered_stroke_style = b.hovered_hotspots_stroke_style;
        c && c.stroke_style_hovered && (this.hovered_stroke_style = c.stroke_style_hovered);
        this.selected_fill_style = b.selected_hotspots_fill_style;
        c && c.fill_style_selected && (this.selected_fill_style = c.fill_style_selected);
        this.selected_stroke_style = b.selected_hotspots_stroke_style;
        c && c.stroke_style_selected && (this.selected_stroke_style = c.stroke_style_selected);
        this.areas = [];
        for (var e = a.areas || [], f = 0; f < e.length; f++) this.areas.push(new db(e[f]));
        this.links = a.hotspotKeyLinks || [];
        e = void 0;
        this.active ? c && c.default_mask_color && (e = c.default_mask_color) : b.grey_out_disabled_masks && (e = hb);
        f = b.mask_fill_style;
        c && c.mask_color_hover && (f = c.mask_color_hover);
        b = b.mask_fill_style;
        c && c.mask_color_selected && (b = c.mask_color_selected);
        this.default_masks = [];
        this.hovered_masks = [];
        this.selected_masks = [];
        a = a.masks || [];
        for (c = 0; c < a.length; c++) {
            var g = a[c];
            e && this.default_masks.push(new ha(g, e, d));
            f && this.hovered_masks.push(new ha(g, f, d));
            b && this.selected_masks.push(new ha(g, b, d))
        }
    }

    function na(a) {
        var b = A(this.cid), c = b.hotspots_from_backend;
        if (c) {
            for (var d = this.options, e = this.hs_overrides, f = [], g = 0; g < c.length; g++) f.push(new gb(c[g], d, e, a));
            b.hotspots = f
        } else a.add().done()
    }

    function ia(a) {
        if ((a = 'string' === typeof a ? a.trim() : void 0) && 0 < a.length) return 'hs_' + a
    }

    function G() {
        this.idx = {};
        this.count = 0
    }

    function Ea(a, b) {
        this.waiter = a;
        this.idx = b
    }

    function T(a) {
        this.started_epoch_millis = Date.now();
        this.callback = a;
        this.idx = 0;
        this.pendings = []
    }

    function I() {
        this.defined = !1;
        this.height = this.width = this.top = this.left = this.key = void 0
    }

    function k() {
        this.background_fill_style = 'white';
        // start: modified by dachcom
        this.button_fit_src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAABCCAMAAAAMhlTQAAAAAXNSR0IArs4c6QAAAYBQTFRFu7y+xcbHv8DBpaaon6CikpSWjY6Rp6iq19fZrK6wtba43N3eqautwMHCubm7ioyPr7CysbK039/gt7i5zc3PxcXH2dnaoqSmhoiL4+Pkl5mb////oaKloKGkqKmrmJqcy8zNoKKkrK2v09TVlZeZ3t7fvL2+oaKkz9DRyMnKkJKV5eXlycnLq6yum5yf0dLTs7S2w8TF7e3t6enp0dHS4eLiwcLEoqSlubq8lZaZi42Q5eXml5ib5+jozc7QiIqNsLGzpqep7OzskJGUkZOVnJ6g3d7e1dbXjY+RjI2QlpeanJ2g4+TltLW3lpia8PDw5OXlvb7Avr/Bqqytj5GTh4mMmpyesrO1x8jJycrL5+fonZ+h3Nzdnp+i3+DhpKWolJWY2tvc+Pj47Ozt/v7+zM3NiouO19jZlJaYpKWn8/P09vb28PDx1NXWjpCSjpCT7u7v29zd/Pz8pqeq7e3u7+/w6uvr6+vs8fHx+/v75ebm09PU8fHy4ODhmZudgIKFU8qcGwAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfbBxoKMSjH1gc5AAACs0lEQVRIx82V91ciMRDHgQWBoygsd4csvVdBkCogqCj2rth7b9f7zfzrZkv4UfY32ZdM8vI+mdlkvi+jQFmf4u0wJnbvVfXEHkP6W3fhQfs6Fh0Vps60gx8OERuKIGoKDcSSC4MLEnb7Wdpi8SIuGEbRrEwZMOdWWlhtCP9Sb0ojdT2DqGcKGCJrJhLBoEhZXUqK2VwUs2pI6DCPKQphxDyyjmuFVyNhH7sY6epRvEaMDcWQH4dKMy6ViDWUEsU88mYdmXwshPmYlZz8AGfW9SKmSR8K1PrvFHWrJr0VJMYhNPFCnA9mYo333GSPLNhyf3I6yx6Xk5XTSe6TrNRPXvyMy1HINy4vS0h7tj5SL8j63hBjA2ezlz0xQ2KNLdXvzK9jH6LCdOnUzw8WAFNyA9x1E0AnAxsNCWOHpS2mWYDGYhQ66fAiZEtp03tzAg6ot7HuMVYB1tg6JMiajURYTIbHM2MU02UoNu4moXd5LFnfBaiB0n+UnHVL2LsuRvpOFP4DBFQBgCMAVWc1cylipjGJYg288QJbCySgFhgnJx+GVe+aiLlPLQLl/RKmbndIb2wQ4xeaeCFLdx1+dsat9MiCLvsjazWdc1lZOV3h/slK/crFd60chfziarKEdK7rI/X2fc1qg3F7uSe2H5kaeKpq269jC8JjjXOtCX4YRIyXt9BeJe/mTQW3riRsgFaZ6W3EK58DTzybPmSePNOadgRb1Jvnmbr2IU61qxgha8skgq+86ax4KDZSoZjTTkIHeaxcJcWjiCcT6vK2XcIGuhj/aw6+xkAchFoTv/FVlkUs7pGo9j5v5rFdhAgWwUlO7kLf/JSI2VuDAjWf2qRu+YXBLWImhCZeyJz2hNhno77ZIwsjzC2jnj7WM7Jy2tRrZaW+6W/a5Sjkq74oS0jHI/2j3hdkME6+dNXTRgAAAABJRU5ErkJggg==';
        // end: modified by dachcom
        this.button_height = 22;
        this.button_margin = 4;
        this.button_width = 38;
        this.hotspot_hover_changed_handler =
            this.double_click_handler = void 0;
        this.hotspot_padding = 0;
        this.hotspot_selection_changed_handler = void 0;
        this.hotspots_deselect_on_void_click = !0;
        this.deselect_last_selected_hotspots_per_rectangle_on_void_click = !1;
        this.hotspots_stroke_style = 'rgba(245,195,0,.2)';
        this.hovered_hotspots_stroke_style = '#980000';
        this.multi_selection_enabled = !1;
        this.scale_factor_max = 5;
        this.scale_factor_min = .3;
        this.selected_hotspots_fill_style = 'rgba(245,195,0,.4)';
        this.selected_hotspots_stroke_style = 'rgb(245,195,0)';
        this.state_draw_fill_style =
            'black';
        this.state_draw_font_style = '20px monospace';
        this.tooltip_background_style = 'rgba(255,204,0,.9)';
        this.tooltip_padding = 5;
        this.tooltip_fill_style = 'black';
        this.tooltip_font = '14px Verdana, Arial, sans-serif';
        this.tooltip_height = 14;
        this.update_button_states_handler = void 0;
        this.wheel_zoom_factor = .3;
        this.rect_angle_stroke_style = 'rgba(237, 29, 35, .8)';
        this.rect_angle_fill_style = 'rgba(192,192,192, 0.1)';
        this.mask_fill_style = ib;
        this.wid = 'imageviewer';
        this.load_messages = {};
        this.grey_out_disabled_masks =
            this.display_grayscale = !1
    }

    function O(a, b) {
        this.load_messages[a] = b;
        return this
    }

    function D() {
        this.default_mask_color = this.mask_color_hover = this.mask_color_selected = this.ref_hotspot = this.fill_style_selected = this.stroke_style_hovered = this.stroke_style_selected = this.fill_style = this.stroke_style = this.tooltip = void 0
    }

    function L() {
        this.excl = !1;
        this.mem = {}
    }

    var ib = M('rgb(245,195,0)'), V = new I, hb = M('rgb(255,255,255,.7)'), X = {};
    q(1, 'initialized', '\u2026');
    q(2, 'loading', '\u21a7');
    q(3, 'loaded', void 0);
    q(4, 'not_found',
        '\u2205');
    q(5, 'error', 'err\u2639r');
    var B = {INITIALIZING: 0, KEY_PAIR_GENERATED: 1, SUCCESSFUL: 2, ERROR: 3}, C = B.INITIALIZING, Q, R, Y = [], Z = [];
    (function () {
        var a = h.crypto || h.msCrypto;
        if (a && a.subtle) {
            var b = a.subtle.generateKey({
                name: 'RSA-OAEP',
                modulusLength: 2048,
                publicExponent: new Uint8Array([1, 0, 1]),
                hash: {name: 'SHA-256'}
            }, !1, ['encrypt', 'decrypt']);
            'function' === typeof b.then ? b.then(function (a) {
                Q = a;
                C = B.KEY_PAIR_GENERATED;
                z()
            })['catch'](function (a) {
                v(a);
                C = B.ERROR;
                K('' + a)
            }) : (b.oncomplete = function () {
                Q = b.result;
                C =
                    B.KEY_PAIR_GENERATED;
                z()
            }, b.onerror = function () {
                v('keyGenRes.onerror with ', b.result);
                C = B.ERROR;
                K('' + b.result)
            })
        } else v('No crypto available; hint: it needs a secure context (https).'), C = B.ERROR, K('No crypto available; hint: it needs a secure context (https).')
    })();
    y.prototype.destroy = function () {
        var a = A(this.cid);
        a.$j_event_div.off('touchcancel', this.handle_touchcancel);
        a.$j_event_div.off('touchmove', this.handle_touchmove);
        a.$j_event_div.off('touchend', this.handle_touchend);
        a.$j_event_div.off('touchstart',
            this.handle_touchstart);
        a.$j_event_div.off('mousemove', this.handle_mousemove);
        a.$j_event_div.off('mousedown', this.handle_mousedown);
        a.$j_event_div.off('wheel', this.handle_wheel);
        a.$j_event_div.off('mouseout', this.handle_mouseout);
        t(h).off('resize', this.handle_resize);
        var a = this.cid, b = W[a];
        b && b.clear();
        delete W[a]
    };
    y.prototype.clear = function () {
        var a = P.call(this), b = A(this.cid);
        -1 !== this.load_timeout && (h.clearTimeout(this.load_timeout), this.load_timeout = -1);
        var c = a.canvas.width, d = a.canvas.height;
        a.fillStyle =
            this.options.background_fill_style;
        a.fillRect(0, 0, c, d);
        this.requested_animation_frame = void 0;
        this.state = 1;
        this.touch_manager = new F(this.cid);
        this.path = void 0;
        b.$j_canvas.empty();
        this.scaled_width = this.scaled_height = this.original_width = this.original_height = void 0;
        b.hotspots = [];
        this.hs_overrides = new L;
        this.hotspot_area_hovered = this.hotspot_key_hovered = void 0;
        this.selected_hotspots.clear();
        b.images = [];
        this.scale_factor = 1;
        this.offset_y = this.offset_x = 0;
        this.dragging = void 0;
        this.skip_drawing = !1;
        this.latest_click =
            this.prev_mouse_event = void 0;
        H.call(this);
        l.call(this)
    };
    y.prototype.turn_off_drawing = function () {
        this.skip_drawing = !0;
        H.call(this);
        var a = P.call(this), b = a.canvas.width, c = a.canvas.height;
        a.fillStyle = this.options.background_fill_style;
        a.fillRect(0, 0, b, c)
    };
    y.prototype.load = function (a, b, c, d) {
        d = void 0 === d ? !1 : d;
        var e = this.path !== a, f = t(h), g = this;
        if (e || d) {
            this.clear();
            d = Ia(f.width(), f.height());
            var k = a + d;
            this.path = a;
            this.current_ajax_request && 4 > this.current_ajax_request.readyState && (this.current_ajax_request.abort(),
                this.current_ajax_request = void 0);
            this.state = 2;
            l.call(this);
            a = Ka(a);
            v('Wait millis', a);
            0 > a ? (v('Do not even try to load the image. Its token will never be valid.'), this.state = 5, l.call(this)) : this.load_timeout = h.setTimeout(function () {
                Wa.call(g, k, b, c)
            }, a)
        } else 'function' === typeof b && b.call(this, this.options.wid), this.skip_drawing = !1, l.call(this)
    };
    y.prototype.fake_hotspot_key_hovered = function (a) {
        'string' !== typeof a ? 'undefined' !== typeof this.hotspot_key_hovered && (this.hotspot_key_hovered = void 0, l.call(this)) :
            this.hotspot_key_hovered !== a && (this.hotspot_key_hovered = a, l.call(this))
    };
    y.prototype.enable_rectangle = function (a) {
        this.rect_active = 'undefined' === typeof a ? !0 : !!a
    };
    y.prototype.unselect_last_selected_hotspots_per_rectangle = function () {
        var a = this, b = this.rect_last_selected_hotspots, c = !1;
        void 0 !== b && 0 < b.length && (b.forEach(function (b) {
            a.selected_hotspots.unselect(b, !0);
            c = !0
        }), 'function' === typeof this.options.hotspot_selection_changed_handler && this.options.hotspot_selection_changed_handler.call(this, this.selected_hotspots.get_hotspot_keys()),
            l.call(this));
        this.rect_last_selected_hotspots = void 0;
        return c
    };
    y.prototype.select_hotspot = function (a, b) {
        v('select hotspot', a);
        if (this.selected_hotspots.select(a, this.options.multi_selection_enabled) && !b) {
            var c = A(this.cid), d = !1, e = P.call(this).canvas, f = e.width, g = e.height, h, k, p, m, n,
                q = this.original_width / this.scaled_width, t = this.original_height / this.scaled_height, w = e = 1E6,
                x = 0, r = 0, y = this.original_width / 20, z = this.original_height / 20, B;
            if (c.hotspots && !this.selected_hotspots.is_empty()) for (B = sa.call(this),
                                                                           h = 0; h < c.hotspots.length; h++) if (k = c.hotspots[h], p = (k.key || '').trim(), 0 !== p.length && B.contains(p)) {
                m = k.areas;
                for (p = 0; p < m.length; p++) n = m[p], ka(n) && (e = Math.min(e, n.left - this.options.hotspot_padding - y), w = Math.min(w, n.top - this.options.hotspot_padding - z), x = Math.max(x, n.left + n.width + this.options.hotspot_padding + y), r = Math.max(r, n.top + n.height + this.options.hotspot_padding + z), d = !0);
                for (p = 0; p < k.selected_masks.length; p++) d = k.selected_masks[p], e = Math.min(e, d.left - this.options.hotspot_padding - y), w = Math.min(w, d.top -
                    this.options.hotspot_padding - z), x = Math.max(x, d.left + d.width + this.options.hotspot_padding + y), r = Math.max(r, d.top + d.height + this.options.hotspot_padding + z), d = !0
            }
            d && (e = Math.max(0, e), w = Math.max(0, w), x = Math.min(this.original_width, x), r = Math.min(this.original_height, r), c = f / (x - e) * q, d = g / (r - w) * t, c = c >= this.scale_factor && d > this.scale_factor ? this.scale_factor : c <= d ? c : d, q /= c, t /= c, d = -this.offset_x * q, h = (f - this.offset_x) * q, f = -this.offset_y * t, g = (g - this.offset_y) * t, e < d || x > h || w < f || r > g ? (q = ((h - d - x + e) / 2 - e) / q, g = ((g - f - r + w) /
                2 - w) / t) : (q = this.offset_x, g = this.offset_y), v(this.offset_x, this.offset_y, 'original visible', e, w, x, r), ra.call(this, q, g, c));
            l.call(this)
        }
    };
    y.prototype.unselect_hotspot = function (a) {
        v('unselect hotspot', a);
        this.selected_hotspots.unselect(a, this.options.multi_selection_enabled) && l.call(this)
    };
    y.prototype.toggle_hotspot_selection = function (a, b) {
        v('toggle hotspot', a);
        this.selected_hotspots.contains(a) ? this.unselect_hotspot(a) : this.select_hotspot(a, b)
    };
    y.prototype.get_selected_hotspot_keys = function () {
        return this.selected_hotspots.get_hotspot_keys()
    };
    y.prototype.get_selected_id = function () {
        var a = this.get_selected_hotspot_keys(), b, c;
        if (a && 0 < a.length) {
            b = '';
            for (c = 0; c < a.length; c++) 0 < c && (b += ','), b += a[c];
            return b
        }
    };
    y.prototype.get_state = function () {
        return this.state ? X[this.state] : void 0
    };
    y.prototype.set_hotspot_overrides = function (a) {
        if (a instanceof L) this.hs_overrides = a, this.adopt_hotspot_override_masks(); else throw'no HSOverrides';
    };
    y.prototype.adopt_hotspot_override_masks = function () {
        var a = this, b = new T(function () {
            l.call(a)
        });
        na.call(this, b)
    };
    y.prototype.draw =
        l;
    y.prototype.fit_drawing_into_canvas = function () {
        la.call(this)
    };
    y.prototype.version = function () {
        return '3.1.3'
    };
    y.prototype.zoom_in = function () {
        Ba.call(this, !0)
    };
    y.prototype.zoom_out = function () {
        Ba.call(this, !1)
    };
    var cb = 0, W = {};
    Ca.prototype.clear = function () {
        0 <= this.override_stuff_timeout && (h.clearTimeout(this.override_stuff_timeout), this.override_stuff_timeout = -1);
        this.$j_canvas && this.$j_canvas.remove();
        this.$j_event_div && this.$j_event_div.remove()
    };
    F.prototype.reset = function () {
        this.primary_override = this.primary_page_y_last =
            this.primary_page_x_last = this.primary_offset_y_start = this.primary_offset_x_start = this.primary_page_y_start = this.primary_page_x_start = this.primary_id = this.start_date = void 0;
        this.reset_secondary()
    };
    F.prototype.reset_secondary = function () {
        this.secondary_page_y_last = this.secondary_page_x_last = this.secondary_page_y_start = this.secondary_page_x_start = this.secondary_id = void 0;
        this.scale_factor_start = -1
    };
    F.prototype.add = function (a, b, c, d, e, f) {
        'undefined' === typeof this.primary_id ? (this.reset(), this.start_date =
            Date.now(), this.primary_id = a, this.primary_page_x_start = b, this.primary_page_y_start = c, this.primary_offset_x_start = d, this.primary_offset_y_start = e, this.primary_page_x_last = b, this.primary_page_y_last = c) : 'undefined' === typeof this.secondary_id && (this.reset_secondary(), Da.call(this), this.secondary_id = a, this.secondary_page_x_start = b, this.secondary_page_y_start = c, this.secondary_page_x_last = b, this.secondary_page_y_last = c, this.scale_factor_start = f)
    };
    F.prototype.move = function (a, b, c) {
        a === this.primary_id ? (this.primary_page_x_last =
            b, this.primary_page_y_last = c) : a === this.secondary_id && (this.secondary_page_x_last = b, this.secondary_page_y_last = c)
    };
    F.prototype.remove = function (a) {
        var b = !1;
        a === this.primary_id ? (this.reset(), b = !0) : a === this.secondary_id && (Da.call(this), this.reset_secondary());
        return b
    };
    F.prototype.calc = function (a) {
        var b = A(this.cid), c, d, e, f, g;
        f = d = c = void 0;
        'undefined' !== typeof this.primary_id && (c = this.primary_page_x_last - this.primary_page_x_start, d = this.primary_page_y_last - this.primary_page_y_start, c = this.primary_offset_x_start +
            c, d = this.primary_offset_y_start + d, 'undefined' !== typeof this.secondary_id && (f = this.secondary_page_x_start - this.primary_page_x_start, e = this.secondary_page_y_start - this.primary_page_y_start, f = Math.sqrt(f * f + e * e), e = this.secondary_page_x_last - this.primary_page_x_last, g = this.secondary_page_y_last - this.primary_page_y_last, e = Math.sqrt(e * e + g * g), e /= f, f = this.scale_factor_start * e, g = ea.call(a), a = fa.call(a), f < g ? (f = g, e = f / this.scale_factor_start) : f > a && (f = a, e = f / this.scale_factor_start), a = b.$j_canvas.offset(), b = this.primary_page_x_last -
            a.left, a = this.primary_page_y_last - a.top, c = b - (b - c) * e, d = a - (a - d) * e), this.primary_override = {
            px: this.primary_page_x_last,
            py: this.primary_page_y_last,
            ox: c,
            oy: d
        });
        return {ox: c, oy: d, sf: f}
    };
    F.prototype.duration_millis = function () {
        return 'undefined' !== typeof this.start_date ? Date.now() - this.start_date : -1
    };
    S.prototype.state = function () {
        return this.active ? this.hovered ? 2 : 1 : 3
    };
    S.prototype.draw = function (a, b, c) {
        var d = (this.state() - 1) * this.button_height;
        a.drawImage(this.img, 0, d, this.button_width, this.button_height, b, c,
            this.button_width, this.button_height)
    };
    S.prototype.fire_click = function () {
        this.active && 'function' === typeof this.click_handler && this.click_handler.call(this)
    };
    G.prototype.clear = function () {
        var a = 0 < this.count;
        this.idx = {};
        this.count = 0;
        return a
    };
    G.prototype.is_empty = function () {
        return 0 === this.count
    };
    G.prototype.contains = function (a) {
        a = ia(a);
        return 'undefined' === typeof a ? !1 : this.idx.hasOwnProperty(a)
    };
    G.prototype.select = function (a, b) {
        var c = ia(a), d = 'string' !== typeof c, e = d ? !1 : this.idx.hasOwnProperty(c);
        if (b) {
            if (d ||
                e) return !1;
            this.idx[c] = 1;
            this.count++;
            return !0
        }
        if (d) return this.clear();
        if (e) return 1 < this.count ? (this.clear(), this.idx[c] = 1, this.count++, !0) : !1;
        0 < this.count && this.clear();
        this.idx[c] = 1;
        this.count++;
        return !0
    };
    G.prototype.unselect = function (a, b) {
        if (!b && 0 < this.count) return this.clear();
        var c = ia(a);
        return ('string' !== typeof c ? 0 : this.idx.hasOwnProperty(c)) ? (delete this.idx[c], this.count--, !0) : !1
    };
    G.prototype.get_hotspot_keys = function () {
        var a = [], b, c;
        for (b in this.idx) this.idx.hasOwnProperty(b) && (c = b, c = 'string' ===
        typeof c && 3 < c.length && 0 === c.indexOf('hs_') ? c.substring(3) : void 0, 'string' === typeof c && a.push(c));
        a.sort();
        return a
    };
    Ea.prototype.done = function () {
        this.waiter.done(this.idx)
    };
    T.prototype.add = function () {
        var a = this.idx++;
        this.pendings[a] = !0;
        return new Ea(this, a)
    };
    T.prototype.done = function (a) {
        this.pendings[a] = !1;
        a = this.pendings.length;
        for (var b = 0; b < a; b++) if (this.pendings[b]) return;
        v('All pending stuff done after', Date.now() - this.started_epoch_millis, 'milliseconds. Calling back ...');
        this.callback()
    };
    I.prototype.with_key =
        function (a) {
            this.key = a;
            this.defined = !0;
            return this
        };
    I.prototype.with_coords = function (a, b) {
        this.left = a;
        this.top = b;
        this.defined = !0;
        return this
    };
    I.prototype.with_dimensions = function (a, b) {
        this.width = a;
        this.height = b;
        this.defined = !0;
        return this
    };
    I.prototype.distance = function (a, b) {
        if ('undefined' === typeof this.left || 'undefined' === typeof this.top || 'undefined' === typeof this.width || 'undefined' === typeof this.height) throw'Not enough info';
        var c = Math.abs(this.left + this.width / 2 - a), d = Math.abs(this.top + this.height /
            2 - b);
        return Math.sqrt(c * c + d * d)
    };
    k.prototype.with_display_image_grayscale = function (a) {
        this.display_grayscale = a;
        return this
    };
    k.prototype.with_show_grey_out_masks = function (a) {
        this.grey_out_disabled_masks = a;
        return this
    };
    k.prototype.with_background_fill_style = function (a) {
        this.background_fill_style = a;
        return this
    };
    k.prototype.with_button_fit_src = function (a) {
        this.button_fit_src = a;
        return this
    };
    k.prototype.with_button_height = function (a) {
        this.button_height = a;
        return this
    };
    k.prototype.with_button_margin = function (a) {
        this.button_margin =
            a;
        return this
    };
    k.prototype.with_button_width = function (a) {
        this.button_width = a;
        return this
    };
    k.prototype.with_hotspot_hover_changed_handler = function (a) {
        this.hotspot_hover_changed_handler = a;
        return this
    };
    k.prototype.with_double_click_handler = function (a) {
        this.double_click_handler = a;
        return this
    };
    k.prototype.with_hotspot_padding = function (a) {
        this.hotspot_padding = a;
        return this
    };
    k.prototype.with_hotspot_selection_changed_handler = function (a) {
        this.hotspot_selection_changed_handler = a;
        return this
    };
    k.prototype.with_hotspots_deselect_on_void_click =
        function (a) {
            this.hotspots_deselect_on_void_click = !!a;
            return this
        };
    k.prototype.with_deselect_last_selected_hotspots_per_rectangle_on_void_click = function (a) {
        this.deselect_last_selected_hotspots_per_rectangle_on_void_click = !!a;
        return this
    };
    k.prototype.with_hotspots_stroke_style = function (a) {
        this.hotspots_stroke_style = a;
        return this
    };
    k.prototype.with_hovered_hotspots_stroke_style = function (a) {
        this.hovered_hotspots_stroke_style = a;
        return this
    };
    k.prototype.with_multi_selection_enabled = function (a) {
        this.multi_selection_enabled =
            a;
        return this
    };
    k.prototype.with_selected_hotspots_fill_style = function (a) {
        this.selected_hotspots_fill_style = a;
        return this
    };
    k.prototype.with_selected_hotspots_stroke_style = function (a) {
        this.selected_hotspots_stroke_style = a;
        return this
    };
    k.prototype.with_scale_factor_max = function (a) {
        this.scale_factor_max = a;
        return this
    };
    k.prototype.with_scale_factor_min = function (a) {
        this.scale_factor_min = a;
        return this
    };
    k.prototype.with_state_draw_fill_style = function (a) {
        this.state_draw_fill_style = a;
        return this
    };
    k.prototype.with_state_draw_font_style =
        function (a) {
            this.state_draw_font_style = a;
            return this
        };
    k.prototype.with_tooltip_background_style = function (a) {
        this.tooltip_background_style = a;
        return this
    };
    k.prototype.with_tooltip_padding = function (a) {
        this.tooltip_padding = a;
        return this
    };
    k.prototype.with_tooltip_fill_style = function (a) {
        this.tooltip_fill_style = a;
        return this
    };
    k.prototype.with_tooltip_font = function (a) {
        this.tooltip_font = a;
        return this
    };
    k.prototype.with_tooltip_height = function (a) {
        this.tooltip_height = a;
        return this
    };
    k.prototype.with_update_button_states_handler =
        function (a) {
            this.update_button_states_handler = a;
            return this
        };
    k.prototype.with_wheel_zoom_factor = function (a) {
        this.wheel_zoom_factor = a;
        return this
    };
    k.prototype.with_rect_angle_fill_style = function (a) {
        this.rect_angle_fill_style = a;
        return this
    };
    k.prototype.with_mask_fill_style = function (a) {
        this.mask_fill_style = M(a);
        return this
    };
    k.prototype.with_rect_angle_stroke_style = function (a) {
        this.rect_angle_stroke_style = a;
        return this
    };
    k.prototype.with_wid = function (a) {
        this.wid = a;
        return this
    };
    k.prototype.with_load_state_message =
        function (a, b) {
            return O.call(this, b, a)
        };
    k.prototype.with_initialized_message = function (a) {
        return O.call(this, 1, a)
    };
    k.prototype.with_loading_message = function (a) {
        return O.call(this, 2, a)
    };
    k.prototype.with_loaded_message = function (a) {
        return O.call(this, 3, a)
    };
    k.prototype.with_not_found_message = function (a) {
        return O.call(this, 4, a)
    };
    k.prototype.with_error_message = function (a) {
        return O.call(this, 5, a)
    };
    D.prototype.with_default_mask_color = function (a) {
        this.default_mask_color = M(a);
        return this
    };
    D.prototype.with_mask_fill_style_select =
        function (a) {
            this.mask_color_selected = M(a);
            return this
        };
    D.prototype.with_mask_fill_style_hover = function (a) {
        this.mask_color_hover = M(a);
        return this
    };
    D.prototype.with_tooltip = function (a) {
        'string' === typeof a && (this.tooltip = a.trim());
        return this
    };
    D.prototype.with_stroke_style = function (a) {
        this.stroke_style = a;
        return this
    };
    D.prototype.with_fill_style = function (a) {
        this.fill_style = a;
        return this
    };
    D.prototype.with_stroke_style_selected = function (a) {
        this.stroke_style_selected = a;
        return this
    };
    D.prototype.with_stroke_style_hovered =
        function (a) {
            this.stroke_style_hovered = a;
            return this
        };
    D.prototype.with_ref_hotspot = function (a) {
        this.ref_hotspot = 'undefined' === typeof a ? !0 : !!a;
        return this
    };
    D.prototype.with_fill_style_selected = function (a) {
        this.fill_style_selected = a;
        return this
    };
    L.prototype.with_others_disabled = function (a) {
        this.excl = 'undefined' === typeof a ? !0 : !!a;
        return this
    };
    L.prototype.put_new_override = function (a) {
        if ('string' !== typeof a) throw'no string';
        var b = new D;
        return this.mem[a] = b
    };
    h.ImageOptions = k;
    h.HSOverrides = L;
    h.ImageWidget =
        y;
    h.image_widget_rsa_public_key = function (a, b) {
        C === B.SUCCESSFUL ? a.call(this, R) : C === B.ERROR ? b.call(this, 'Crypto could not be initialized.') : (Y.push(a), Z.push(b))
    };
    h.new_image_widget = function (a, b) {
        var c = b || new k;
        return new h.ImageWidget(a, c)
    }
})(window, jQuery);
