/**
 * Material-UI Bug: finddomnode is deprecated in strictmode material-ui *
 * Solution:https://github.com/mui-org/material-ui/issues/13394
 */
import { createTheme } from '@mui/material/styles';
import { components, themeName, palette, typography, shape, props } from './baseTheme'
import { overrideTheme } from './overrideTheme';

let theme = createTheme({ components, themeName, palette, typography, shape, props });
theme = overrideTheme(theme);

export default theme;
