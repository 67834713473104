import {
    ENQUEUE_SUCCESS_SNACKBAR,
    ENQUEUE_INFO_SNACKBAR,
    ENQUEUE_WARNING_SNACKBAR,
    ENQUEUE_ERROR_SNACKBAR,
    REMOVE_SNACKBAR,
    RESET_ALL_SNACKBARS,
} from './snackbarTypes';

const initialState = {
    notifications: [],
};

const SnackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case ENQUEUE_SUCCESS_SNACKBAR:
            return {
                ...state, notifications: [
                    ...state.notifications,
                    {
                        key: new Date().getTime() + Math.random(),
                        message: action.value,
                        type: 'success',
                    },
                ],
            };

        case ENQUEUE_INFO_SNACKBAR:
            return {
                ...state, notifications: [
                    ...state.notifications,
                    {
                        key: new Date().getTime() + Math.random(),
                        message: action.value,
                        type: 'info',
                    },
                ],
            };

        case ENQUEUE_WARNING_SNACKBAR:
            return {
                ...state, notifications: [
                    ...state.notifications,
                    {
                        key: new Date().getTime() + Math.random(),
                        message: action.value,
                        type: 'warning',
                    },
                ],
            };

        case ENQUEUE_ERROR_SNACKBAR:
            return {
                ...state, notifications: [
                    ...state.notifications,
                    {
                        key: new Date().getTime() + Math.random(),
                        message: action.value,
                        type: 'error',
                    },
                ],
            };

        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key,
                ),
            };

        case RESET_ALL_SNACKBARS:
            return initialState;

        default:
            return state;
    }
};

export default SnackbarReducer;
