import React from 'react';
import {connect} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import DialogTitleWithClose from '../../base/dialogTitleWithClose';
import {setCartContext, setCartSelectionDialogState} from '../../../redux/cart/cartAction';
import CartList from './cartList';
import withCartApi from '../../../api/cartApi';

class CartSelectionDialog extends React.Component {

    handleCartSelectionClose = () => () => {
        this.props.setCartSelectionDialogState(false);
    };

    handleCartSelectionItemClick = (cart) => {
        this.props.setCartSelectionDialogState(false);

        if (this.props.cartContextId !== cart.cartId) {
            this.props.setCartContext(cart);
        }
    };

    render() {
        const {t} = this.props;

        return (
            <Dialog
                open={this.props.cartSelectionDialogShow}
                onClose={this.handleCartSelectionClose()}
                aria-labelledby="cart-selection-dialog-title"
                aria-describedby="cart-selection-dialog-description"
            >
                <DialogTitleWithClose onClose={this.handleCartSelectionClose()}>
                    {t('cart_selection')}
                </DialogTitleWithClose>
                <Box pb={3}>
                    <CartList
                        carts={this.props.carts}
                        onItemClick={this.handleCartSelectionItemClick}
                    />
                </Box>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    cartSelectionDialogShow: state.cart.cartSelectionDialogShow,
    cartContextId: state.cart.cartContext.cartId,
    dealerId: state.cart.dealer.dealerId,
});

const mapDispatchToProps = {
    setCartSelectionDialogState,
    setCartContext,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withCartApi,
    withTranslation(),
)
(CartSelectionDialog);
