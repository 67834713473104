import React, {Fragment} from 'react';
import MuiContainer from '@mui/material/Container';
import {withStyles} from '@mui/styles';
import classNames from 'classnames';

const useStyles = theme => ({
    paddingX: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '0 !important',
            paddingRight: '0 !important'
        },
    },
    noPaddingXSM: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '0 !important',
            paddingRight: '0 !important'
        },
    },
    paddingY: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    noPadding: {
        padding: '0 !important',
    },
    bgWhite: {
        background: theme.palette.white,
    },
    flexGrow: {
        flex: '1 0 auto',
    },
    limitHeight: {
        flex: '1',
        display: 'flex !important',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: theme.spacing(2),
        }
    },
    stickyBottom: {
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
    },
    overflow: {
        overflow: 'auto',
    },
    overflowHidden: {
        overflow: 'hidden',
    }
});

class Container extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <MuiContainer
                disableGutters={this.props.disableGutters}
                maxWidth="md"
                className={
                    classNames(
                        this.props.className,
                        this.props.paddingX ? classes.paddingX : '',
                        this.props.noPaddingXSM ? classes.noPaddingXSM : '',
                        this.props.paddingY ? classes.paddingY : '',
                        this.props.bgWhite ? classes.bgWhite : '',
                        this.props.flexGrow ? classes.flexGrow : '',
                        this.props.stickyBottom ? classes.stickyBottom : '',
                        this.props.noPadding ? classes.noPadding : '',
                        this.props.limitHeight ? classes.limitHeight : '',
                        this.props.overflow ? classes.overflow : '',
                        this.props.overflowHidden ? classes.overflowHidden : '',
                    )
            }>
                {this.props.children ? this.props.children : <Fragment/>}
            </MuiContainer>
        );
    }
}

export default withStyles(useStyles)(Container);
