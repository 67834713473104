import React from 'react';
import {
    Box, 
    Button, 
    IconButton
} from '@mui/material';
import theme from '../../theme/theme';

class BrandItem extends React.Component {
    render() {
        const { brand, variant } = this.props;

        const isImageItem = variant === 'image';
        const ButtonComponent = isImageItem ? Button : IconButton;

        const stylesButton = isImageItem ? {
            minWidth: theme.spacing(9),
            width: '100%',
            padding: '0 0 100% 0',
            backgroundColor: 'white'
        } : {
            px: 0,
            py: 1,
            width: 'auto'
        };

        const stylesImage = isImageItem ? {
            m: 1,
            position: 'absolute',
            left: 0,
            top: 0,
            width: `calc(100% - ${theme.spacing(2)})`,
            height: `calc(100% - ${theme.spacing(2)})`
        } : {
            width: theme.spacing(6),
            height: theme.spacing(6)
        };
        
        return !brand 
            ? null 
            : (
                <ButtonComponent
                    color="default"
                    onClick={() => this.props.onClick ? this.props.onClick(this.props.brand) : null}
                    sx={{
                        ...stylesButton,
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    <Box
                        component="img" 
                        src={this.props.brand.iconUrl}
                        alt={this.props.brand.name}
                        sx={{
                            ...stylesImage,
                            objectPosition: 'center',
                            objectFit: 'contain'
                        }}
                    />
                </ButtonComponent>
            );
    }
}

export default BrandItem;
