import React from 'react';
import {compose} from 'redux';
import ModelSubheader from '../restriction/restrictionSubheader';
import Container from '../../base/container';
import {connect} from 'react-redux';
import withCatalog from '../../../service/withCatalog';
import {
    resetCurrentBomBase,
    updateCurrentBomBase,
    updateCurrentPartInformation,
} from '../../../redux/catalog/catalogAction';
import catmetaApi from '../../../api/catmetaApi';
import DynamicRestrictionTable from '../dynamicRestrictionTable';

class BomBaseTable extends React.Component {

    constructor(props) {
        super(props);

        this.onPartClick = this.onPartClick.bind(this);
    }

    componentDidMount() {
        this.loadPartOverview();
    }

    onBackClick = () => {
        const {history} = this.props;
        if (history.location.pathname.indexOf('/catalog/part') >= 0) {
            this.props.partHistoryGoBack();
        } else {
            history.goBack();
        }
    };

    onPartClick(part) {
        if (!part.link || !part.link.path) {
            return;
        }

        catmetaApi.getPartInformation(part.link.path)
            .then((partInformation) => {
                partInformation.title = part.link.wid;
                this.props.updateCurrentPartInformation(partInformation);
                this.props.routeToVehicleInformation();
            });
    };

    loadPartOverview() {
        if (!this.props.currentBomBasePart.link) {
            console.error('Error happened on loadPartOverview, no link to get overview data: ', this.props.currentPart);
        }

        this.props.resetCurrentBomBase();
        catmetaApi.getPartOverview(this.props.currentBomBasePart.link)
            .then((partOverviewBaseData) => {
                this.props.updateCurrentBomBase(partOverviewBaseData);
            });
    }

    render() {
        return (
            <Container paddingX>
                <ModelSubheader
                    title={this.props.currentBomBasePart?.values?.caption}
                    onBackClick={() => this.onBackClick()}
                />
                <DynamicRestrictionTable onItemClick={this.onPartClick}
                                         showTrigger={true}
                                         widgetKey={this.props.currentBomBasePart?.nextWidgetKey}
                                         restrictions={this.props.currentBomBase?.records}/>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    currentBomBase: state.catalog.currentBomBase,
    currentBomBasePart: state.catalog.currentBomBasePart,
    currentPart: state.catalog.currentPart,
});

const mapDispatchToProps = dispatch => ({
    updateCurrentPartInformation: (payload) => dispatch(updateCurrentPartInformation(payload)),
    resetCurrentBomBase: (payload) => dispatch(resetCurrentBomBase(payload)),
    updateCurrentBomBase: (payload) => dispatch(updateCurrentBomBase(payload)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withCatalog,
)(BomBaseTable);