import {combineReducers} from 'redux';
import globalReducer from './global/globalReducer';
import catalogReducer from './catalog/catalogReducer';
import authReducer from './auth/authReducer';
import cartReducer from './cart/cartReducer';
import snackbarReducer from './snackbar/snackbarReducer';
import searchReducer from './search/searchReducer';
import dealerManagementReducer from './dealerManagement/dealerManagementReducer';
import browserHistoryReducer from './browserHistory/browserHistoryReducer';
import crumbsReducer from './crumbs/crumbsReducer';

let reducer = combineReducers({
    global: globalReducer,
    catalog: catalogReducer,
    auth: authReducer,
    cart: cartReducer,
    snackbar: snackbarReducer,
    search: searchReducer,
    dealerManagement: dealerManagementReducer,
    browserHistory: browserHistoryReducer,
    crumbs: crumbsReducer,
});

export default reducer;
