import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import ModelSubheader from '../restriction/restrictionSubheader';
import CatmetaService from '../../../service/catmetaService';
import {
    resetCurrentBomBasePart,
    resetCurrentHotspot, 
    updateCurrentBomBasePart,
    updateCurrentPart,
    updateCurrentPartRestriction,
} from '../../../redux/catalog/catalogAction';
import CatalogService from '../../../service/catalogService';
import Container from '../../base/container';
import withCatalog from '../../../service/withCatalog';
import DynamicRestrictionTable from '../dynamicRestrictionTable';

class SubPartRestriction extends React.Component {
    constructor(props) {
        super(props);

        this.onRestrictionClick = this.onRestrictionClick.bind(this);
    }

    componentDidMount() {
        const { history, currentPartRestriction } = this.props;

        this.unregisterHistoryListener = history.listen(() => this.onHistoryChange());
        this.loadSubRestrictions(currentPartRestriction);
    }

    componentWillUnmount() {
        if (this.unregisterHistoryListener) {
            this.unregisterHistoryListener();
        }
    }

    onHistoryChange() {
        this.forceUpdate(() => {
            const restrictionByURL = CatalogService.getRestrictionById(this.props.match.params.restrictionId);

            this.props.updateCurrentPartRestriction({...{}, ...restrictionByURL});
            this.loadSubRestrictions(restrictionByURL);
        });
    }

    loadSubRestrictions(currentPartRestriction) {
        CatalogService.loadSubRestriction(currentPartRestriction);
    }

    onBackClick = (restriction) => () => {
        if (restriction.parentId.indexOf(this.props.currentBrand.id) !== -1) {
            // this.props.updateCurrentPartRestriction({});
            this.props.routeToModel(restriction.parentId);
        } else {
            this.props.routeToSubModel(CatalogService.getParentRestriction(restriction.parentId).id);
        }
    }

    isPartIdentified(restriction) {
        return restriction.nextWidgetKey.toLowerCase() === CatmetaService.KEY_BOMLIST.toLowerCase()
            || restriction.nextWidgetKey.toLowerCase() === CatmetaService.KEY_BOMLIST_WITH_DEPLOYMENT.toLowerCase();
    }

    onRestrictionClick(restriction) {
        if (this.isPartIdentified(restriction)) {
            this.props.updateCurrentPart(restriction);
            this.props.routeToPartOverview();

        } else if (CatmetaService.isBomBaseWidget(restriction.nextWidgetKey.toLowerCase())) {
            this.props.resetCurrentBomBasePart();
            this.props.updateCurrentBomBasePart(restriction);
            this.props.routeToBomBaseOverview();

        } else {
            this.props.routeToSubModel(restriction.id);
        }

        this.props.resetCurrentHotspot();
    }

    render() {
        const { restrictions, currentPartRestriction } = this.props;

        return (
            <Container paddingX limitHeight>
                <ModelSubheader
                    title={CatmetaService.getPageTitle(currentPartRestriction?.nextWidgetKey)}
                    onBackClick={this.onBackClick(currentPartRestriction)}
                />
                <DynamicRestrictionTable
                    restrictions={CatalogService.getRestrictionsByParent(restrictions, currentPartRestriction?.id)}
                    widgetKey={currentPartRestriction?.nextWidgetKey}
                    onItemClick={this.onRestrictionClick}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    restrictions: state.catalog.restrictions,
    currentBrand: state.catalog.currentBrand,
    currentPartRestriction: state.catalog.currentPartRestriction,
});

const mapDispatchToProps = dispatch => ({
    updateCurrentPartRestriction: (payload) => dispatch(updateCurrentPartRestriction(payload)),
    updateCurrentPart: (payload) => dispatch(updateCurrentPart(payload)),
    updateCurrentBomBasePart: (payload) => dispatch(updateCurrentBomBasePart(payload)),
    resetCurrentBomBasePart: (payload) => dispatch(resetCurrentBomBasePart(payload)),
    resetCurrentHotspot: () => dispatch(resetCurrentHotspot()),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
    withCatalog,
)(SubPartRestriction);
