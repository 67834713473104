import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import Container from '../../base/container';

class CartStepper extends React.Component {

    getSteps = () => {
        return ['cart', 'delivery', 'overview'];
    };

    handleStepClick = (index, activeStep, steps) => {
        if(activeStep >= steps.length){
            // Clicking not allowed, because the shopping cart has already been sent to the backend
            return;
        }

        this.props.handleStep(index)
    }

    render() {
        const {activeStep, t} = this.props;
        const steps = this.getSteps();

        return (
            <Container bgWhite>
                <Stepper activeStep={activeStep}>
                    {steps.map((key, index) => {
                        return (
                            <Step key={key} onClick={() => this.handleStepClick(index, activeStep, steps)}>
                                <StepLabel>
                                    {activeStep === index ? t('cart_step_' + key) : ''}
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Container>
        );
    }
}


export default compose(
    withTranslation(),
)
(CartStepper);
