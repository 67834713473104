import Api from './api';
import {store} from '../redux/store';
import i18n from '../i18n';
import {enqueueErrorSnackbar} from '../redux/snackbar/snackbarAction';
import {trackPromise} from 'react-promise-tracker';
import {loadingAreas} from '../config/loadingAreas';
import CatmetaService from '../service/catmetaService';
import Vehicle from '../service/vehicleService';
import {updateCurrentVehicleSegments} from '../redux/catalog/catalogAction';

class catmetaApi {

    static getRestrictions(url) {
        return new Promise((resolve) => {
            trackPromise(
                Api().get(url)
                    .then(response => {
                        if (!response || !response.data) {
                            // console.error('Error happened on getModels: data not inside \'response.data\'', url);
                            resolve({data: {}});
                            return;
                        }
                        resolve(response.data);

                    }).catch(error => {
                    // console.error('error', error);
                    store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_data_loading')));
                    resolve([]);

                })
                , loadingAreas.catalog);
        });
    }

    static getSimpleVehicle(url, listDefinitions = null) {
        Api().get(url)
            .then(response => {
                if (!response || !response.data) {
                    // console.error('Error happened on getSimpleVehicle: data not inside \'response.data\'');
                    return;
                }

                // make fake segments to fit with vehicle form search
                // text from breadcrumbs do not need to be formatted
                const segments = {};

                segments[CatmetaService.BASIC_VEHICLE_INFO_KEY] = {records: Vehicle.getSimpleVehicleFromObject(response.data.crumbs, listDefinitions), showUnformattedText: true};

                store.dispatch(updateCurrentVehicleSegments(segments));

            }).catch(error => {
            // console.error('error', error);
            store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_data_loading')));
        });
    }

    static getPartOverview(url) {
        return new Promise((resolve) => {
            trackPromise(
                Api().get(url)
                    .then(response => {
                        if (!response || !response.data || !response.data.data) {
                            // console.error('Error happened on getPartOverview: data not inside \'response.data.data\'');
                            resolve({});
                            return;
                        }

                        resolve(response.data.data);

                    }).catch(error => {
                    // console.error('error', error);
                    store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_data_loading')));
                    resolve(null);
                })
                , loadingAreas.catalog);
        });
    }

    static getPartInformation(url) {
        return new Promise((resolve) => {
            trackPromise(
                Api().get(url)
                    .then(response => {
                        if (!response || !response.data || !response.data.data) {
                            // console.error('Error happened on getPartInformation: data not inside \'response.data.data\'');
                            resolve({});
                            return;
                        }

                        const data = !response.data.messages
                            ? response.data.data
                            : {
                                ...response.data.data,
                                messages: response.data.messages,
                            };

                        // TODO: This is only for tests
                        // data.records = data.records.map(record => ({
                        //     ...record,
                        //     supplier: 123,
                        //     manufacturer: 'test'
                        // }));

                        resolve(data);
                    }).catch(error => {
                    // console.error('error', error);
                    store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_data_loading')));
                    resolve(null);
                })
                , loadingAreas.catalog);
        });
    }

    static getImage(url) {
        return new Promise((resolve) => {
            trackPromise(
                Api().get(url)
                    .then(response => {
                        if (!response || !response.data) {
                            // console.error('Error happened on getImage: data not inside \'response.data\'');
                            resolve([]);
                            return;
                        }

                        resolve(response.data);

                    }).catch(error => {
                    // console.error('error', error);
                    store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_data_loading')));
                    resolve([]);
                })
                , loadingAreas.catalog);
        });
    }

}

export default (catmetaApi);
