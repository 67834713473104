import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import { IconButton, TableCell } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import CatalogService from '../../service/catalogService';
import { palette } from '../../theme/baseTheme';

const useStyles = () => ({
    tableCell: {
        position: 'relative'
    },
    extendableTableCellText: {
        maxHeight: '100px',
        display: 'block',
        overflow: 'hidden',
    },
    extendableTableCellArrow: {
        position: 'absolute',
        zIndex: '100',
        bottom: '0',
        right: '0',
    }
});

class DynamicRestrictionTableCell extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            extendable: false, 
            extended: false
        };
        
        this.tableCellTextRef = React.createRef();
    }

    componentDidMount() {
        if(this.props.column.id === 'restriction' && this.tableCellTextRef.current) {
            this.setState({ 
                extendable: CatalogService.isCellOverflown(this.tableCellTextRef.current) 
            });
        }
    }

    render() {
        const { column, cellClass, restriction, onItemClick, getText, classes } = this.props;
        
        const restrictionValue = restriction.values;
        const styleTableCell = restriction.unavailable ? { color: palette.grey300 } : {};

        if (restrictionValue && (restrictionValue[column.id])) {
            let text = restrictionValue[column.id];

            if (!text) {
                text = restrictionValue['description'];
            }
            
            return (
                <TableCell className={cellClass} sx={styleTableCell}>
                    <div className={!this.state.extended && column.id === 'restriction' ? classes.extendableTableCellText : null}
                         ref={this.tableCellTextRef}
                         onClick={() => onItemClick(restriction)}>
                        {getText(text)}
                    </div>
                    {this.state.extendable && <label htmlFor="icon-arrow-downward">
                        <IconButton 
                            className={classes.extendableTableCellArrow} 
                            color="primary" 
                            aria-label="extend text"
                            component="span" 
                            onClick={() => this.setState({ extended: !this.state.extended })}
                        >
                            {this.state.extended ? <ArrowUpward/> : <ArrowDownward/> }
                        </IconButton>
                    </label>}
                </TableCell>
            );
        } else {
            return (<TableCell/>);
        }
    }
}

const mapStateToProps = state => ({
    listDefinitions: state.catalog.currentBrand.listDefinitions,
});

export default compose(
    connect(
        mapStateToProps,
    ),
    withStyles(useStyles),
)(DynamicRestrictionTableCell);
