import {loadingAreas} from '../config/loadingAreas';
import Api from './api';
import {store} from '../redux/store';
import {enqueueErrorSnackbar, enqueueSuccessSnackbar} from '../redux/snackbar/snackbarAction';
import i18n from '../i18n';
import {trackPromise} from 'react-promise-tracker';

class dealerApi {

    static setCatalogDealer(dealerId, serviceCatalog) {
        return new Promise((resolve, reject) => {
            trackPromise(
                Api().put('/pl24-appgtw/ext/api/1.0/catalogDealers?catalogService=' + serviceCatalog, {
                    dealerId: dealerId,
                })
                    .then(response => {
                        if (response.status === 200 || response.status === 204) {
                            store.dispatch(enqueueSuccessSnackbar(i18n.t('success_on_cart_put_dealer')));
                            resolve(response);

                        } else {
                            // console.error('Error happened on setCatalogDealer, status: ', response.status);
                            store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_cart_put_dealer')));
                            reject();
                        }
                    })
                    .catch(error => {
                        store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_cart_put_dealer')));
                        // console.error('Error happened on setCatalogDealer: ', error);
                        reject();
                    })
                , loadingAreas.dealerManagement);
        });
    };

    static getCatalogDealer(catalogService) {
        return new Promise((resolve) => {
            trackPromise(
                Api().get(`/cart/ext/api/2.0/catalogDealers?catalogService=${catalogService}`)
                    .then(response => {
                        if (!response || !response.data) {
                            // console.error('Error happened on getDealers: data not inside \'response.data.dealers\'');
                            resolve([]);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => {
                        // console.error('error', error);
                        store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_data_loading')));
                        resolve([]);
                    })
                , loadingAreas.dealerManagement);
        });
    }

    static getDealer(dealerId) {
        return new Promise((resolve) => {
            trackPromise(
                Api().get(`/cart/ext/api/2.0/dealers/${dealerId}/dealerSummary`)
                    .then(response => {
                        if (!response || !response.data) {
                            // console.error('Error happened on getDealers: data not inside \'response.data.dealer\'');
                            resolve(0);
                            return;
                        }
                        resolve(response.data);
                    })
                    .catch(error => {
                        if (error.response && error.response.status === 410) {
                            // console.error(error.response.data.title, error.response.data.detail);
                            resolve(0);
                            return;
                        }

                        // console.error('Error happened on getDealers:', error);
                        store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_loading_dealer')));
                        resolve(0);
                    })
                , loadingAreas.dealerManagement);
        });
    }

    static getDealers(catalogService) {
        return new Promise((resolve) => {
            trackPromise(
                Api().get(`/pl24-appgtw/ext/api/1.0/dealers?catalogService=${catalogService}`)
                    .then(response => {
                        if (!response || !response.data) {
                            // console.error('Error happened on getDealers: data not inside \'response.data.dealer\'');
                            resolve(null);
                            return;
                        }
                        resolve(response.data.dealers);
                    })
                    .catch(error => {
                        // console.error('error', error);
                        store.dispatch(enqueueErrorSnackbar(i18n.t('error_on_data_loading')));
                        resolve(null);
                    })
                , loadingAreas.dealerManagement);
        });
    }

}

export default (dealerApi);
