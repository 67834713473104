import React, { Fragment } from 'react';
import { Typography } from '@mui/material';
import theme from '../../../theme/theme';

// TODO: Refactor!
class CartItemPrice extends React.Component {
    render() {
        const { price, discountPrice, bestPrice } = this.props;

        if (discountPrice) {
            return (
                <Fragment>
                    <Typography align="right" variant="body2" sx={{
                        textDecoration: 'line-through',
                        color: theme.palette.error.light,
                        fontWeight: 700
                    }}>
                        {price}
                    </Typography>
                    <Typography align="right" variant="body2" sx={{
                        fontWeight: 700
                    }}>
                        {discountPrice}
                    </Typography>
                </Fragment>

            );
        } else if (bestPrice) {
            return (
                <Fragment>
                    <Typography align="right" variant="body2" sx={{
                        textDecoration: 'line-through',
                        color: theme.palette.error.light,
                        fontWeight: 700
                    }}>
                        {price}
                    </Typography>
                    <Typography align="right" variant="body2" sx={{
                        fontWeight: 700
                    }}>
                        {bestPrice}
                    </Typography>
                </Fragment>
            );
        } else {
            return (
                <Typography align="right" variant="body2" sx={{
                    fontWeight: 700
                }}>
                    {price}
                </Typography>
            );
        }
    }
}

export default CartItemPrice;
