import React, {Fragment} from 'react';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import {connect} from 'react-redux';
import CartBackHeader from './partial/cartBackHeader';
import List from '@mui/material/List';
import Container from '../base/container';
import CartTotal from './partial/cartTotal';
import {Button} from '@mui/material';
import {withStyles} from '@mui/styles';
import ListComment from './list/listComment';
import withCartApi from '../../api/cartApi';
import ListDeliveryDetail from './list/listDeliveryDetail';
import CartHeaderContainer from './partial/cartHeaderContainer';
import ListAddressGroup from './list/listAddressGroup';
import ListDealer from './list/listDealer';
import ListOptions from './list/listOptions';
import Box from '@mui/material/Box';

const useStyles = () => ({
    overflowAuto: {
        overflow: 'auto'
    }
})

class StepDelivery extends React.Component {


    showDeliveryDetail() {
        const {cart} = this.props;
        return cart.dealerProperties && (cart.dealerProperties.orderTypes || cart.dealerProperties.dispatchTypes);
    }

    render() {
        const {t, cart, cartSubmitClick, classes} = this.props;

        return (

            <Fragment>

                <CartHeaderContainer>
                    <CartBackHeader cart={cart} handleBack={this.props.handleBack}/>
                </CartHeaderContainer>
                <Box className={classes.overflowAuto}>
                    <Container disableGutters>

                        <List component="nav">

                            <ListDealer/>
                            <ListComment cartId={cart.cartId} comment={cart.comment}/>

                            {this.showDeliveryDetail() && <ListDeliveryDetail cartId={cart.cartId}
                                                                              dealerProperties={cart.dealerProperties}
                                                                              cartSubmitClick={cartSubmitClick}
                                                                              currentOrderTypeId={cart.orderTypeId}
                                                                              currentDispatchTypeId={cart.dispatchTypeId}/>
                            }

                            <ListOptions cart={cart}/>
                            <ListAddressGroup cart={cart} cartSubmitClick={cartSubmitClick}/>

                        </List>

                    </Container>

                    <Container flexGrow>

                        <CartTotal cart={cart}/>

                    </Container>
                </Box>
                <Container paddingY>

                    <Button onClick={this.props.handleNext}>{t('cart_next')}</Button>

                </Container>


            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    cart: state.cart.cart,
    cartSubmitClick: state.cart.cartSubmitClick,
});

const mapDispatchToProps = {};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withCartApi,
    withStyles(useStyles),
)
(StepDelivery);
