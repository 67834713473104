import React from 'react';
import {connect} from 'react-redux';
import CatmetaService from '../../../service/catmetaService';
import CatalogService from '../../../service/catalogService';
import ModelSubheader from '../restriction/restrictionSubheader';
import {
    setTopRightButton,
    updateCurrentVehicle,
    updateCurrentVehicleRestriction,
} from '../../../redux/catalog/catalogAction';
import {compose} from 'redux';
import Container from '../../base/container';
import withCatalog from '../../../service/withCatalog';
import TopRightButton from './topRightButton';
import DynamicRestrictionTable from '../dynamicRestrictionTable';

class MainVehicleRestriction extends React.Component {

    constructor(props) {
        super(props);

        this.onItemClick = this.onItemClick.bind(this);
    }

    componentDidMount() {
        if (!this.props.restrictions.length) {
            CatalogService.loadMainVehicleRestriction();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // on brand-dialog click other brand
        if (prevProps.lastBrandChange !== this.props.lastBrandChange) {

            CatalogService.loadMainVehicleRestriction();
        }
    }

    onBackClick = () => () => this.props.routeToRootComponent();

    onItemClick(restriction) {
        this.props.updateCurrentVehicleRestriction(restriction);

        // Special Case for BMW "Sonderkataloge vor VIN"
        if (CatalogService.isBMW() && CatalogService.isVehicleIdentified(restriction)) {
            this.props.updateCurrentVehicle(restriction);
            this.props.routeToMainPart(false, this.props.currentBrand.id);
        } else {
            this.props.routeToSubModel(restriction.id);
        }
    };

    handleTopRightButtonClick = () => {
        const oldMode = this.props.topRightButton.currentMode;
        const newMode = this.getNextMode();

        const updatedTopRightButton = {...this.props.topRightButton};
        updatedTopRightButton.currentMode = newMode;
        this.props.setTopRightButton({...updatedTopRightButton});

        if (!this.props.topRightButton[oldMode]) {
            CatalogService.loadMainVehicleRestriction(this.props.topRightButton[newMode].path);
        }
    };

    getNextMode() {
        return this.props.topRightButton.currentMode === 'MINUS' ? 'PLUS' : 'MINUS';
    }

    getTopRightButton() {
        return (
            <TopRightButton topRightButton={this.props.topRightButton[this.getNextMode()]}
                            handleClick={this.handleTopRightButtonClick}/>
        );
    }

    filterByRestriction(restriction) {
        if (!this.props.topRightButton.hasTopRightButton) {
            return true;
        }
        return restriction.mode === this.props.topRightButton.currentMode;
    }

    getMainVehicleRestrictions() {
        if (!this.props.restrictions.length) {
            return [];
        } else {
            return this.props.restrictions.filter(restriction => {
                return restriction.parentId === this.props.currentBrand.id && this.filterByRestriction(restriction);
            });
        }
    }

    render() {
        return (
            <Container paddingX limitHeight>
                <ModelSubheader
                    title={CatmetaService.getPageTitle(this.props.currentBrand?.nextWidgetKey)}
                    onBackClick={this.onBackClick()} 
                    rightElement={this.getTopRightButton()}
                />
                <DynamicRestrictionTable
                    restrictions={this.getMainVehicleRestrictions()}
                    widgetKey={this.props.currentBrand?.nextWidgetKey}
                    onItemClick={this.onItemClick}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    restrictions: state.catalog.restrictions,
    currentBrand: state.catalog.currentBrand,
    topRightButton: state.catalog.topRightButton,
    topRightButtonMode: state.catalog.topRightButtonMode,
    lastBrandChange: state.catalog.lastBrandChange,
    currentVehicle: state.catalog.currentVehicle,
});

const mapDispatchToProps = dispatch => ({
    updateCurrentVehicleRestriction: (payload) => dispatch(updateCurrentVehicleRestriction(payload)),
    updateCurrentVehicle: (payload) => dispatch(updateCurrentVehicle(payload)),
    setTopRightButton: (payload) => dispatch(setTopRightButton(payload)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withCatalog,
)
(MainVehicleRestriction);
