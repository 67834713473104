import React, {Fragment} from 'react';
import {compose} from 'redux';
import SmallIconButton from '../../base/smallIconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';

class TopRightButton extends React.Component {

    getIcon() {
        if (!this.props.topRightButton) {
            return null;
        }

        if (this.props.topRightButton.type === 'MINUS') {
            return (<RemoveCircleOutlineOutlinedIcon/>);

        } else {
            return (<AddCircleOutlineIcon/>);
        }
    }

    getContent() {
        if (this.props.handleClick && this.props.topRightButton) {
            return (
                <SmallIconButton color="inherit" onClick={this.props.handleClick}>
                    <Tooltip title={this.props.topRightButton.hint}>
                        {this.getIcon()}
                    </Tooltip>
                </SmallIconButton>);
        } else {
            return null;
        }
    }

    render() {
        return (
            <Fragment>
                {this.getContent()}
            </Fragment>
        );
    }
}

export default compose()(TopRightButton);