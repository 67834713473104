 import {
    UPDATE_SEARCH_QUERY,
    ADD_ITEM_TO_SELECT_HISTORY,
    RESET_ALL_SEARCH,
    UPDATE_SEARCH_DIALOG_STATE,
    SET_PART_SEARCH_RESULTS,
    ADD_PART_SEARCH_RESULTS, RESET_PART_SEARCH_RESULTS
} from './searchTypes';

export const updateSearchDialogState = (value) => ({
    type: UPDATE_SEARCH_DIALOG_STATE,
    value: value,
});
export const updateSearchQuery = (value) => ({
    type: UPDATE_SEARCH_QUERY,
    value: value,
});
export const addItemToSelectHistory = (value) => ({
    type: ADD_ITEM_TO_SELECT_HISTORY,
    value: value,
});
export const resetAllSearch = () => {
    return {type: RESET_ALL_SEARCH}
};

 export const setPartSearchResults = (value) => ({
     type: SET_PART_SEARCH_RESULTS,
     value: value,
 });

 export const addPartSearchResults = (value) => ({
     type: ADD_PART_SEARCH_RESULTS,
     value: value,
 });

 export const resetPartSearchResults = () => {
     return {type: RESET_PART_SEARCH_RESULTS}
 };