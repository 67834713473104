import React from 'react';
import ModelSubheader from '../restriction/restrictionSubheader';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'split.js';
import { withStyles } from '@mui/styles';
import { compose } from 'redux';
import PartOverviewTable from './partOverviewTable';
import {
    resetCurrentPartImages,
    resetCurrentPartOverview,
    updateCurrentHotspot,
    updateCurrentPartDetailInformation,
    updateCurrentPartInformation,
    updateCurrentPartOverview, updateCurrentPartOverviewState, updateCurrentPartRestriction,
    updateCurrentTopHeight,
    updateSelectedPart,
} from '../../../redux/catalog/catalogAction';
import ImageViewer from './imageViewer';
import catmetaApi from '../../../api/catmetaApi';
import Container from '../../base/container';
import withCatalog from '../../../service/withCatalog';
import CatalogService from '../../../service/catalogService';
import SplitPane from '../../layout/SplitPane';
import withSnackbar from '../../../service/withSnackbar';
import { removeLastPartHistory } from '../../../redux/browserHistory/browserHistoryAction';

const useStyles = (theme) => ({
    split: {
        height: '81vh',
    },
    gutter: {
        position: 'relative',
        boxShadow: 'rgb(224, 224, 224) 0 -4px 5px 0',
        width: '100%',
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        height: '5px',
        background: theme.palette.white,
    },
    splitIndicator: {
        height: '2px',
        background: theme.palette.black,
        width: '26px',
        display: 'block',
        position: 'absolute',
        zIndex: 20,
        left: '50%',
        transform: 'translateX(-50%)',
        marginTop: '5px',
    },
    imageViewer: {
        height: '100%',
        background: theme.palette.white,
    },
});

class PartOverview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPart: {},
            selectedImage: null,
            currentDetailLink: ''
        };
        this.onAnchorChange = this.onAnchorChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleOnBackClick = this.handleOnBackClick.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
    }

    componentDidMount() {
        this.loadPartOverview();
        this.props.resetCurrentPartImages();
        this.onPartDoubleClick = this.onPartDoubleClick.bind(this);
        this.onPartClick = this.onPartClick.bind(this);
        this.onHotspotClick = this.onHotspotClick.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentPartOverview.images && this.props.currentPartOverview.images &&
            prevProps.currentPartOverview.images.length !== this.props.currentPartOverview.images.length) {
            this.setState({ selectedImage: this.props.currentPartOverview.images[0] })
        }
    }

    componentWillUnmount() {
        this.props.resetCurrentPartOverview();
    }

    loadPartOverview() {
        if (!this.props.currentPart.link) {
            console.error('Error happened on loadPartOverview, no link to get overview data: ', this.props.currentPart);
        }
        catmetaApi.getPartOverview(this.props.currentPart.link)
            .then((partOverviewBaseData) => {
                if (partOverviewBaseData.image) {
                    this.setState({ selectedImage: partOverviewBaseData.images[0] })
                }
                this.props.updateCurrentPartOverview(partOverviewBaseData);
                if (this.props.selectedPart && partOverviewBaseData) {
                    this.preSetHotspot(partOverviewBaseData);
                }
            });
    }

    preSetHotspot(partOverviewBaseData) {
        const selectedPart = partOverviewBaseData.records?.find(part => {
            return part.id === this.props.selectedPart.id;
        },
        );

        if (selectedPart && selectedPart.hotspotId) {
            this.props.updateSelectedPart(selectedPart);
        }
    }

    onHotspotClick(hotspotId) {
        let selectedPart;

        selectedPart = this.props.currentPartOverview.records.find(record => !record.unavailable && record.hotspotId === hotspotId);

        this.props.updateSelectedPart(selectedPart);
    }

    onPartClick(part) {
        this.props.updateSelectedPart(part);
    }

    onPartDoubleClick(part) {
        if (!part.link || !part.link.path) {
            return;
        }

        if (part.link.wid === 'bomlistDetail') {
            if (this.state.currentDetailLink === part.link.path) { // already loaded
                this.props.updateCurrentPartOverviewState('details');
            } else {
                catmetaApi.getPartInformation(part.link.path).then(partDetails => {
                    partDetails.title = part.link.wid;

                    this.props.updateCurrentPartDetailInformation(partDetails);
                    this.setState({ currentDetailLink: part.link.path });
                    this.props.updateCurrentPartOverviewState('details')
                });
            }
        } else {
            catmetaApi.getPartInformation(part.link.path).then(partDetails => {
                partDetails.title = part.link.wid;

                this.props.updateCurrentPartInformation(partDetails);
                this.props.routeToPartInformation();
                this.props.updateSelectedPart(part);
            });
        }
    }

    onBackClick() {
        if (!this.props.currentPart.parentId) {
            this.props.routeToModel(this.props.currentBrand.id);
        } else {
            // special case: when suspensions are being loaded from partOverview,
            // currentPartRestriction is set to feathers
            // here we explicitly change the currentPartRestriction to whatever was its parent
            // subPartRestriction.js can't do this because it's only listening to historyChange, which is not being
            // fired if the view has not been loaded yet
            const restrictionByParent = CatalogService.getRestrictionById(this.props.currentPart.parentId);

            this.props.updateCurrentPartRestriction({ ...{}, ...restrictionByParent });
            this.props.routeToSubModel(this.props.currentBrand.parentId);
            this.props.routeToSubModel(CatalogService.getParentRestriction(this.props.currentPart.parentId).id);
        }
    };

    getBackLinkCallback() {
        const { history } = this.props;

        if (this.isPartFromSearchResult()) {
            this.props.removeLastPartHistory();
            return history.goBack();
        } else {
            return this.onBackClick();
        }
    }

    isPartFromSearchResult() {
        return !this.props.currentPart.parentId;
    }

    onAnchorChange(anchor) {
        this.props.updateCurrentTopHeight(anchor);
    }

    getImageName() {
        if (this.props.currentPartOverview.images && this.props.currentPartOverview.images.length && this.props.currentPartOverview.images[0]) {
            return this.props.currentPartOverview.images[0].name;
        } else {
            return '';
        }
    }

    getImageNames() {
        if (this.props.currentPartOverview.images && this.props.currentPartOverview.images.length > 0) {
            return this.props.currentPartOverview.images.map(image => image.name)
        } else {
            return [];
        }
    }

    handleImageChange(ev) {
        let newImageName = ev.target.value;
        let newImage = this.props.currentPartOverview.images.find(image => image.name === newImageName);
        this.setState({ selectedImage: newImage })
    }

    handleOnBackClick() {
        if (this.props.currentPartOverviewState === 'details') {
            this.props.updateCurrentPartOverviewState('overview')
        } else {
            this.getBackLinkCallback();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <Container paddingX>
                <ModelSubheader
                    title={this.getImageName()}
                    dropdown={this.getImageNames()}
                    handleSelectChange={this.handleImageChange}
                    selectedValue={this.state.selectedImage && this.props.currentPartOverview.images && this.props.currentPartOverview.images.length > 1 ? this.state.selectedImage.name : null}
                    onBackClick={this.handleOnBackClick}
                />
                <SplitPane onAnchorChange={this.onAnchorChange} selectedPart={this.props.selectedPart}>
                    <div className={classes.imageViewer}>
                        {this.state.selectedImage &&
                            <ImageViewer
                                key={this.state.selectedImage.id} image={this.state.selectedImage}
                                selectedPart={this.props.selectedPart}
                                records={this.props.currentPartOverview.records}
                                onHotspotClick={this.onHotspotClick}
                                onHotSpotDoubleClick={this.onPartDoubleClick}
                            />
                        }
                    </div>
                    <PartOverviewTable
                        selectedPart={this.props.selectedPart} onPartClick={this.onPartClick}
                        onPartDoubleClick={this.onPartDoubleClick}
                        widgetKey={'bomlist'}
                        records={this.props.currentPartOverviewState === 'details' ?
                            this.props.currentPartDetailOverview.records
                            : this.props.currentPartOverview.records}
                    />
                </SplitPane>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    currentPart: state.catalog.currentPart,
    currentPartOverview: state.catalog.currentPartOverview,
    currentPartImages: state.catalog.currentPartImages,
    currentPartRestriction: state.catalog.currentPartRestriction,
    currentPartDetailOverview: state.catalog.currentPartDetailOverview,
    selectedPart: state.catalog.selectedPart,
    currentPartOverviewState: state.catalog.currentPartOverviewState,
});

const mapDispatchToProps = dispatch => ({
    updateCurrentPartOverview: (payload) => dispatch(updateCurrentPartOverview(payload)),
    resetCurrentPartOverview: (payload) => dispatch(resetCurrentPartOverview(payload)),
    resetCurrentPartImages: (payload) => dispatch(resetCurrentPartImages(payload)),
    updateCurrentPartInformation: (payload) => dispatch(updateCurrentPartInformation(payload)),
    updateCurrentPartDetailInformation: (payload) => dispatch(updateCurrentPartDetailInformation(payload)),
    updateCurrentTopHeight: (payload) => dispatch(updateCurrentTopHeight(payload)),
    updateCurrentHotspot: (payload) => dispatch(updateCurrentHotspot(payload)),
    updateSelectedPart: (payload) => dispatch(updateSelectedPart(payload)),
    updateCurrentPartOverviewState: (payload) => dispatch(updateCurrentPartOverviewState(payload)),
    updateCurrentPartRestriction: (payload) => dispatch(updateCurrentPartRestriction(payload)),
    removeLastPartHistory: () => dispatch(removeLastPartHistory()),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withCatalog,
    withSnackbar,
    withStyles(useStyles),
)
    (withRouter(PartOverview));
