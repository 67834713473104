import React from 'react';
import {compose} from 'redux';
import Container from '../../../base/container';
import ModelSubheader from '../../restriction/restrictionSubheader';
import SplitPane from '../../../layout/SplitPane';
import {connect} from 'react-redux';
import CatmetaService from '../../../../service/catmetaService';
import SuspensionResults from './suspensionResults';
import CatalogService from '../../../../service/catalogService';
import SuspensionFilter from './suspensionFilter';
import {withRouter} from 'react-router-dom';
import withCatalog from '../../../../service/withCatalog';

class Suspension extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            filterQuery: '',
        };

        this.onBackClick = this.onBackClick.bind(this);
    }

    componentDidMount() {
        this.setFilterQuery();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.restrictions.length !== this.props.restrictions.length){
            this.setFilterQuery();
        }
    }

    onAnchorChange = () => {};

    onBackClick() {
        this.props.partHistoryGoBack();
    };

    handleSelectionChange = () => {
        this.setFilterQuery();
    }

    setFilterQuery() {
        const restrictions = CatalogService.getRestrictionsByParent(this.props.restrictions, this.props.currentPartRestriction?.id);
        const selectedRestrictionIds = restrictions.filter(r => r.isSelected).map(r => r.originalId);
        this.setState({filterQuery: (selectedRestrictionIds.length ? selectedRestrictionIds.join('~') : '')});
    }

    render() {
        return (
            <Container paddingX>
                <ModelSubheader
                    title={CatmetaService.getPageTitle(this.props.currentPartRestriction?.nextWidgetKey)}
                    onBackClick={this.onBackClick}
                />

                <SplitPane
                    onAnchorChange={this.onAnchorChange}
                    scrollBehaviour="auto"
                    selectedPart={null}>
                    <SuspensionFilter onSelectionChange={this.handleSelectionChange}/>
                    <SuspensionResults filterQuery={this.state.filterQuery} goBack={this.onBackClick} />
                </SplitPane>
            </Container>
        );
    }
}


const mapStateToProps = state => ({
    restrictions: state.catalog.restrictions,
    currentPartRestriction: state.catalog.currentPartRestriction,
});


const mapDispatchToProps = () => ({});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
    withCatalog,
)(Suspension);