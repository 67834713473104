import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@mui/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import PartService from '../../../service/partService';
import FormattedText from '../../text/formattedText';
import PartOverviewTableCell from './partOverviewTableCell';

const useStyles = theme => ({
    tableContainer: {
        height: '100%',
    },
    tableCellReducedWidth: {
        paddingRight: '8px',
    },
    tableCell: {
        verticalAlign: 'top',
    },
    isClickable: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    },
    unavailable: {
        color: theme.palette.grey300,
    },
});


class PartOverviewTable extends React.Component {

    constructor(props) {
        super(props);
        this.rowRefs = [];
        this.refTableHead = React.createRef();

        this.onItemClick = this.onItemClick.bind(this);
    }

    componentDidMount() {
        if (this.props.selectedPart) {
            this.scrollToItem(this.props.selectedPart);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedPart !== this.props.selectedPart || prevProps.records?.length !== this.props.records?.length) { // active hotspotId or records changed
            this.scrollToItem(this.props.selectedPart);
        }
    }

    scrollToItem(selectedPart) {
        let matchingRow = selectedPart && this.rowRefs.find(obj => {
            if(selectedPart && selectedPart.values && obj.partno === selectedPart.values.partno &&
                obj.hotspotId === selectedPart.hotspotId) {
                return true;
            } else {
                return selectedPart && selectedPart.values && obj.partno === selectedPart.values.partno;
            }

        });
        if (!matchingRow) {
            return;
        }

        const rowScrollTo = document.getElementById(`${matchingRow.rowRefs.id}`);
        if (!rowScrollTo) {
            return;
        }

        let scrollParent = getScrollParent(rowScrollTo);
        this.rowRefs.some(ref => {
            return ref.rowRefs.id === matchingRow.rowRefs.id;
        });

        // scrolls all to top
        rowScrollTo.scrollIntoView();
        // correct fixed header
        if (matchingRow.rowRefs.id !== this.rowRefs[this.rowRefs.length - 1].rowRefs.id) {
            scrollParent.scrollBy(0, -this.refTableHead.current.offsetHeight);
        }
    }

    onItemClick(record) {
        if (record.hotspotId) {
            this.props.onPartClick(record);
        }
    };

    onItemDoubleClick = (record) => () => {
        this.props.onPartDoubleClick(record);
    };

    onTriggerClick = (record) => () => {
        this.props.onPartDoubleClick(record);
    };

    isSelected(record) {
        return this.props.selectedPart && this.props.selectedPart.values &&
            this.props.selectedPart.values.partno
            && this.props.selectedPart.values.partno === record.values.partno
            && this.props.selectedPart.hotspotId === record.hotspotId
            && this.props.selectedPart.id === record.id;
    }

    getTableColumns() {
        const { widgetKey } = this.props;

        if (!widgetKey || !this.props.listDefinitions[widgetKey]) {
            return [];
        }

        return this.props.listDefinitions[widgetKey].elements;
    }

    getRowTrigger(restriction) {
        const {classes} = this.props;
        const hasIcon = PartService.getTrimmedPartNo(restriction).length > 0 && restriction.link && restriction.link.path;

        return (
            <TableCell
                className={classes.isClickable}
                onClick={this.onTriggerClick(restriction)}>
                {hasIcon ? <InfoIcon/> : null}
            </TableCell>
        );
    }

    getRowCells(record) {
        return this.getTableColumns().map((column, i) => (
            <PartOverviewTableCell 
                key={i} 
                record={record} 
                column={column}
                onItemDoubleClick={this.onItemDoubleClick}
                onItemClick={this.onItemClick}
            />
        ));
    }

    getTableRow(record, index) {
        const hotspotId = record.hotspotId ? record.hotspotId : null;
        const partno = record.values ? record.values.partno : null;

        return (
            <TableRow key={'row_' + record.id + '_' + index} id={`row-${index}`}
                      selected={this.isSelected(record)}
                      ref={rowRefs => this.rowRefs.push({rowRefs, hotspotId, partno})}>
                {this.getRowTrigger(record)}
                {this.getRowCells(record, index)}
            </TableRow>
        );
    }

    getTableHeaderCell() {
        return (
            this.getTableColumns().map((column, i) => {
                return <TableCell key={i}>{column.title}</TableCell>;
            })
        );
    }

    getTableRowHeadline(restriction, index) {
        const restrictionValues = restriction.values;
        let quantityColRows = 0;
        const headerCells = this.getTableColumns()?.map((column) => {
            if (column.id === 'pos' || column.id === 'partno') {
                quantityColRows++;
                return null;
            }
            return {id: column.id, value: restrictionValues[column.id]};
        }).filter(i => i);

        if (headerCells) {
            return (
                <TableRow key={'headline_' + index + restriction.id}>
                    <TableCell/>
                    {headerCells.map((column, i) => {
                        if (column.value) {
                            let colSpan = 0;
                            
                            if (column.id === 'description') {
                                colSpan = quantityColRows + 1;
                            }

                            return (
                                <TableCell colSpan={colSpan} key={i}>
                                    <FormattedText value={column.value}/>
                                </TableCell>
                            );
                        } else {
                            return <TableCell key={i} />;
                        }
                    })}
                </TableRow>
            );
        } else {
            return null;
        }
    }

    getTableRows() {
        const {records} = this.props;

        return (
            records && records.map((restriction, i) => {
                if (restriction.characteristic) {
                    return this.getTableRowHeadline(restriction, i);
                } else {
                    return this.getTableRow(restriction, i);
                }
            }).filter(i => i)
        );
    }

    render() {
        return (
            <TableContainer component={Paper} sx={{ height: '100%' }}>
                <Table aria-label="Part Overview" stickyHeader={true} size="small">
                    <TableHead ref={this.refTableHead}>
                        <TableRow>
                            <TableCell/>
                            {this.getTableHeaderCell()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.getTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

function getScrollParent(element, includeHidden) {
    let style = getComputedStyle(element);
    let excludeStaticParent = style.position === 'absolute';
    let overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position === 'fixed') return document.body;
    for (let parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === 'static') {
            continue;
        }
        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
    }

    return document.body;
}

const mapStateToProps = state => ({
    definitions: state.catalog.currentBrand.definitions.bomlist.elements,
    listDefinitions: state.catalog.currentBrand.listDefinitions,
});

export default compose(
    connect(
        mapStateToProps,
    ),
    withStyles(useStyles),
)(PartOverviewTable);