import {
    UPDATE_LOG_IN_STATE,
    UPDATE_ERROR_STATE,
    SESSION_OVER_TAKE_SHOWN,
    SET_USERNAME
} from './authTypes';

const initialState = {
    loggedIn: false,
    error: false,
    devLogin: false,
    sessionOverTakeShown: false,
    username: null
};

export default function authReducer(state = initialState, action) {

    switch (action.type) {
        case UPDATE_LOG_IN_STATE:
            return { ...state, loggedIn: action.value };
        case UPDATE_ERROR_STATE:
            return { ...state, error: action.value };
        case SESSION_OVER_TAKE_SHOWN:
            return { ...state, sessionOverTakeShown: action.value };
        case SET_USERNAME:
            return { ...state, username: action.value };
        default:
            return state;
    }
}
