import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {compose} from 'redux';
import {Box} from '@mui/material';
import CartService from '../../../service/cartService';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


class AddressSelect extends React.Component {

    render() {
        const {addresses, selectedAddressId, onSelect, readonly} = this.props;

        return (
            <Box border={1} borderColor="border.main" borderTop={0} borderLeft={0} borderRight={0}>

                <List dense>
                    {
                        addresses.map((address) => {
                            return (
                                <ListItem
                                    key={address.addressId}
                                    button
                                    onClick={() => onSelect(address.addressId)}
                                    disabled={readonly}
                                    divider={false}>

                                    {
                                        address.addressId === selectedAddressId && (
                                            <ListItemIcon>
                                                <CheckCircleIcon/>
                                            </ListItemIcon>
                                        )
                                    }

                                    <ListItemText
                                        primary={CartService.getAddressString(address)}
                                        inset={address.addressId !== selectedAddressId}
                                    />

                                </ListItem>
                            );
                        })
                    }
                </List>

            </Box>
        );
    }

}


export default compose()(AddressSelect);

