import React from 'react';
import Container from '../base/container';
import {withTranslation} from 'react-i18next';
import ModelSubheader from "../catalog/restriction/restrictionSubheader";
import {compose} from "redux";
import withMenu from "../../service/withMenu";
import DealerList from "./dealerList";
import {addCatalogDealer, updateCatalogDealer} from "../../redux/dealerManagement/dealerManagementAction";
import {connect} from "react-redux";
import dealerApi from "../../api/dealerApi";
import LoadingBackdrop from "../base/loadingBackdrop";
import {loadingAreas} from "../../config/loadingAreas";

class DealerManagement extends React.Component {

    constructor(props) {
        super(props);

        this.onDealerClick = this.onDealerClick.bind(this)
        this.onBackClick = this.onBackClick.bind(this)
    }

    componentDidMount() {
        this.props.catalogs.forEach((catalog) => {
            dealerApi.getCatalogDealer(catalog.id).then(catalogDealer => {
                if (catalogDealer.dealerId) {
                    dealerApi.getDealer(catalogDealer.dealerId)
                        .then((dealer) => {
                            this.props.addCatalogDealer({...catalog, dealer})
                        });
                } else {
                    this.props.addCatalogDealer({...catalog, dealer: null})
                }
            });
        })
    }

    onBackClick() {
        this.props.routeToRootComponent();
    }

    onDealerClick = (dealer) => {
        if(dealer.dealer) {
            this.props.routeToDealer(dealer.id);
        } else {
            this.props.routeToLastDealers(dealer.id);
        }
    }

    render() {
        const {t} = this.props;

        return (
            <Container disableGutters limitHeight>
                <ModelSubheader
                    title={t('change_dealer_management_title')}
                    onBackClick={this.onBackClick}
                />
                <DealerList catalogDealers={this.props.catalogDealers} onDealerClick={this.onDealerClick}
                            sortBy={(a, b) => a.id.localeCompare(b.id)}/>

                <LoadingBackdrop area={loadingAreas.dealerManagement}/>
            </Container>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    updateCatalogDealer: (payload) => dispatch(updateCatalogDealer(payload)),
    addCatalogDealer: (payload) => dispatch(addCatalogDealer(payload)),
});

const mapStateToProps = state => ({
    catalogDealers: state.dealerManagement.catalogDealers,
    catalogs: state.catalog.catalogs,
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withMenu,
)(DealerManagement);
