import axios from 'axios';
import { store } from '../redux/store';

export default class VinLensApi {
    static sendImage(url, formData) {
        return new Promise((resolve, reject) => {
            axios.post(url, formData, {
                headers: {
                    'Authorization': `Bearer ${store.getState().global.token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }).then(({ data }) => {
                if (data.status.toLowerCase() === 'success' && data.vin) {
                    resolve(data);
                }

                reject(data);
            }).catch(response => reject(response));
        });
    }
}