export const SET_CART_ITEMS_COUNT = 'SET_CART_ITEMS_COUNT';
export const SET_STEP = 'SET_STEP';
export const SET_CART = 'SET_CART';
export const SET_CART_CONTEXT = 'SET_CART_CONTEXT';
export const SET_CART_SELECTION_DIALOG_STATE = 'SET_CART_SELECTION_DIALOG_STATE';
export const SET_CART_RENAME_DIALOG_STATE = 'SET_CART_RENAME_DIALOG_STATE';
export const SET_DEALER = 'SET_DEALER';
export const CART_SUBMISSION = 'CART_SUBMISSION';
export const RESET_ALL_CART = 'RESET_ALL_CART';
export const SET_CART_REMOVE_DIALOG_STATE = 'SET_CART_REMOVE_DIALOG_STATE';
export const SET_DEALER_DIALOG_STATE = 'SET_DEALER_DIALOG_STATE';
export const RESET_CART_SUBMISSION = 'RESET_CART_SUBMISSION';
export const RESET_CART_ONLY = 'RESET_CART_ONLY';
export const RESET_CART_DEALER = 'RESET_CART_DEALER';
export const SET_CART_SUBMIT_CLICK = 'SET_CART_SUBMIT_CLICK';
export const ADD_CARTS_CATALOG = 'ADD_CARTS_CATALOG';
export const DELETE_CARTS_CATALOG = 'DELETE_CARTS_CATALOG';
export const UPDATE_CART = 'UPDATE_CART';

