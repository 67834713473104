import React from 'react';
import {compose} from 'redux';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Api from '../../../api/api';
import CartTitle from '../partial/cartTitle';
import DateTimeService from '../../../service/dateTimeService';

class CartSelectItem extends React.Component {
    render() {
        return (

            <Grid container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}>

                <Grid item xs="auto"  onClick={this.props.onBrandClick}>
                    <Box height="35px" width="35px">
                        <img width="100%" height="auto"
                             src={Api().defaults.baseURL +  this.props.cartLogo}
                             alt={'Brandlogo of cart ' + this.props.cartTitle} />
                    </Box>
                </Grid>

                <Grid item xs={9} sm={10} md={11} onClick={this.props.onClick}>
                    <CartTitle
                        cartTitle={this.props.cartTitle}
                        cartSubTitle={DateTimeService.commaSeparatedDateTime(this.props.cartSubTitle)}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default compose()(CartSelectItem);
