import React from 'react';
import {compose} from 'redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {withTranslation} from 'react-i18next';
import DialogTitleWithClose from '../../base/dialogTitleWithClose';

class CartItemDeleteDialog extends React.Component {

    render() {

        const {t} = this.props;

        return (
            <Dialog open={this.props.open} onClose={this.props.handleClose}>
                <DialogTitleWithClose onClose={this.props.handleClose}>
                    {t('cart_item_delete_dialog_title')}
                </DialogTitleWithClose>
                <DialogContent>
                    <DialogContentText>
                        {t('cart_item_delete_dialog_text', {itemName: this.props.itemName})}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleConfirm} variant="text" color="default">
                        {t('cart_item_delete_dialog_confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default compose(
    withTranslation(),
)(CartItemDeleteDialog);
