import React from 'react';
import {withTranslation} from 'react-i18next';
import {Box} from '@mui/material';
import SearchTrigger from '../../search/searchTrigger';
import {compose} from 'redux';
import BrandSelectionTrigger from '../../brand/brandSelectionTrigger';
import theme from '../../../theme/theme';

class VehicleHeader extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: `calc(100% + ${theme.spacing(2)})`,
                mr: -2,
            }}>
                <Box sx={{
                    flexShrink: 0,
                    width: 'auto', 
                    height: theme.spacing(8)
                }}>
                    <BrandSelectionTrigger/>
                </Box>

                <SearchTrigger
                    label={t('direct_entry')} 
                    styles={{ 
                        [theme.breakpoints.down('sm')]: {
                            maxWidth: theme.spacing(32),
                            flexGrow: 1,
                            ml: 4,
                        },
                        [theme.breakpoints.up('sm')]: {
                            width: '50%'
                        }
                    }}
                />
            </Box>
        );
    }
}

export default compose(
    withTranslation(),
)
(VehicleHeader);
