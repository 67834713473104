import React, {Fragment} from 'react';
import {compose} from 'redux';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import {Box} from '@mui/material';
import TextField from '@mui/material/TextField';
import {withTranslation} from 'react-i18next';
import withCartApi from '../../../api/cartApi';

class ListComment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            listOpen: false,
            cartComment: this.props.comment,
        };
    }

    handleListToggleClick = () => {
        this.setState(state => ({listOpen: !state.listOpen}));
    };

    setComment = () => {
        this.props.setCartComment(this.props.cartId, this.state.cartComment).then(() => {
            this.props.updateCurrentCart();
        });
    };

    getTruncatedComment(str, n = 125, useWordBoundary = true) {
        if (!str || str.length <= n) {
            return str;
        }
        const subString = str.substr(0, n - 1);
        return (useWordBoundary
            ? subString.substr(0, subString.lastIndexOf(' '))
            : subString) + '...';
    }

    render() {

        const {t, readonly} = this.props;
        const {listOpen} = this.state;

        return (
            <Fragment>
                <ListItem button onClick={this.handleListToggleClick}>
                    <ListItemText
                        primaryTypographyProps={{variant: 'h6'}}
                        primary={t('cart_comment_title')}
                        secondary={this.getTruncatedComment(this.props.comment)}
                    />
                    {!readonly ? listOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/> : <Fragment/>}
                </ListItem>

                {!readonly && (
                    <Collapse in={listOpen}>
                        <Box border={1} borderColor="border.main" borderTop={0} borderLeft={0} borderRight={0}>

                            <ListItem divider={false}>

                                <TextField
                                    fullWidth
                                    multiline
                                    rows={3}
                                    size="small"
                                    inputProps={{maxLength: 2048}}
                                    label={t('cart_comment_input_label')}
                                    value={this.state.cartComment}
                                    onChange={
                                        (e) => this.setState({
                                            cartComment: e.target.value,
                                        })
                                    }
                                    onBlur={this.setComment}
                                />

                            </ListItem>
                        </Box>

                    </Collapse>
                )}

            </Fragment>
        );
    }
}

export default compose(
    withTranslation(),
    withCartApi,
)(ListComment);
