import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import { Box } from '@mui/material';

import i18n from '../i18n';
import AppHeader from './layout/appHeader';
import AppFooter from './layout/appFooter';
import AppBody from './layout/appBody';

import Login from './pages/login';
import LoadingBackdrop from './base/loadingBackdrop';
import { loadingAreas } from '../config/loadingAreas';
import withAuthApi from '../api/authApi';
import dealerApi from '../api/dealerApi';
import CookieLifeTime from './pages/cookieLifeTime';
import BrandSelectionDialog from './brand/brandSelectionDialog';
import { groupInfo, routeLogin } from '../config/routeItems';

import PwaResolutionMessage from './base/pwaResolutionMessage';
import HistoryListener from './history/HistoryListener';
import CartDealerDialog from './cart/partial/cartDealerDialog';
import { updateNewVersionMessageState } from '../redux/global/globalAction';
import { setDealer, setDealerDialogState } from '../redux/cart/cartAction';
import PwaOldVersionMessage from './base/pwaOldVersionMessage';
import { palette } from '../theme/baseTheme';

class App extends React.Component {
    componentDidMount() {
        this.props.tryAutoLogin();
        this.checkIfNewVersionIsAvailable();

        this.cartUrl = `${window.location.origin}/pl24-shopping-cart-ui/cart-page-one`;
    }

    componentDidUpdate() {
        this.checkIfNewVersionIsAvailable();
    }

    checkIfNewVersionIsAvailable() {
        const { history } = this.props;

        if (!localStorage.getItem('lastLoadedBuildVersion')) {
            return;
        }

        this.props.updateNewVersionMessageState(history.location.pathname.indexOf(routeLogin.url()) === -1);
    }

    onDealerDialogSelected = dealerId => {
        dealerApi.getDealer(dealerId).then(dealerSummery => {
            if (dealerSummery) {
                this.props.setDealer(dealerSummery);
                this.redirectToCart();
            }
        });
    }

    redirectToCart() {
        const { cartContext, dealer } = this.props;

        if (!cartContext || !cartContext.cartId || !dealer || !dealer.dealerId) {
            return;
        }

        const params = new URLSearchParams();

        params.append('cart-id', cartContext.cartId);
        params.append('lang', i18n.language);
        params.append('dealer-id', dealer.dealerId);
        params.append('backlink', window.location.href);
        params.append('origin', 'pl24-app');

        // this.props.setCartContext(null);

        window.location.assign(`${this.cartUrl}?${params.toString()}`);
    }

    render() {
        const { loggedIn, setDealerDialogState } = this.props;

        return (
            <HistoryListener>
                <Switch>
                    {groupInfo.map((item) => (
                        <Route key={item.key} exact={item.exact} path={item.url()}>
                            {item.component}
                        </Route>
                    ))}

                    <Route exact={false} path={'/'}>
                        <Box sx={{
                            maxHeight: '100vh',
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden'
                        }}>
                            <AppHeader/>

                            {!loggedIn 
                                ? <Fragment>
                                    <Login/>
                                    <LoadingBackdrop area={loadingAreas.global}/>
                                </Fragment>
                                : <Fragment>
                                    <Box sx={{
                                        flex: '1',
                                        overflow: 'hidden',
                                        backgroundColor: palette.background.main,
                                        position: 'relative'
                                    }}>
                                        <AppBody/>
                                    </Box>
                                    <BrandSelectionDialog/>
                                    <CartDealerDialog 
                                        onDealerSelected={dealerId => this.onDealerDialogSelected(dealerId)} 
                                    />
                                    <AppFooter
                                        selectDealer={() => setDealerDialogState(true)} 
                                        redirectToCart={() => this.redirectToCart()}
                                    /> 
                                    <CookieLifeTime/>
                                    <LoadingBackdrop area={loadingAreas.global}/>
                                </Fragment>}

                            <PwaResolutionMessage />
                            <PwaOldVersionMessage />
                        </Box>
                    </Route>
                </Switch>
            </HistoryListener>
        )
    }
}

const mapStateToProps = state => ({
    loggedIn: state.auth.loggedIn,
    cartContext: state.cart.cartContext,
    dealer: state.cart.dealer
});

const mapDispatchToProps = dispatch => ({
    updateNewVersionMessageState: value => dispatch(updateNewVersionMessageState(value)),
    setDealer: value => dispatch(setDealer(value)),
    setDealerDialogState: value => dispatch(setDealerDialogState(value))
});


export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
    withAuthApi
)
(App);
