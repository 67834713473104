import React from 'react';
import {compose} from 'redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {promiseTrackerHoc} from 'react-promise-tracker';
import {withStyles} from '@mui/styles';
import {loadingAreas} from '../../config/loadingAreas';

const useStyles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    notFullScreen: {
        bottom: `calc( env(safe-area-inset-bottom, 0px) + 56px)`,
    },
});

class LoadingBackdrop extends React.Component {

    constructor(props) {
        super(props);

        this.props.config.delay = this.props.delay || 300;
        this.props.config.area = this.props.area;
    }

    isGlobalArea = () => {
        return this.props.area === loadingAreas.global;
    };

    render() {
        const {classes} = this.props;

        return (
            <Backdrop className={[classes.backdrop, (!this.isGlobalArea() ? classes.notFullScreen : '')].join(' ')}
                      open={this.props.promiseInProgress}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        );
    }
}

export default compose(
    promiseTrackerHoc,
    withStyles(useStyles),
)(LoadingBackdrop);
