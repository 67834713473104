import React, {Fragment} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import {compose} from 'redux';
import {withStyles} from '@mui/styles';
import {connect} from 'react-redux';
import FormattedText from '../../../text/formattedText';
import RestrictionService from '../../../../service/restrictionService';
import classNames from 'classnames';

const useStyles = () => ({
    tableCell: {
        verticalAlign: 'top',
        cursor: 'pointer',
        borderBottom: 'none',
    },
    headerCell: {
        '&:empty': {
            padding: '0',
        },
    },
    iconCell: {
        position: 'relative',
    },
    cellIcon: {
        position: 'absolute',
        top: 'calc(50% - 12px)'
    },
    bigHeadline: {
        fontWeight: 'bold',
    },
    smallTableCell: {
        paddingRight: '8px',
    },
});

class SuspensionPartTable extends React.Component {

    onItemClick(restriction) {
        if (RestrictionService.isRestrictionNotClickable(restriction)) {
            return null;
        }

        return this.props.onItemClick(restriction);
    }

    getRowTrigger(restriction) {
        const { classes } = this.props;

        return (
            <TableCell
                className={classNames(classes.tableCell, classes.isClickable, classes.smallTableCell, classes.iconCell)}
                onClick={this.onItemClick(restriction)}
            >
                <InfoIcon className={classes.cellIcon} />
            </TableCell>
        );
    }

    getTableColumns() {
        const { widgetKey } = this.props;

        if (!widgetKey || !this.props.listDefinitions[widgetKey]) {
            return [];
        }

        return this.props.listDefinitions[widgetKey].elements;
    }

    getRowCells(restriction) {
        const { classes } = this.props;
        const restrictionValue = restriction.values;

        return this.getTableColumns().map((column, i) => (
            restrictionValue[column.id] ? (
                <TableCell 
                    key={i} 
                    className={classes.tableCell} 
                    onClick={this.onItemClick(restriction)}
                >
                    <FormattedText value={(restrictionValue[column.id])} />
                </TableCell>
            ) : (
                <TableCell key={i}/>
            )
        ));
    }

    getTableRowHeadline(restriction, index) {
        const { classes } = this.props;
        const headerCells = this.getTableColumns()?.map(column => restriction.values[column.id]);

        if (!headerCells) {
            return null;
        }

        return (
            <TableRow key={'headline_' + index + restriction.id}>
                <TableCell />
                {headerCells.map((column, i) => (
                    column ? (
                            <TableCell className={classes.bigHeadline} key={i}>
                                <FormattedText value={column}/>
                            </TableCell>
                        ) : (
                            <TableCell key={i}/>
                        )
                ))}
            </TableRow>
        );
    }

    getTableRow(restriction, isTopRow, index) {
        const { classes } = this.props;

        return (
            <TableRow key={'row_' + restriction.id + '_' + index}>
                {isTopRow ? this.getRowTrigger(restriction) : <TableCell className={classes.tableCell}/>}
                {this.getRowCells(restriction, index)}
            </TableRow>
        );
    }

    // TODO: Refactor (┛◉Д◉)┛彡┻━┻
    getTableRows() {
        const { restrictions } = this.props;
        let lastWasHeadline = false;

        return (
            restrictions && restrictions.map((restriction, i) => {

                if (restriction.characteristic) {
                    lastWasHeadline = true;

                    return this.getTableRowHeadline(restriction, i);
                } else {
                    const tableRow = this.getTableRow(restriction, lastWasHeadline, i);

                    lastWasHeadline = false;
                    return tableRow;
                }
            }).filter(i => i)
        );
    }

    render() {
        return (
            <TableContainer component={Paper}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><Fragment/></TableCell>
                            {this.getTableColumns().map((column, i) => (
                                <TableCell key={i} className={this.props.classes.headerCell}>
                                    {column.title}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.getTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

const mapStateToProps = state => ({
    listDefinitions: state.catalog.currentBrand.listDefinitions,
});

export default compose(
    connect(
        mapStateToProps,
    ),
    withStyles(useStyles),
)(SuspensionPartTable);
