import {
    UPDATE_CATALOGS,
    UPDATE_CURRENT_BRAND,
    UPDATE_CURRENT_VEHICLE_RESTRICTION,
    UPDATE_CURRENT_PART_RESTRICTION,
    UPDATE_CURRENT_VEHICLE,
    SET_RESTRICTIONS,
    UPDATE_CURRENT_PART,
    RESET_CURRENT_BRAND,
    RESET_CURRENT_PART_RESTRICTION,
    RESET_CURRENT_VEHICLE_RESTRICTION,
    RESET_CURRENT_VEHICLE,
    RESET_CURRENT_PART,
    UPDATE_CURRENT_PART_OVERVIEW,
    RESET_CURRENT_PART_OVERVIEW,
    RESET_CURRENT_PART_IMAGES,
    RESET_ALL_CATALOG,
    UPDATE_CURRENT_PART_INFORMATION,
    UPDATE_BRAND_SELECTION_DIALOG_STATE,
    UPDATE_CURRENT_TOPHEIGHT,
    UPDATE_CURRENT_HOTSPOT,
    UPDATE_CURRENT_VEHICLE_SEGMENTS,
    UPDATE_CURRENT_VEHICLE_SEARCH,
    RESET_CURRENT_HOTSPOT,
    UPDATE_SELECTED_PART,
    UPDATE_BOM_BASE_TRIGGER_ITEMS,
    SET_TOP_RIGHT_BUTTON,
    RESET_RESTRICTIONS,
    RESET_TOP_RIGHT_BUTTON,
    SET_LAST_BRAND_CHANGE,
    REMOVE_RESTRICTIONS,
    UPDATE_CURRENT_PART_DETAIL_OVERVIEW,
    UPDATE_CURRENT_PART_OVERVIEW_STATE,
    RESET_CURRENT_PART_DETAIL_OVERVIEW,
    UPDATE_CURRENT_PART_PATH,
    REMOVE_RESTRICTIONS_BY_PARENT_ID,
    UPDATE_CURRENT_BOMBASE,
    RESET_CURRENT_BOMBASE,
    UPDATE_CURRENT_BOMBASE_PART,
    RESET_CURRENT_BOMBASE_PART,
    SET_COMMUNITY_IMAGES,
    SET_READ_ONLY_COMMUNITY_IMAGES,
    SET_OWN_IMAGES,
    UPDATE_COMMUNITY_IMAGE,
    REMOVE_COMMUNITY_IMAGE
} from './catalogTypes';
import CatalogService from '../../service/catalogService';
import {resetAllCart, resetCartSubmission} from '../cart/cartAction';
import {resetCrumbs} from '../crumbs/crumbsAction';
import {clearPartHistory} from '../browserHistory/browserHistoryAction';
import {resetPartSearchResults} from '../search/searchAction';

export const updateCatalogs = (value) => ({
    type: UPDATE_CATALOGS,
    value: value,
});

export const setRestrictions = (value) => {
    let restrictions = value;

    // remove double entries
    restrictions = restrictions.filter((v, i, a) => a.findIndex(t => (t.parentId === v.parentId && t.mode === v.mode &&
        t.link === v.link && t.originalId === v.originalId && t.values.caption === v.values.caption && t.values.abbreviation === v.values.abbreviation)) === i)

    return {
        type: SET_RESTRICTIONS,
        value: restrictions,
    }
};

export const setLastBrandChange = () => ({
    type: SET_LAST_BRAND_CHANGE,
    value: new Date().getTime(),
});

export const setTopRightButton = (value) => ({
    type: SET_TOP_RIGHT_BUTTON,
    value: value,
});

export const updateCurrentBrand = (value) => {
    return (dispatch, getState) => {
        dispatch(resetCurrentVehicle());
        dispatch(resetCurrentPart());
        dispatch(resetCurrentVehicleRestriction());
        dispatch(resetCurrentPartRestriction());
        dispatch(resetCurrentPartOverview());
        dispatch(resetCartSubmission());

        /**
         * If we don't reload the models for a brand (this checks if the current selection is selected again, to avoid
         * reload), the model list stays empty sometimes
         */
        // if (getState().catalog.currentBrand && getState().catalog.currentBrand.id !== value.id) {
        dispatch({ type: UPDATE_CURRENT_BRAND, value: value });
        dispatch(setLastBrandChange());
        dispatch(resetRestrictions());
        // }

        // Do not reset the shopping cart, as it will be reloaded and overwritten anyway. Otherwise it can come to memory leaks
    };
};

export const updateCurrentVehicleRestriction = (value) => ({
    type: UPDATE_CURRENT_VEHICLE_RESTRICTION,
    value: value,
});

export const updateCurrentPartRestriction = (value) => ({
    type: UPDATE_CURRENT_PART_RESTRICTION,
    value: value,
});

export const updateCurrentPartInformation = (value) => ({
    type: UPDATE_CURRENT_PART_INFORMATION,
    value: value,
});

export const updateCurrentPartOverviewState = (value) => ({
    type: UPDATE_CURRENT_PART_OVERVIEW_STATE,
    value: value,
});

export const updateCurrentPartDetailInformation = (value) => ({
    type: UPDATE_CURRENT_PART_DETAIL_OVERVIEW,
    value: value,
});

export const updateCurrentVehicleSegments = (value) => ({
    type: UPDATE_CURRENT_VEHICLE_SEGMENTS,
    value: value,
});

export const updateCurrentVehicleSearch = (value) => ({
    type: UPDATE_CURRENT_VEHICLE_SEARCH,
    value: value,
});

export const updateCurrentVehicle = (value) => {
    return (dispatch) => {
        dispatch(resetCurrentPart());
        dispatch(resetCurrentPartRestriction());
        dispatch(resetPartSearchResults());
        dispatch({type: UPDATE_CURRENT_VEHICLE, value: CatalogService.addUniqueIdToVehicle(value)});
    };
};

export const updateCurrentPartOverview = (value) => ({
    type: UPDATE_CURRENT_PART_OVERVIEW,
    value: value,
});

export const updateCurrentTopHeight = (value) => ({
    type: UPDATE_CURRENT_TOPHEIGHT,
    value: value,
});

export const updateCurrentHotspot = (value) => ({
    type: UPDATE_CURRENT_HOTSPOT,
    value: value,
});

export const updateCurrentPartPath = (value) => ({
    type: UPDATE_CURRENT_PART_PATH,
    value: value,
});

export const updateSelectedPart = (value) => ({
    type: UPDATE_SELECTED_PART,
    value: value,
});

export const setCommunityImages = (value) => ({
    type: SET_COMMUNITY_IMAGES,
    value: value,
});

export const setReadOnlyCommunityImages = (value) => ({
    type: SET_READ_ONLY_COMMUNITY_IMAGES,
    value: value,
});

export const setOwnImages = (value) => ({
    type: SET_OWN_IMAGES,
    value: value,
});

export const updateCommunityImage = (value) => ({
    type: UPDATE_COMMUNITY_IMAGE,
    value: value,
})

export const removeCommunityImage = (value) => ({
    type: REMOVE_COMMUNITY_IMAGE,
    value: value,
})

export const resetCurrentHotspot = (value) => ({
    type: RESET_CURRENT_HOTSPOT,
    value: value,
});

export const resetCurrentPartDetailOverview = (value) => ({
    type: RESET_CURRENT_PART_DETAIL_OVERVIEW,
    value: value,
});

export const updateCurrentPart = (value) => ({
    type: UPDATE_CURRENT_PART,
    value: value,
});

export const updateCurrentBomBase = (value) => ({
    type: UPDATE_CURRENT_BOMBASE,
    value: value,
});

export const updateCurrentBomBasePart = (value) => ({
    type: UPDATE_CURRENT_BOMBASE_PART,
    value: value,
});
export const updateBomBaseTriggerItems = (value) => ({
    type: UPDATE_BOM_BASE_TRIGGER_ITEMS,
    value: value,
});

export const resetCurrentBrand = () => {
    return (dispatch) => {
        dispatch(resetAllCart());
        dispatch(resetRestrictions());
        dispatch(resetCurrentVehicle());
        dispatch(resetCurrentPart());
        dispatch(resetPartSearchResults());
        dispatch(resetCurrentVehicleRestriction());
        dispatch(resetCurrentPartRestriction());
        dispatch(resetCurrentPartOverview());
        dispatch(resetCurrentBomBase());
        dispatch(resetCurrentBomBasePart());
        dispatch(resetCurrentBomBase());
        dispatch({type: RESET_CURRENT_BRAND});
        dispatch(setLastBrandChange());
    };
};

export const resetCurrentBrandToDefault = () => {
    return (dispatch) => {
        dispatch(resetRestrictions());
        dispatch(resetCurrentVehicle());
        dispatch(resetCurrentPart());
        dispatch(resetPartSearchResults());
        dispatch(resetCurrentVehicleRestriction());
        dispatch(resetCurrentPartRestriction());
        dispatch(resetCurrentPartOverview());
        dispatch(resetCurrentBomBase());
        dispatch(resetCurrentBomBasePart());
    };
};

export const resetRestrictions = () => {
    return (dispatch) => {
        dispatch(resetTopRightButton());
        dispatch(resetCrumbs());
        dispatch({type: RESET_RESTRICTIONS});
    };
};

export const removeRestrictions = (nextWidgetKey) => ({
    type: REMOVE_RESTRICTIONS,
    value: nextWidgetKey,
});

export const removeRestrictionsByParentId = (parentId) => ({
    type: REMOVE_RESTRICTIONS_BY_PARENT_ID,
    value: parentId,
});

export const resetTopRightButton = () => ({
    type: RESET_TOP_RIGHT_BUTTON,
});
export const resetCurrentPart = () => ({
    type: RESET_CURRENT_PART,
});
export const resetCurrentVehicle = () => {
    return (dispatch) => {
        dispatch(clearPartHistory());
        dispatch({type: RESET_CURRENT_VEHICLE});
    }
};
export const resetCurrentVehicleRestriction = () => ({
    type: RESET_CURRENT_VEHICLE_RESTRICTION,
});
export const resetCurrentPartRestriction = () => ({
    type: RESET_CURRENT_PART_RESTRICTION,
});
export const resetCurrentPartOverview = () => ({
    type: RESET_CURRENT_PART_OVERVIEW,
});
export const resetCurrentPartImages = () => ({
    type: RESET_CURRENT_PART_IMAGES,
});
export const resetCurrentBomBase = () => ({
    type: RESET_CURRENT_BOMBASE,
});
export const resetCurrentBomBasePart = () => ({
    type: RESET_CURRENT_BOMBASE_PART,
});
export const updateBrandSelectionDialogState = (value) => ({
    type: UPDATE_BRAND_SELECTION_DIALOG_STATE,
    value: value,
});
export const resetAllCatalog = () => ({
    type: RESET_ALL_CATALOG,
});