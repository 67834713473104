import React from 'react';
import {compose} from 'redux';
import CartHeaderContainer from './partial/cartHeaderContainer';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {Box, Button, Typography} from '@mui/material';
import {withStyles} from '@mui/styles';
import CartRow from './partial/cartRow';
import CartItemReadonly from './item/cartItemReadonly';
import Container from '../base/container';
import CartTotal from './partial/cartTotal';
import List from '@mui/material/List';
import ListComment from './list/listComment';
import ListDeliveryDetail from './list/listDeliveryDetail';
import ListAddressGroup from './list/listAddressGroup';
import Grid from '@mui/material/Grid';
import {
    // routeCart,
    routeBrandSelection,
    routeVehicleMainRestriction,
} from '../../config/routeItems';
import CartStepper from './partial/cartStepper';
import {resetCartSubmission} from '../../redux/cart/cartAction';
import {resetCurrentBrand} from '../../redux/catalog/catalogAction';

const useStyles = () => ({
    overflowAuto: {
        overflow: 'auto'
    }
})

class StepSuccess extends React.Component {
    handleButtonClick = (url, reset = false) => {
        const {history} = this.props;

        if (reset) {
            this.props.resetCurrentBrand();
        }

        this.props.resetCartSubmission();
        history.push(url);
    };

    showDeliveryDetail(cart) {
        return cart.dealerProperties && (cart.dealerProperties.orderTypes || cart.dealerProperties.dispatchTypes);
    }

    render() {
        const { t, cartSubmission, classes } = this.props;
        const { cart, submitMsg } = cartSubmission;

        return (
            <Box minHeight="100%" display="flex" flexDirection="column" bgcolor="white">
                <CartStepper activeStep={3}/>
                <CartHeaderContainer>
                    <Typography variant="h6">
                        {submitMsg}
                    </Typography>
                    <Typography variant="body2">
                        {cart.cartName}
                    </Typography>
                </CartHeaderContainer>

                <Box className={classes.overflowAuto}>
                    <Container>
                        {cart && cart.items.map((item) => (
                            <CartRow key={item.cartItemId}>
                                <CartItemReadonly item={item}/>
                            </CartRow>
                        ))}
                        <Box my={2}>
                            <CartTotal cart={cart}/>
                        </Box>
                    </Container>
                    <Container disableGutters>
                        <List component="nav">
                            {cart.comment && (
                                <ListComment 
                                    readonly 
                                    comment={cart.comment}
                                />
                            )}

                            {this.showDeliveryDetail(cart) && 
                                <ListDeliveryDetail
                                    cartId={cart.cartId}
                                    dealerProperties={cart.dealerProperties}
                                    currentOrderTypeId={cart.orderTypeId}
                                    currentDispatchTypeId={cart.dispatchTypeId}
                                    readonly
                                />}

                            <ListAddressGroup 
                                cart={cart} 
                                shippingAddress={cart.shippingAddress}
                                billingAddress={cart.billingAddress} 
                                readonly
                            />
                        </List>
                    </Container>
                    <Container paddingY>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Button 
                                    variant="outlined"
                                    onClick={() => this.handleButtonClick(routeBrandSelection.url(), true)
                                }>
                                    {t('cart_success_to_brand_overview')}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button 
                                    variant="outlined"
                                    onClick={() => this.handleButtonClick(routeVehicleMainRestriction.url())
                                }>
                                    {t('cart_success_to_catalog', {catalogName: cart.catalogProperties.brandName})}
                                </Button>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Button onClick={this.handleButtonClick(routeCart.url())}>
                                    {t('cart_success_to_cart_overview')}
                                </Button>
                            </Grid> */}
                        </Grid>
                    </Container>
                </Box>
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    cartSubmission: state.cart.cartSubmission,
    currentBrand: state.catalog.currentBrand,
});

const mapDispatchToProps = {
    resetCartSubmission,
    resetCurrentBrand,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withRouter,
    withStyles(useStyles),
)
(StepSuccess);
