import CatmetaService from './catmetaService';

class RestrictionService {

    static getRestrictionsFromArray(restrictionData, parentId) {
        if(!restrictionData){
            return [];
        }

        let records = restrictionData.records;
        if (!Array.isArray(records)) {
            return [];
        }

        // bomBse special-cases e.g. chemical restrictions, federn
        // see also: "catmetaService.js --> convertWidget()
        if (restrictionData.bomBaseLink) {
            const widget = CatmetaService.getPageByNextWidgetKey(restrictionData.bomBaseLink.wid);

            if (widget && widget.type === 'filterbom') {
                // todo remove manuel handling from bomBaseWidget-List --> is given by catmeta-page list
                CatmetaService.addBomBaseWidget(restrictionData.bomBaseLink.wid);

                // todo rename functions from BomBase to Filterbom
                records = RestrictionService.handleBomBaseSpecialCase(restrictionData, records);
            }
        }

        return records.map(object => {
            return RestrictionService.getRestrictionsFromObject(object, parentId);
        }).filter(i => i);
    }

    static getRestrictionsFromObject(restriction, parentId = '') {
        let id = null;
        if (!restriction) {
            return null;
        }

        if(restriction.id) {
            id = restriction.id + '-'
        } else {
            id = 'record-';
        }

        id += Date.now().toString().replace('/', '--');

        return {
            // ids need to be unique over all restrictions
            // e.g. 010A occurs in several restrictions but has a different URL stored in it with the previous restriction ids
            id: id,
            originalId: restriction.id,
            parentId: parentId,
            nextWidgetKey: restriction.link ? restriction.link.wid : (restriction.wid ? restriction.wid : ''),
            link: restriction.link ? restriction.link.path : (restriction.path ? restriction.path : ''),
            elink: restriction.elink ? restriction.elink.path : '',
            values: restriction.values,
            remark: restriction.remark,
            isSelected: restriction.presel,
            validity: restriction.validity,
            unavailable: restriction.unavailable ? restriction.unavailable : false,
            unclickable: RestrictionService.isRestrictionNotClickable(restriction),
            characteristic: RestrictionService.isRestrictionHeadline(restriction) ? restriction.characteristic : null,
        };
    }

    static handleBomBaseSpecialCase(restrictionData, records) {
        return records.map(record => {
            // headline
            if (RestrictionService.isRestrictionHeadline(record)) {
                return record;
            }

            // unavailable records in bomBase cases are not clickable
            record.unclickable = record.unavailable;

            record.link = {
                path: restrictionData.bomBaseLink.path + record.id,
                wid: restrictionData.bomBaseLink.wid,
            };
            record.nextWidgetKey = restrictionData.bomBaseLink.wid;
            return record;
        });
    }

    static isRestrictionHeadline(restriction) {
        return restriction.characteristic === CatmetaService.RESTRICTION_HEADLINE_KEY;
    }

    static isRestrictionNotClickable(restriction) {
        return !restriction
            || (!restriction.elink && !restriction.link)
            || restriction.unclickable
            || RestrictionService.isRestrictionHeadline(restriction);
    }
}

export default (RestrictionService);