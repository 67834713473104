import React from 'react';
import {Box} from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {connect} from 'react-redux';
import {
    setCartRemoveDialogState,
    setCartRenameDialogState,
    setCartSelectionDialogState,
} from '../../../redux/cart/cartAction';
import {compose} from 'redux';
import CartSelectionDialog from './cartSelectionDialog';
import CartItem from './cartSelectItem';
import CartRenameDialog from './cartRenameDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import CartRemoveDialog from './cartRemoveDialog';
import {updateBrandSelectionDialogState} from '../../../redux/catalog/catalogAction';
import withCartApi from '../../../api/cartApi';

class CartSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            availableCarts: [],
        };
    }

    componentDidMount() {
        this.props.getCarts(this.props.currentBrand.id).then(carts => this.setState({availableCarts: carts}));
    }

    handleCartSelectionClick = () => () => {
        this.props.setCartSelectionDialogState(true);
    };
    handleRenameCartClick = () => () => {
        this.props.setCartRenameDialogState(true);
    };
    handleDeleteCartClick = () => () => {
        this.props.setCartRemoveDialogState(true);
    };
    handleBrandClick = () => () => {
        this.props.updateBrandSelectionDialogState(true);
    };

    render() {
        const {cart, cartLogo} = this.props;
        const {availableCarts} = this.state;

        return (
            <Box p={.5} bgcolor="white" border={1} borderColor="border.main">
                <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>

                    <Grid item xs>
                        <CartItem
                            cartTitle={cart.cartName}
                            onBrandClick={this.handleBrandClick()}
                            onClick={this.handleCartSelectionClick()}
                            cartSubTitle={cart.creationTime}
                            cartLogo={cartLogo}
                        />
                    </Grid>

                    <Grid item xs="auto">
                        <Grid container>
                            <Grid item>
                                <Box pr={.5}>
                                    <IconButton size="small" onClick={this.handleDeleteCartClick()}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    <IconButton size="small" onClick={this.handleRenameCartClick()}>
                                        <EditIcon/>
                                    </IconButton>
                                </Box>
                            </Grid>

                            {availableCarts.length > 1 && (
                                <Grid item>
                                    <Box pl={.5} border={1} borderColor="border.main" borderTop={0} borderBottom={0}
                                         borderRight={0}>
                                        <IconButton size="small" onClick={this.handleCartSelectionClick()}>
                                            <KeyboardArrowDownIcon/>
                                        </IconButton>
                                    </Box>
                                </Grid>
                            )}

                        </Grid>
                    </Grid>

                </Grid>

                {availableCarts.length > 1 && (
                    <CartSelectionDialog carts={availableCarts}/>
                )}

                <CartRenameDialog cart={cart} />
                <CartRemoveDialog cart={cart} />
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    cart: state.cart.cart,
    cartLogo: state.cart.cart.catalogProperties.brandLogoPath,
    currentBrand: state.catalog.currentBrand,
});

const mapDispatchToProps = {
    setCartSelectionDialogState,
    setCartRenameDialogState,
    setCartRemoveDialogState,
    updateBrandSelectionDialogState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withCartApi,
)(CartSelect);
