import React, { Fragment, createRef } from 'react';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import { withTranslation } from 'react-i18next';
import {
    Box,
    Button,
} from '@mui/material';
import FlipIcon from '@mui/icons-material/Flip';
import theme from '../../theme/theme';

const HEIGHT_HEADER = 64;

const useStyles = () => ({
    video: {
        display: 'block',
        width: '100%',
        maxHeight: 'calc(100vh - 104px)',
        height: 'auto',
        objectFit: 'cover',
        objectPosition: 'center'
    },
    overlayShow: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        zIndex: 20
    },
    overlayHide: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        opacity: 0
    },
    canvas: {
        position: 'absolute',
        visibility: 'hidden',
        left: 0,
        top: 0,
        zIndex: 0
    },
    picture: {
        width: '100%',
        height: '100%',
        zIndex: 20,
        position: 'relative'
    },
    pictureSelection: {
        position: 'absolute',
        left: 0,
        top: 0,
    },
    show: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        visibility: 'visible',
        overflow: 'hidden'
    },
    hide: {
        position: 'absolute',
        zIndex: 10,
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        width: '100%',
        height: '100%',
        visibility: 'hidden'
    }
});

class VinLensCanvas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSelectionActive: false,
            isStreaming: false,
            canvasWidth: 0,
            canvasHeight: 0
        };

        this.refVideoWrapper = createRef();
        this.refVideo = createRef();
        this.refCanvas = createRef();
        this.refCanvasSelection = createRef();
        this.refCanvasRotated = createRef();
        this.refPicture = createRef();
        this.refPictureSelection = createRef();
        this.refOverlay = createRef();
    }

    componentDidMount() {
        this.isDrawing = false;
        this.startX = 0;
        this.startY = 0;
        this.endX = 0;
        this.endY = 0;

        this.ctxCanvas = this.refCanvas.current.getContext('2d');
        this.ctxCanvasSelection = this.refCanvasSelection.current.getContext('2d');
        this.ctxCanvasRotated = this.refCanvasRotated.current.getContext('2d');
        this.ctx = this.refOverlay.current.getContext('2d');

        this.startStreaming();
    }

    componentWillUnmount() {
        this.stopStreaming();
        this.reset();
    }

    reset() {
        this.props.onPictureTaken(false);
        this.ctx.clearRect(0, 0, this.refOverlay.current.width, this.refOverlay.current.height);
        this.setState({
            isSelectionActive: false,
            isStreaming: false,
            canvasWidth: 0,
            canvasHeight: 0
        });

        this.refPictureSelection.current.setAttribute('src', '');
        this.refCanvasSelection.current.style.zIndex = 0;
    }

    handleTouchStart = e => {
        if (this.props.isLoading || !this.props.isPictureTaken) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        this.isDrawing = true;
        this.startX = e.changedTouches[0].clientX * this._xDistort;
        this.startY = e.changedTouches[0].clientY - HEIGHT_HEADER;

        this.setState({ isSelectionActive: false });
    }

    handleTouchEnd = e => {
        if (this.props.isLoading || !this.props.isPictureTaken) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        this.isDrawing = false;
        this.endX = e.changedTouches[0].clientX * this._xDistort;
        this.endY = e.changedTouches[0].clientY - HEIGHT_HEADER;

        this.setState({ isSelectionActive: true });
    }

    handleTouchMove = e => {
        if (this.props.isLoading || !this.props.isPictureTaken) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        if (!this.isDrawing) {
            return;
        }

        const width = e.changedTouches[0].clientX * this._xDistort - this.startX;
        const height = e.changedTouches[0].clientY - this.startY;

        this.ctx.clearRect(0, 0, this.refOverlay.current.width, this.refOverlay.current.height);
        this.ctx.strokeStyle = 'red';
        this.ctx.lineWidth = '2px';

        this.ctx.strokeRect(this.startX, this.startY, width, height - HEIGHT_HEADER);
    }

    startStreaming = () => {
        if (this.state.isStreaming) {
            return;
        }

        const aspectRatio = window.innerWidth >= 1280 ? 1.777 : 0.75;

        // Check if 'mediaDevices' are supported (iOS 12 and up)
        navigator.mediaDevices.getUserMedia({
            video: {
                facingMode: 'environment',
                aspectRatio: { exact: aspectRatio },
                // height: { min: screenWidth / aspectRatio, max: screenHeight - HEIGHT_HEADER - HEIGHT_BOTTOM_BAR },
            },
            audio: false
        }).then(stream => {
            this.refVideo.current.srcObject = stream;
            this.refVideo.current.setAttribute('muted', true);
            this.refVideo.current.setAttribute('playsinline', true);
            this.refVideo.current.play().then(() => {
                const ratio = this.refVideo.current.videoWidth / this.refVideo.current.videoHeight;

                let width = window.innerWidth;
                let height = width / ratio;

                this._pictureHeight = window.innerWidth / ratio;
                this._xDistort = height / this._pictureHeight; // positive float below 1

                this.refVideoWrapper.current.style.width = `${width}px`;
                this.refVideoWrapper.current.style.height = `${height}px`;

                this.refOverlay.current.setAttribute('width', width);
                this.refOverlay.current.setAttribute('height', height);

                setTimeout(() => {
                    this.refCanvas.current.setAttribute('width', width);
                    this.refCanvas.current.setAttribute('height', height);

                    this.setState({
                        canvasWidth: width,
                        canvasHeight: height
                    });
                }, 0);

                this.setState({ isStreaming: true });
            });
        }).catch(() => {
            this.props.onError();
        });
    }

    stopStreaming = () => {
        if (this.refVideo?.current?.srcObject) {
            this.refVideo.current.srcObject.getTracks().forEach(track => {
                track.stop();
            });
        }

        setTimeout(() => {
            this.setState({ isStreaming: false });
        }, 0);
    }

    takePicture = () => {
        setTimeout(() => {
            this.ctxCanvas.drawImage(this.refVideo.current, 0, 0, this.state.canvasWidth, this.state.canvasHeight);

            const data = this.refCanvas.current.toDataURL('image/png');
            this.refPicture.current.setAttribute('src', data);

            this.props.onPictureTaken(true);
        }, 100);
    }

    updateImageSelection = () => {
        // Swap points if 'endX' smaller than 'startX'
        if (this.endX < this.startX) {
            const temp = this.endX;
            this.endX = this.startX;
            this.startX = temp;
        }

        // Swap points if 'endY' smaller than 'startY'
        if (this.endY < this.startY) {
            const temp = this.endY;
            this.endY = this.startY;
            this.startY = temp;
        }

        const width = this.endX - this.startX; // Source image width
        const height = this.endY - this.startY; // Source image height

        this.refPictureSelection.current.style.width = `${width}px`;
        this.refPictureSelection.current.style.height = `${height}px`;

        this.refCanvasSelection.current.setAttribute('width', width);
        this.refCanvasSelection.current.setAttribute('height', height);
        this.refCanvasSelection.current.style.zIndex = 50;
        this.ctxCanvasSelection.drawImage(this.refCanvas.current, this.startX, this.startY, width, height, 0, 0, width, height);

        const data = this.refCanvasSelection.current.toDataURL('image/png');

        this.refPictureSelection.current.setAttribute('src', data);

        return [width, height];
    }

    rotateImage = (width, height, degrees) => {
        this.refCanvasRotated.current.setAttribute('width', height);
        this.refCanvasRotated.current.setAttribute('height', width);

        const angle = (degrees / 180) * Math.PI ;
        const x = height / 2;
        const y = width / 2;

        this.ctxCanvasRotated.translate(x, y);
        this.ctxCanvasRotated.rotate(-angle);
        this.ctxCanvasRotated.drawImage(this.refCanvasSelection.current, -y, -x, width, height);
        this.ctxCanvasRotated.rotate(angle);
        this.ctxCanvasRotated.translate(-x, -y);

        const data = this.refCanvasRotated.current.toDataURL('image/png');
        this.refPictureSelection.current.setAttribute('src', data);
    }

    sendSelection = async () => {
        const [width, height] = this.updateImageSelection();

        if (width < height) {
            this.rotateImage(width, height, 90);
        }

        const success = await this.props.onSendSelection(this.refPictureSelection.current.src);

        if (!success) {
            this.reset();
        } 
    }

    render() {
        const { t, classes, isLoading, isPictureTaken } = this.props;

        return (
            <Fragment>
                <Box ref={this.refVideoWrapper} sx={{
                    position: 'relative',
                    maxHeight: 'calc(100vh - 104px)',
                    overflow: 'hidden'
                }}>
                    <video ref={this.refVideo} className={classes.video} />
                    <div className={isPictureTaken ? classes.show : classes.hide}>
                        <img ref={this.refPicture} className={classes.picture} alt="" />
                        <img ref={this.refPictureSelection} className={classes.pictureSelection} alt="" />
                    </div>
                    <canvas ref={this.refCanvas} className={classes.canvas} />
                    <canvas
                        ref={this.refOverlay}
                        className={isPictureTaken ? classes.overlayShow : classes.overlayHide}
                        onTouchStart={this.handleTouchStart}
                        onTouchEnd={this.handleTouchEnd}
                        onTouchMove={this.handleTouchMove}
                    />
                    <canvas ref={this.refCanvasSelection} className={classes.canvas} />
                    <canvas ref={this.refCanvasRotated} className={classes.canvas} />
                </Box>
                <Box sx={{
                    zIndex: 20,
                    position: 'sticky',
                    bottom: 0,
                    width: '100%',
                    height: theme.spacing(5),
                    backgroundColor: theme.palette.grey200
                }}>
                    {!this.state.isSelectionActive && (
                        <Button
                            variant="contained"
                            startIcon={<FlipIcon/>}
                            onClick={this.takePicture}
                            disabled={isPictureTaken}
                        >
                            {t('take_picture')}
                        </Button>
                    )}
                    {this.state.isSelectionActive && (
                        <Button
                            variant="contained"
                            startIcon={<FlipIcon />}
                            onClick={this.sendSelection}
                            disabled={isLoading}
                        >
                            {t('send_selection')}
                        </Button>
                    )}
                </Box>
            </Fragment>
        );
    }
}

export default compose(
    withStyles(useStyles),
    withTranslation()
)(VinLensCanvas);