class DateTimeService {

    static commaSeparatedDateTime(dateTime) {
        return new Date(dateTime).toLocaleString([], {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })
    }
}

export default (DateTimeService);