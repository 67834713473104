import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import {connect} from 'react-redux';
import {clearStore} from '../../redux/auth/authAction';
import {updateNewVersionMessageState} from '../../redux/global/globalAction';


class PwaOldVersionMessage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            oldVersion: false,
        };
    }

    resetLocalStorage() {
        this.props.updateNewVersionMessageState(false);
    }

    render() {
        const {t} = this.props;

        return (
            <Dialog
                open={this.props.newVersionMessageShow}
                aria-labelledby="old-version-dialog-title"
                aria-describedby="old-version-dialog-description"
            >
                <DialogContent>
                    <DialogContentText>
                        {t('pwa_old_version_message')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.resetLocalStorage()}>
                        {t('pwa_old_version_reset_message')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    newVersionMessageShow: state.global.newVersionMessageShow,
});

const mapDispatchToProps = {
    clearStore,
    updateNewVersionMessageState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
    withTranslation(),
)(PwaOldVersionMessage);
