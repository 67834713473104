import {
    UPDATE_SEARCH_QUERY,
    ADD_ITEM_TO_SELECT_HISTORY,
    RESET_ALL_SEARCH,
    UPDATE_SEARCH_DIALOG_STATE,
    SET_PART_SEARCH_RESULTS,
    ADD_PART_SEARCH_RESULTS, RESET_PART_SEARCH_RESULTS
} from './searchTypes';

const initialState = {
    searchQuery: '',
    searchDialogShow: '',
    selectHistory: [],
    partSearchResults: [],
};

export default function searchReducer(state = initialState, action) {

    switch (action.type) {
        case UPDATE_SEARCH_DIALOG_STATE:
            return {...state, searchDialogShow: action.value};
        case UPDATE_SEARCH_QUERY:
            return {...state, searchQuery: action.value};
        case ADD_ITEM_TO_SELECT_HISTORY:
            return {...state, selectHistory: removeExistingItemAndAddItOnTop(state.selectHistory, action.value)};
        case RESET_ALL_SEARCH:
            return {...initialState, selectHistory: [...initialState.selectHistory]};
        case SET_PART_SEARCH_RESULTS:
            return {...initialState, partSearchResults: action.value};
        case ADD_PART_SEARCH_RESULTS:
            return {...initialState, partSearchResults: [...state.partSearchResults, ...action.value]};
        case RESET_PART_SEARCH_RESULTS:
            return {...initialState, partSearchResults: [...initialState.partSearchResults]};
        default:
            return state;
    }
}

export const selectHistoryByBrand = state => state.search.selectHistory.filter(item => item.brandId === state.catalog.currentBrand.id);

function removeExistingItemAndAddItOnTop(arr, item) {
    // originalDescription: multiple items were found on search, they all have the same VIN but a different oD
    const itemIndex = arr.findIndex((i) => i.vin === item.vin && i.originalDescription === item.originalDescription);

    if (itemIndex !== -1) {
        arr.splice(itemIndex, 1);
    }
    arr.unshift(item);

    // only save the first 5 items
    arr = arr.slice(0, 5);

    return arr;
}
