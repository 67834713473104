export const UPDATE_CATALOGS = 'UPDATE_CATALOGS';
export const SET_RESTRICTIONS = 'SET_RESTRICTIONS';
export const UPDATE_CURRENT_BRAND = 'UPDATE_CURRENT_BRAND';
export const UPDATE_CURRENT_VEHICLE_RESTRICTION = 'UPDATE_CURRENT_VEHICLE_RESTRICTION';
export const UPDATE_CURRENT_PART_RESTRICTION = 'UPDATE_CURRENT_PART_RESTRICTION';
export const UPDATE_CURRENT_VEHICLE = 'UPDATE_CURRENT_VEHICLE';
export const UPDATE_CURRENT_PART = 'UPDATE_CURRENT_PART';
export const UPDATE_CURRENT_PART_OVERVIEW = 'UPDATE_CURRENT_PART_OVERVIEW';
export const UPDATE_CURRENT_PART_INFORMATION = 'UPDATE_CURRENT_PART_INFORMATION';
export const UPDATE_CURRENT_PART_PATH = 'UPDATE_CURRENT_PART_PATH';
export const UPDATE_CURRENT_PART_DETAIL_OVERVIEW = 'UPDATE_CURRENT_PART_DETAIL_OVERVIEW';
export const UPDATE_CURRENT_HOTSPOT = 'UPDATE_CURRENT_HOTSPOT';
export const UPDATE_CURRENT_TOPHEIGHT = 'UPDATE_CURRENT_TOPHEIGHT';
export const UPDATE_SELECTED_PART = 'UPDATE_SELECTED_PART';
export const UPDATE_CURRENT_PART_OVERVIEW_STATE = 'UPDATE_CURRENT_PART_OVERVIEW_STATE';
export const UPDATE_CURRENT_BOMBASE = 'UPDATE_CURRENT_BOMBASE';
export const UPDATE_CURRENT_BOMBASE_PART = 'UPDATE_CURRENT_BOMBASE_PART';
export const SET_COMMUNITY_IMAGES = 'SET_CURRENT_COMMUNITY_IMAGES';
export const SET_READ_ONLY_COMMUNITY_IMAGES = 'SET_READ_ONLY_COMMUNITY_IMAGES';
export const SET_OWN_IMAGES = 'SET_CURRENT_OWN_IMAGES';
export const UPDATE_COMMUNITY_IMAGE = 'UPDATE_CURRENT_COMMUNITY_IMAGE';
export const REMOVE_COMMUNITY_IMAGE = 'REMOVE_CURRENT_COMMUNITY_IMAGE';

export const RESET_RESTRICTIONS = 'RESET_RESTRICTIONS';
export const REMOVE_RESTRICTIONS = 'REMOVE_RESTRICTIONS';
export const REMOVE_RESTRICTIONS_BY_PARENT_ID = 'REMOVE_RESTRICTIONS_BY_PARENT_ID';
export const RESET_TOP_RIGHT_BUTTON = 'RESET_TOP_RIGHT_BUTTON';
export const RESET_CURRENT_BRAND = 'RESET_CURRENT_BRAND';
export const RESET_CURRENT_PART = 'RESET_CURRENT_PART';
export const RESET_CURRENT_VEHICLE = 'RESET_CURRENT_VEHICLE';
export const RESET_CURRENT_VEHICLE_RESTRICTION = 'RESET_CURRENT_VEHICLE_RESTRICTION';
export const RESET_CURRENT_PART_RESTRICTION = 'RESET_CURRENT_PART_RESTRICTION';
export const RESET_CURRENT_PART_OVERVIEW = 'RESET_CURRENT_PART_OVERVIEW';
export const RESET_CURRENT_PART_IMAGES = 'RESET_CURRENT_PART_IMAGES';
export const RESET_CURRENT_HOTSPOT = 'RESET_CURRENT_HOTSPOT';
export const RESET_CURRENT_TOPHEIGHT = 'RESET_CURRENT_TOPHEIGHT';
export const RESET_CURRENT_BOMBASE = 'RESET_CURRENT_BOMBASE';
export const RESET_CURRENT_BOMBASE_PART = 'RESET_CURRENT_BOMBASE_PART';
export const RESET_SELECTED_PART = 'RESET_SELECTED_PART';
export const RESET_ALL_CATALOG = 'RESET_ALL_CATALOG';
export const RESET_CURRENT_PART_DETAIL_OVERVIEW = 'RESET_CURRENT_PART_DETAIL_OVERVIEW';
export const RESET_CURRENT_COMMUNITY_IMAGES = 'RESET_CURRENT_COMMUNITY_IMAGES';
export const UPDATE_BRAND_SELECTION_DIALOG_STATE = 'UPDATE_BRAND_SELECTION_DIALOG_STATE';
export const UPDATE_CURRENT_VEHICLE_SEGMENTS = 'UPDATE_CURRENT_VEHICLE_SEGMENTS';
export const UPDATE_CURRENT_VEHICLE_SEARCH = 'UPDATE_CURRENT_VEHICLE_SEARCH';
export const UPDATE_BOM_BASE_TRIGGER_ITEMS = 'UPDATE_BOM_BASE_TRIGGER_ITEMS';
export const SET_TOP_RIGHT_BUTTON = 'SET_TOP_RIGHT_BUTTON';
export const SET_LAST_BRAND_CHANGE = 'SET_LAST_BRAND_CHANGE';