import {store} from '../redux/store';
import VehicleApi from '../api/catmetaApi';
import RestrictionService from './restrictionService';
import {
    setRestrictions, setTopRightButton,
    updateCurrentPartRestriction,
    updateCurrentVehicleSearch,
} from '../redux/catalog/catalogAction';
import CatmetaService from './catmetaService';
import {updateCrumbs} from '../redux/crumbs/crumbsAction';

class CatalogService {

    static isBMW() {
        return store.getState().catalog.currentBrand.id === 'bmw_parts' ||
            store.getState().catalog.currentBrand.id === 'mini_parts' ||
            store.getState().catalog.currentBrand.id === 'bmwclassic_parts' ||
            store.getState().catalog.currentBrand.id === 'bmwmotorradclassic_parts' ||
            store.getState().catalog.currentBrand.id === 'bmwmotorrad_parts';
    }

    static isMercedes() {
        return store.getState().catalog.currentBrand.id === 'mercedes_parts' ||
            store.getState().catalog.currentBrand.id === 'mercedestrucks_parts' ||
            store.getState().catalog.currentBrand.id === 'mercedesunimog_parts' ||
            store.getState().catalog.currentBrand.id === 'mercedesvans_parts';
    }

    static isVehicleIdentified(restriction) {
        return restriction.nextWidgetKey.toLowerCase() === CatmetaService.VEHICLE_END_TRIGGER.toLowerCase() ||
            restriction.nextWidgetKey.toLowerCase() === CatmetaService.VEHICLE_END_TRIGGER_RENAULT.toLowerCase() ||
            restriction.nextWidgetKey.toLowerCase() === CatmetaService.VEHICLE_END_TRIGGER_ALT.toLowerCase() ||
            restriction.nextWidgetKey.toLowerCase() === CatmetaService.VEHICLE_END_VW.toLowerCase() ||
            (CatalogService.isMercedes() && restriction.nextWidgetKey.toLowerCase() === CatmetaService.VEHICLE_END_TRIGGER_MERCEDES.toLowerCase());
    }

    static loadMainVehicleRestriction(url = store.getState().catalog.currentBrand.link) {
        if (url && this.isLinkedFetched(url)) {
            return;
        }

        VehicleApi.getRestrictions(url).then(restrictionData => {
                let restrictions = [];

                if (restrictionData.data && restrictionData.data.records) {
                    restrictions = RestrictionService.getRestrictionsFromArray(restrictionData.data, store.getState().catalog.currentBrand.id);

                    let topRightButton = restrictionData.data.topRightButton;
                    if (topRightButton) {
                        const newTopRightButton = {...store.getState().catalog.topRightButton};
                        newTopRightButton.hasTopRightButton = true;
                        newTopRightButton[topRightButton.type] = topRightButton;

                        store.dispatch(setTopRightButton({...newTopRightButton}));
                        restrictions.map(item => item.mode = store.getState().catalog.topRightButton.currentMode);
                    }
                }

                if (restrictionData.crumbs) {
                    store.dispatch(updateCrumbs(restrictionData.crumbs));
                }

                store.dispatch(setRestrictions([...store.getState().catalog.restrictions, ...restrictions]));
            });
    }

    static loadMainPartRestriction(currentVehicle) {
        const task = (restrictionData, catalogRestrictions) => {
            // for part-search, each restriction has custom search-url which need to be set on current vehicle
            if (restrictionData.search) {
                store.dispatch(updateCurrentVehicleSearch(restrictionData.search.path));
            }
            return catalogRestrictions;
        }

        this.loadRestriction(currentVehicle, task);
    }

    static loadSubRestriction(currentRestriction) {
        const task = (restrictionData, catalogRestrictions) => {
            // for part-search, each restriction has custom search-url which need to be set on current part restriction
            if (restrictionData.search) {
                currentRestriction.searchUrl = restrictionData.search.path;
            }

            store.dispatch(updateCurrentPartRestriction({...currentRestriction}));

            return catalogRestrictions;
        }

        this.loadRestriction(currentRestriction, task);
    }

    static loadSuspensionRestriction(currentRestriction) {
        const task = (restrictionData, catalogRestrictions) => {
            if (restrictionData.data && restrictionData.data.bomBaseLink) {
                let newRestriction = currentRestriction;
                // set filterUrl to partRestriction
                const parentRestriction = catalogRestrictions.find(r => r.id === currentRestriction.id);
                if(parentRestriction) {
                    newRestriction = parentRestriction;
                }
                newRestriction.filterUrl = restrictionData.data.bomBaseLink.path;

                store.dispatch(updateCurrentPartRestriction(newRestriction));
            }

            return catalogRestrictions;
        }

        this.loadRestriction(currentRestriction, task);
    }

    static loadRestriction(currentRestriction, task) {
        if (this.isLinkedFetched(currentRestriction.link)) {
            return;
        }

        VehicleApi.getRestrictions(currentRestriction.link)
            .then((restrictionData) => {
                let restrictions = [];
                let newRestrictions = [];
                let crumbs = restrictionData.crumbs;
                let catalogRestrictions = store.getState().catalog.restrictions;

                if (restrictionData.data && restrictionData.data.records) {
                    restrictions = RestrictionService.getRestrictionsFromArray(restrictionData.data, currentRestriction.id);
                }

                if (task) {
                    catalogRestrictions = task(restrictionData, catalogRestrictions);
                }

                newRestrictions = [...catalogRestrictions, ...restrictions];
                store.dispatch(setRestrictions(newRestrictions));

                if (restrictionData.crumbs) {
                    store.dispatch(updateCrumbs(crumbs));
                }
            });
    }

    static getParentRestriction(parentId) {
        return store.getState().catalog.restrictions.find(restriction => restriction.id === parentId);
    }

    static getRestrictionsByParent(restrictions, parentId) {
        if (!restrictions || !parentId) {
            return [];
        }
        return restrictions.filter(restriction => restriction.parentId === parentId);
    }

    static getRestrictionById(restrictionId) {
        const restrictions = store.getState().catalog.restrictions;

        return restrictions.find(restriction => restriction.id === restrictionId);
    }

    // set brandId as restriction id. to set as root page with NO back-arrow
    // and a prefix to make a difference between mainVehicle restrictions and mainPart restrictions!
    static addUniqueIdToVehicle(restriction) {
        let restrictionId = '';
        if(!store.getState().catalog.currentVehicleRestriction){
            restrictionId = 'search';
        } else {
            restrictionId = store.getState().catalog.currentVehicleRestriction.id
        }

        restriction.id = `vehicle-${store.getState().catalog.currentBrand.id}-${restrictionId}`;
        return restriction;
    }

    static isLinkedFetched(link) {
        if(store.getState().crumbs.length <= 0) {
            return false;
        }
        let crumb = store.getState().crumbs.find(crumb => crumb.link.path === link);

        // special case -> in some cases (ex. search) links can't be matched because their parameters slightly differ from what is delivered in crumbs
        if (!crumb) {
            crumb = store.getState().crumbs.find(crumb =>
                this.normalizePartslinkUrl(crumb.link.path) === this.normalizePartslinkUrl(link));
        }

        // console.log(store.getState().crumbs);
        // console.log(this.normalizePartslinkUrl(store.getState().crumbs[store.getState().crumbs.length - 1].link.path));
        // console.log(this.normalizePartslinkUrl(link));
        // console.log(crumb ? 'found' : 'not found');
        // if(crumb)
        // console.log(crumb.link.path);

        if (crumb) {
            return crumb.fetched;
        }

        return false;
    }

    static normalizePartslinkUrl(url) {
        const parametersToRemove = ['blockPresel', 'upds']

        url = this.removeURLParameters(url, parametersToRemove);
        url = url.replace('mdl_scope', '');
        url = url.replace('mdl_aggregate', '');
        return url;
    }

    static removeURLParameters(url, parameters) {
        for (let i = 0; i < parameters.length; i++) {
            const parameter = parameters[i];
            url = this.removeURLParameter(url, parameter)
        }
        return url;
    }

    static removeURLParameter(url, parameter) {
        let urlparts = url.split('?');
        if (urlparts.length >= 2) {

            let prefix = encodeURIComponent(parameter) + '=';
            let pars = urlparts[1].split(/[&;]/g);

            for (let i = pars.length; i-- > 0;) {
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }

    static isCellOverflown({ clientWidth, clientHeight, scrollWidth, scrollHeight }) {
        return scrollHeight > clientHeight || scrollWidth > clientWidth;
    }
}

export default (CatalogService);