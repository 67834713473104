import React from 'react';
import {compose} from 'redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import {withTranslation} from 'react-i18next';
import DialogTitleWithClose from '../../base/dialogTitleWithClose';
import TextField from '@mui/material/TextField';

class CartItemEditDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            itemComment: this.props.itemComment,
        };
    }

    onEnter = () => {
        this.setState({itemComment: this.props.itemComment});
    }

    render() {

        const {t} = this.props;

        return (
            <Dialog open={this.props.open}
                    onEnter={this.onEnter}
                    onClose={this.props.handleClose}>
                <DialogTitleWithClose onClose={this.props.handleClose}>
                    {t('cart_item_edit_dialog_title')}
                </DialogTitleWithClose>
                <DialogContent>
                    <DialogContentText>
                        {t('cart_item_edit_dialog_text', {itemName: this.props.itemName})}
                    </DialogContentText>

                    <form onSubmit={() => this.props.handleConfirm(this.state.itemComment)} noValidate>
                        <TextField
                            variant="filled"
                            multiline
                            rows={4}
                            label={t('cart_item_edit_comment_label')}
                            value={this.state.itemComment}
                            inputProps={{ maxLength: 59 }}
                            onChange={e => this.setState({
                                itemComment: e.target.value
                            })}
                        />
                    </form>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.handleConfirm('')} variant="outlined" color="primary" fullWidth={false}>
                        {t('cart_item_edit_dialog_delete')}
                    </Button>
                    <Button onClick={() => this.props.handleConfirm(this.state.itemComment)} variant="contained" color="primary" fullWidth={false}>
                        {t('cart_item_edit_dialog_confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default compose(
    withTranslation(),
)(CartItemEditDialog);
