import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import {ThemeProvider, responsiveFontSizes} from '@mui/material/styles';
import App from './components/app';
import theme from './theme/theme';
import {store, persistor} from './redux/store';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';
import {snackbarConfig} from './config/snackbarConfig';
import {PersistGate} from 'redux-persist/integration/react';

if (process.env.NODE_ENV === 'development') {
    require('./debug.css');
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter basename="/pl24-app">
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ThemeProvider theme={responsiveFontSizes(theme)}>
                        <SnackbarProvider {...snackbarConfig}>
                            <App/>
                        </SnackbarProvider>
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);

serviceWorkerRegistration.register({
    onUpdate: registration => {
        console.log('registration', registration);

        if (registration && registration.waiting) {
            console.log('new version is waiting, ', registration.waiting);
            localStorage.setItem('lastLoadedBuildVersion', true);

            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
    },
});