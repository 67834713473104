import React, {Fragment} from 'react';
import Container from '../base/container';
import {withTranslation} from 'react-i18next';
import ModelSubheader from '../catalog/restriction/restrictionSubheader';
import {compose} from 'redux';
import withMenu from '../../service/withMenu';
import Typography from '@mui/material/Typography';
import {connect} from 'react-redux';
import dealerApi from '../../api/dealerApi';
import {resetCatalogDealer, setCatalogDealer} from '../../redux/dealerManagement/dealerManagementAction';
import Grid from '@mui/material/Grid';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import {withStyles} from '@mui/styles';
import LoadingBackdrop from '../base/loadingBackdrop';
import {loadingAreas} from '../../config/loadingAreas';
import {resetCartDealer} from '../../redux/cart/cartAction';

const useStyles = () => ({
    image: {
        width: '100%',
        height: '100%',
        maxWidth: '10rem',
        maxHeight: 'auto',
    },
});

class Dealer extends React.Component {

    constructor(props) {
        super(props);
        let serviceName = this.props.match.params.serviceName;
        this.state = {serviceName};

        this.onBackClick = this.onBackClick.bind(this);
    }

    componentDidMount() {
        // todo only load if brand has changed
        this.props.resetCatalogDealer();
        dealerApi.getCatalogDealer(this.state.serviceName).then(catalogDealer => {
            if (catalogDealer.dealerId) {
                dealerApi.getDealer(catalogDealer.dealerId)
                    .then((dealer) => {
                        let catalog = this.props.catalogs.find((catalog) => (catalog.id === this.state.serviceName));
                        this.props.setCatalogDealer({...catalog, dealer});
                    });
            }
        });
    }

    onBackClick() {
        this.props.routeToDealerManagement();
    }

    onChangeDealerClick = () => {
        this.props.routeToLastDealers(this.state.serviceName);
    };

    onDealerRemove = () => {
        dealerApi.setCatalogDealer(0, this.state.serviceName);
        this.props.resetCartDealer();
    };

    getBrandNameByCatalogDealers() {
        const catalogDealer = this.props.catalogDealers.find(dealer => dealer.id === this.state.serviceName);
        return catalogDealer ? catalogDealer.name : '';
    }

    render() {
        const {t, classes} = this.props;

        return (
            <Fragment>
                <Container disableGutters>
                    <ModelSubheader
                        title={t('your_dealer_for') + ' ' + this.getBrandNameByCatalogDealers()}
                        onBackClick={this.onBackClick}
                    />
                </Container>
                <Container overflow>
                    <Box mb={2} py={3}>
                        {this.props.catalogDealer.dealer && <Fragment>
                            <Typography variant="h4">{this.props.catalogDealer.dealer.companyName}</Typography>
                            <Grid spacing={2} container justifyContent="flex-start" alignItems="center">
                                <Grid item xs={4} sm={'auto'}>
                                    <img className={classes.image} src={this.props.catalogDealer.iconUrl}
                                         alt={this.props.catalogDealer.name}/>
                                </Grid>
                                <Grid item>
                                    {this.props.catalogDealer.dealer.addressLines.map(((addressLine, i) => {
                                        return <Typography key={i} variant="body1">{addressLine}</Typography>;
                                    }))}
                                </Grid>
                            </Grid>
                        </Fragment>}

                    </Box>
                    <Button variant="contained" onClick={this.onChangeDealerClick}>
                        <Box color="black">{t('change_dealer')}</Box>
                    </Button>

                    {process.env.NODE_ENV === 'development' && <Fragment>
                        <Box my={1}>
                            <Button variant="contained" color="default" onClick={this.onDealerRemove}>
                                {t('remove_dealer')}
                            </Button>
                        </Box>
                    </Fragment>}

                </Container>

                <LoadingBackdrop area={loadingAreas.dealerManagement}/>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    catalogDealer: state.dealerManagement.catalogDealer,
    catalogDealers: state.dealerManagement.catalogDealers,
    catalogs: state.catalog.catalogs,
});

const mapDispatchToProps = dispatch => ({
    setCatalogDealer: (payload) => dispatch(setCatalogDealer(payload)),
    resetCatalogDealer: (payload) => dispatch(resetCatalogDealer(payload)),
    resetCartDealer: (payload) => dispatch(resetCartDealer(payload)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withMenu,
    withStyles(useStyles),
)(Dealer);
