import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import {withTranslation} from 'react-i18next';
import Container from '../base/container';
import {
    groupMyAccount,
    routeSettings,
    routeLogout,
    routeDealerManagement,
} from '../../config/routeItems';
import {Button, Typography} from '@mui/material';
import {compose} from 'redux';
import withAuthApi from '../../api/authApi';
import {connect} from 'react-redux';
import i18n from '../../i18n';
import {updateLogInState} from '../../redux/auth/authAction';
import withSnackbar from '../../service/withSnackbar';
import appgtwApi from '../../api/appgtwApi';
import {store} from '../../redux/store';
import {setContentLinks} from '../../redux/global/globalAction';
import theme from '../../theme/theme';

class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            myAccountMenuOpen: false,
            legalMenuOpen: false,
        };
    }

    componentDidMount() {
        if (this.props.contentLinks.length <= 0) {
            appgtwApi.getContentLinks().then(contentLinks => {
                store.dispatch(setContentLinks(contentLinks));
            });
        }
    }

    handleMyAccountMenuToggleClick = () => {
        this.setState(state => ({myAccountMenuOpen: !state.myAccountMenuOpen}));
    };

    handleLegalMenuToggleClick = () => {
        this.setState(state => ({legalMenuOpen: !state.legalMenuOpen}));
    };

    getListItems = (items, withIcon = false, nested = false, bold = true) => {
        const { t } = this.props;

        return items.map(item => (
            <ListItem 
                button
                key={item.key}
                component={Link}
                divider={false}
                to={item.url()}
                sx={{
                    backgroundColor: 'white',
                    marginTop: nested ? 0 : '8px',
                    marginBottom: nested ? '2px' : '4px',
                    paddingLeft: nested ? '32px' : '16px',
            }}>
                {withIcon && (
                    <ListItemIcon sx={{ minWidth: theme.spacing(4) }}>
                        {item.icon}
                    </ListItemIcon>
                )}
                <ListItemText primary={
                    <Typography sx={{ fontWeight: bold ? 700 : 400 }}>
                        {t(item.label)}
                    </Typography>}
                />
            </ListItem>
        ));
    };

    getLinkListItems = items => {
        const { t } = this.props;

        return items.map((item) => (
            <ListItem 
                button
                key={item.name}
                component="a"
                target="_blank"
                href={item.url}
                sx={{
                    backgroundColor: 'white',
                    marginTop: '4px',
                    marginBottom: '2px',
                    paddingLeft: '32px',
            }}>
                <ListItemText primary={
                    <Typography>
                        {t(item.name)}
                    </Typography>}
                />
            </ListItem>
        ));
    };

    logout() {
        const { logout, enqueueErrorSnackbar } = this.props;

        logout().then(() => {
            this.props.closeAllSnacks();
            this.props.updateLogInState(false);
        }).catch(() => {
            enqueueErrorSnackbar(i18n.t('error_on_logout'));
        })
    }

    render() {
        const { t } = this.props;

        return (
            <Container paddingX overflow>
                <List component="nav">
                    {this.getListItems([routeDealerManagement], false)}

                    <ListItem 
                        button 
                        onClick={this.handleMyAccountMenuToggleClick} 
                        divider={false}
                        sx={{
                            backgroundColor: theme.palette.white,
                            marginTop: 1,
                            marginBottom: 0.5
                    }}>
                        <ListItemText primary={
                            <Typography sx={{ fontWeight: 700 }}>
                                {t('menu_my_account')}
                            </Typography>}
                        />
                        {this.state.myAccountMenuOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Collapse in={this.state.myAccountMenuOpen} timeout="auto" unmountOnExit sx={{
                        backgroundColor: theme.palette.white
                    }}>
                        <List component="div" disablePadding>
                            {this.getListItems(groupMyAccount, true, true, false)}
                        </List>
                    </Collapse>
                    <ListItem 
                        button 
                        onClick={this.handleLegalMenuToggleClick} 
                        divider={false}
                        sx={{
                            backgroundColor: theme.palette.white,
                            marginTop: 1,
                            marginBottom: 0.5
                        }}>

                        <ListItemText primary={
                            <Typography sx={{ fontWeight: 700 }}>
                                {t('menu_legal')}
                            </Typography>}
                        />
                        {this.state.legalMenuOpen ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>

                    <Collapse in={this.state.legalMenuOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {this.getLinkListItems(this.props.contentLinks)}
                        </List>
                    </Collapse>

                    <List component="div" disablePadding>
                        {this.getListItems([routeSettings])}
                    </List>

                    <List component="div" disablePadding>
                        <ListItem 
                            button
                            divider={false}
                            key={routeLogout.key}
                            component={Button}
                            onClick={() => this.logout()}
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                marginTop: 1,
                                marginBottom: 0.5
                        }}>
                            <ListItemIcon sx={{ minWidth: theme.spacing(5) }}>
                                {routeLogout.icon}
                            </ListItemIcon>
                            <ListItemText primary={t(routeLogout.label)} />
                        </ListItem>
                    </List>
                </List>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    contentLinks: state.global.contentLinks,
});

const mapDispatchToProps = {
    updateLogInState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
    withTranslation(),
    withAuthApi,
    withSnackbar,
)
(Menu);
