import React, {Fragment} from 'react';
import {compose} from 'redux';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {withTranslation} from 'react-i18next';
import {withStyles} from '@mui/styles';

const useStyles = () => ({
    popover: {
        width: '100%',
        maxWidth: '500px',
    },
    textRight: {
        textAlign: 'right',
    },
});

class CartItemInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            infoPopoverOpen: false,
        };
    }

    handleInfoToggle = (e) => {
        this.setState({
            infoPopoverOpen: !this.state.infoPopoverOpen,
            anchorEl: e.currentTarget,
        });
    };


    render() {
        const {t, classes} = this.props;

        return (
            <Fragment>
                <IconButton size="small" onClick={(e) => this.handleInfoToggle(e)}>
                    <MoreHorizIcon/>
                </IconButton>
                <Popover
                    className={classes.popover}
                    open={this.state.infoPopoverOpen}
                    anchorEl={this.state.anchorEl}
                    onClose={(e) => this.handleInfoToggle(e)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Box p={1}>

                        <Typography variant="body2" component="div">
                            <Grid container>
                                {
                                    this.props.listPrice && (
                                        <Fragment>
                                            <Grid item xs={8}>{t('cart_item_info_list_price')}</Grid>
                                            <Grid item xs={4}
                                                  className={classes.textRight}>{this.props.listPrice}</Grid>
                                        </Fragment>
                                    )
                                }
                                {
                                    this.props.discountPercentPretty && (
                                        <Fragment>
                                            <Grid item xs={8}>{t('cart_item_info_discount_percent')}</Grid>
                                            <Grid item xs={4}
                                                  className={classes.textRight}>{this.props.discountPercentPretty}</Grid>
                                        </Fragment>
                                    )
                                }
                                {
                                    this.props.unitPrice && (
                                        <Fragment>
                                            <Grid item xs={8}>{t('cart_item_info_unit_price')}</Grid>
                                            <Grid item xs={4}
                                                  className={classes.textRight}>{this.props.unitPrice}</Grid>
                                        </Fragment>
                                    )
                                }
                                {
                                    this.props.discountGroup && (
                                        <Fragment>
                                            <Grid item xs={8}>{t('cart_item_info_discount_group')}</Grid>
                                            <Grid item xs={4}
                                                  className={classes.textRight}>{this.props.discountGroup}</Grid>
                                        </Fragment>
                                    )
                                }
                                {
                                    this.props.comment && (
                                        <Grid item xs={12}>
                                            <Box mt={2}>
                                                <strong>{t('cart_comment_title')}</strong>
                                            </Box>
                                            <Box>
                                                {this.props.comment.split('\n').map((item, key) => {
                                                    return <Fragment key={key}>{item}<br/></Fragment>;
                                                })}
                                            </Box>
                                        </Grid>
                                    )
                                }
                            </Grid>

                        </Typography>

                    </Box>
                </Popover>
            </Fragment>
        );
    }
}

export default compose(
    withTranslation(),
    withStyles(useStyles),
)(CartItemInfo);
