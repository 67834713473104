import React from 'react';
import {compose} from 'redux';
import CatalogService from '../../../../service/catalogService';
import DynamicRestrictionTable from '../../dynamicRestrictionTable';
import {connect} from 'react-redux';
import {setRestrictions} from '../../../../redux/catalog/catalogAction';

class SuspensionFilter extends React.Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        CatalogService.loadSuspensionRestriction(this.props.currentPartRestriction);
    }

    handleClick(restriction) {
        let catalogRestrictions = this.props.restrictions;
        const currentCatalogRestriction = catalogRestrictions.find(r => r.id === restriction.id);
        currentCatalogRestriction.isSelected = !currentCatalogRestriction.isSelected;
        this.props.setRestrictions([...catalogRestrictions]);
        this.props.onSelectionChange();
    }

    render() {
        return (
            <DynamicRestrictionTable
                cellAlign = 'center'
                showCheckbox = {true}
                restrictions={CatalogService.getRestrictionsByParent(this.props.restrictions, this.props.currentPartRestriction?.id)}
                widgetKey={this.props.currentPartRestriction?.nextWidgetKey}
                onItemClick={this.handleClick}/>
        );
    }
}

const mapStateToProps = state => ({
    restrictions: state.catalog.restrictions,
    currentPartRestriction: state.catalog.currentPartRestriction,
});

const mapDispatchToProps = {
    setRestrictions,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(SuspensionFilter);