import React from 'react';

class TabPanel extends React.Component {

    render() {
        const {children, value, index, ...other } = this.props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {children}
            </div>
        );
    }
}

export default (TabPanel);
