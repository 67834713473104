import React from 'react';
import {connect} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import BrandList from './brandList';
import {updateBrandSelectionDialogState} from '../../redux/catalog/catalogAction';
import {withStyles} from '@mui/styles';
import DialogTitleWithClose from '../base/dialogTitleWithClose';
import withCatalog from '../../service/withCatalog';

const useStyles = (theme) => ({
    dialogTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    dialogContent: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
});

class BrandSelectionDialog extends React.Component {

    handleBrandSelectionClose = () => () => {
        this.props.updateBrandSelectionDialogState(false);
    };

    handleBrandSelectionItemClick = (brand) => {
        this.props.updateBrandSelectionDialogState(false);
        this.props.routeToModel(brand.id);
    };

    render() {
        const {t, classes} = this.props;

        return (
            <Dialog
                open={this.props.brandSelectionDialogShow}
                onClose={this.handleBrandSelectionClose()}
                aria-labelledby="brand-selection-dialog-title"
                aria-describedby="brand-selection-dialog-description"
            >
                <DialogTitleWithClose 
                    className={classes.dialogTitle} 
                    onClose={this.handleBrandSelectionClose()}
                >
                    {t('brand_selection')}
                </DialogTitleWithClose>
                <DialogContent className={classes.dialogContent}>
                    <BrandList onBrandItemClick={this.handleBrandSelectionItemClick} />
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    brandSelectionDialogShow: state.catalog.brandSelectionDialogShow,
});

const mapDispatchToProps = {
    updateBrandSelectionDialogState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withCatalog,
    withTranslation(),
    withStyles(useStyles),
)
(BrandSelectionDialog);
