import {
    ENQUEUE_SUCCESS_SNACKBAR,
    ENQUEUE_INFO_SNACKBAR,
    ENQUEUE_WARNING_SNACKBAR,
    ENQUEUE_ERROR_SNACKBAR,
    REMOVE_SNACKBAR,
    RESET_ALL_SNACKBARS,
} from './snackbarTypes';

export const enqueueInfoSnackbar = (value) => ({
    type: ENQUEUE_INFO_SNACKBAR,
    value: value,
});

export const enqueueSuccessSnackbar = (value) => ({
    type: ENQUEUE_SUCCESS_SNACKBAR,
    value: value,
});

export const enqueueWarningSnackbar = (value) => ({
    type: ENQUEUE_WARNING_SNACKBAR,
    value: value,
});

export const enqueueErrorSnackbar = (value) => ({
    type: ENQUEUE_ERROR_SNACKBAR,
    value: value,
});

export const removeSnackbar = key => ({
    type: REMOVE_SNACKBAR,
    key,
});

export const resetAllSnackbars = () => ({
    type: RESET_ALL_SNACKBARS,
});