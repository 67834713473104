import CatmetaService from './catmetaService';

class CatmetaDataMapping {

    static getVehicleInfoTabOrder(brandObject) {
        if (!brandObject || !brandObject.pages ||
            !brandObject.pages[CatmetaService.VEHICLE_INFO_KEY] ||
            !brandObject.pages[CatmetaService.VEHICLE_INFO_KEY].segments) {
            return [];
        }
        return brandObject.pages[CatmetaService.VEHICLE_INFO_KEY].segments.map(segment => segment.wid);
    }

    static getDefinitions(brandObject) {
        let definitions = [];

        if (!brandObject || !brandObject.listDefinitions) {
            return definitions;
        }

        // vehicle restrictions and part restrictions
        for (const [key, value] of Object.entries(brandObject.listDefinitions)) {
            definitions[key] = {
                elements: CatmetaDataMapping.mapColumnsOfPage(value.elements),
                // deprecated title
                // title: CatmetaDataMapping.mapPageTitle(value.allSpanTitle, key),
            };

            if(value.elements && value.elements.length && value.elements[0].id === 'caption') {
                definitions[key].title = CatmetaDataMapping.mapPageTitle(value.elements[0].title, key);
            }
        }

        Object.keys(brandObject.pages).forEach(container => {
            if (brandObject.pages[container].widgets) {
                brandObject.pages[container].widgets.forEach((item) => {
                    definitions[item.wid] = {
                        ...definitions[item.wid], ...{
                            title: CatmetaDataMapping.mapPageTitle(item.title, item.wid),
                        },
                    };
                });
            }
            // vehicle overview
            else if (brandObject.pages[container].segments) {
                brandObject.pages[container].segments.forEach(item => {
                    definitions[item.wid] = {
                        ...definitions[item.wid], ...{
                            title: CatmetaDataMapping.mapPageTitle(item.title, item.wid),
                        },
                    };
                });
            }
            // e.g. chemicals
            else if (brandObject.pages[container].title) {
                definitions[container] = {
                    ...definitions[container], ...{
                        title: CatmetaDataMapping.mapPageTitle(brandObject.pages[container].title, container),
                    },
                };
            }
        });

        return definitions;
    }

    static mapPageTitle(title, viewKey) {
        if (title) {
            return title;
        } else {
            // console.warn('View has no title set! ', viewKey)
            return viewKey;
        }
    }

    static mapColumnsOfPage(elements) {
        if (!elements || !Array.isArray(elements)) {
            return [];
        }

        return elements.map(element => {
            return {id: element.id, title: element.title};
        });
    }
}

export default (CatmetaDataMapping);