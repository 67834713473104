import React from 'react';
import CartSelectItem from './cartSelectItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withCartApi from '../../../api/cartApi';
import {withStyles} from '@mui/styles';

const useStyles = theme => ({
    noCarts: {
        fontFamily: theme.typography.fontFamily,
        display: 'flex',
        padding: '16px 32px',
        color: theme.palette.grey700
    },
    list: {
        height: '100%',
        paddingTop: 0,
        paddingBottom: 0
    },
    listItem: {
        overflow: 'hidden',
    },
    listItemActive: {
        backgroundColor: theme.palette.primary.superLight,
    }
});

class CartList extends React.Component {
    handleRenameClick = (e, cart) => {
        e.stopPropagation();

        this.props.onRenameClick(cart);
    };

    handleDeleteClick = (e, cart) => {
        e.stopPropagation();

        this.props.onDeleteClick(cart);
    };

    render() {
        const { t, classes } = this.props;

        return (
            <Box sx={{
                overflow: 'hidden',
                maxHeight: 'calc(100% - 4rem)',
                bgcolor: 'white',
                border: 1,
                borderColor: 'border.main',
            }}>
                <List className={classes.list}>
                    {!this.props.isLoading && !this.props.carts.length &&
                        <span className={classes.noCarts}>
                            {t('cart_list_empty')}
                        </span>}

                    {this.props.carts.map(cart => (
                        <ListItem
                            button
                            className={[classes.listItem, (cart.cartId === this.props.cartContext.cartId ? classes.listItemActive : '')].join(' ')}
                            key={cart.creationTime}
                            onClick={() => this.props.onItemClick(cart)}
                        >
                            <CartSelectItem
                                cartTitle={cart.cartName}
                                cartSubTitle={cart.creationTime}
                                cartLogo={cart.catalogProperties.brandLogoPath}
                            />
                            <Grid item>
                                <Box sx={{
                                    pr: .5,
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <IconButton size="small" onClick={e => this.handleDeleteClick(e, cart)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    <IconButton size="small" onClick={e => this.handleRenameClick(e, cart)}>
                                        <EditIcon/>
                                    </IconButton>
                                </Box>
                            </Grid>
                        </ListItem>
                    ))}
                </List>
            </Box>
        );
    }
}


const mapStateToProps = state => ({
    cart: state.cart.cart,
    cartContext: state.cart.cartContext,
    cartLogo: state.cart.cart.catalogProperties.brandLogoPath,
});

export default compose(
    connect(
        mapStateToProps,
    ),
    withStyles(useStyles),
    withCartApi,
)(CartList);
