import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { Box, InputBase } from '@mui/material';
import SmallIconButton from '../base/smallIconButton';
import { updateSearchQuery } from '../../redux/search/searchAction';
import { routeCatalogPart, routeCatalogVehicle } from '../../config/routeItems';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import withCatalog from '../../service/withCatalog';
import CatalogService from '../../service/catalogService';
import withSnackbar from '../../service/withSnackbar';

const useStyles = theme => ({
    modelSubheader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    smallButton: {
        width: theme.palette.headerIconSize,
        height: theme.palette.headerIconSize,
    },
    smallButtonEnd: {
        justifyContent: 'flex-end',
    },
    fullWidth: {
        width: '100%',
    },
});

class SearchInput extends React.Component {
    constructor(props) {
        super(props);
        const {t} = this.props;

        this.state = {
            isValid: false,
            searchValue: '',
            placeholder: t('search_placeholder_default'),
            minLength: 0,
            maxLength: 100,
            lengthErrorMessage: null,
        };
    }

    componentDidMount() {
        this.getSearchTypeSpecificSettings();
        this.props.updateSearchQuery('');
    }

    onBackClick = () => {
        const {history} = this.props;

        if (history.location.pathname.indexOf('/catalog/part') >= 0) {
            this.props.partHistoryGoBack();
        } else {
            history.goBack();
        }

    };

    onSearchSubmit = () => (event) => {
        event.preventDefault();

        if(!this.state.isValid && this.state.lengthErrorMessage) {
            this.props.enqueueErrorSnackbar(this.state.lengthErrorMessage);
        }

        if (!this.state.isValid) {
            return;
        }

        this.props.updateSearchQuery(this.state.searchValue.trim());
    };

    isValid(searchValue = this.state.searchValue) {
        const currentSearchInputValue = searchValue.trim();
        this.setState({isValid: (currentSearchInputValue && currentSearchInputValue.length >= this.state.minLength)});
    }

    getSearchTypeSpecificSettings() {
        const {t, history} = this.props;

        if (history.location.pathname.indexOf(routeCatalogVehicle.url()) !== -1) {
            if(CatalogService.isMercedes()) {
                this.setState({placeholder: t('search_vehicle_placeholder'), maxLength: 17, minLength: 17, lengthErrorMessage: t('mercedes_length_error_message')});
            } else {
                this.setState({placeholder: t('search_vehicle_placeholder'), maxLength: 17, minLength: 5});
            }
        } else if (history.location.pathname.indexOf(routeCatalogPart.url()) !== -1) {
            this.setState({placeholder: t('search_part_placeholder'), maxLength: 100, minLength: 2});
        }
    }

    handleChange = (event) => {
        let newValue = event.target.value;
        this.setState({searchValue: newValue});
        this.isValid(newValue);
    };

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.modelSubheader}>
                <SmallIconButton onClick={() => this.onBackClick()}>
                    <ArrowBackIcon/>
                </SmallIconButton>

                <Box pt={.5} ml={2} display="flex" alignItems="center" justifyContent="flex-start" flex={1}>
                    {/* action='.' --> to trigger blue ios-search button*/}
                    <form onSubmit={this.onSearchSubmit()} className={classes.fullWidth} action={'.'}>
                        <InputBase
                            type={'search'}
                            autoFocus={true}
                            classes={{
                                root: classes.fullWidth,
                                input: classes.fullWidth,
                            }}
                            placeholder={this.state.placeholder}
                            inputProps={{
                                'aria-label': 'search',
                                maxLength: this.state.maxLength,
                            }}
                            value={this.state.searchValue}
                            onChange={(event) => this.handleChange(event)}
                        />
                    </form>
                </Box>

                <SmallIconButton onClick={this.onSearchSubmit()} insideBox>
                    <SearchIcon color={this.state.lengthErrorMessage || this.state.isValid ? 'inherit' : 'disabled'}/>
                </SmallIconButton>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
    updateSearchQuery: (payload) => dispatch(updateSearchQuery(payload)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
    withCatalog,
    withTranslation(),
    withSnackbar,
    withStyles(useStyles),
)
(SearchInput);
