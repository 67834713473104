import CatmetaDataMapping from './catmetaDataMapping';
import  Api from '../api/api';

class BrandService {

    static getCatalogFromArray(objectArray) {
        if (!Array.isArray(objectArray)) {
            return [];
        }

        return objectArray.map(object => {
            return BrandService.getCatalogFromObject(object);
        });
    }

    static getCatalogFromObject(catalogObject) {
        if (!catalogObject) {
            return null;
        }

        return {
            id: catalogObject['service-name'],
            name: catalogObject.name,
            iconUrl: catalogObject['icon-url'] ?  Api().defaults.baseURL + catalogObject['icon-url'] : '',
            catUrl: catalogObject['cat-url'],
        };
    };

    static getBrandFromObject(brandObject, catalogItem) {
        if (!brandObject) {
            return null;
        }

        return {
            id: catalogItem.id,
            iconUrl: catalogItem.iconUrl,
            name: brandObject.carbrand ? brandObject.carbrand.brandName : '',
            nextWidgetKey: brandObject.catalogEntryPoint ? brandObject.catalogEntryPoint.wid : '',
            link: brandObject.catalogEntryPoint ? brandObject.catalogEntryPoint.path : '',
            definitions: CatmetaDataMapping.getDefinitions(brandObject),
            searchUrl: brandObject.directAccess ? brandObject.directAccess.path : undefined,
            pages: brandObject.pages,
            listDefinitions: brandObject.listDefinitions,
            vehicleInfoTabOrder: CatmetaDataMapping.getVehicleInfoTabOrder(brandObject),
        };
    }
}

export default (BrandService);