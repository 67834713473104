import {
    UPDATE_LOG_IN_STATE,
    UPDATE_ERROR_STATE,
    SESSION_OVER_TAKE_SHOWN,
    SET_USERNAME,
} from './authTypes';
import {
    resetAllCatalog, 
    resetCurrentBomBase, 
    resetCurrentBomBasePart,
    resetCurrentBrand,
    resetCurrentPart, 
    resetCurrentPartRestriction,
    resetCurrentVehicle, 
    resetCurrentVehicleRestriction,
    resetRestrictions
} from '../catalog/catalogAction';
import { resetAllCart } from '../cart/cartAction';
import { resetContentLinks, resetToken } from '../global/globalAction';
import { resetAllSnackbars } from '../snackbar/snackbarAction';
import { resetCatalogDealer } from '../dealerManagement/dealerManagementAction';
import { resetAllSearch, resetPartSearchResults } from '../search/searchAction';

export const updateLogInState = value => {
    return (dispatch) => {
        if (!value) {
            dispatch(resetToken());
            dispatch(clearStore());
        }

        dispatch({ 
            type: UPDATE_LOG_IN_STATE, 
            value: value
        });
    };
};

export const updateErrorState = (value) => ({
    type: UPDATE_ERROR_STATE,
    value: value,
});

export const setSessionOverTakeShown = (value) => ({
    type: SESSION_OVER_TAKE_SHOWN,
    value: value,
});

export const setUsername = value => ({
    type: SET_USERNAME,
    value: value
});

export const clearStore = () => {
    return (dispatch) => {
        dispatch(resetCurrentBrand());
        dispatch(resetCurrentVehicle());
        dispatch(resetRestrictions());
        dispatch(resetCurrentPart());
        dispatch(resetAllSearch());
        dispatch(resetCatalogDealer());
        dispatch(resetCurrentPartRestriction());
        dispatch(resetCurrentVehicleRestriction());
        dispatch(resetAllCatalog());
        dispatch(resetAllCart());
        dispatch(resetCurrentBomBase());
        dispatch(resetCurrentBomBasePart());
        dispatch(resetContentLinks());
        dispatch(resetAllSnackbars());
        dispatch(resetPartSearchResults());
    };
};
