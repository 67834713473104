import colors from './colors';

export const themeName = 'partslink24';

export const typography = {
    fontFamily: '\'Arial\', sans-serif',
    htmlFontSize: 16,
    h6: {
        fontSize: '16px',
        fontWeight: 700,
    }
};

export const palette = {
    type: 'light',
    primary: {
        main: colors.primary, 
        dark: colors.secondary, 
        superLight: colors.tertiary
    },
    secondary: {main: colors.secondary},
    success: {main: colors.success},
    error: {main: colors.error, light: colors.errorPrice},
    warning: {main: colors.warning},
    default: {main: colors.grey100},
    disabled: {main: colors.grey100},
    background: {main: colors.grey100},
    border: {main: colors.grey200},
    white: colors.white,
    black: colors.black,
    grey50: colors.grey50,
    grey100: colors.grey100,
    grey200: colors.grey200,
    grey300: colors.grey300,
    grey700: colors.grey700,
    headerIconSize: '63px',
};

export const shape = {
    borderRadius: 0,
};

export const props = {
    MuiAppBar: {
        color: 'default',
    },
    MuiButton: {
        color: 'primary',
        variant: 'contained',
        fullWidth: true,
    },
    MuiTextField: {
        variant: 'filled',
        fullWidth: true,
        color: 'secondary',
    },
    MuiCheckbox: {
        color: 'secondary',
    },
    MuiSwitch: {
        color: 'primary',
    },
    MuiBadge: {
        color: 'primary',
    },
    MuiFormControl: {
        variant: 'filled',
        fullWidth: true,
        color: 'secondary',
    },
    MuiTooltip: {
        arrow: true,
        disableFocusListener: true,
        enterTouchDelay: 0,
    },
    MuiDialog: {
        fullWidth: true,
    },
    MuiTabs: {
        variant: 'scrollable',
        scrollButtons: 'off'
    },
    MuiListItem: {
        divider: true,
    },
};

export const components = {
    MuiAppBar: {
        styleOverrides: {
            root: {
                backgroundColor: palette.white,
                boxShadow: 'none',
            },
            colorDefault: {
                backgroundColor: palette.white,
            },
        }
    },

    MuiFilledInput: {
        styleOverrides: {
            root: {
                '&.Mui-focused, &:hover:hover': {
                    backgroundColor: palette.white,
    
                    '@media (hover: none)': {
                        backgroundColor: palette.white,
                    },
                },
            },
        }
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                '& ::-webkit-search-cancel-button': {
                    display: 'none',
                },
            },
        }
    },

    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                fontSize: '14px',
            },
            notchedOutline: {
                transition: 'border-color 200ms',
                borderWidth: '1px !important',
            },
        }
    },

    MuiTab: {
        styleOverrides: {
            root: {
                textTransform: 'none',
    
                '&.Mui-selected': {
                    fontWeight: 700,
                    color: palette.black
                },
            },
        }
    },

    MuiButton: {
        styleOverrides: {
            root: {
                fontWeight: 700,
                textTransform: 'none',
                lineHeight: '2',
            },
            contained: {
                '&, &:hover, &:focus': {
                    boxShadow: 'none',
                },
            },
            outlinedPrimary: {
                borderWidth: '2px',
                color: palette.black,
                borderColor: palette.secondary,
                padding: '4.5px 15px',

                '&:hover': {
                    borderWidth: '2px',

                    '@media (hover: none)': {
                        borderWidth: '2px',
                    },
                },

            },
            outlinedSecondary: {
                borderWidth: '2px',
                color: palette.black,
            },
        }
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                marginLeft: 'auto',
            }
        }
    },

    MuiIconButton: {
        styleOverrides: {
            root: {
                color: palette.black,
            },
        }
    },

    MuiBottomNavigation: {
        styleOverrides: {
            root: {
                backgroundColor: palette.primary,
            },
        }
    },

    MuiLink: {
        styleOverrides: {
            root: {
                textDecoration: 'none',
                '&:hover': {
                    textDecoration: 'underline'
                }
            }
        }
    },

    MuiList: {
        styleOverrides: {
            root: {
                overflowX: 'hidden',
            },
        }
    },

    MuiListItem: {
        styleOverrides: {
            root: {
                backgroundColor: palette.white,

                '&:hover:hover': {
                    backgroundColor: palette.white,
                },
            },
            divider: {
                borderBottomColor: palette.grey200,
            },
        }
    },

    MuiBottomNavigationAction: {
        styleOverrides: {
            root: {
                backgroundColor: palette.primary.main,
                color: palette.black,
                minWidth: 'auto',
                transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background 250ms cubic-bezier(0.4, 0, 0.2, 1)',
                flex: '1 1 0',
                width: 0,
                maxWidth: 'none',
                '&.Mui-selected': {
                    color: palette.black,
                    backgroundColor: palette.white,
                },

                '&.Mui-disabled': {
                    '.MuiBadge-root': {
                        opacity: .3,
                    }
                },
            },

            label: {
                maxWidth: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '11px',

                '&.Mui-selected': {
                    fontSize: '11px',
                },
            },
        }
    },


    MuiTabs: {
        styleOverrides: {
            root: {
                backgroundColor: palette.grey50,
            },
            indicator: {
                height: '3px',
            },
        }
    },

    MuiTableHead: {
        styleOverrides: {
            root: {
                borderBottom: '2px solid',
                borderBottomColor: palette.primary,
            },
        }
    },

    MuiTableCell: {
        styleOverrides: {
            root: {
                fontSize: '1rem',
            },
            sizeSmall: {
                fontSize: '0.875rem',
            },
            head: {
                color: palette.grey700,
                fontWeight: 'bold',
            },
        }
    },

    MuiStepper: {
        styleOverrides: {
            root: {
                padding: 2,
            },
        }
    },

    MuiStepIcon: {
        styleOverrides: {
            root: {
                color: palette.grey200,

                '&.Mui-active': {
                    color: palette.black,

                    '& $text': {
                        fill: palette.white,
                    },
                },
            },
        }
    },

    MuiStepLabel: {
        styleOverrides: {
            label: {
                '&.Mui-active': {
                    fontWeight: 700,
                    color: palette.grey700,
                },
            },
        }
    }
};