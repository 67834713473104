import {
    ADD_PART_HISTORY, CANCEL_ADD_PART_HISTORY,
    CLEAR_PART_HISTORY, REMOVE_LAST_PART_HISTORY,
    REMOVE_PART_HISTORY,
    SET_PART_HISTORY
} from './browserHistoryTypes';
import {store} from '../store';

export function addPartHistory(value) {
    const partHistory = store.getState().browserHistory.partHistory;
    if(partHistory[partHistory.length - 1] === value) {
        return {
            type: CANCEL_ADD_PART_HISTORY,
            value: value,
        }
    }
    return {
        type: ADD_PART_HISTORY,
        value: value,
    }
};

export function removeLastPartHistory() {
    return {
        type: REMOVE_LAST_PART_HISTORY
    }
};

export const removePartHistory = (value) => ({
    type: REMOVE_PART_HISTORY,
    value: value,
});

export const clearPartHistory = () => ({
    type: CLEAR_PART_HISTORY
});

export const setPartHistory = (value) => ({
    type: SET_PART_HISTORY,
    value: value,
});