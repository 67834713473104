import React from 'react';
import {
    List,
    ListItem,
    ListItemText
} from '@mui/material';

class SearchResultList extends React.Component {
    getDescription(item) {
        if (item.originalDescription) {
            return item.originalDescription;
        } else if(item.description) {
            return item.description;
        } else {
            return null;
        }
    }

    getListItem(item) {
        const primary = item.vin ? item.vin : this.getDescription(item);
        const secondary = item.vin ? this.getDescription(item) : null;

        return (
            <ListItemText
                primary={primary}
                secondary={secondary}
            />
        );
    }

    render() {
        return (
            <List>
                {this.props.items.map((item, index) => (
                    <ListItem 
                        button 
                        key={`search_result_i${item.vin}_${index}`}
                        onClick={this.props.onItemClick(item)}
                    >
                        {this.getListItem(item)}
                    </ListItem>
                ))}
            </List>
        );
    }
}

export default SearchResultList;