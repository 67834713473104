import {store} from '../redux/store';
import appgtwApi from '../api/appgtwApi';
import {setContentLinks} from '../redux/global/globalAction';

class ContentPageService {
    static loadContentPageLinks() {
        return new Promise((resolve) => {

            if(store.getState().global.contentLinks && store.getState().global.contentLinks.length) {
                // console.log('store-contentLinks', store.getState().global.contentLinks);
                resolve(store.getState().global.contentLinks);
            } else {
                appgtwApi.getContentLinks().then(contentLinks => {
                    // console.log('loaded-contentLinks', contentLinks);
                    store.dispatch(setContentLinks(contentLinks));
                    resolve(contentLinks);
                });
            }
        });
    }
}

export default (ContentPageService);