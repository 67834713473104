import React from 'react';
import {connect} from 'react-redux';
import {
    removeRestrictionsByParentId,
    resetCurrentPart, resetCurrentVehicle,
    updateCurrentBrand,
    updateCurrentVehicleRestriction, updateCurrentVehicleSegments,
} from '../../../redux/catalog/catalogAction';
import VehicleApi from '../../../api/catmetaApi';
import ModelSubheader from '../restriction/restrictionSubheader';
import {withTranslation} from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../base/tabPanel';
import Button from '@mui/material/Button';
import Container from '../../base/container';
import Grid from '@mui/material/Grid';
import {compose} from 'redux';
import withCatalog from '../../../service/withCatalog';
import VehicleService from '../../../service/vehicleService';
import CatmetaService from '../../../service/catmetaService';
import Box from '@mui/material/Box';
import {withRouter} from 'react-router-dom';
import DynamicRestrictionTable from '../dynamicRestrictionTable';
import {removeCrumbByWidgetId} from '../../../redux/crumbs/crumbsAction';
import {withStyles} from '@mui/styles';

const useStyles = theme => ({
    fullHeight: {
        height: '100%',
        // [theme.breakpoints.up('sm')]: {
        //     marginTop: theme.spacing(2)
        // }
    }
})

class VehicleOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabState: 0,
            segments: VehicleService.getVehicleData(this.props.currentVehicle.segments),
        };
    }

    componentDidMount() {
        this.loadVehicle();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.currentVehicle.segments !== this.props.currentVehicle.segments) {
            this.setState({segments: VehicleService.getVehicleData(this.props.currentVehicle.segments)})
        }
    }

    loadVehicle() {
        if (this.props.currentVehicle.link && !Object.keys(this.props.currentVehicle.segments).length) {
            VehicleApi.getSimpleVehicle(this.props.currentVehicle.link, this.props.currentBrand.listDefinitions);
        }
    }

    onBackClick = (restriction) => () => {
        if (!restriction) {
            return;
        }

        this.props.removeRestrictionsByParentId(this.props.currentVehicle.id);
        this.props.removeCrumbByWidgetId(this.props.currentVehicle.nextWidgetKey);
        this.props.resetCurrentVehicle();

        // This is the only condition I found, to distinguish between a selected vehicle and a "Sonderkatalog"
        if (this.props.currentVehicle.originalId === '_SK_') {
            this.props.routeToMainVehicle(true, this.props.currentBrand.id);
        } else if (this.props.restrictions[restriction.parentId]) {
            this.props.routeToModel(restriction.parentId);
        } else {
            this.props.routeToSubModel(restriction.parentId);
        }
    };

    handleTabChange = () => (event, newValue) => {
        this.setState({tabState: newValue});
    };

    getBackLinkCallback() {
        if (this.isVehicleFromSearchResult()) {
            return () => this.props.routeToSearch();
        } else {
            return this.onBackClick(this.props.currentVehicle);
        }
    }

    isVehicleFromSearchResult() {
        return !this.props.currentVehicle.parentId;
    }

    a11yProps(index) {
        return {
            id: `vehicle-detail-tab-${index}`,
            'aria-controls': `vehicle-detail-tabpanel-${index}`,
        };
    }

    getTabs() {
        return this.state.segments?.map((tab, index) => {
            return (
                <Tab
                    label={CatmetaService.getPageTitle(tab.nextWidgetKey)} {...this.a11yProps(index)}
                    key={tab.nextWidgetKey + '-' + index}
                    sx={{
                        '&.Mui-selected': {
                            color: 'black'
                        }
                    }}
                />
            );
        });
    }

    getTabContent() {
        return this.state.segments?.map((segment, index) => (
            <TabPanel
                value={this.state.tabState}
                index={index}
                key={index}
                className={this.props.classes.fullHeight}
            >
                <DynamicRestrictionTable
                    showUnformattedText={segment.showUnformattedText}
                    restrictions={segment.restrictions}
                    widgetKey={segment.nextWidgetKey}
                    onItemClick={null}
                    fullHeight
                />
            </TabPanel>
        ));
    }

    onRouteToPartRootComponentClick() {
        this.props.resetCurrentPart();
        this.props.routeToPartRootComponent()
    }

    render() {
        const { t } = this.props;

        return (
            <Box height="100%" display="flex" flexDirection="column">
                <Container disableGutters={false}>
                    <ModelSubheader
                        title={t('vehicle_detail_title')}
                        onBackClick={this.getBackLinkCallback()}
                    />
                </Container>
                <Container disableGutters={true}>
                    <AppBar position="static">
                        <Tabs
                            value={this.state.tabState}
                            onChange={this.handleTabChange()}
                            aria-label="vehicle detail tabs"
                        >
                            {this.getTabs()}
                        </Tabs>
                    </AppBar>
                </Container>
                <Container paddingX bgWhite overflowHidden>
                    {this.getTabContent()}
                </Container>
                <Container paddingY bgWhite>
                    <Grid container spacing={1} sx={{ display: 'flex' }}>
                        <Grid item xs={12}>
                            {/* route to first page of selected brand NOT homepage */}
                            <Button
                                variant="outlined"
                                onClick={() => this.props.routeToModel(this.props.currentBrand.id)}
                                sx={{ width: '100%' }}
                            >
                                {t('vehicle_detail_reset_vehicle')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={() => this.onRouteToPartRootComponentClick()}
                                sx={{ width: '100%' }}
                            >
                                {t('vehicle_detail_route_to_pieces_catalog')}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    currentVehicle: state.catalog.currentVehicle,
    currentBrand: state.catalog.currentBrand,
    restrictions: state.catalog.restrictions,
    currentVehicleRestriction: state.catalog.currentVehicleRestriction,
});

const mapDispatchToProps = dispatch => ({
    updateCurrentBrand: (payload) => dispatch(updateCurrentBrand(payload)),
    updateCurrentVehicleRestriction: (payload) => dispatch(updateCurrentVehicleRestriction(payload)),
    updateCurrentVehicleSegments: (payload) => dispatch(updateCurrentVehicleSegments(payload)),
    resetCurrentPart: () => dispatch(resetCurrentPart()),
    resetCurrentVehicle: () => dispatch(resetCurrentVehicle()),
    removeRestrictionsByParentId: (payload) => dispatch(removeRestrictionsByParentId(payload)),
    removeCrumbByWidgetId: (payload) => dispatch(removeCrumbByWidgetId(payload)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withRouter,
    withCatalog,
    withStyles(useStyles),
)(VehicleOverview);
