import React from 'react';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import {withStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import {compose} from 'redux';
import withCartApi from '../../../api/cartApi';
import CartItemPrice from './cartItemPrice';
import CartItemAvailability from './cartItemAvailability';

const useStyles = () => ({
    bold: {
        fontWeight: 700,
        hyphens: 'auto',
    },
});

class CartItemReadonly extends React.Component {

    render() {
        const {item, classes, t} = this.props;
        const {
            description,
            lineTotalPretty,
            quantity,
            discountGroup,
            partNumberNormalized,
            availability,
        } = item;

        return (
            <Typography component="div" variant="body2">

                <Grid container spacing={0} direction="row" justifyContent="space-between" alignItems="flex-start">

                    <Grid item xs={5}>
                        <Box fontWeight="fontWeightBold" className={classes.bold}>
                            {description}
                        </Box>
                        <Box mt={1}>
                            {partNumberNormalized}
                        </Box>
                        {discountGroup && <Box mt={1}>
                            {t('discount_group')}: {discountGroup}
                        </Box>}
                    </Grid>

                    <Grid item xs={7}>

                        <Grid container spacing={1} alignItems="flex-start" justifyContent="flex-end">

                            <Grid item xs="auto">
                                <Typography variant="body2">
                                    {quantity} {t('unit_name')}
                                </Typography>
                            </Grid>

                            {availability && <Grid item xs="auto">
                                <CartItemAvailability type={availability}/>
                            </Grid>}

                            <Grid item xs="auto">
                                <CartItemPrice price={lineTotalPretty}/>
                            </Grid>

                        </Grid>


                    </Grid>

                </Grid>

            </Typography>
        );
    }
}


export default compose(
    withCartApi,
    withStyles(useStyles),
)
(CartItemReadonly);
