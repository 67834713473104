import React from 'react';
import UserAgent from '../../service/userAgent';

class Info extends React.Component {
    render() {
        return (
            UserAgent.getAppVersion()
        );
    }
}

export default (Info);
