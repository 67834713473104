import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography
} from '@mui/material';
import Container from '../base/container';
import { updateCatalogs } from '../../redux/catalog/catalogAction';
import { updateErrorState, updateLogInState } from '../../redux/auth/authAction';
import withAuthApi from '../../api/authApi';
import { routeLogin } from '../../config/routeItems';
import { trackPromise } from 'react-promise-tracker';
import { loadingAreas } from '../../config/loadingAreas';
import { PASSWORD_FORGOTTEN_URL } from '../../config/urlConfig';
import ContentPageService from '../../service/contentPageService';
import appgtwApi from '../../api/appgtwApi';
import BrandService from '../../service/brandService';
import i18n from '../../i18n';
import withSnackbar from '../../service/withSnackbar';
import SessionInUseDialog from '../base/sessionInUseDialog';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.refForm = React.createRef();

        this.state = {
            checkValid: false,
            showPassword: false,
            account: localStorage.getItem('accountId') || '',
            maxInputLength: 16,
            user: '',
            pwd: '',
        };
    }

    componentDidMount() {
        this.props.updateLogInState(false);
        this.props.updateErrorState(false);
        this.changeUrlToLoginUrl();

        this.refForm.current.focus();
    }

    changeUrlToLoginUrl() {
        let {history} = this.props;

        if (history) {
            history.push(routeLogin.url());
        }
    }

    isValid() {
        return this.state.account !== '' && this.state.user !== '' && this.state.pwd !== '';
    }

    isPasswordLengthValid() {
        return this.state.pwd.length <= this.state.maxInputLength;
    }

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword});
    };

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            checkValid: true,
        });

        if (!this.isValid()) {
            return;
        }

        if(!this.isPasswordLengthValid()) {
            this.props.enqueueErrorSnackbar(i18n.t('error_on_login_pw_to_long', {maxLength: this.state.maxInputLength}));
            return;
        }

        this.login();
    };

    login(forceLogin = false) {
        trackPromise(
            new Promise((resolve, reject) => {
                this.props.login(this.state.account, this.state.user, this.state.pwd, forceLogin)
                    .then(() => {
                        Promise.all([
                            appgtwApi.getCatalogs(),
                            ContentPageService.loadContentPageLinks(),
                        ]).then((response) => {
                            const catalogs = BrandService.getCatalogFromArray(response[0]);
                            this.props.updateCatalogs(catalogs);

                            this.props.updateLogInState(true);
                            resolve();

                        }).catch((error) => {
                            console.error('Error happened on after-login-process', error);
                            resolve();
                        });
                    }).catch(() => reject());
            })
            , loadingAreas.global);
    }

    render() {
        const { t } = this.props;

        return (
            <Container>
                <Box py={4}>
                    <form
                        autoComplete="on"
                        ref={this.refForm}
                        onSubmit={this.handleSubmit}
                    >
                        <Box mb={3}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="filled"
                                        value={this.state.account}
                                        onChange={(event) => {
                                            this.setState({account: event.target.value});
                                            localStorage.setItem('accountId', event.target.value);
                                        }}
                                        error={(this.state.checkValid && this.state.account === '') || this.props.authError}
                                        id="account"
                                        inputProps={{maxLength: this.state.maxInputLength}}
                                        label={t('login_form_account')}
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        variant="filled"
                                        value={this.state.user}
                                        onChange={e => this.setState({ user: e.target.value })}
                                        error={(this.state.checkValid && this.state.user === '') || this.props.authError}
                                        id="username"
                                        inputProps={{maxLength: this.state.maxInputLength}}
                                        label={t('login_form_user')}
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="filled"
                                        value={this.state.pwd}
                                        onChange={e => this.setState({ pwd: e.target.value })}
                                        error={(this.state.checkValid && this.state.pwd === '') || this.props.authError}
                                        id="password"
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        label={t('login_form_password')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={this.handleClickShowPassword}>
                                                        {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>

                            </Grid>
                        </Box>

                        <Box sx={{ 
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <Typography sx={{ mb: 2 }}>
                                <Link
                                    href={PASSWORD_FORGOTTEN_URL}
                                    target="_blank"
                                    sx={{ 
                                        textDecoration: 'none',
                                        '&:hover': {
                                            textDecoration: 'underline'
                                        }
                                    }}>
                                    {t('login_form_password-forgotten')}
                                </Link>
                            </Typography>
                            <Button
                                variant="contained" 
                                type="submit"
                                disabled={!this.isValid()}
                                sx={{ width: '100%' }}
                            >
                                {t('login_form_submit')}
                            </Button>
                        </Box>
                    </form>
                </Box>
                <SessionInUseDialog onConfirm={() => this.login(true)} />
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    authError: state.auth.error,
    devEnv: state.global.devEnv,
});

const mapDispatchToProps = {
    updateLogInState,
    updateErrorState,
    updateCatalogs,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
    withTranslation(),
    withAuthApi,
    withSnackbar
)
(Login);
