import React from 'react';
import {Link} from 'react-router-dom';
import {withStyles} from '@mui/styles';
import {routeBrandSelection} from '../../../config/routeItems';
import {compose} from 'redux';

const useStyles = () => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    logo: {
        height: '22px',
    },
});

class DefaultHeader extends React.Component {
    render() {
        const {classes} = this.props;

        return (
            <div className={classes.wrapper}>
                <Link to={routeBrandSelection.url} className={classes.logo}>
                    <img src={process.env.PUBLIC_URL + '/logo.png'}
                         className={classes.logo}
                         alt='partslink24'
                    />
                </Link>
            </div>
        );
    }
}

export default compose(
    withStyles(useStyles),
)(DefaultHeader);
