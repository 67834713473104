import React, {Fragment} from 'react';
import ModelSubheader from '../restriction/restrictionSubheader';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import 'split.js';
import {withStyles} from '@mui/styles';
import {compose} from 'redux';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '../../base/tabPanel';
import {withTranslation} from 'react-i18next';
import withCatalog from '../../../service/withCatalog';
import Container from '../../base/container';
import Box from '@mui/material/Box';
import PartItem from './partInformationTabs/partItem';
import CatmetaService from '../../../service/catmetaService';
import ListView from './partInformationTabs/listView';
import ValidityMatrix from './partInformationTabs/validityMatrix';
// import CommunityImages from './partInformationTabs/communityImages/communityImages';

const useStyles = () => ({
    scrollX: {
        overflowX: 'auto'
    }
});

class PartInformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tabState: 0,
            inlineSegments: [],
            tabSegments: [],
        };
    }

    componentDidMount() {
        const { t, currentPartInformation } = this.props;
        const segmentKeys = currentPartInformation.segments ? Object.keys(currentPartInformation.segments) : [];
        let segmentsToShow = [];

        // add custom tab "Part"
        if (currentPartInformation?.records?.length) {
            segmentsToShow.push({
                title: t('part_info_view_tab_part'),
                definition: 'pwa_partInfo',
                wid: 'pwa_partInfo',
            });
        }

        // TODO: What to do with empty title within special cases / Review Sarah
        const nextWidgetKey = currentPartInformation.title ? currentPartInformation.title : 'partinfo'; // title === wid
        const catMetaPages = CatmetaService.getPageByNextWidgetKey(nextWidgetKey);

        catMetaPages.segments.forEach(tab => {
            if (segmentKeys.indexOf(tab.wid) !== -1) {
                const segment = this.props.currentPartInformation.segments[tab.wid];

                segmentsToShow.push({...tab, ...segment});
            }
        });

        //todo check if definiton or wid?
        /*
        Mail von Herr Schilling
        im RichClient wird immer die „wid“ verwendet.
        „definition“ ist fast immer identisch zur „wid“, aber ist nicht als ID zu verstehen.
        Die Idee ist, dass es auch mal ein Widget geben kann mit dergleichen „definition“, aber
        anderem „title“ oder sonstigen Unterschieden.
         */
        // add listDefintion to segment
        segmentsToShow.map(tab => {
            return tab.listDefintion = CatmetaService.getListWidgetByNextWidgetKey(tab.definition);
        });

        // split to inline and tab segment
        const inlineSegments = [];
        const tabSegments = [];

        segmentsToShow.forEach(segment => {
            const listDefintion = segment.listDefintion;

            if (!listDefintion) {
                tabSegments.push(segment);
                return;
            }

            if (listDefintion.actionset === 'orderable') {
                inlineSegments.push(segment);
            } else {
                tabSegments.push(segment);
            }
        });

        this.setState({inlineSegments: inlineSegments});
        this.setState({tabSegments: tabSegments});
    }

    handleTabChange = () => (event, newValue) => {
        this.setState({tabState: newValue});
    };

    onBackClick = () => () => {
        // const {history} = this.props;
        // this.props.routeToPartOverview(true);
        this.props.partHistoryGoBack();
    };

    getTabContent(tab) {
        const { currentPartInformation } = this.props;
        const listDefinition = tab.listDefintion;

        if (tab.wid === 'pwa_partInfo' && currentPartInformation.records && currentPartInformation.records.length) {
            return <Fragment>
                <PartItem 
                    item={currentPartInformation.records[0]}
                    messages={currentPartInformation.messages}
                    inlineSegments={this.state.inlineSegments}
                    segments={currentPartInformation.segments}
                />
                {/* <CommunityImages item={currentPartInformation.records[0]} /> */}
            </Fragment>
        }

        if (tab.wid === 'codeValidityMatrix' && tab.direct) {
            return <ValidityMatrix data={tab.direct}/>
        }

        if (!listDefinition) {
            return;
        }

        if (listDefinition.type === 'listView' && listDefinition.actionset === 'none') {
            return <ListView showEmptyHeaders={true} data={tab}/>;
        }
    }

    render() {
        const { t, classes } = this.props;

        return (
            <Box sx={{
                height: '100%',
                display: 'flex', 
                flexDirection: 'column'
            }}>
                <Container>
                    <ModelSubheader
                        onBackClick={this.onBackClick()}
                        title={t('part_information_title')}
                    />
                </Container>
                <Container disableGutters>
                    <AppBar position="static">
                        <Tabs
                            value={this.state.tabState}
                            onChange={this.handleTabChange()}
                            aria-label="part info tabs">
                            {this.state.tabSegments.map((tab, i) => 
                                <Tab key={i}
                                    id={`part-info-tab-${i}`}
                                    label={tab.title}
                                    aria-controls={`part-intro-tabpanel-${i}`}
                                    sx={{ color: 'black' }}
                                />
                            )}
                        </Tabs>
                    </AppBar>
                </Container>
                <Container className={classes.scrollX} paddingX paddingY bgWhite>
                    {this.state.tabSegments.map((tab, i) => (
                        <TabPanel value={this.state.tabState} index={i} key={i}>
                            {this.getTabContent(tab)}
                        </TabPanel>
                    ))}
                </Container>
            </Box>
        );
    }
}

const mapStateToProps = state => ({
    currentPartInformation: state.catalog.currentPartInformation,
    currentBrand: state.catalog.currentBrand,
});

const mapDispatchToProps = dispatch => ({});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withTranslation(),
    withCatalog,
    withRouter,
    withStyles(useStyles),
)
(withRouter(PartInformation));
