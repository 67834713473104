import React from 'react';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import Grid from '@mui/material/Grid';
import NumberField from '../../base/numberField';
import {compose} from 'redux';
import withCartApi from '../../../api/cartApi';
import CartItemDelete from './cartItemDelete';
import CartItemPrice from './cartItemPrice';
import CartItemInfo from './cartItemInfo';
import CartItemEdit from './cartItemEdit';
import CartItemAvailability from './cartItemAvailability';


class CartItem extends React.Component {

    handleCartItemUpdate = (cartItemId, newValue) => {
        this.props.setCartItemQuantity(cartItemId, newValue).then(() => {
            this.props.updateCurrentCart();
        });
    };

    render() {
        const {item} = this.props;
        const {
            description,
            quantity,
            partNumberNormalized,
            cartItemId,
            listPricePretty,
            discountPercentPretty,
            unitPricePretty,
            discountGroup,
            availability,
        } = item;

        return (
            <Typography component="div" variant="body2">

                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" wrap="nowrap" spacing={2}>
                    <Grid item xs="6">
                        <Box fontWeight="fontWeightBold">
                            {description}
                        </Box>
                        <Box>
                            {partNumberNormalized}
                        </Box>
                    </Grid>

                    <Grid item xs="auto">

                        <Grid container>
                            {availability && <Grid item xs="auto">
                                <CartItemAvailability type={availability}/>
                            </Grid>}

                            <Grid item xs="auto">
                                <Box fontWeight="fontWeightBold">
                                    <CartItemPrice price={listPricePretty}
                                                   discountPrice={unitPricePretty !== listPricePretty ? unitPricePretty : null}/>
                                </Box>
                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>

                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item xs={6}>
                        <Box mr={1} display="inline-block">
                            <CartItemInfo
                                discountPercentPretty={discountPercentPretty}
                                listPrice={listPricePretty}
                                unitPrice={unitPricePretty}
                                discountGroup={discountGroup}
                                comment={item.comment}
                            />
                        </Box>
                        <Box display="inline-block">
                            <CartItemEdit item={item}/>
                        </Box>
                    </Grid>

                    <Grid item xs={6}>
                        <Grid container direction="row" justifyContent="flex-end" alignItems="center">

                            <Box width={90} mr={3} display="inline-block">
                                <NumberField change={(newValue) => this.handleCartItemUpdate(cartItemId, newValue)}
                                             value={quantity} min={1} withDebounce={true}
                                />
                            </Box>

                            <CartItemDelete item={item}/>

                        </Grid>
                    </Grid>
                </Grid>

            </Typography>
        );
    }
}


export default compose(
    withCartApi,
)
(CartItem);
