import React, {Fragment} from 'react';
import Button from '@mui/material/Button';
import CartSelect from './select/cartSelect';
import CartRow from './partial/cartRow';
import CartItem from './item/cartItem';
import CartAvailabilityLegend from './item/cartAvailabilityLegend';
// import AddPart from './partial/addPart';
import CartTotal from './partial/cartTotal';
import {withTranslation} from 'react-i18next';
import {compose} from 'redux';
import {connect} from 'react-redux';
import Container from '../base/container';
import Box from '@mui/material/Box';
import {setCartContext, setDealerDialogState} from '../../redux/cart/cartAction';
import Grid from '@mui/material/Grid';
import withCartApi from '../../api/cartApi';
import {withStyles} from '@mui/styles';

const useStyles = () => ({
    overflowAuto: {
        overflow: 'auto'
    }
})

class StepCart extends React.Component {
    handleToCheckout = () => {
        this.props.handleNext();
    };

    handleCreateNewCart = () => () => {
        this.props.createNewCart(this.props.currentBrand.id).then(newCart => {
            if (newCart) {
                this.props.setCartContext(newCart);
            }
        });
    };

    render() {
        const {t, cart, items, classes} = this.props;

        return (
            <Fragment>
                <Container overflowHidden limitHeight>
                    <CartSelect/>

                    <Box className={classes.overflowAuto}>
                        {items.map((item) => (
                            <CartRow key={item.cartItemId}>
                                <CartItem item={item}/>
                            </CartRow>
                        ))}

                        {cart.dealerProperties && parseInt(cart.dealerProperties.dealerId) > 0 && (
                            <CartRow>
                                <CartAvailabilityLegend/>
                            </CartRow>
                        )}

                        {/* <AddPart/> */}
                        <CartTotal cart={cart} />
                    </Box>
                </Container>
                <Container paddingY>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Button
                                onClick={this.handleCreateNewCart()}>
                                {t('create_new_cart')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {<Button onClick={this.handleToCheckout}>
                                <Box> {t('cart_to_checkout')}</Box>
                            </Button>}
                        </Grid>
                    </Grid>
                </Container>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    cart: state.cart.cart,
    items: state.cart.cart.items,
    hasDealer: state.cart.dealer.dealerId > 0,
    dealerId: state.cart.dealer.dealerId,
    currentBrand: state.catalog.currentBrand,
});

const mapDispatchToProps = {
    setCartContext,
    setDealerDialogState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withCartApi,
    withTranslation(),
    withStyles(useStyles)
)
(StepCart);
