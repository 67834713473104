export const overrideTheme = baseTheme => {
    baseTheme.overrides = {
        MuiContainer: {
            root: {
                [baseTheme.breakpoints.between('sm', 'xl')]: {
                    paddingLeft: baseTheme.spacing(2),
                    paddingRight: baseTheme.spacing(2),
                },
            },
        },
        MuiFormControl: {
            root: {
                '.MuiOutlinedInput-notchedOutline legend span': {
                    paddingRight: baseTheme.spacing(2.25)
                }
            }
        }
    };

    return baseTheme;
};