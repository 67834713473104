import React from 'react';
import Grid from '@mui/material/Grid';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import CartRow from './cartRow';
import {Typography, Box} from '@mui/material';
import {withStyles} from '@mui/styles';

const useStyles = () => ({
    alignRight: {
        marginLeft: 'auto',
    },
});

// TODO: Refactor, can be removed
class cartTotal extends React.Component {

    getGrid(label, value) {
        const {classes} = this.props;

        return (
            <Grid container justifyContent="space-between">
                <Grid item>
                    {label}
                </Grid>
                <Grid className={classes.alignRight}>
                    {value}
                </Grid>
            </Grid>
        );
    }

    render() {
        const {t, cart} = this.props;
        const {
            subTotalNetPretty,
            taxAmountPretty,
            taxRatePretty,
            totalDiscountPretty,
            totalGrossPretty,
            totalNetPretty,
            onlineDiscountAmountPretty,
        } = cart;

        return (
            <Typography variant="body2" component="div">
                <CartRow>
                    {subTotalNetPretty && (this.getGrid(t('cart_total_sub_net'), subTotalNetPretty))}
                    {onlineDiscountAmountPretty && (this.getGrid(t('cart_total_online_discount_amount'), onlineDiscountAmountPretty))}
                    {totalNetPretty && (this.getGrid(t('cart_total_net'), totalNetPretty))}
                    {taxAmountPretty && (this.getGrid([t('cart_total_tax_add'), taxRatePretty, t('cart_total_tax_abb')].join(' '), taxAmountPretty))}
                </CartRow>

                {totalGrossPretty && (
                    <CartRow>
                        <Box fontWeight="fontWeightBold">
                            {this.getGrid(t('cart_total_gross'), totalGrossPretty)}
                        </Box>
                    </CartRow>
                )}

                {totalDiscountPretty && (this.getGrid(t('cart_total_discount'), totalDiscountPretty))}
            </Typography>
        );
    }

}

export default compose(
    withTranslation(),
    withStyles(useStyles),
)
(cartTotal);
