import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { 
    Box,  
    IconButton, 
    TableCell
} from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import FormattedText from '../../text/formattedText';
import CatalogService from '../../../service/catalogService';
import CatmetaService from '../../../service/catmetaService';
import { updateCurrentPartRestriction } from '../../../redux/catalog/catalogAction';

import withCatalog from '../../../service/withCatalog';
import RestrictionService from '../../../service/restrictionService';
import { palette } from '../../../theme/baseTheme';

class PartOverviewTableCell extends React.Component {

    constructor(props) {
        super(props);
        this.cellConfig = {
            'partno': {'style': {'minWidth': '105px'}},
            'pos': {'align': 'right'},
            'qty': {'align': 'right'},
        };
        this.state = {extendable: false, extended: false}
        this.tableCellTextRef = React.createRef();
        this.onItemClick = this.onItemClick.bind(this);
    }

    componentDidMount() {
        if((this.props.column.id === 'restriction' || this.props.column.id === 'restrictions') && this.tableCellTextRef.current) {
            this.setState({extendable: CatalogService.isCellOverflown(this.tableCellTextRef.current)})
        }
    }

    getConfig(columnId) {
        const configs = this.cellConfig[columnId.toString()];
        if (!configs) {
            return null;
        }
        return configs;
    }

    onItemClick(record) {
        if (record.values && record.values.description
            && record.values.description.wid && record.values.description.wid.toLowerCase() === CatmetaService.SPRING_TRIGGER.toLowerCase()) {
            this.props.updateCurrentPartRestriction(RestrictionService.getRestrictionsFromObject(record.values.description));
            this.props.routeToSpringOverview();
            return;
        }

        this.props.onItemClick(record)
    }

    render() {
        const { column, record, onItemDoubleClick } = this.props;
        let recordValue = record.values[column.id];

        if (!recordValue) {
            return <TableCell/>;
        }

        if (recordValue.objType === 'link') {
            recordValue = recordValue.caption;
        }

        const styleCell = { verticalAlign: 'top' };

        if (record.unavailable || record.validity === 'INVALID') {
            styleCell.color = palette.grey300;
        }

        if (record.hotspotId || record.values?.description?.objType === 'link') {
            styleCell.cursor = 'pointer';
        }

        const styleText = column.id === 'restriction' || column.id === 'restrictions' ? {
            maxHeight: '100px',
            display: 'block',
            overflow: 'hidden'
        } : {};

        return (
            <TableCell 
                // className={cellClass}
                {...this.getConfig(column.id)}
                onDoubleClick={onItemDoubleClick(record)}
                onClick={this.onItemClick.bind(this, record)}
                sx={styleCell}
            >
                <Box sx={styleText} ref={this.tableCellTextRef}>
                    <FormattedText value={recordValue} formatTabs />
                </Box>
                {this.state.extendable && <label htmlFor="icon-arrow-downward">
                    <IconButton
                        color="primary" 
                        aria-label="extend text"
                        component="span" 
                        onClick={() => this.setState({extended: !this.state.extended})}
                        sx={{
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                    }}>
                        {this.state.extended ? <ArrowUpward/> : <ArrowDownward/> }
                    </IconButton>
                </label>}
            </TableCell>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    updateCurrentPartRestriction: (payload) => dispatch(updateCurrentPartRestriction(payload))
});

export default compose(
    connect(
        mapDispatchToProps,
    ),
    withCatalog,
)(PartOverviewTableCell);
