import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {compose} from 'redux';
import {Box} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListSubheader from '@mui/material/ListSubheader';
import {withTranslation} from 'react-i18next';


class DispatchTypeSelect extends React.Component {

    render() {
        const {t, dispatchTypes, currentDispatchTypeId, onSelect, readonly} = this.props;

        return (
            <Box border={1} borderColor="border.main" borderTop={0} borderLeft={0} borderRight={0}>

                <List
                    dense
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {t('cart_dispatch_typ_title')}
                        </ListSubheader>
                    }
                >
                    {dispatchTypes &&
                    dispatchTypes.map((dispatchType) => {
                        return (
                            <ListItem
                                key={dispatchType.id}
                                button
                                onClick={() => onSelect(dispatchType.id)}
                                disabled={readonly}>

                                {
                                    dispatchType.id === currentDispatchTypeId && (
                                        <ListItemIcon>
                                            <CheckCircleIcon/>
                                        </ListItemIcon>
                                    )
                                }

                                <ListItemText
                                    primary={dispatchType.namePretty}
                                    inset={dispatchType.id !== currentDispatchTypeId}
                                />

                            </ListItem>
                        );
                    })
                    }
                </List>

            </Box>
        );
    }

}


export default compose(
    withTranslation(),
)(DispatchTypeSelect);

