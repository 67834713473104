import React from 'react';
import CartTitle from './cartTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import DateTimeService from '../../../service/dateTimeService';

class CartBackHeader extends React.Component {

    render() {
        return (
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={3}>
                    <IconButton onClick={this.props.handleBack}>
                        <ArrowBackIcon/>
                    </IconButton>
                </Grid>

                <Grid item xs={6}>
                    <Typography align="center" component="div">
                        <CartTitle cartTitle={this.props.cart.cartName}
                                   cartSubTitle={DateTimeService.commaSeparatedDateTime(this.props.cart.creationTime)}/>
                    </Typography>
                </Grid>

                <Grid item xs={3}/>
            </Grid>
        );
    }

}

export default CartBackHeader;
