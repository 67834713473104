import {REMOVE_CRUMB_BY_WIDGET_ID, RESET_CRUMBS, UPDATE_CRUMBS} from './crumbsTypes';
import {clearPartHistory} from '../browserHistory/browserHistoryAction';

export const updateCrumbs = (value) => ({
    type: UPDATE_CRUMBS,
    value: value,
});

export const resetCrumbs = () => {
    return (dispatch) => {
        dispatch(clearPartHistory());
        dispatch({type: RESET_CRUMBS});
    };
};

export const removeCrumbByWidgetId = (value) => {
    return (dispatch) => {
        dispatch({type: REMOVE_CRUMB_BY_WIDGET_ID, value: value});
    };
};