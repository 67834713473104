import React from 'react';
import {compose} from 'redux';
import {Box} from '@mui/material';
import Container from '../../base/container';

class CartHeaderContainer extends React.Component {
    render() {
        return (
            <Box p={1} bgcolor="grey200">
                <Container>
                    {this.props.children}
                </Container>
            </Box>
        );
    }
}

export default compose()(CartHeaderContainer);
