import React from 'react';
import {compose} from 'redux';
import BrandItem from './brandItem';
import {updateBrandSelectionDialogState} from '../../redux/catalog/catalogAction';
import {connect} from 'react-redux';

class BrandSelectionTrigger extends React.Component {
    handleBrandItemClick = () => {
        this.props.updateBrandSelectionDialogState(true);
    };

    render() {
        return (
            <BrandItem
                brand={this.props.currentBrand}
                onClick={this.handleBrandItemClick}
            />
        );
    }
}

const mapStateToProps = state => ({
    currentBrand: state.catalog.currentBrand,
});

const mapDispatchToProps = {
    updateBrandSelectionDialogState,
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
)(BrandSelectionTrigger);