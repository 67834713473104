import {
    UPDATE_CATALOGS,
    SET_RESTRICTIONS,
    UPDATE_CURRENT_BRAND,
    UPDATE_CURRENT_VEHICLE_RESTRICTION,
    UPDATE_CURRENT_PART_RESTRICTION,
    UPDATE_CURRENT_VEHICLE,
    UPDATE_CURRENT_PART,
    RESET_CURRENT_BRAND,
    RESET_CURRENT_PART_RESTRICTION,
    RESET_CURRENT_VEHICLE_RESTRICTION,
    RESET_CURRENT_PART,
    RESET_CURRENT_VEHICLE,
    UPDATE_CURRENT_PART_OVERVIEW,
    RESET_CURRENT_PART_OVERVIEW,
    RESET_ALL_CATALOG,
    UPDATE_CURRENT_PART_INFORMATION,
    UPDATE_BRAND_SELECTION_DIALOG_STATE,
    RESET_CURRENT_HOTSPOT,
    RESET_CURRENT_TOPHEIGHT,
    UPDATE_CURRENT_HOTSPOT,
    UPDATE_CURRENT_TOPHEIGHT,
    UPDATE_CURRENT_VEHICLE_SEGMENTS,
    UPDATE_CURRENT_VEHICLE_SEARCH,
    UPDATE_SELECTED_PART,
    RESET_SELECTED_PART,
    UPDATE_BOM_BASE_TRIGGER_ITEMS,
    SET_TOP_RIGHT_BUTTON,
    RESET_TOP_RIGHT_BUTTON,
    RESET_RESTRICTIONS,
    SET_LAST_BRAND_CHANGE,
    REMOVE_RESTRICTIONS,
    UPDATE_CURRENT_PART_DETAIL_OVERVIEW,
    UPDATE_CURRENT_PART_OVERVIEW_STATE,
    RESET_CURRENT_PART_DETAIL_OVERVIEW,
    UPDATE_CURRENT_PART_PATH,
    REMOVE_RESTRICTIONS_BY_PARENT_ID,
    RESET_CURRENT_BOMBASE,
    UPDATE_CURRENT_BOMBASE,
    UPDATE_CURRENT_BOMBASE_PART,
    RESET_CURRENT_BOMBASE_PART,
    SET_COMMUNITY_IMAGES,
    SET_READ_ONLY_COMMUNITY_IMAGES,
    SET_OWN_IMAGES,
    UPDATE_COMMUNITY_IMAGE,
    REMOVE_COMMUNITY_IMAGE
} from './catalogTypes';

const initialState = {
    catalogs: [],
    restrictions: [],
    topRightButton: {
        hasTopRightButton: false,
        currentMode: 'MINUS',
    },
    currentVehicleRestriction: {values: {}},
    currentPartRestriction: {values: {}},
    lastBrandChange: 0,
    currentBrand: {
        definitions: [],
        listDefinitions: {},
        vehicleInfoTabOrder: [],
        pages: {
            bom: {
                image: {}
            }
        }
    },
    currentPart: {values: {}},
    communityImages: [],
    readOnlyImages: [],
    ownImages: [],
    currentPartPath: '',
    currentVehicle: {values: {}, segments: {}},
    currentPartOverview: {
        records: [],
        images: [],
    },
    currentPartDetailOverview: {
        records: [],
    },
    currentPartOverviewState: 'overview',
    currentHotspot: null,
    currentBomBase: {records: []},
    currentBomBasePart: {values: {}},
    selectedPart: {}, // selected on partOverview
    currentTopHeight: null,
    currentPartInformation: null,
    brandSelectionDialogShow: false,
    bomBaseTriggerItems: [],
};

export default function catalogReducer(state = initialState, action) {
    let currentImageIndex = -1;
    let newImages = [];

    switch (action.type) {
        case UPDATE_CATALOGS:
            return {
                ...state,
                catalogs: action.value
            };
        case SET_RESTRICTIONS:
            return {...state, restrictions: [...action.value]};
        case SET_LAST_BRAND_CHANGE:
            return {...state, lastBrandChange: action.value};
        case SET_TOP_RIGHT_BUTTON:
            return {...state, topRightButton: {...action.value}};
        case UPDATE_CURRENT_BRAND:
            return {
                ...state,
                currentBrand: {
                    ...action.value,
                    definitions: {...action.value.definitions},
                    vehicleInfoTabOrder: [...action.value.vehicleInfoTabOrder],
                    pages: {...action.value.pages},
                },
            };

        case UPDATE_CURRENT_VEHICLE_RESTRICTION:
            return {
                ...state,
                currentVehicleRestriction: {
                    ...action.value,
                    values: {...action.value.values},
                },
            };
        case UPDATE_CURRENT_PART_RESTRICTION:
            return {
                ...state,
                currentPartRestriction: {
                    ...action.value,
                    values: {...action.value.values},
                },
            };
        case UPDATE_CURRENT_VEHICLE:
            const segments = action.value.segments ? action.value.segments : {};
            return {
                ...state,
                currentVehicle: {
                    ...action.value,
                    values: {...action.value.values},
                    segments: {...segments},
                },
            };
        case UPDATE_CURRENT_VEHICLE_SEGMENTS:
            return {
                ...state,
                currentVehicle: {
                    ...state.currentVehicle,
                    segments: {...action.value},
                },
            };
        case UPDATE_CURRENT_VEHICLE_SEARCH:
            return {
                ...state,
                currentVehicle: {
                    ...state.currentVehicle,
                    searchUrl: action.value,
                },
            };
        case UPDATE_CURRENT_PART:
            return {
                ...state,
                currentPart: {
                    ...action.value,
                    values: {...action.value.values},
                },
            };
        case UPDATE_BOM_BASE_TRIGGER_ITEMS:
            return {...state, bomBaseTriggerItems: [...action.value]};
        case UPDATE_CURRENT_PART_INFORMATION:
            return {...state, currentPartInformation: {...action.value}};
        case UPDATE_CURRENT_PART_PATH:
            return {...state, currentPartPath: action.value};
        case UPDATE_CURRENT_PART_DETAIL_OVERVIEW:
            return {...state, currentPartDetailOverview: {...action.value}};
        case UPDATE_CURRENT_PART_OVERVIEW_STATE:
            return {...state, currentPartOverviewState: action.value};
        case UPDATE_CURRENT_PART_OVERVIEW:
            return {...state, currentPartOverview: {...action.value}};
        case UPDATE_CURRENT_HOTSPOT:
            return {...state, currentHotspot: action.value};
        case UPDATE_CURRENT_TOPHEIGHT:
            return {...state, currentTopHeight: action.value};
        case UPDATE_SELECTED_PART:
            return {...state, selectedPart: action.value};
        case UPDATE_CURRENT_BOMBASE:
            return {...state, currentBomBase: action.value};
        case UPDATE_CURRENT_BOMBASE_PART:
            return {...state, currentBomBasePart: action.value};
        case SET_COMMUNITY_IMAGES:
            return {...state, communityImages: action.value};
        case SET_READ_ONLY_COMMUNITY_IMAGES:
            return {...state, readOnlyImages: action.value};
        case SET_OWN_IMAGES:
            return {...state, ownImages: action.value};
        case UPDATE_COMMUNITY_IMAGE:
            currentImageIndex = state.communityImages.findIndex(image => image.id === action.value.id);
            newImages = [];

            // Image isn't found in 'communityImages'
            if (currentImageIndex === -1) {
                currentImageIndex = state.ownImages.findIndex(image => image.id === action.value.id);

                // Image isn't found altogether
                if (currentImageIndex === -1) {
                    return state;
                }

                newImages = state.ownImages.splice(currentImageIndex, 1, action.value)

                // console.info('ownImages (new):', newImages)

                return { ...state, ownImages: newImages };
            }

            newImages = state.communityImages.splice(currentImageIndex, 1, action.value)

            // console.info('communityImages (new):', newImages)

            return { ...state, communityImages: newImages };
        case REMOVE_COMMUNITY_IMAGE:
            newImages = state.communityImages.filter(a => a.id !== action.value);

            if (state.communityImages.length !== newImages.length) {
                return { ...state, communityImages: newImages };
            } else {
                newImages = state.ownImages.filter(a => a.id !== action.value);

                return { ...state, ownImages: newImages };
            }
        case RESET_RESTRICTIONS:
            return {...state, restrictions: [...initialState.restrictions]};
        case REMOVE_RESTRICTIONS:
            let nextWidgetKey = action.value;
            return {...state, restrictions: state.restrictions.filter((obj) => obj.nextWidgetKey !== nextWidgetKey)};
        case REMOVE_RESTRICTIONS_BY_PARENT_ID:
            let parentId = action.value;
            return {...state, restrictions: state.restrictions.filter((obj) => obj.parentId !== parentId)};
        case RESET_TOP_RIGHT_BUTTON:
            return {...state, topRightButton: {...initialState.topRightButton}};
        case RESET_CURRENT_BRAND:
            return {...state, currentBrand: {...initialState.currentBrand}};
        case RESET_CURRENT_VEHICLE:
            return {...state, currentVehicle: {...initialState.currentVehicle}};
        case RESET_CURRENT_PART:
            return {...state, currentPart: {...initialState.currentPart}};
        case RESET_CURRENT_PART_DETAIL_OVERVIEW:
            return {
                ...state,
                currentPartDetailOverview: {...initialState.currentPartDetailOverview},
                currentPartOverviewState: initialState.currentPartOverviewState
            };
        case RESET_CURRENT_VEHICLE_RESTRICTION:
            return {...state, currentVehicleRestriction: {...initialState.currentVehicleRestriction}};
        case RESET_CURRENT_PART_RESTRICTION:
            return {...state, currentPartRestriction: {...initialState.currentPartRestriction}};
        case RESET_CURRENT_PART_OVERVIEW:
            return {...state, currentPartOverview: {...initialState.currentPartOverview}};
        case RESET_CURRENT_HOTSPOT:
            return {...state, currentHotspot: {...initialState.currentHotspot}};
        case RESET_CURRENT_TOPHEIGHT:
            return {...state, currentPartOverview: {...initialState.currentTopHeight}};
        case RESET_SELECTED_PART:
            return {...state, selectedPart: initialState.selectedPart};
        case RESET_CURRENT_BOMBASE:
            return {...state, currentBomBase: initialState.currentBomBase};
        case RESET_CURRENT_BOMBASE_PART:
            return {...state, currentBomBasePart: initialState.currentBomBasePart};
        case UPDATE_BRAND_SELECTION_DIALOG_STATE:
            return {...state, brandSelectionDialogShow: action.value};

        case RESET_ALL_CATALOG:
            return initialState;

        default:
            return state;
    }
}
