import React from 'react';
import {
    updateCurrentVehicleRestriction,
    updateCurrentVehicle,
    updateCurrentPart,
    resetCurrentBomBasePart,
    updateCurrentBomBasePart,
} from '../../../redux/catalog/catalogAction';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import CatmetaService from '../../../service/catmetaService';
import ModelSubheader from '../restriction/restrictionSubheader';
import CatalogService from '../../../service/catalogService';
import {compose} from 'redux';
import Container from '../../base/container';
import withCatalog from '../../../service/withCatalog';
import DynamicRestrictionTable from '../dynamicRestrictionTable';

class SubVehicleRestriction extends React.Component {

    constructor(props) {
        super(props);

        this.onRestrictionClick = this.onRestrictionClick.bind(this);
    }

    componentDidMount() {
        const {history} = this.props;
        this.unregisterHistoryListener = history.listen(() => this.onHistoryChange());

        this.loadSubRestrictions(this.props.currentVehicleRestriction);
    }

    componentWillUnmount() {
        if (this.unregisterHistoryListener) {
            this.unregisterHistoryListener();
        }
    }

    onHistoryChange() {
        this.forceUpdate(() => {
            const restrictionByURL = CatalogService.getRestrictionById(this.props.match.params.restrictionId);
            this.props.updateCurrentVehicleRestriction({...{}, ...restrictionByURL});
            this.loadSubRestrictions(restrictionByURL);
        });
    }

    loadSubRestrictions(currentVehicleRestriction) {
        CatalogService.loadSubRestriction(currentVehicleRestriction);
    }

    onBackClick = (restriction) => () => {
        if (restriction.parentId === this.props.currentBrand.id) {
            this.props.routeToModel(restriction.parentId);

        } else {
            this.props.routeToSubModel(CatalogService.getParentRestriction(restriction.parentId).id);
        }
    };

    onRestrictionClick(restriction) {
        if (CatalogService.isVehicleIdentified(restriction)) {
            this.props.updateCurrentVehicle(restriction);
            this.props.routeToVehicleOverview();
        } else if (CatmetaService.isBomBaseWidget(restriction.nextWidgetKey.toLowerCase())) {
            this.props.resetCurrentBomBasePart();
            this.props.updateCurrentBomBasePart(restriction);
            this.props.routeToBomBaseOverview();
        } else {
            this.props.routeToSubModel(restriction.id);
        }
    };

    render() {
        return (
            <Container paddingX limitHeight>
                <ModelSubheader
                    title={CatmetaService.getPageTitle(this.props.currentVehicleRestriction?.nextWidgetKey)}
                    onBackClick={this.onBackClick(this.props.currentVehicleRestriction)}
                />
                <DynamicRestrictionTable
                    restrictions={CatalogService.getRestrictionsByParent(this.props.restrictions, this.props.currentVehicleRestriction?.id)}
                    widgetKey={this.props.currentVehicleRestriction?.nextWidgetKey}
                    onItemClick={this.onRestrictionClick}
                />
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    restrictions: state.catalog.restrictions,
    currentBrand: state.catalog.currentBrand,
    currentVehicleRestriction: state.catalog.currentVehicleRestriction,
});

const mapDispatchToProps = dispatch => ({
    updateCurrentVehicleRestriction: (payload) => dispatch(updateCurrentVehicleRestriction(payload)),
    updateCurrentVehicle: (payload) => dispatch(updateCurrentVehicle(payload)),
    updateCurrentPart: (payload) => dispatch(updateCurrentPart(payload)),
    updateCurrentBomBasePart: (payload) => dispatch(updateCurrentBomBasePart(payload)),
    resetCurrentBomBasePart: (payload) => dispatch(resetCurrentBomBasePart(payload)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withRouter,
    withCatalog,
)
(SubVehicleRestriction);
