import React, {Fragment} from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {withStyles} from '@mui/styles';

const useStyles = (theme) => ({
    outlinedInput: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    input: {
        textAlign: 'center',
    },
    button: {
        padding: 0,
    },
    inputAdornedStart: {
        paddingLeft: `${theme.spacing(.25)} !important`,
    },
    inputAdornedEnd: {
        paddingRight: `${theme.spacing(.25)} !important`,
    },
    svgIcon: {
        fontSize: '21px',
    },
});

class NumberField extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ? this.props.value : 0,
            lastValidValue: this.props.value ? this.props.value : 0,
            min: this.props.min ? this.props.min : 0,
            max: this.props.max ? this.props.max : 999,
        };
    }

    handleClickDown = () => {
        const newValue = this.state.value - 1;
        this.setState({value: newValue});
        this.submitChange(newValue);
    };

    handleClickUp = () => {
        const newValue = this.state.value + 1;
        this.setState({value: newValue});
        this.submitChange(newValue);
    };

    handleChange = (newValue) => {
        if (newValue.match('.')) {
            newValue = parseInt(newValue);
        }

        if (newValue !== '' && !Number.isInteger(newValue)) {
            return;
        }

        if (newValue > this.state.max) {
            return;
        }

        this.setState({value: newValue});
        this.submitChange(newValue);
    };

    submitChange(newValue) {
        if (newValue > this.state.max || newValue < this.state.min) {
            return;
        }

        if (this.props.withDebounce) {
            clearTimeout(this.timeoutHandleChange);
            this.timeoutHandleChange = setTimeout(() => {
                this.setState({lastValidValue: newValue});
                this.props.change(newValue);
            }, 500);

        } else {
            this.setState({lastValidValue: newValue});
            this.props.change(newValue);
        }
    }

    handleBlur = () => {
        const value = this.state.value;
        if (value > this.state.max || value < this.state.min) {
            this.setState({value: this.state.lastValidValue});
        } else {
            this.submitChange(value);
        }
    };

    render() {
        const { classes } = this.props;
        const { value, min, max } = this.state;

        return (
            <Fragment>
                <TextField
                    value={value}
                    onChange={e => this.handleChange(e.target.value)}
                    onBlur={() => this.handleBlur()}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        classes: {
                            root: classes.outlined,
                            input: classes.input,
                            adornedEnd: classes.inputAdornedEnd,
                            adornedStart: classes.inputAdornedStart,
                        },
                        startAdornment: (
                            <InputAdornment position="start">
                                <IconButton 
                                    disabled={value <= min} 
                                    className={classes.button}
                                    onClick={() => this.handleClickDown()}
                                >
                                    <RemoveIcon className={classes.svgIcon} />
                                </IconButton>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton 
                                    disabled={value >= max} 
                                    className={classes.button}
                                    onClick={() => this.handleClickUp()}
                                >
                                    <AddIcon className={classes.svgIcon}/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                >
                </TextField>
            </Fragment>
        );
    }
}

export default withStyles(useStyles)(NumberField);
