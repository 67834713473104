import React from 'react';
import {compose} from 'redux';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';


class DialogTitleWithClose extends React.Component {

    render() {

        return (
            <Grid container direction="row" justifyContent="space-between" wrap="nowrap" spacing={0}>
                <Grid item xs="auto">
                    <MuiDialogTitle>
                        {this.props.children}
                    </MuiDialogTitle>
                </Grid>
                <Grid item xs="auto">
                    {this.props.onClose && (
                        <IconButton aria-label="close" onClick={this.props.onClose}>
                            <CloseIcon/>
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        );
    }
}

export default compose()(DialogTitleWithClose);
