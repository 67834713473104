import React from 'react';
import Container from '../../base/container';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {
    addItemToSelectHistory,
    addPartSearchResults,
    resetPartSearchResults,
    setPartSearchResults,
    updateSearchDialogState,
    updateSearchQuery
} from '../../../redux/search/searchAction';
import withSearchApi from '../../../api/searchApi';
import SearchDialog from '../searchDialog';
import {updateCurrentPart, updateSelectedPart} from '../../../redux/catalog/catalogAction';
import withCatalog from '../../../service/withCatalog';
import LoadingBackdrop from '../../base/loadingBackdrop';
import {loadingAreas} from '../../../config/loadingAreas';
import {withRouter} from 'react-router-dom';
import SearchResultTable from './searchResultTable';
import {withTranslation} from 'react-i18next';
import PartService from '../../../service/partService';

class SearchPart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pagination: '',
            isFetching: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.searchQuery && prevProps.searchQuery !== this.props.searchQuery) {
            this.handleSearchQueryChange();
        }
    }
    handleSearchQueryChange = () => {
        this.props.resetPartSearchResults();
        this.props.searchPart().then((response) => {
            this.onSearchEnd(response);
        });
    };

    handlePagination() {
        if (!this.pagination) {
            return;
        }
        if(this.state.isFetching) {
            return;
        }

        this.setState({isFetching: true})

        this.props.searchPart(this.pagination, false).then((response) => {
            this.pagination = this.getPagination(response);

            if(!response.data){
                return;
            }

            const additionalResults = response.data.records.map(item => PartService.getPartFromObject(item)).filter(i => i.link !== '');
            this.props.addPartSearchResults(additionalResults);
            this.setState({isFetching: false});

            // todo prefill until scroll is possible (only for desktop necessary so its optional)
            // load pagination until there is enough content to get a scrollbar body

            // if(this.pagination && window.offsetHeight >= document.height){
            //     this.handlePagination();
            // }
        });
    };

    onSearchEnd(response) {
        this.pagination = this.getPagination(response);

        if (this.isMessageOnly(response)) {
            this.showMessage(response.messages);
            return;
        }

        if (this.hasMessage(response)) {
            this.showMessage(response.messages);
        }

        if (this.isMultipleElementResponse(response)) {
            this.props.setPartSearchResults(response.data.records.map(item => PartService.getPartFromObject(item)).filter(i => i.link !== ''))

            if (this.pagination) {
                // load pagination once to get more initial result
                this.handlePagination();
            }

            return;
        }

        console.warn('Response with new Content', response);
    }

    handleScroll = ($event) => {
        const currentScrollTop = $event.target.scrollTop;


        if (currentScrollTop > this.lastScrollTop &&
            $event.target.offsetHeight + $event.target.scrollTop >= $event.target.scrollHeight - 50) {
            this.handlePagination();
        }

        this.lastScrollTop = currentScrollTop;
    };

    getPagination(response) {
        return response.data && response.data.nextPagePath ? response.data.nextPagePath : '';
    }

    isMultipleElementResponse(response) {
        return response.data && response.data.records;
    }

    isMessageOnly(response) {
        return this.hasMessage(response) && !this.isMultipleElementResponse(response);
    }

    hasMessage(response) {
        return response.messages && response.messages.length;
    }

    showMessage(messages) {
        const msg = messages.join('. ');
        this.props.updateSearchDialogState(msg);
    }

    handleRouteToPartOverview = (part) => () => {
        this.props.updateCurrentPart(part);
        this.props.updateSelectedPart(part);
        this.props.routeToPartOverview();
    };

    render() {
        return (
            <Container paddingX overflow maxHeight>
                <SearchResultTable searchResults={this.props.partSearchResults}
                                   handleScroll={this.handleScroll}
                                   onRowClick={this.handleRouteToPartOverview}/>
                <SearchDialog/>

                <LoadingBackdrop area={loadingAreas.search}/>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    searchQuery: state.search.searchQuery,
    currentBrand: state.catalog.currentBrand,
    partSearchResults: state.search.partSearchResults,
});

const mapDispatchToProps = dispatch => ({
    updateSearchQuery: (payload) => dispatch(updateSearchQuery(payload)),
    addItemToSelectHistory: (payload) => dispatch(addItemToSelectHistory(payload)),
    updateSearchDialogState: (payload) => dispatch(updateSearchDialogState(payload)),
    updateCurrentPart: (payload) => dispatch(updateCurrentPart(payload)),
    updateSelectedPart: (payload) => dispatch(updateSelectedPart(payload)),
    setPartSearchResults: (payload) => dispatch(setPartSearchResults(payload)),
    addPartSearchResults: (payload) => dispatch(addPartSearchResults(payload)),
    resetPartSearchResults: (payload) => dispatch(resetPartSearchResults(payload)),
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withSearchApi,
    withCatalog,
    withTranslation(),
    withRouter,
)
(SearchPart);
